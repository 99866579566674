import type { TableColumn } from '@zen/DesignSystem';
import {
  chargeTypeColumn,
  defaultChargeDetailsColumn,
  getActionsColumn,
  getCargoOptionsColumn,
  getChargeBasisColumn,
  getChargeNameColumn,
  getCurrencyColumn,
  getLocationColumn,
  getUnitPriceColumn
} from '@zen/RateCards/components/helpers';
import type { RateCardCharge } from '@zen/RateCards/reducer';

import type { ChargeLocationType, ChargeTableAction, LocationType } from '../../components/types';

export const getPortChargeTableColumns = (
  actions: ChargeTableAction,
  isEditable: boolean = false,
  type: LocationType
): TableColumn<RateCardCharge>[] => {
  const { onUpdate } = actions;
  const locationKey: ChargeLocationType = type === 'origin' ? 'fromLocation' : 'toLocation';
  const unitPriceColumnTitle: string = isEditable ? 'Custom price' : 'Unit price';
  const currencyColumnTitle: string = isEditable ? 'Custom currency' : 'Currency';

  return [
    getLocationColumn(locationKey, `Port of ${type}`, { width: 600 }),
    ...(isEditable ? [chargeTypeColumn] : []),
    getChargeNameColumn(isEditable, onUpdate),
    getChargeBasisColumn(isEditable, onUpdate),
    getCargoOptionsColumn(isEditable, onUpdate),
    ...(isEditable ? [defaultChargeDetailsColumn] : []),
    getUnitPriceColumn(isEditable, onUpdate, unitPriceColumnTitle),
    getCurrencyColumn(isEditable, onUpdate, currencyColumnTitle),
    ...(isEditable ? [getActionsColumn(actions)] : [])
  ];
};
