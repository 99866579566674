import cx from 'classnames';
import type { ReactNode } from 'react';

import type { Option } from '@zen/DesignSystem';
import { Chip, Tooltip } from '@zen/DesignSystem';
import { useRateCardContext } from '@zen/RateCards/RateCardContext';
import type { ArrayElement } from '@zen/utils/typescript';

interface Props<T> {
  content: ReactNode;
  isDisabled: boolean;
  isSelectAll: boolean;
  selectAllLabel: string;
  selectedOptions: Option<ArrayElement<T>>[];
  variant: 'default' | 'inline';
}

const RateCardMultiSelectLabel = <T extends {}>({
  content,
  isDisabled,
  selectAllLabel,
  isSelectAll,
  selectedOptions,
  variant
}: Props<T>) => {
  const { isEditable } = useRateCardContext();

  const isInline: boolean = variant === 'inline';
  const className: string = cx(
    {
      'bg-navy-light': !isInline && isEditable,
      'bg-transparent hover:bg-transparent': isInline || isDisabled
    },
    'text-grey-dark'
  );

  const tooltipContent: ReactNode = (
    <div className="space-y-1">
      {selectedOptions.slice(1).map((option: Option<ArrayElement<T>>, index: number) => (
        <div key={index}>{option.label}</div>
      ))}
    </div>
  );

  const getContent = (): ReactNode => {
    const shouldShowEmptyValue: boolean = !selectedOptions.length && (!isEditable || (isDisabled && isInline));

    if (shouldShowEmptyValue) {
      return '-';
    }

    if (!selectedOptions.length && isEditable) {
      return 'Select...';
    }

    if (isSelectAll) {
      return selectAllLabel;
    }

    return content;
  };

  const renderElement = (): ReactNode => {
    const elementContent: ReactNode = getContent();
    const hasAtLeastOneOptionSelected: boolean = isSelectAll || selectedOptions.length === 1;

    if (!selectedOptions.length) {
      return <div className="w-full text-sm text-grey-light">{elementContent} </div>;
    }

    if (hasAtLeastOneOptionSelected && isInline) {
      return elementContent;
    }

    const chip: ReactNode = <Chip className={className} label={elementContent} size="compact" />;

    if (!isInline) {
      return chip;
    }

    const tooltipElement: ReactNode = !isInline ? chip : elementContent;

    return (
      <Tooltip placement="right" tooltipContent={tooltipContent}>
        {tooltipElement}
      </Tooltip>
    );
  };

  const classNames: string = cx({
    'px-1': isEditable && !!selectedOptions.length
  });

  return (
    <div className={classNames} data-testid="rate-card-multi-select-label">
      {renderElement()}
    </div>
  );
};

export type { Props as RateCardMultiSelectLabelProps };

export default RateCardMultiSelectLabel;
