import { useLocation } from 'react-router-dom';

import useRole from '@zen/Auth/hooks/useRole';
import { Role } from '@zen/Networks';
import { SortingOrder } from '@zen/types';
import type { PaginatedQueryResult } from '@zen/utils/hooks/pagination';
import { usePagination } from '@zen/utils/hooks/pagination';
import useAccount from '@zen/utils/hooks/useAccount';
import type { QueryParams } from '@zen/utils/QueryParams';
import { parseQueryParams } from '@zen/utils/QueryParams/queryParams';

import { prepareFilterVariables } from '../components/Filters/helpers';
import type { OrderListQueryResult, OrderListQueryVariables } from '../graphql';
import { useOrderListQuery } from '../graphql';
import type { OrderFilters, OrderListViewItem } from '../types';

interface Params {
  availableForBooking?: string;
  availableForCargo?: string;
  onlyOwnAccount?: boolean;
  withLotCountsForCargo?: boolean;
}

const useOrdersListQuery = (
  params: Params = {},
  filters: OrderFilters = {}
): PaginatedQueryResult<OrderListQueryResult, OrderListQueryVariables, OrderListViewItem> => {
  const { availableForBooking, availableForCargo, onlyOwnAccount = false, withLotCountsForCargo = false } = params;
  const { accountUuid: accountId } = useAccount();
  const { search } = useLocation();
  const { onlyCurrentSupplier, textContains }: QueryParams = parseQueryParams(search);
  const role = useRole();

  const getFilter = () => {
    if (onlyOwnAccount || role === Role.ADMIN) {
      return { customerUuids: accountId ? [accountId] : undefined };
    }

    if (onlyCurrentSupplier) {
      return { manufacturerIds: [accountId] };
    }

    return {};
  };

  const results = usePagination<OrderListQueryResult, OrderListQueryVariables, OrderListViewItem>(
    useOrderListQuery,
    'purchaseOrders',
    {
      ...prepareFilterVariables(filters),
      ...getFilter(),
      availableForBooking,
      availableForCargo,
      textContains: textContains ? textContains.toString() : '',
      order: {
        field: 'createdAt',
        direction: SortingOrder.DESC
      },
      withLotCountsForCargo
    },
    20,
    { fetchPolicy: 'cache-and-network', nextFetchPolicy: 'cache-first' }
  );

  return results;
};

export default useOrdersListQuery;
