export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date in YYYY-MM-DD format */
  Date: string;
  DateTime: string;
  /** An ISO 8601-encoded date */
  ISO8601Date: string;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: string;
  /** An ISO 8601-encoded OffsetDateTime */
  ISO8601OffsetDateTime: string;
  /** A time in ISO8601 time format (hh:mm:ss) */
  ISO8601Time: string;
  /** Official timezone id formatted as 'Europe/London' */
  TimeZone: string;
};

export interface ApiConsoleGlobalPermissions {
  __typename?: 'APIConsoleGlobalPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
}

/** Return payload types */
export interface AcceptInvitePayload {
  __typename?: 'AcceptInvitePayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of AcceptQuoteOption */
export interface AcceptQuoteOptionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteOptionId: Scalars['String'];
}

/** Autogenerated return type of AcceptQuoteOption */
export interface AcceptQuoteOptionPayload {
  __typename?: 'AcceptQuoteOptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quoteOption?: Maybe<QuoteOption>;
}

export enum AccessControlledResourceName {
  ANALYTICS = 'analytics',
  APICONSOLE = 'apiConsole',
  BOOKINGS = 'bookings',
  CONTACTS = 'contacts',
  FREIGHTSPEND = 'freightSpend',
  INTAKEPLANNING = 'intakePlanning',
  LOCATIONS = 'locations',
  ORDERS = 'orders',
  PARTNERS = 'partners',
  PRODUCTS = 'products',
  QUOTES = 'quotes',
  SHIPMENTS = 'shipments',
  UTILISATION = 'utilisation',
  WEBHOOKS = 'webhooks',
  WORKFLOWS = 'workflows'
}

export enum AccessLevel {
  ADMIN = 'admin',
  MEMBER = 'member'
}

export interface Account extends NetworksAssignableInterface {
  __typename?: 'Account';
  businessHours?: Maybe<BusinessHours>;
  businessUnits: Array<Account>;
  canCreateBooking: Scalars['Boolean'];
  demoMode: Scalars['Boolean'];
  hasBusinessUnitsThatSupplyEachOther?: Maybe<Scalars['Boolean']>;
  /** Account identifier */
  id: Scalars['String'];
  label?: Maybe<NetworksLabelType>;
  legalInformation: AccountLegalInformation;
  parentAccountId?: Maybe<Scalars['String']>;
  permissions?: Maybe<AccountPermissions>;
  referencePrefix: Scalars['String'];
  ssoDetails: SsoDetails;
  status: AccountStatus;
  timeZone?: Maybe<Scalars['String']>;
  tradingName: Scalars['String'];
  type: AccountType;
  /** set of user groups (roles) belonging to an account */
  userGroups: Array<AccountUserGroup>;
}

export interface AccountBillingDetails {
  __typename?: 'AccountBillingDetails';
  billingAddress: Address;
  billingContact: AccountContactDetails;
}

/** Types */
export interface AccountConnection {
  __typename?: 'AccountConnection';
  /** A list of edges. */
  edges: Array<Maybe<AccountEdge>>;
  /** A list of nodes. */
  nodes: Array<Maybe<Account>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total amount of elements. */
  totalCount: Scalars['Int'];
}

export interface AccountConnectionType {
  __typename?: 'AccountConnectionType';
  account: Account;
  connectionType: AccountConnectionTypeEnum;
}

/** Types */
export interface AccountConnectionTypeConnection {
  __typename?: 'AccountConnectionTypeConnection';
  /** A list of edges. */
  edges: Array<Maybe<AccountConnectionTypeEdge>>;
  /** A list of nodes. */
  nodes: Array<Maybe<AccountConnectionType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total amount of elements. */
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface AccountConnectionTypeEdge {
  __typename?: 'AccountConnectionTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AccountConnectionType>;
}

export enum AccountConnectionTypeEnum {
  CUSTOMER = 'CUSTOMER',
  SUPPLIER = 'SUPPLIER'
}

/** Inputs */
export interface AccountConnectionsFiltersInput {
  connectionSourceTarget: ConnectionSourceTargetEnum;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
}

export interface AccountContactDetails {
  __typename?: 'AccountContactDetails';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
}

export interface AccountContactDetailsInput {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
}

/** An edge in a connection. */
export interface AccountEdge {
  __typename?: 'AccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Account>;
}

export interface AccountGlobalPermissions {
  __typename?: 'AccountGlobalPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
  canActivate?: Maybe<AuthorizationResult>;
  canCreateActivityFeed?: Maybe<AuthorizationResult>;
  canCreateBusinessUnits?: Maybe<AuthorizationResult>;
  canCreateLocations?: Maybe<AuthorizationResult>;
  canCreateRateCards?: Maybe<AuthorizationResult>;
  canDeactivate?: Maybe<AuthorizationResult>;
  canDeleteRateCards?: Maybe<AuthorizationResult>;
  canEditBusinessUnits?: Maybe<AuthorizationResult>;
  canSwitch?: Maybe<AuthorizationResult>;
  canUpdateAccountDetails?: Maybe<AuthorizationResult>;
  canUpdateDemoMode?: Maybe<AuthorizationResult>;
  canUpdateLegalDetails?: Maybe<AuthorizationResult>;
  canViewAccountSettings?: Maybe<AuthorizationResult>;
  canViewActivityFeed?: Maybe<AuthorizationResult>;
  canViewAuthentication?: Maybe<AuthorizationResult>;
  canViewBusinessUnits?: Maybe<AuthorizationResult>;
  canViewRateCards?: Maybe<AuthorizationResult>;
  isViewOnlyUser?: Maybe<AuthorizationResult>;
}

export interface AccountLegalInformation {
  __typename?: 'AccountLegalInformation';
  companyRegistrationNumber: Scalars['String'];
  eoriNumber: Scalars['String'];
  registeredAddress: AccountLocation;
  registeredName: Scalars['String'];
  vatIdentificationNumber: Scalars['String'];
}

export interface AccountLegalInformationInput {
  companyRegistrationNumber: Scalars['String'];
  eoriNumber: Scalars['String'];
  registeredName: Scalars['String'];
  vatIdentificationNumber: Scalars['String'];
}

export interface AccountLocation extends CostTrackingLocation, NetworksAssignableInterface {
  __typename?: 'AccountLocation';
  address: Address;
  addressLabel: Scalars['String'];
  businessHours?: Maybe<BusinessHours>;
  companyName: Scalars['String'];
  geolocation?: Maybe<Geolocation>;
  id: Scalars['String'];
  isArchived: Scalars['Boolean'];
  isRegisteredLegalAddress: Scalars['Boolean'];
  label?: Maybe<NetworksLabelType>;
  name: Scalars['String'];
  permissions?: Maybe<LocationPermissions>;
  timeZone?: Maybe<Scalars['String']>;
  usageContext: UsageContext;
}

export interface AccountLocationConnection {
  __typename?: 'AccountLocationConnection';
  /** A list of edges. */
  edges: Array<Maybe<AccountLocationEdge>>;
  /** A list of nodes. */
  nodes: Array<Maybe<AccountLocation>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total amount of elements. */
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface AccountLocationEdge {
  __typename?: 'AccountLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AccountLocation>;
}

export interface AccountLocationInput {
  address: AddressInput;
  businessHours: BusinessHoursInput;
  companyName: Scalars['String'];
  geolocation: GeolocationInput;
  name: Scalars['String'];
  usageContext: UsageContext;
}

export interface AccountLocationsFiltersInput {
  companies?: InputMaybe<Array<Scalars['String']>>;
  hqOnly?: InputMaybe<Scalars['Boolean']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  locationIds?: InputMaybe<Array<Scalars['String']>>;
  searchQuery?: InputMaybe<Scalars['String']>;
  usageContexts?: InputMaybe<Array<UsageContext>>;
}

export interface AccountMember {
  __typename?: 'AccountMember';
  account: Account;
  assignedRoles: Array<AccountUserGroup>;
  businessUnits: Array<Account>;
  canSwitchAccounts: Scalars['Boolean'];
  email: Scalars['String'];
  featureFlags: Array<CurrentUserFeatureFlag>;
  firstName: Scalars['String'];
  fullName: Scalars['String'];
  id: Scalars['String'];
  invitationStatus: InvitationStatus;
  isLinkedToAllBusinessUnits: Scalars['Boolean'];
  isLinkedToAllLocations: Scalars['Boolean'];
  isProfileCompleted: Scalars['Boolean'];
  lastName: Scalars['String'];
  locations: Array<AccountLocation>;
  phoneNumber: Scalars['String'];
}

export type AccountMemberFeatureFlagsArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};

/** Types */
export interface AccountMemberConnection {
  __typename?: 'AccountMemberConnection';
  /** A list of edges. */
  edges: Array<Maybe<AccountMemberEdge>>;
  /** A list of nodes. */
  nodes: Array<Maybe<AccountMember>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total amount of elements. */
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface AccountMemberEdge {
  __typename?: 'AccountMemberEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AccountMember>;
}

export interface AccountMembersFiltersInput {
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  memberIds?: InputMaybe<Array<Scalars['String']>>;
  searchQuery?: InputMaybe<Scalars['String']>;
}

export interface AccountPermissions {
  __typename?: 'AccountPermissions';
  bookings?: Maybe<BookingAccountPermissions>;
  insights?: Maybe<InsightsAccountPermissions>;
  products?: Maybe<ProductAccountPermissions>;
  purchaseOrders?: Maybe<PurchaseOrderAccountPermissions>;
}

export interface AccountSettings {
  __typename?: 'AccountSettings';
  canViewStaleDataTracking: AuthorizationResult;
  staleDataTracking?: Maybe<StaleDataSettings>;
}

/** Enums */
export enum AccountStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING'
}

export enum AccountType {
  DIVISION = 'DIVISION',
  PARENT = 'PARENT',
  SUBSIDIARY = 'SUBSIDIARY'
}

export interface AccountUserGroup {
  __typename?: 'AccountUserGroup';
  accountId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  isDefaultRole: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  name?: Maybe<Scalars['String']>;
  userGroupId: Scalars['String'];
}

export interface Accounts {
  __typename?: 'Accounts';
  canAccessRoute: AuthorizationResult;
  canCreateAccount: AuthorizationResult;
  canSwitch: AuthorizationResult;
}

export interface AccountsFiltersInput {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  excludeCurrentAccount?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Array<AccountStatus>>;
}

export interface Accrual {
  __typename?: 'Accrual';
  adjustedExchangeRates?: Maybe<Array<AdjustedExchangeRate>>;
  canArchive: AuthorizationResult;
  canPublish: AuthorizationResult;
  canUpdate: AuthorizationResult;
  canUpdateInvoicedDate: AuthorizationResult;
  costItems: Array<CostItem>;
  exchangeRateAppliedAt?: Maybe<RichDateTime>;
  id: Scalars['String'];
  invoiceCurrency?: Maybe<Scalars['String']>;
  invoiceExchangeRate: Scalars['Float'];
  invoiceRevenue: Scalars['Float'];
  invoicedOn?: Maybe<Scalars['Date']>;
  publishedAt?: Maybe<RichDateTime>;
  publishedBy?: Maybe<UpdatedBy>;
  rawExchangeRates?: Maybe<Array<ExchangeRate>>;
  revenueDate?: Maybe<Scalars['Date']>;
  state: AccrualState;
  totalCost: Scalars['Float'];
  totalMargin: Scalars['Float'];
  totalMarginPercentage?: Maybe<Scalars['Float']>;
  totalRevenue: Scalars['Float'];
  updatedAt: RichDateTime;
  updatedBy?: Maybe<UpdatedBy>;
  updatedByUserAt?: Maybe<RichDateTime>;
  zencargoReference: Scalars['String'];
}

export interface AccrualInput {
  costItems: Array<CostItemInput>;
  invoiceCurrency?: InputMaybe<Scalars['String']>;
  invoiceExchangeRate: Scalars['Float'];
  invoiceRevenue: Scalars['Float'];
  totalCost: Scalars['Float'];
  totalMargin: Scalars['Float'];
  totalMarginPercentage?: InputMaybe<Scalars['Float']>;
  totalRevenue: Scalars['Float'];
}

export enum AccrualState {
  ARCHIVED = 'archived',
  DRAFT = 'draft',
  PUBLISHED = 'published',
  QUOTE_OPTION = 'quote_option'
}

export interface AccrualTemplate {
  __typename?: 'AccrualTemplate';
  id: Scalars['String'];
  templateName: Scalars['String'];
}

/** The connection type for AccrualTemplate. */
export interface AccrualTemplateConnection {
  __typename?: 'AccrualTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AccrualTemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<AccrualTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface AccrualTemplateEdge {
  __typename?: 'AccrualTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<AccrualTemplate>;
}

export interface Accruals {
  __typename?: 'Accruals';
  canAccessRoute: AuthorizationResult;
  canCreate: AuthorizationResult;
}

export interface ActionItem {
  __typename?: 'ActionItem';
  /** Description */
  description: Scalars['String'];
  /** Due Date */
  dueDate: Scalars['Date'];
  /** Unique identifier */
  id: Scalars['String'];
  /** Name */
  name: Scalars['String'];
  /** Resolved Date */
  resolvedAt?: Maybe<Scalars['Date']>;
  /** Status */
  status: ActionItemStatus;
  /** Title */
  title: Scalars['String'];
  /** Type */
  type: ActionItemActionType;
}

export enum ActionItemActionType {
  APPROVAL = 'approval',
  FILEUPLOAD = 'fileupload',
  TEXTINPUT = 'textinput'
}

export enum ActionItemStatus {
  CANCELLED = 'cancelled',
  PENDING = 'pending',
  RESOLVED = 'resolved'
}

export interface ActionItemTypesActionItemType {
  __typename?: 'ActionItemTypesActionItemType';
  actionType: ActionItemActionType;
  description: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
  title: Scalars['String'];
}

/** Autogenerated input type of ActivateAccount */
export interface ActivateAccountInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ActivateAccount */
export interface ActivateAccountPayload {
  __typename?: 'ActivateAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  id: Scalars['String'];
}

export interface ActivateInactiveAccountPayload {
  __typename?: 'ActivateInactiveAccountPayload';
  errors?: Maybe<Array<Error>>;
}

export interface ActivatePendingAccountPayload {
  __typename?: 'ActivatePendingAccountPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of ActivateQuoteOption */
export interface ActivateQuoteOptionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  expiryDate: Scalars['Date'];
  quoteOptionId: Scalars['String'];
}

/** Autogenerated return type of ActivateQuoteOption */
export interface ActivateQuoteOptionPayload {
  __typename?: 'ActivateQuoteOptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quoteOption?: Maybe<QuoteOption>;
}

export interface Activity {
  __typename?: 'Activity';
  createdAt: Scalars['String'];
  id: Scalars['String'];
  itemId: Scalars['String'];
  itemType: ActivityFeedItemTypeEnum;
  legacyUser?: Maybe<NetworksContact>;
  metadata?: Maybe<Metadata>;
  permissions: Permissions;
  targetId: Scalars['String'];
  targetType: ActivityFeedTargetTypeEnum;
  user?: Maybe<AccountMember>;
  userDetails?: Maybe<ActivityUserDetails>;
}

/** The connection type for Activity. */
export interface ActivityConnection {
  __typename?: 'ActivityConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ActivityEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Activity>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ActivityEdge {
  __typename?: 'ActivityEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Activity>;
}

export interface ActivityFeed {
  __typename?: 'ActivityFeed';
  activities?: Maybe<ActivityConnection>;
  textMessages?: Maybe<Array<TextMessage>>;
}

export type ActivityFeedActivitiesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  itemTypes?: InputMaybe<Array<ActivityFeedItemTypeEnum>>;
  last?: InputMaybe<Scalars['Int']>;
  targetId: Scalars['String'];
  targetType: ActivityFeedTargetTypeEnum;
};

export type ActivityFeedTextMessagesArgs = {
  idsByTarget: Array<TextMessagesInput>;
};

export enum ActivityFeedItemTypeEnum {
  ACCEPTED_QUOTE_OPTION = 'accepted_quote_option',
  ADDED_ACTION_ITEM = 'added_action_item',
  ADDED_CARGO_ITEM = 'added_cargo_item',
  APPROVED_ACTION_ITEM = 'approved_action_item',
  ARCHIVED_ISSUE = 'archived_issue',
  AUTOMATICALLY_REJECTED_QUOTE_OPTION = 'automatically_rejected_quote_option',
  CANCELLED_ACTION_ITEM = 'cancelled_action_item',
  CLOSED_PURCHASE_ORDER = 'closed_purchase_order',
  COMPLETED_COLLECTION = 'completed_collection',
  COMPLETED_DELIVERY = 'completed_delivery',
  CONFIRMED_COLLECTION = 'confirmed_collection',
  CONFIRMED_DELIVERY = 'confirmed_delivery',
  CREATED_PURCHASE_ORDER = 'created_purchase_order',
  CUSTOMER_APPROVED_BOOKING = 'customer_approved_booking',
  DELETED_CARGO_ITEM = 'deleted_cargo_item',
  ETA_REMOVED_ITEM = 'eta_removed_item',
  ETA_UPDATED_ITEM = 'eta_updated_item',
  FORWARDER_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING = 'forwarder_bypassed_customer_approval_for_booking',
  FORWARDER_RESUBMITTED_BOOKING_FOR_CUSTOMER_APPROVAL = 'forwarder_resubmitted_booking_for_customer_approval',
  FORWARDER_REVERTED_REJECTION_AND_BYPASSED_CUSTOMER_APPROVAL_FOR_BOOKING = 'forwarder_reverted_rejection_and_bypassed_customer_approval_for_booking',
  MANUFACTURER_REQUESTED_BOOKING = 'manufacturer_requested_booking',
  MANUFACTURER_RESUBMITTED_BOOKING_FOR_FORWARDER_APPROVAL = 'manufacturer_resubmitted_booking_for_forwarder_approval',
  OPENED_ISSUE = 'opened_issue',
  QUOTE_OPTION = 'quote_option',
  REJECTED_BOOKING_REQUEST = 'rejected_booking_request',
  REJECTED_QUOTE_OPTION = 'rejected_quote_option',
  REQUESTED_COLLECTION = 'requested_collection',
  REQUESTED_DELIVERY = 'requested_delivery',
  REQUIRED_CUSTOMER_APPROVAL = 'required_customer_approval',
  REREQUESTED_COLLECTION = 'rerequested_collection',
  REREQUESTED_DELIVERY = 'rerequested_delivery',
  RESOLVED_ACTION_ITEM = 'resolved_action_item',
  RESOLVED_ISSUE = 'resolved_issue',
  STARTED_ISSUE = 'started_issue',
  TEXT_MESSAGE = 'text_message',
  UPDATED_CARGO_ITEM = 'updated_cargo_item',
  UPDATED_CARGO_READY_DATES = 'updated_cargo_ready_dates',
  UPDATED_CARGO_TYPE = 'updated_cargo_type',
  UPDATED_DELIVERY_ESTIMATES = 'updated_delivery_estimates',
  UPDATED_ISSUE = 'updated_issue',
  ZENSEI_TEXT_MESSAGE = 'zensei_text_message'
}

export enum ActivityFeedTargetTypeEnum {
  BOOKING = 'booking',
  PURCHASE_ORDER = 'purchase_order'
}

export interface ActivityUserDetails {
  __typename?: 'ActivityUserDetails';
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisationName: Scalars['String'];
}

export interface ActivityUserDetailsInput {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  organisationName: Scalars['String'];
}

export interface AddCostTrackingChargesInput {
  charges?: InputMaybe<Array<ChargeInput>>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  costTrackingId: Scalars['String'];
  displayCurrency?: InputMaybe<Currency>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated input type of AddDivision */
export interface AddDivisionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentAccountId: Scalars['String'];
}

/** Autogenerated return type of AddDivision */
export interface AddDivisionPayload {
  __typename?: 'AddDivisionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  id: Scalars['String'];
}

/** Autogenerated input type of AddEdd */
export interface AddEddInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  edd: Scalars['ISO8601Date'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of AddEdd */
export interface AddEddPayload {
  __typename?: 'AddEddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface AddJourneyRequestedScheduleInput {
  cargoIds: Array<Scalars['String']>;
  carrier: Scalars['String'];
  scheduledLegs: Array<ScheduledLegInput>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface AddJourneyRequestedSchedulePayload {
  __typename?: 'AddJourneyRequestedSchedulePayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of AddTradeLane */
export interface AddTradeLaneInput {
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  destination: AddTradeLaneLocationInput;
  origin: AddTradeLaneLocationInput;
}

export interface AddTradeLaneLocationInput {
  terminal?: InputMaybe<AddTradeLaneTerminalInput>;
  warehouse?: InputMaybe<AddTradeLaneWarehouseInput>;
}

/** Autogenerated return type of AddTradeLane */
export interface AddTradeLanePayload {
  __typename?: 'AddTradeLanePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  tradeLane?: Maybe<TradeLane>;
}

export interface AddTradeLaneTerminalInput {
  unlocode: Scalars['String'];
}

export interface AddTradeLaneWarehouseInput {
  address: Scalars['String'];
  countryCode: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of AddVoyageMilestoneDelay */
export interface AddVoyageMilestoneDelayInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  delayData: Array<VoyageDelayDataInput>;
  milestoneName: VoyageMilestoneNameEnum;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of AddVoyageMilestoneDelay */
export interface AddVoyageMilestoneDelayPayload {
  __typename?: 'AddVoyageMilestoneDelayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  delays: Array<VoyageDelay>;
  errors?: Maybe<Array<Error>>;
}

export interface Address {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countyOrState?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postalCodeOrZip?: Maybe<Scalars['String']>;
}

export interface AddressInput {
  city?: InputMaybe<Scalars['String']>;
  country: CountryCode;
  countyOrState?: InputMaybe<Scalars['String']>;
  line1: Scalars['String'];
  line2?: InputMaybe<Scalars['String']>;
  postalCodeOrZip?: InputMaybe<Scalars['String']>;
}

export interface AdjustedExchangeRate {
  __typename?: 'AdjustedExchangeRate';
  adjustedRate: Scalars['Float'];
  date: Scalars['Date'];
  fromCurrency: Currency;
  id: Scalars['String'];
  originalRate: Scalars['Float'];
  toCurrency: Currency;
}

export interface AdminPanel {
  __typename?: 'AdminPanel';
  canView: AuthorizationResult;
}

export interface AgentOrganisation extends NetworksOrganisationInterface {
  __typename?: 'AgentOrganisation';
  assignedRoles: Array<Role>;
  contacts: NetworksContactConnection;
  /** Organisation ID */
  id: Scalars['String'];
  /** Locations assigned to the organisation */
  locations: Array<NetworksLocation>;
  /** Organisation name */
  name: Scalars['String'];
  /** Organization type */
  type: OrganisationTypeValue;
}

export type AgentOrganisationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export interface AirlineCarrier {
  __typename?: 'AirlineCarrier';
  airlineCarrierName: Scalars['String'];
  carrierIata: Scalars['String'];
  id: Scalars['String'];
}

export interface AirlineCarrierConnection {
  __typename?: 'AirlineCarrierConnection';
  edges?: Maybe<Array<AirlineCarrierEdge>>;
  nodes?: Maybe<Array<AirlineCarrier>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface AirlineCarrierEdge {
  __typename?: 'AirlineCarrierEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AirlineCarrier>;
}

export interface AllShipmentsSection {
  __typename?: 'AllShipmentsSection';
  canAccessRoute: AuthorizationResult;
}

export enum AmPm {
  AM = 'am',
  PM = 'pm'
}

export enum AmPmEnum {
  AM = 'am',
  PM = 'pm'
}

export interface AnalyticsAccountPermissions {
  __typename?: 'AnalyticsAccountPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
}

export interface ApiKey {
  __typename?: 'ApiKey';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}

/** Enums */
export enum ApiKeyStatus {
  ACTIVE = 'ACTIVE',
  EXPIRED = 'EXPIRED'
}

export enum Applicability {
  BOOKING = 'BOOKING',
  DESTINATION = 'DESTINATION',
  FREIGHT = 'FREIGHT',
  ORIGIN = 'ORIGIN'
}

export interface ApplicabilityTraits {
  applicability: Applicability;
  referenceId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of ApproveBooking */
export interface ApproveBookingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of ApproveBooking */
export interface ApproveBookingPayload {
  __typename?: 'ApproveBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface ArchiveAccountConnectionInput {
  connectionType: AccountConnectionTypeEnum;
  sourceAccountId: Scalars['String'];
  targetAccountId: Scalars['String'];
}

export interface ArchiveAccountConnectionPayload {
  __typename?: 'ArchiveAccountConnectionPayload';
  errors?: Maybe<Array<Error>>;
}

export interface ArchiveAccountLocationPayload {
  __typename?: 'ArchiveAccountLocationPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of ArchiveAccrualMutation */
export interface ArchiveAccrualMutationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ArchiveAccrualMutation */
export interface ArchiveAccrualMutationPayload {
  __typename?: 'ArchiveAccrualMutationPayload';
  accrual?: Maybe<Accrual>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of ArchiveBooking */
export interface ArchiveBookingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of ArchiveBooking */
export interface ArchiveBookingPayload {
  __typename?: 'ArchiveBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of Archive */
export interface ArchiveInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated input type of ArchiveIssue */
export interface ArchiveIssueInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ArchiveIssue */
export interface ArchiveIssuePayload {
  __typename?: 'ArchiveIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issue?: Maybe<Issue>;
}

/** Autogenerated return type of Archive */
export interface ArchivePayload {
  __typename?: 'ArchivePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  id?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of ArchiveProduct */
export interface ArchiveProductInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID (pk) of product to be archived */
  id: Scalars['String'];
}

/** Autogenerated return type of ArchiveProduct */
export interface ArchiveProductPayload {
  __typename?: 'ArchiveProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Product>;
}

/** Autogenerated input type of ArchivePurchaseOrderDelayReason */
export interface ArchivePurchaseOrderDelayReasonInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ArchivePurchaseOrderDelayReason */
export interface ArchivePurchaseOrderDelayReasonPayload {
  __typename?: 'ArchivePurchaseOrderDelayReasonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  purchaseOrderDelayReason?: Maybe<PurchaseOrderDelayReason>;
}

/** Autogenerated input type of ArchiveQuoteOption */
export interface ArchiveQuoteOptionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteOptionId: Scalars['String'];
}

/** Autogenerated return type of ArchiveQuoteOption */
export interface ArchiveQuoteOptionPayload {
  __typename?: 'ArchiveQuoteOptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quoteOption?: Maybe<QuoteOption>;
}

/** Autogenerated input type of ArchiveRule */
export interface ArchiveRuleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ArchiveRule */
export interface ArchiveRulePayload {
  __typename?: 'ArchiveRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  rule?: Maybe<Rule>;
}

/** Autogenerated input type of ArchiveShipmentDelayReason */
export interface ArchiveShipmentDelayReasonInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
}

/** Autogenerated return type of ArchiveShipmentDelayReason */
export interface ArchiveShipmentDelayReasonPayload {
  __typename?: 'ArchiveShipmentDelayReasonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  shipmentDelayReason?: Maybe<ShipmentDelayReason>;
}

export interface ArchiveShippingDelayReasonInput {
  id: Scalars['String'];
}

export interface ArchiveShippingDelayReasonPayload {
  __typename?: 'ArchiveShippingDelayReasonPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of ArchiveTextMessage */
export interface ArchiveTextMessageInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ArchiveTextMessage */
export interface ArchiveTextMessagePayload {
  __typename?: 'ArchiveTextMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  textMessage?: Maybe<TextMessage>;
}

/** Autogenerated input type of AssignCommercialManager */
export interface AssignCommercialManagerInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  managerId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of AssignCommercialManager */
export interface AssignCommercialManagerPayload {
  __typename?: 'AssignCommercialManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface AssignContractInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface AssignContractOutput {
  __typename?: 'AssignContractOutput';
  contractId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface AssignContractPayload {
  __typename?: 'AssignContractPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contractAssignment?: Maybe<AssignContractOutput>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of Assign */
export interface AssignInput {
  assignableId: Scalars['String'];
  assignmentName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: AssignmentTargetTypeEnum;
}

/** Autogenerated input type of AssignNotifiedParty */
export interface AssignNotifiedPartyInput {
  assignableId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notifiedPartyId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of AssignNotifiedParty */
export interface AssignNotifiedPartyPayload {
  __typename?: 'AssignNotifiedPartyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of AssignOperationsManager */
export interface AssignOperationsManagerInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  managerId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of AssignOperationsManager */
export interface AssignOperationsManagerPayload {
  __typename?: 'AssignOperationsManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated return type of Assign */
export interface AssignPayload {
  __typename?: 'AssignPayload';
  assignable?: Maybe<NetworksAssignableInterface>;
  assignmentName?: Maybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  targetId?: Maybe<Scalars['String']>;
  targetType?: Maybe<Scalars['String']>;
}

/** Types */
export interface AssignWorkflowTasksPayload {
  __typename?: 'AssignWorkflowTasksPayload';
  errors?: Maybe<Array<Error>>;
}

/** Inputs */
export interface AssignWorkflowTasksToUserInput {
  assigneeId: Scalars['String'];
  taskIds: Array<Scalars['String']>;
}

/** An edge in a connection. */
export interface AssignableEdge {
  __typename?: 'AssignableEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NetworksAssignableInterface>;
}

export interface AssignablePrivilege {
  __typename?: 'AssignablePrivilege';
  accessLevel: AccessLevel;
  id: Scalars['ID'];
  member: NetworksContact;
  organisationId: Scalars['ID'];
  roleId: Scalars['ID'];
}

export enum AssignmentTargetType {
  BOOKING_ID = 'BOOKING_ID',
  ZENCARGO_REFERENCE = 'ZENCARGO_REFERENCE'
}

export enum AssignmentTargetTypeEnum {
  BOOKING = 'booking',
  BOOKING_CARGO = 'booking_cargo',
  BOOKING_NOTIFIED_PARTY = 'booking_notified_party',
  CLIENT_LOGISTICS = 'client_logistics',
  CONSOLIDATED_SHIPMENT = 'consolidated_shipment',
  CONSOLIDATED_SHIPMENT_LEG = 'consolidated_shipment_leg',
  PURCHASE_ORDER = 'purchase_order',
  TRADE_LANE = 'trade_lane'
}

export enum AssignmentTypeValue {
  BUYER = 'buyer',
  COLLECTION_LOCATION = 'collection_location',
  CONSIGNEE = 'consignee',
  CONSIGNOR = 'consignor',
  DELIVERY_LOCATION = 'delivery_location',
  DESTINATION = 'destination',
  FORWARDER = 'forwarder',
  MANUFACTURER = 'manufacturer',
  OLD_CONTACTS = 'old_contacts',
  ORIGIN = 'origin',
  ORIGIN_AGENT = 'origin_agent',
  PARTY = 'party',
  SELLER = 'seller'
}

export interface AssortedInput {
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous: Scalars['Boolean'];
  reefer: Scalars['Boolean'];
  stackable?: InputMaybe<Scalars['Boolean']>;
  volume?: InputMaybe<Scalars['Float']>;
  /** @deprecated This field will be deprecated in favour of the `gross_weight`. */
  weight?: InputMaybe<Scalars['Float']>;
}

export interface Authorization {
  __typename?: 'Authorization';
  /** List of accounts that the current user may access as an agent forwarder. */
  agentForwarderAccounts: Array<LegacyAccount>;
  /** List of accounts that the current user may access as a customer user. */
  customerUserAccounts: Array<LegacyAccount>;
  /** Is the current user an admin? */
  isAdmin: Scalars['Boolean'];
  /** List of accounts that the current user may access as a manufacturer. */
  manufacturerAccounts: Array<LegacyAccount>;
}

export interface AuthorizationResult {
  __typename?: 'AuthorizationResult';
  /** Human-readable error message */
  message?: Maybe<Scalars['String']>;
  /** Reasons of check failure */
  reasons?: Maybe<FailureReasons>;
  /** Result of applying a policy rule */
  value: Scalars['Boolean'];
}

export interface AverageUtilisation {
  __typename?: 'AverageUtilisation';
  averageUtilisation?: Maybe<Scalars['Float']>;
  bestDay?: Maybe<Scalars['Float']>;
  percentageChange?: Maybe<Scalars['Float']>;
  supplier?: Maybe<Supplier>;
  teu?: Maybe<Scalars['Float']>;
  worstDay?: Maybe<Scalars['Float']>;
}

export enum AverageUtilisationGroupByEnum {
  OVERALL = 'OVERALL',
  SUPPLIER = 'SUPPLIER'
}

export interface Backoffice {
  __typename?: 'Backoffice';
  canAccessRoute: AuthorizationResult;
}

export interface BillingDetails {
  __typename?: 'BillingDetails';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
}

export interface Booking {
  __typename?: 'Booking';
  account?: Maybe<Account>;
  accountUuid: Scalars['String'];
  accrual?: Maybe<Accrual>;
  accruals?: Maybe<Array<Accrual>>;
  accrualsEarliestState?: Maybe<AccrualState>;
  actionItems?: Maybe<Array<ActionItem>>;
  activatedQuoteOptionsCount: Scalars['Int'];
  activeQuoteOptionsCount: Scalars['Int'];
  actualDeparture?: Maybe<LocalDateTimeType>;
  /** Time when the booking was archived. Null if the booking is not archived */
  archivedAt?: Maybe<RichDateTime>;
  bookingDocumentTemplate?: Maybe<BookingDocumentTemplate>;
  /** Booking Documents */
  bookingDocuments: Array<BookingDocument>;
  bookingManagementSettings?: Maybe<BookingManagementSettings>;
  bookingRequest?: Maybe<BookingRequest>;
  /** Current stage of booking */
  bookingStage?: Maybe<BookingStage>;
  bookingStages?: Maybe<Array<BookingStage>>;
  /** Calculated info for the booking */
  calculatedInfo: CalculatedInfo;
  canAccessAccruals: AuthorizationResult;
  canAddCargo?: Maybe<CanAddCargoResult>;
  canApproveBookingRequest: AuthorizationResult;
  canArchiveShipment: AuthorizationResult;
  canCancelCollection: AuthorizationResult;
  canCancelDelivery: AuthorizationResult;
  canClone: AuthorizationResult;
  canCompleteCollection: AuthorizationResult;
  canCompleteDelivery: AuthorizationResult;
  canConfirmCollection: AuthorizationResult;
  canConfirmDelivery: AuthorizationResult;
  canEditAgent: AuthorizationResult;
  canEditDestination: AuthorizationResult;
  canEditOrigin: AuthorizationResult;
  canManageAssignedLots: AuthorizationResult;
  canManageCargo: AuthorizationResult;
  canManageCollectionAndDeliveryScheduling: AuthorizationResult;
  canManageSchedule: AuthorizationResult;
  canManageTradeParties: AuthorizationResult;
  canModifyIssueVisibility: AuthorizationResult;
  canOpenIssue: AuthorizationResult;
  canPin: AuthorizationResult;
  canScheduleCollection: AuthorizationResult;
  canScheduleDelivery: AuthorizationResult;
  canSendTextMessageToAgentForwarder: AuthorizationResult;
  canSendTextMessageToCustomerUser: AuthorizationResult;
  canSendTextMessageToManufacturer: AuthorizationResult;
  canSetCommercialManager: AuthorizationResult;
  canSetOperationsManager: AuthorizationResult;
  canSetShippingOrderNotRequired: AuthorizationResult;
  canToggleCollection?: Maybe<Scalars['Boolean']>;
  canToggleCollectionCurrently?: Maybe<JourneyToggleCollectionValidationResult>;
  canToggleShippingOrderConfirmed: AuthorizationResult;
  canUpdateBookingManagementSettings: AuthorizationResult;
  canUpdateCarrierBookingReference: AuthorizationResult;
  canUpdateContractId: AuthorizationResult;
  canUpdateCustomsOnly: AuthorizationResult;
  canUpdateForwarderApproved: AuthorizationResult;
  canUpdateForwarderReference: AuthorizationResult;
  canUpdateHouseBillOfLading: AuthorizationResult;
  canUpdateIncoterms: AuthorizationResult;
  canUpdateMasterBillOfLading: AuthorizationResult;
  canUpdateModeOfTransport: AuthorizationResult;
  canUpdateMultiStop: AuthorizationResult;
  canUpdateNotes: AuthorizationResult;
  canUpdatePricingRequired: AuthorizationResult;
  canUpdateShipmentStatus: AuthorizationResult;
  canUpdateShipmentType: AuthorizationResult;
  canUpdateStage: AuthorizationResult;
  canUpdateTradeRole: AuthorizationResult;
  canUpdateTruckSwap: AuthorizationResult;
  canUpdateVehiclePlateNumber: AuthorizationResult;
  canView: AuthorizationResult;
  canViewActionItems: AuthorizationResult;
  canViewActivity: AuthorizationResult;
  canViewCommercialManager: AuthorizationResult;
  canViewCompleteCollection: AuthorizationResult;
  canViewContractId: AuthorizationResult;
  canViewCustomer: AuthorizationResult;
  canViewForwarderApproved: AuthorizationResult;
  canViewHouseBillOfLading: AuthorizationResult;
  canViewIssues: AuthorizationResult;
  canViewKeyContact: AuthorizationResult;
  canViewMasterBillOfLading: AuthorizationResult;
  canViewNotes: AuthorizationResult;
  canViewOperationsManager: AuthorizationResult;
  canViewRoadTracking: AuthorizationResult;
  canViewSchedule: AuthorizationResult;
  canViewShipmentTracking: AuthorizationResult;
  canViewStaleData: AuthorizationResult;
  canViewTradeParties: AuthorizationResult;
  /** Cargo related details */
  cargo?: Maybe<Cargo>;
  cargoDescription?: Maybe<Scalars['String']>;
  cargoJourneyDetails: Array<Maybe<CargoJourneyDetail>>;
  cargoJourneys?: Maybe<CargoJourneys>;
  cargoJourneysSummary?: Maybe<CargoJourneysSummary>;
  cargoReadyDate?: Maybe<RichDate>;
  /** @deprecated Field no longer supported */
  cargos?: Maybe<Array<CoreCargo>>;
  /** Booking identifier used by the client */
  clientReference?: Maybe<Scalars['String']>;
  collectionScheduleRequired?: Maybe<Scalars['Boolean']>;
  commercialManager?: Maybe<NetworksOrgLocContact>;
  consignee?: Maybe<NetworksAssignableInterface>;
  /** Consignee company name */
  consigneeLabel?: Maybe<Scalars['String']>;
  consignor?: Maybe<NetworksAssignableInterface>;
  /** Consignor company name */
  consignorLabel?: Maybe<Scalars['String']>;
  consolidatedShipment?: Maybe<ConsolidatedShipment>;
  coreCargos?: Maybe<Array<CoreCargo>>;
  costTracking?: Maybe<CostTracking>;
  /** Creation time of the booking */
  createdAt?: Maybe<RichDateTime>;
  /** Customer */
  customer?: Maybe<BookingCustomerType>;
  customsOnly: Scalars['Boolean'];
  /**
   * Delay
   * @deprecated This field will be deprecated.
   */
  delay?: Maybe<Delay>;
  deliveryScheduleRequired?: Maybe<Scalars['Boolean']>;
  /** Destination warehouse address or terminal name */
  destinationLabel?: Maybe<Scalars['String']>;
  /**
   * The date the booking is estimated to arrive at the destination port. This
   * represents the latest known date, which may differ from the originally
   * scheduled date.
   * @deprecated This field will be deprecated in favour of `booking.scheduledLegs`.
   */
  estimatedArrival?: Maybe<LocalDateTimeType>;
  /**
   * The date the booking is estimated to be collected. This represents the latest
   * known date, which may differ from the originally scheduled date.
   * @deprecated This field will be deprecated.
   */
  estimatedCollection?: Maybe<LocalDateTimeType>;
  /**
   * The date the booking is estimated to be delivered. This represents the latest
   * known date, which may differ from the originally scheduled date.
   * @deprecated This field will be deprecated.
   */
  estimatedDelivery?: Maybe<LocalDateTimeType>;
  /**
   * The date the booking is estimated to depart from the origin port. This
   * represents the latest known date, which may differ from the originally
   * scheduled date.
   * @deprecated This field will be deprecated in favour of `booking.scheduledLegs`.
   */
  estimatedDeparture?: Maybe<LocalDateTimeType>;
  /** The assigned Forwarder for this Booking */
  forwarder?: Maybe<NetworksAssignableInterface>;
  /** The assigned Forwarder's reference for this Booking */
  forwarderReference?: Maybe<Scalars['String']>;
  haulier?: Maybe<NetworkContact>;
  /** House Bill of Lading Number */
  houseBillOfLading?: Maybe<Scalars['String']>;
  /** Incoterms */
  incoterms?: Maybe<Incoterms>;
  isCollectionEnabled?: Maybe<Scalars['Boolean']>;
  issues?: Maybe<Array<BookingIssue>>;
  keyContact?: Maybe<NetworksContact>;
  /** Lots assigned to this booking - default page size 50 */
  lots?: Maybe<LotConnection>;
  manufacturers?: Maybe<Array<NetworksAssignableInterface>>;
  /**
   * Master Bill of Lading Number
   * @deprecated This field will be deprecated.
   */
  masterBillOfLading?: Maybe<Scalars['String']>;
  milestones?: Maybe<Array<VoyageMilestone>>;
  /** The main mode of transportation for the booking */
  modeOfTransport?: Maybe<ModeOfTransport>;
  multiStop?: Maybe<MultiStopEnum>;
  networksDestination?: Maybe<NetworksAssignableInterface>;
  networksNotifiedParties?: Maybe<Array<NotifiedParty>>;
  networksOrigin?: Maybe<NetworksAssignableInterface>;
  note?: Maybe<Note>;
  oceanTracking?: Maybe<OceanTracking>;
  operationsManager?: Maybe<NetworksOrgLocContact>;
  originAgent?: Maybe<NetworksAssignableInterface>;
  /** Origin warehouse address or terminal name */
  originLabel?: Maybe<Scalars['String']>;
  permissions?: Maybe<BookingPermissions>;
  pinned: Scalars['Boolean'];
  podDemurrageFreeTimeEnds?: Maybe<Scalars['ISO8601DateTime']>;
  podDetentionFreeTime?: Maybe<Scalars['Int']>;
  polDemurrageFreeTimeBegins?: Maybe<Scalars['ISO8601DateTime']>;
  priceCalculation?: Maybe<PriceCalculation>;
  purchaseOrderReferences?: Maybe<PurchaseOrderReferences>;
  quoteAccepted: Scalars['Boolean'];
  quoteAcceptedAt?: Maybe<RichDateTime>;
  /** Quote options associated with the booking */
  quoteOptions: Array<Maybe<QuoteOption>>;
  quoteRequired?: Maybe<Scalars['Boolean']>;
  quoteRequiredAt?: Maybe<RichDateTime>;
  revenueDate?: Maybe<Scalars['ISO8601Date']>;
  roadTracking?: Maybe<RoadTrackingDetails>;
  /** Information about the scheduled carriage legs associated with this booking, ordered chronologically; null if not applicable */
  scheduledLegs?: Maybe<Array<Maybe<ScheduledLeg>>>;
  /** Shipment Type */
  shipmentType?: Maybe<Shipment>;
  specialInstructions?: Maybe<BookingSpecialInstructions>;
  /**
   * Information about the stage of the booking
   * @deprecated This field will be deprecated in favour of `booking.bookingStage` and `booking.scheduledLegs`.
   */
  stage?: Maybe<Stage>;
  staleData?: Maybe<BookingStaleData>;
  tradeLane?: Maybe<TradeLane>;
  /** Trade Role */
  tradeRole?: Maybe<TradeRole>;
  truckSwap?: Maybe<TruckSwapEnum>;
  updatedAt?: Maybe<RichDateTime>;
  /**
   * The current voyage milestone
   * @deprecated This field will be deprecated in favour of `booking.scheduledLegs`.
   */
  voyageMilestone?: Maybe<VoyageMilestone>;
  /** Booking identifier at Zencargo */
  zencargoReference: Scalars['String'];
}

export type BookingAccrualArgs = {
  id: Scalars['String'];
};

export type BookingActionItemsArgs = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type BookingBookingDocumentTemplateArgs = {
  legacyId: Scalars['Int'];
};

export type BookingCostTrackingArgs = {
  displayCurrency?: Currency;
};

export type BookingLotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type BookingPriceCalculationArgs = {
  customerId: Scalars['String'];
};

export type BookingPurchaseOrderReferencesArgs = {
  first?: InputMaybe<Scalars['Int']>;
};

export type BookingQuoteOptionsArgs = {
  ids?: InputMaybe<Array<Scalars['String']>>;
  includeDrafts?: InputMaybe<Scalars['Boolean']>;
};

export interface BookingAccountPermissions {
  __typename?: 'BookingAccountPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
  canCreate?: Maybe<AuthorizationResult>;
}

export interface BookingAggregatesCollectionAndDeliveryEventType {
  __typename?: 'BookingAggregatesCollectionAndDeliveryEventType';
  data: Scalars['String'];
  type: BookingAggregatesCollectionAndDeliveryEventTypeEnum;
  user?: Maybe<User>;
}

export enum BookingAggregatesCollectionAndDeliveryEventTypeEnum {
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_ADDRESS_SELECTED = 'booking_collection_and_delivery_collection_address_selected',
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_CANCELLED = 'booking_collection_and_delivery_collection_cancelled',
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_COMPLETED = 'booking_collection_and_delivery_collection_completed',
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_CONFIRMED = 'booking_collection_and_delivery_collection_confirmed',
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_REFERENCE_UPDATED = 'booking_collection_and_delivery_collection_reference_updated',
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_REQUESTED = 'booking_collection_and_delivery_collection_requested',
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_SCHEDULE_REMOVED = 'booking_collection_and_delivery_collection_schedule_removed',
  BOOKING_COLLECTION_AND_DELIVERY_COLLECTION_SCHEDULE_REQUIRED = 'booking_collection_and_delivery_collection_schedule_required',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_ADDRESS_SELECTED = 'booking_collection_and_delivery_delivery_address_selected',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_CANCELLED = 'booking_collection_and_delivery_delivery_cancelled',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_COMPLETED = 'booking_collection_and_delivery_delivery_completed',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_CONFIRMED = 'booking_collection_and_delivery_delivery_confirmed',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_REFERENCE_UPDATED = 'booking_collection_and_delivery_delivery_reference_updated',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_REQUESTED = 'booking_collection_and_delivery_delivery_requested',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_SCHEDULE_REMOVED = 'booking_collection_and_delivery_delivery_schedule_removed',
  BOOKING_COLLECTION_AND_DELIVERY_DELIVERY_SCHEDULE_REQUIRED = 'booking_collection_and_delivery_delivery_schedule_required'
}

export interface BookingCollectionAndDeliveryLocationInput {
  locationId: Scalars['String'];
}

export interface BookingCollectionMetadataType {
  __typename?: 'BookingCollectionMetadataType';
  collectedOn?: Maybe<Scalars['String']>;
  locationLabel?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['String']>;
}

/** The connection type for Booking. */
export interface BookingConnection {
  __typename?: 'BookingConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookingEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Booking>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** Provides information about the customer */
export interface BookingCustomerType {
  __typename?: 'BookingCustomerType';
  division?: Maybe<Division>;
  /** is the booking owned by a division account? */
  isDivision?: Maybe<Scalars['Boolean']>;
  /** is the booking owned by a parent account? */
  isParent?: Maybe<Scalars['Boolean']>;
  /** Customer name */
  name: Scalars['String'];
  parentAccount?: Maybe<BookingCustomerType>;
  uuid: Scalars['String'];
}

export interface BookingDeliveryMetadataType {
  __typename?: 'BookingDeliveryMetadataType';
  deliveredOn?: Maybe<Scalars['String']>;
  locationLabel?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['String']>;
}

/** Provides information about attached documents */
export interface BookingDocument {
  __typename?: 'BookingDocument';
  actionItemDocument?: Maybe<Scalars['Boolean']>;
  /** Asset URL */
  assetUrl?: Maybe<Scalars['String']>;
  /** Created at */
  createdAt: RichDateTime;
  /** Description */
  description: Scalars['String'];
  /** Document Type */
  documentType?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  /** Id */
  id: Scalars['String'];
  permissions: Permissions;
  state: BookingDocumentState;
}

export interface BookingDocumentInput {
  assetUrl: Scalars['String'];
  attachmentId: Scalars['String'];
  description: Scalars['String'];
  documentType: Scalars['String'];
  permissions: BookingDocumentPermissionsInput;
  uuid: Scalars['String'];
}

export interface BookingDocumentPermissionsInput {
  agentForwarder?: InputMaybe<Scalars['Boolean']>;
  customerUser?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['Boolean']>;
}

export enum BookingDocumentState {
  ARCHIVED = 'ARCHIVED',
  FAILED_TO_GENERATE = 'FAILED_TO_GENERATE',
  GENERATING = 'GENERATING',
  UPLOADED = 'UPLOADED'
}

export interface BookingDocumentTemplate {
  __typename?: 'BookingDocumentTemplate';
  fieldGroups?: Maybe<Array<FieldGroup>>;
  legacyId: Scalars['Int'];
  name: Scalars['String'];
}

export interface BookingDocumentType {
  __typename?: 'BookingDocumentType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  permissions: Permissions;
  title?: Maybe<Scalars['String']>;
}

/** The connection type for BookingDocumentType. */
export interface BookingDocumentTypeConnection {
  __typename?: 'BookingDocumentTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookingDocumentTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BookingDocumentType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface BookingDocumentTypeEdge {
  __typename?: 'BookingDocumentTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BookingDocumentType>;
}

export interface BookingDocumentTypePermissionsInputType {
  agentForwarder?: Scalars['Boolean'];
  customerUser?: Scalars['Boolean'];
  manufacturer?: Scalars['Boolean'];
}

/** An edge in a connection. */
export interface BookingEdge {
  __typename?: 'BookingEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Booking>;
}

export interface BookingGlobalPermissions {
  __typename?: 'BookingGlobalPermissions';
  canAcceptQuote?: Maybe<AuthorizationResult>;
  canAccessRoute?: Maybe<AuthorizationResult>;
  canRequest?: Maybe<AuthorizationResult>;
  canUpdateClientReference?: Maybe<AuthorizationResult>;
  canViewQuote?: Maybe<AuthorizationResult>;
}

export interface BookingIssue {
  __typename?: 'BookingIssue';
  associatedId: Scalars['String'];
  associatedType: IssueAssociatedObject;
  canArchive: AuthorizationResult;
  canModifyVisibility: AuthorizationResult;
  canResolve: AuthorizationResult;
  canStart: AuthorizationResult;
  canUpdate: AuthorizationResult;
  canUpdateCategory: AuthorizationResult;
  canUpdateProblemDescription: AuthorizationResult;
  category: IssueCategory;
  id: Scalars['String'];
  occurredOn: RichDateTime;
  openedAt: RichDateTime;
  openedBy: NetworksContact;
  problemDescription: Scalars['String'];
  resolvedAt?: Maybe<RichDateTime>;
  resolvedBy?: Maybe<NetworksContact>;
  severity: IssueSeverity;
  solutionDescription?: Maybe<Scalars['String']>;
  startedAt?: Maybe<RichDateTime>;
  startedBy?: Maybe<NetworksContact>;
  state: IssueState;
  title?: Maybe<Scalars['String']>;
  visibility: IssueVisibility;
  zencargoReference: Scalars['String'];
}

export interface BookingIssueSortInput {
  direction: SortingOrder;
  field: BookingIssueSortingField;
}

export enum BookingIssueSortingField {
  OCCURRED_ON = 'OCCURRED_ON',
  OPENED_AT = 'OPENED_AT'
}

export interface BookingManagementSettings {
  __typename?: 'BookingManagementSettings';
  forwarderApproved?: Maybe<Scalars['Boolean']>;
  pricingRequired?: Maybe<Scalars['Boolean']>;
}

export interface BookingManager {
  __typename?: 'BookingManager';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<ManagerTeam>>;
}

export interface BookingManagerAssignmentRule {
  __typename?: 'BookingManagerAssignmentRule';
  accountId: Scalars['String'];
  id: Scalars['String'];
  managerId?: Maybe<Scalars['String']>;
  modeOfTransport?: Maybe<ModeOfTransport>;
  shipmentType?: Maybe<Shipment>;
  team: ManagerTeam;
}

/** The connection type for BookingManagerAssignmentRule. */
export interface BookingManagerAssignmentRuleConnection {
  __typename?: 'BookingManagerAssignmentRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<BookingManagerAssignmentRuleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<BookingManagerAssignmentRule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface BookingManagerAssignmentRuleEdge {
  __typename?: 'BookingManagerAssignmentRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<BookingManagerAssignmentRule>;
}

export interface BookingNotificationSettingsGlobalPermissions {
  __typename?: 'BookingNotificationSettingsGlobalPermissions';
  canToggleAutoSubscribe?: Maybe<AuthorizationResult>;
}

export interface BookingPermissions {
  __typename?: 'BookingPermissions';
  canAcceptQuote?: Maybe<AuthorizationResult>;
  canApproveOrReject?: Maybe<AuthorizationResult>;
  canUpdateClientReference?: Maybe<AuthorizationResult>;
  canUploadDocuments?: Maybe<AuthorizationResult>;
  canViewQuote?: Maybe<AuthorizationResult>;
  isCustomerOnBooking?: Maybe<AuthorizationResult>;
  isSupplierOnBooking?: Maybe<AuthorizationResult>;
}

export interface BookingRequest {
  __typename?: 'BookingRequest';
  canReject: AuthorizationResult;
  canRevertRejection: AuthorizationResult;
  canRevertRejectionAsManufacturer: AuthorizationResult;
  canUpdateCustomerApproved: AuthorizationResult;
  canUpdateForwarderApproved: AuthorizationResult;
  customerApprovalRequired?: Maybe<Scalars['Boolean']>;
  customerApprovalRequiredAt?: Maybe<RichDateTime>;
  customerApproved?: Maybe<Scalars['Boolean']>;
  customerApprovedAt?: Maybe<RichDateTime>;
  forwarderApprovalRequired?: Maybe<Scalars['Boolean']>;
  forwarderApprovalRequiredAt?: Maybe<RichDateTime>;
  forwarderApproved?: Maybe<Scalars['Boolean']>;
  forwarderApprovedAt?: Maybe<RichDateTime>;
  id: Scalars['String'];
  quoteApproved?: Maybe<Scalars['Boolean']>;
  quoteApprovedAt?: Maybe<RichDateTime>;
  quoteRequired?: Maybe<Scalars['Boolean']>;
  quoteRequiredAt?: Maybe<RichDateTime>;
  rejected?: Maybe<Scalars['Boolean']>;
  rejectedAdditionalInfo?: Maybe<Scalars['String']>;
  rejectedAt?: Maybe<RichDateTime>;
  rejectedReason?: Maybe<RejectedReasonEnum>;
}

export interface BookingRequests {
  __typename?: 'BookingRequests';
  canAccessRoute: AuthorizationResult;
  canViewApprovalRequired: AuthorizationResult;
  canViewManufacturerRequested: AuthorizationResult;
  canViewRejected: AuthorizationResult;
}

/** Autogenerated input type of BookingRequestsRevertRejectionAsManufacturer */
export interface BookingRequestsRevertRejectionAsManufacturerInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of BookingRequestsRevertRejectionAsManufacturer */
export interface BookingRequestsRevertRejectionAsManufacturerPayload {
  __typename?: 'BookingRequestsRevertRejectionAsManufacturerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of BookingRequestsRevertRejection */
export interface BookingRequestsRevertRejectionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerApprovalRequired: Scalars['Boolean'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of BookingRequestsRevertRejection */
export interface BookingRequestsRevertRejectionPayload {
  __typename?: 'BookingRequestsRevertRejectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of BookingRequestsUpdateForwarderApproved */
export interface BookingRequestsUpdateForwarderApprovedInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerApprovalRequired?: InputMaybe<Scalars['Boolean']>;
  forwarderApproved: Scalars['Boolean'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of BookingRequestsUpdateForwarderApproved */
export interface BookingRequestsUpdateForwarderApprovedPayload {
  __typename?: 'BookingRequestsUpdateForwarderApprovedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface BookingSpecialInstructions {
  __typename?: 'BookingSpecialInstructions';
  message?: Maybe<Scalars['String']>;
}

export interface BookingStage {
  __typename?: 'BookingStage';
  checklist: Array<BookingStageCheck>;
  current?: Maybe<Scalars['Boolean']>;
  name: BookingStageEnum;
  started?: Maybe<Scalars['Boolean']>;
  startedAt?: Maybe<LocalDateTimeType>;
}

export interface BookingStageCheck {
  __typename?: 'BookingStageCheck';
  completed: Scalars['Boolean'];
  name: BookingStageCheckEnum;
}

export enum BookingStageCheckEnum {
  FORWARDER_APPROVED = 'FORWARDER_APPROVED',
  QUOTE_ACCEPTED = 'QUOTE_ACCEPTED'
}

export enum BookingStageEnum {
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_RECEIVED = 'BOOKING_RECEIVED',
  BOOKING_REQUESTED = 'BOOKING_REQUESTED'
}

export interface BookingStaleData {
  __typename?: 'BookingStaleData';
  canReconfirm: AuthorizationResult;
  dataUpdatedAt?: Maybe<RichDateTime>;
  enabled: Scalars['Boolean'];
  freshnessThreshold?: Maybe<Scalars['Int']>;
}

export enum BookingStep {
  ARRIVED = 'arrived',
  ARRIVED_POD = 'arrived_pod',
  BOOKED = 'booked',
  BOOKING_REQUESTED = 'booking_requested',
  CARGO_ABOARD = 'cargo_aboard',
  CONTAINER_OUT = 'container_out',
  DEPARTED_POL = 'departed_pol',
  DEPARTED_WAREHOUSE = 'departed_warehouse',
  DISCHARGED = 'discharged',
  IN_GATE = 'in_gate',
  ON_ROUTE_TO_FINAL_DESTINATION = 'on_route_to_final_destination',
  PENDING = 'pending',
  QUOTE_REQUESTED = 'quote_requested'
}

export enum BookingUpcomingEventEnum {
  ARRIVAL = 'ARRIVAL',
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY',
  DEPARTURE = 'DEPARTURE'
}

/** Autogenerated input type of BookingUpdateModeOfTransport */
export interface BookingUpdateModeOfTransportInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  modeOfTransport: ModeOfTransport;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of BookingUpdateModeOfTransport */
export interface BookingUpdateModeOfTransportPayload {
  __typename?: 'BookingUpdateModeOfTransportPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  modeOfTransport?: Maybe<ModeOfTransport>;
}

export interface Bookings {
  __typename?: 'Bookings';
  canAccessRoute: AuthorizationResult;
  canChangeCargoDescription: AuthorizationResult;
  canChangeCustomsOnly: AuthorizationResult;
  canChangeMultiStop: AuthorizationResult;
  canChangeShipmentType: AuthorizationResult;
  canChangeTradeRole: AuthorizationResult;
  canChangeTruckSwap: AuthorizationResult;
  canCreate: AuthorizationResult;
  canExport: AuthorizationResult;
  canPin: AuthorizationResult;
  canQuote: AuthorizationResult;
  canRequest: AuthorizationResult;
  canViewCustomers: AuthorizationResult;
  canViewDocumentPermissions: AuthorizationResult;
}

export interface BoxesInput {
  /** @deprecated This field will be deprecated in favour of the `dimensions_with_metric`. */
  dimensions?: InputMaybe<DimensionsInCm>;
  dimensionsWithMetric?: InputMaybe<CargoDimensionsInputType>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous: Scalars['Boolean'];
  quantity: Scalars['Int'];
  reefer: Scalars['Boolean'];
  stackable?: InputMaybe<Scalars['Boolean']>;
  volume?: InputMaybe<Scalars['Float']>;
  /** @deprecated This field will be deprecated in favour of the `gross_weight`. */
  weight?: InputMaybe<Scalars['Float']>;
}

export enum BreakdownType {
  COST = 'COST',
  REVENUE = 'REVENUE'
}

export interface BulkUpdateCostTrackingCostItemsStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  costTrackingId: Scalars['String'];
  displayCurrency?: InputMaybe<Currency>;
  itemIds: Array<Scalars['String']>;
  status: CostTrackingCostItemStatus;
  zencargoReference: Scalars['String'];
}

export interface BulkUpdateCostTrackingItemsStatusPayload {
  __typename?: 'BulkUpdateCostTrackingItemsStatusPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  costTracking?: Maybe<CostTracking>;
  errors?: Maybe<Array<Error>>;
  failedItemIds?: Maybe<Array<Scalars['String']>>;
  updatedItemIds?: Maybe<Array<Scalars['String']>>;
}

export interface BulkUpdateCostTrackingRevenueItemsStatusInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  costTrackingId: Scalars['String'];
  displayCurrency?: InputMaybe<Currency>;
  itemIds: Array<Scalars['String']>;
  status: CostTrackingRevenueItemStatus;
  zencargoReference: Scalars['String'];
}

export interface BusinessHours {
  __typename?: 'BusinessHours';
  friday?: Maybe<TimeRange>;
  monday?: Maybe<TimeRange>;
  saturday?: Maybe<TimeRange>;
  sunday?: Maybe<TimeRange>;
  thursday?: Maybe<TimeRange>;
  tuesday?: Maybe<TimeRange>;
  wednesday?: Maybe<TimeRange>;
}

export interface BusinessHoursInput {
  friday?: InputMaybe<TimeRangeInput>;
  monday?: InputMaybe<TimeRangeInput>;
  saturday?: InputMaybe<TimeRangeInput>;
  sunday?: InputMaybe<TimeRangeInput>;
  thursday?: InputMaybe<TimeRangeInput>;
  tuesday?: InputMaybe<TimeRangeInput>;
  wednesday?: InputMaybe<TimeRangeInput>;
}

export interface CalculatedInfo {
  __typename?: 'CalculatedInfo';
  cargoSummary?: Maybe<CargoSummary>;
  collectionInFull?: Maybe<InFull>;
  deliveryInFull?: Maybe<InFull>;
}

export enum CanAddCargoReasonEnum {
  BOOKING_DEPARTED_ORIGIN_TERMINAL = 'BOOKING_DEPARTED_ORIGIN_TERMINAL'
}

export interface CanAddCargoResult {
  __typename?: 'CanAddCargoResult';
  reason?: Maybe<CanAddCargoReasonEnum>;
  value: Scalars['Boolean'];
}

/** Autogenerated input type of CancelCollection */
export interface CancelCollectionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CancelCollection */
export interface CancelCollectionPayload {
  __typename?: 'CancelCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CancelDelivery */
export interface CancelDeliveryInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CancelDelivery */
export interface CancelDeliveryPayload {
  __typename?: 'CancelDeliveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CancelJourneyRoadLegInput {
  cargoId: Scalars['String'];
  journeyLegId: Scalars['String'];
  vehicleId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface CancelJourneyRoadLegPayload {
  __typename?: 'CancelJourneyRoadLegPayload';
  errors?: Maybe<Array<Error>>;
}

/** Provides information about the cargo */
export interface Cargo {
  __typename?: 'Cargo';
  cargoItems?: Maybe<Array<CargoCargoItem>>;
  consolidatedCargo?: Maybe<ConsolidatedCargo>;
  containers?: Maybe<Array<CargoContainerType>>;
  description?: Maybe<Scalars['String']>;
  looseCargo?: Maybe<Array<CargoLooseCargoType>>;
  mode?: Maybe<CargoModeEnum>;
  vehicle?: Maybe<CargoVehicleType>;
}

export interface CargoAssignment {
  __typename?: 'CargoAssignment';
  endTimestamp?: Maybe<Scalars['String']>;
  startTimestamp: Scalars['String'];
  targetId?: Maybe<Scalars['String']>;
  targetType?: Maybe<AssignmentTargetType>;
}

export interface CargoAssignmentInput {
  endTimestamp?: InputMaybe<Scalars['String']>;
  startTimestamp?: InputMaybe<Scalars['String']>;
  targetId?: InputMaybe<Scalars['String']>;
  targetType?: InputMaybe<AssignmentTargetType>;
}

export type CargoCargoItem = {
  canManageAssignedLots: AuthorizationResult;
  canManageCollectionLocation: AuthorizationResult;
  canManageDeliveryLocation: AuthorizationResult;
  canUpdateVehicleTrailerId: AuthorizationResult;
  canViewCollectionDetails: AuthorizationResult;
  canViewDeliveryDetails: AuthorizationResult;
  /** The volume of the goods inside the cargo, in cubic meters */
  cbm?: Maybe<Scalars['Float']>;
  collection?: Maybe<CollectionDetails>;
  delivery?: Maybe<DeliveryDetails>;
  /** The gross volume of the cargo including all packaging and containers */
  grossWeight?: Maybe<CargoWeightType>;
  hazardous?: Maybe<Scalars['Boolean']>;
  /** Cargo id */
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  /**
   * The type of loose cargo inside this cargo item.
   * If the cargo type is itself loose, then this represents
   * the specific type being shipped. If it is a container or vehicle,
   * this tells you how the goods inside the outer container have
   * been packaged.
   */
  looseCargoType?: Maybe<LooseCargoTypeEnum>;
  /**
   * The quantity of loose cargo items, such as pallets or boxes,
   * inside this cargo item. For containers, this is sometimes
   * called the piece count
   */
  quantity?: Maybe<Scalars['Int']>;
  reefer?: Maybe<Scalars['Boolean']>;
  /**
   * The type describes the type of cargo item stored in Zencargo,
   * which maps to the outermost type of cargo.
   * For example, a shipping container will always be CONTAINER
   * even if it contains PALLETS. If you want the specific loose cargo
   * inside a container, refer to the loose_cargo_type
   */
  type: CargoItemTypeEnum;
};

export interface CargoConsolidatedItemType {
  __typename?: 'CargoConsolidatedItemType';
  label?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  subType: CargoItemSubTypeEnum;
  type: CargoItemTypeEnum;
}

export interface CargoContainerType extends CargoCargoItem {
  __typename?: 'CargoContainerType';
  canManageAssignedLots: AuthorizationResult;
  canManageCollectionLocation: AuthorizationResult;
  canManageDeliveryLocation: AuthorizationResult;
  canUpdateVehicleTrailerId: AuthorizationResult;
  canViewCollectionDetails: AuthorizationResult;
  canViewDeliveryDetails: AuthorizationResult;
  /** The volume of the goods inside the cargo, in cubic meters */
  cbm?: Maybe<Scalars['Float']>;
  collection?: Maybe<CollectionDetails>;
  /** Container number */
  containerNumber?: Maybe<Scalars['String']>;
  containerSealNumber?: Maybe<Scalars['String']>;
  containerType: ContainerTypeEnum;
  delivery?: Maybe<DeliveryDetails>;
  dimensions?: Maybe<CargoDimensionsType>;
  dryStandard: Scalars['Boolean'];
  flatRack: Scalars['Boolean'];
  garmentsOnHangers: Scalars['Boolean'];
  /** The gross volume of the cargo including all packaging and containers */
  grossWeight?: Maybe<CargoWeightType>;
  hazardous?: Maybe<Scalars['Boolean']>;
  /** Cargo id */
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  /**
   * The type of loose cargo inside this cargo item.
   * If the cargo type is itself loose, then this represents
   * the specific type being shipped. If it is a container or vehicle,
   * this tells you how the goods inside the outer container have
   * been packaged.
   */
  looseCargoType?: Maybe<LooseCargoTypeEnum>;
  openTop: Scalars['Boolean'];
  overweight: Scalars['Boolean'];
  palletType?: Maybe<PalletTypeEnum>;
  /**
   * The quantity of loose cargo items, such as pallets or boxes,
   * inside this cargo item. For containers, this is sometimes
   * called the piece count
   */
  quantity?: Maybe<Scalars['Int']>;
  reefer?: Maybe<Scalars['Boolean']>;
  teu?: Maybe<Scalars['Float']>;
  /**
   * The type describes the type of cargo item stored in Zencargo,
   * which maps to the outermost type of cargo.
   * For example, a shipping container will always be CONTAINER
   * even if it contains PALLETS. If you want the specific loose cargo
   * inside a container, refer to the loose_cargo_type
   */
  type: CargoItemTypeEnum;
}

export interface CargoDimensionsInputType {
  height: Scalars['Float'];
  length: Scalars['Float'];
  metric: CargoDimensionsUnitEnum;
  width: Scalars['Float'];
}

export interface CargoDimensionsType {
  __typename?: 'CargoDimensionsType';
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  metric?: Maybe<CargoDimensionsUnitEnum>;
  width?: Maybe<Scalars['Float']>;
}

export enum CargoDimensionsUnitEnum {
  CM = 'CM',
  IN = 'IN',
  MM = 'MM'
}

export interface CargoInput {
  assorted: Array<AssortedInput>;
  boxes: Array<BoxesInput>;
  fcl: Array<ContainersInput>;
  ftl?: InputMaybe<TruckInput>;
  mode: CargoModeEnum;
  pallets: Array<PalletsInput>;
}

export enum CargoItemSubTypeEnum {
  ASSORTED_CARGO = 'ASSORTED_CARGO',
  BOXES_OR_CRATES = 'BOXES_OR_CRATES',
  PALLETS = 'PALLETS',
  STRAIGHT_TRUCK = 'STRAIGHT_TRUCK',
  TRAILER_3_5_T_BOX = 'TRAILER_3_5_T_BOX',
  TRAILER_3_5_T_CURTAIN_SIDED = 'TRAILER_3_5_T_CURTAIN_SIDED',
  TRAILER_7_5_T_BOX = 'TRAILER_7_5_T_BOX',
  TRAILER_7_5_T_CURTAIN_SIDED = 'TRAILER_7_5_T_CURTAIN_SIDED',
  TRAILER_7_5_T_SLIDING_ROOF = 'TRAILER_7_5_T_SLIDING_ROOF',
  TRAILER_13_6_M_BOX = 'TRAILER_13_6_M_BOX',
  TRAILER_13_6_M_CURTAIN_SIDED = 'TRAILER_13_6_M_CURTAIN_SIDED',
  TRAILER_13_6_M_HIAB = 'TRAILER_13_6_M_HIAB',
  TRAILER_13_6_M_MEGATRAILER = 'TRAILER_13_6_M_MEGATRAILER',
  TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED = 'TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED',
  TRAILER_13_6_M_REFRIGERATED = 'TRAILER_13_6_M_REFRIGERATED',
  TRAILER_13_6_M_SLIDING_ROOF = 'TRAILER_13_6_M_SLIDING_ROOF',
  TRAILER_18_T_BOX = 'TRAILER_18_T_BOX',
  TRAILER_18_T_CURTAIN_SIDED = 'TRAILER_18_T_CURTAIN_SIDED',
  TRAILER_18_T_HIAB = 'TRAILER_18_T_HIAB',
  TRAILER_18_T_REFRIGERATED = 'TRAILER_18_T_REFRIGERATED',
  TRAILER_18_T_SLIDING_ROOF = 'TRAILER_18_T_SLIDING_ROOF',
  TRAILER_53_FT_BOXED = 'TRAILER_53_FT_BOXED',
  VAN_BOX = 'VAN_BOX',
  VAN_CURTAIN_SIDED = 'VAN_CURTAIN_SIDED',
  VAN_SLIDING_ROOF = 'VAN_SLIDING_ROOF',
  X20DV = 'X20DV',
  X20HC = 'X20HC',
  X40DV = 'X40DV',
  X40HC = 'X40HC',
  X45HC = 'X45HC'
}

export enum CargoItemTypeEnum {
  CONTAINER = 'CONTAINER',
  LOOSE_CARGO = 'LOOSE_CARGO',
  VEHICLE = 'VEHICLE'
}

export interface CargoJourney {
  __typename?: 'CargoJourney';
  cargo?: Maybe<CoreCargo>;
  journey?: Maybe<Journey>;
}

export interface CargoJourneyDetail {
  __typename?: 'CargoJourneyDetail';
  actualCollectionDateTime?: Maybe<LocalDateTimeWithNoTimeZone>;
  actualDeliveryDateTime?: Maybe<LocalDateTimeWithNoTimeZone>;
  cargoId: Scalars['String'];
  collectionStop?: Maybe<AccountLocation>;
  deliveryStop?: Maybe<AccountLocation>;
  estimatedCollectionDateTime?: Maybe<LocalDateTimeWithNoTimeZone>;
  estimatedCollectionDateType?: Maybe<MilestoneEstimateDateType>;
  estimatedDeliveryDateTime?: Maybe<LocalDateTimeWithNoTimeZone>;
  estimatedDeliveryDateType?: Maybe<MilestoneEstimateDateType>;
}

export interface CargoJourneys {
  __typename?: 'CargoJourneys';
  cargoReadyDate?: Maybe<JourneyShippingCargoReadyDateMilestone>;
  journeys: Array<CargoJourney>;
}

export interface CargoJourneysSummary {
  __typename?: 'CargoJourneysSummary';
  cargosNotCollected: Array<CoreCargo>;
  collectedCargos: Array<Scalars['String']>;
  collectionStops: Array<CargoJourneysSummaryStop>;
  deliveredCargos: Array<Scalars['String']>;
  deliveryStops: Array<CargoJourneysSummaryStop>;
  milestones: Array<CargoJourneysSummaryMilestone>;
  scheduledDestinationStop?: Maybe<CargoJourneysSummaryScheduledStop>;
  scheduledOriginStop?: Maybe<CargoJourneysSummaryScheduledStop>;
  zencargoReference: Scalars['String'];
}

export interface CargoJourneysSummaryDateDetails {
  __typename?: 'CargoJourneysSummaryDateDetails';
  date?: Maybe<Scalars['ISO8601Date']>;
  dateType?: Maybe<CargoJourneysSummaryDateType>;
  hasDateExceptionBetweenInitialAndFinalDates: Scalars['Boolean'];
}

export enum CargoJourneysSummaryDateType {
  ACTUAL = 'ACTUAL',
  ESTIMATE = 'ESTIMATE'
}

export interface CargoJourneysSummaryMilestone {
  __typename?: 'CargoJourneysSummaryMilestone';
  lastCompletedName?: Maybe<JourneyShippingMilestoneNameEnum>;
  milestoneDatesDetailsWithCargos: Array<CargoJourneysSummaryMilestoneDateDetailsWithCargos>;
  modeOfTransport: JourneyModeOfTransportEnum;
  name: JourneyShippingMilestoneNameEnum;
  progressStage: CargoJourneysSummaryMilestoneProgressStage;
  stopAction: JourneyStopActionEnum;
}

export interface CargoJourneysSummaryMilestoneDateDetailsWithCargos {
  __typename?: 'CargoJourneysSummaryMilestoneDateDetailsWithCargos';
  cargos: Array<CoreCargo>;
  dateDetails?: Maybe<CargoJourneysSummaryDateDetails>;
}

export enum CargoJourneysSummaryMilestoneProgressStage {
  ARRIVED_AT_LAST_SCHEDULED_STOP_NOT_DELIVERED = 'ARRIVED_AT_LAST_SCHEDULED_STOP_NOT_DELIVERED',
  COLLECTED_NOT_DEPARTED_FROM_FIRST_SCHEDULED_STOP = 'COLLECTED_NOT_DEPARTED_FROM_FIRST_SCHEDULED_STOP',
  DELIVERED = 'DELIVERED',
  DEPARTED_FROM_FIRST_SCHEDULED_STOP_NOT_ARRIVED_AT_LAST_SCHEDULED_STOP = 'DEPARTED_FROM_FIRST_SCHEDULED_STOP_NOT_ARRIVED_AT_LAST_SCHEDULED_STOP',
  NOT_COLLECTED = 'NOT_COLLECTED'
}

export interface CargoJourneysSummaryScheduledStop {
  __typename?: 'CargoJourneysSummaryScheduledStop';
  dateDetails?: Maybe<CargoJourneysSummaryDateDetails>;
  isCompleted: Scalars['Boolean'];
  locationType: JourneyStopLocationTypeEnum;
  terminal?: Maybe<Terminal>;
  warehouse?: Maybe<NetworksOrgLoc>;
  warehouseLocation?: Maybe<AccountLocation>;
}

export interface CargoJourneysSummaryStop {
  __typename?: 'CargoJourneysSummaryStop';
  id: Scalars['String'];
  locationType: JourneyStopLocationTypeEnum;
  stopAction: JourneyStopActionEnum;
  terminal?: Maybe<Terminal>;
  warehouse?: Maybe<NetworksOrgLoc>;
  warehouseLocation?: Maybe<AccountLocation>;
}

export interface CargoLooseCargoType extends CargoCargoItem {
  __typename?: 'CargoLooseCargoType';
  canManageAssignedLots: AuthorizationResult;
  canManageCollectionLocation: AuthorizationResult;
  canManageDeliveryLocation: AuthorizationResult;
  canUpdateVehicleTrailerId: AuthorizationResult;
  canViewCollectionDetails: AuthorizationResult;
  canViewDeliveryDetails: AuthorizationResult;
  /** The volume of the goods inside the cargo, in cubic meters */
  cbm?: Maybe<Scalars['Float']>;
  chargeableWeight?: Maybe<CargoWeightType>;
  collection?: Maybe<CollectionDetails>;
  /** Container number */
  containerNumber?: Maybe<Scalars['String']>;
  delivery?: Maybe<DeliveryDetails>;
  dimensions?: Maybe<CargoDimensionsType>;
  /** The gross volume of the cargo including all packaging and containers */
  grossWeight?: Maybe<CargoWeightType>;
  hazardous?: Maybe<Scalars['Boolean']>;
  /** Cargo id */
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  /**
   * The type of loose cargo inside this cargo item.
   * If the cargo type is itself loose, then this represents
   * the specific type being shipped. If it is a container or vehicle,
   * this tells you how the goods inside the outer container have
   * been packaged.
   */
  looseCargoType?: Maybe<LooseCargoTypeEnum>;
  palletType?: Maybe<PalletTypeEnum>;
  /**
   * The quantity of loose cargo items, such as pallets or boxes,
   * inside this cargo item. For containers, this is sometimes
   * called the piece count
   */
  quantity?: Maybe<Scalars['Int']>;
  reefer?: Maybe<Scalars['Boolean']>;
  stackable: Scalars['Boolean'];
  /** The id of the trailer */
  trailerId?: Maybe<Scalars['String']>;
  /**
   * The type describes the type of cargo item stored in Zencargo,
   * which maps to the outermost type of cargo.
   * For example, a shipping container will always be CONTAINER
   * even if it contains PALLETS. If you want the specific loose cargo
   * inside a container, refer to the loose_cargo_type
   */
  type: CargoItemTypeEnum;
}

export interface CargoMetadataContainerType {
  __typename?: 'CargoMetadataContainerType';
  cargoType?: Maybe<CargoItemTypeEnum>;
  containerNumber?: Maybe<Scalars['String']>;
  containerType?: Maybe<ContainerTypeEnum>;
}

export interface CargoMetadataLooseCargoType {
  __typename?: 'CargoMetadataLooseCargoType';
  cargoType?: Maybe<CargoItemTypeEnum>;
  looseCargoType?: Maybe<LooseCargoTypeEnum>;
  palletType?: Maybe<PalletTypeEnum>;
  quantity?: Maybe<Scalars['Int']>;
}

export interface CargoMetadataVehicleType {
  __typename?: 'CargoMetadataVehicleType';
  cargoType?: Maybe<CargoItemTypeEnum>;
  looseCargoType?: Maybe<LooseCargoTypeEnum>;
  quantity?: Maybe<Scalars['Int']>;
  vehicleType?: Maybe<VehicleTypeEnum>;
}

export enum CargoModeEnum {
  FCL = 'FCL',
  FTL = 'FTL',
  LOOSE = 'LOOSE'
}

export enum CargoOptionEnum {
  DRY_STANDARD = 'DRY_STANDARD',
  FLAT_RACK = 'FLAT_RACK',
  FORK_LIFT = 'FORK_LIFT',
  GARMENTS_ON_HANGERS = 'GARMENTS_ON_HANGERS',
  HAZARDOUS = 'HAZARDOUS',
  HEALTH_CERTIFICATE = 'HEALTH_CERTIFICATE',
  OPEN_TOP = 'OPEN_TOP',
  OVERWEIGHT = 'OVERWEIGHT',
  REFRIGERATED = 'REFRIGERATED',
  STACKABLE = 'STACKABLE',
  TAIL_LIFT = 'TAIL_LIFT'
}

export interface CargoOptions {
  __typename?: 'CargoOptions';
  cargoType: Array<CargoModeEnum>;
  name: CargoOptionEnum;
}

export interface CargoRelation {
  __typename?: 'CargoRelation';
  endTimestamp?: Maybe<Scalars['String']>;
  inputCargo?: Maybe<CoreCargo>;
  relation?: Maybe<Relation>;
  startTimestamp: Scalars['String'];
  state?: Maybe<State>;
}

export interface CargoSummary {
  __typename?: 'CargoSummary';
  cargoItemsCount: Scalars['Int'];
  highestRiskLevel?: Maybe<RiskLevelsEnum>;
  label?: Maybe<Scalars['String']>;
  totalActualCbm?: Maybe<Scalars['Float']>;
  totalChargeableWeight?: Maybe<Array<CargoWeightType>>;
  totalGrossWeight?: Maybe<Array<CargoWeightType>>;
  totalValueOfGoods?: Maybe<Array<Money>>;
  type?: Maybe<CargoItemTypeEnum>;
}

export interface CargoVehicleType extends CargoCargoItem {
  __typename?: 'CargoVehicleType';
  actualCbm?: Maybe<Scalars['Float']>;
  canManageAssignedLots: AuthorizationResult;
  canManageCollectionLocation: AuthorizationResult;
  canManageDeliveryLocation: AuthorizationResult;
  canUpdateVehicleTrailerId: AuthorizationResult;
  canViewCollectionDetails: AuthorizationResult;
  canViewDeliveryDetails: AuthorizationResult;
  cargoDetails?: Maybe<Scalars['String']>;
  /** The volume of the goods inside the cargo, in cubic meters */
  cbm?: Maybe<Scalars['Float']>;
  collection?: Maybe<CollectionDetails>;
  delivery?: Maybe<DeliveryDetails>;
  dimensions?: Maybe<CargoDimensionsType>;
  forklift?: Maybe<Scalars['Boolean']>;
  /** The gross volume of the cargo including all packaging and containers */
  grossWeight?: Maybe<CargoWeightType>;
  hazardous?: Maybe<Scalars['Boolean']>;
  healthCertificate?: Maybe<Scalars['Boolean']>;
  /** Cargo id */
  id: Scalars['String'];
  label?: Maybe<Scalars['String']>;
  /**
   * The type of loose cargo inside this cargo item.
   * If the cargo type is itself loose, then this represents
   * the specific type being shipped. If it is a container or vehicle,
   * this tells you how the goods inside the outer container have
   * been packaged.
   */
  looseCargoType?: Maybe<LooseCargoTypeEnum>;
  palletType?: Maybe<PalletTypeEnum>;
  /**
   * The quantity of loose cargo items, such as pallets or boxes,
   * inside this cargo item. For containers, this is sometimes
   * called the piece count
   */
  quantity?: Maybe<Scalars['Int']>;
  reefer?: Maybe<Scalars['Boolean']>;
  riskLevel?: Maybe<RiskLevelsEnum>;
  tailLift?: Maybe<Scalars['Boolean']>;
  /** The id of the trailer */
  trailerId?: Maybe<Scalars['String']>;
  /**
   * The type describes the type of cargo item stored in Zencargo,
   * which maps to the outermost type of cargo.
   * For example, a shipping container will always be CONTAINER
   * even if it contains PALLETS. If you want the specific loose cargo
   * inside a container, refer to the loose_cargo_type
   */
  type: CargoItemTypeEnum;
  valueOfGoods?: Maybe<Money>;
  vehicleType?: Maybe<VehicleTypeEnum>;
}

export interface CargoWeight {
  __typename?: 'CargoWeight';
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface CargoWeightInput {
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface CargoWeightInputType {
  metric?: InputMaybe<CargoWeightUnitEnum>;
  value?: InputMaybe<Scalars['Float']>;
}

export interface CargoWeightType {
  __typename?: 'CargoWeightType';
  /** Weight metric e.g. kg */
  metric?: Maybe<CargoWeightUnitEnum>;
  /** The value of the weight, expressed in a floating point number */
  value?: Maybe<Scalars['Float']>;
}

export enum CargoWeightUnitEnum {
  KG = 'KG',
  LBS = 'LBS'
}

export interface CentralPortCharge {
  __typename?: 'CentralPortCharge';
  chargeType: ChargeType;
  chargeValue: Scalars['Float'];
  createdAt: Scalars['DateTime'];
  currency: Currency;
  id: Scalars['String'];
  incoterms: Array<IncotermsValue>;
  port: Terminal;
  portChargeType: OriginDestinationChargeTypeEnum;
}

export interface CentralPortChargeEdge {
  __typename?: 'CentralPortChargeEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CentralPortCharge>;
}

export interface ChargeBasis {
  __typename?: 'ChargeBasis';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
}

export enum ChargeBasisEnum {
  BILL = 'BILL',
  BOOKING = 'BOOKING',
  CHARGEABLE_WEIGHT = 'CHARGEABLE_WEIGHT',
  CONTAINER = 'CONTAINER',
  DAY = 'DAY',
  HOUR = 'HOUR',
  HS_CODE = 'HS_CODE',
  LABEL = 'LABEL',
  OTHER = 'OTHER',
  PALLET = 'PALLET',
  TEU = 'TEU',
  WEIGHT_MEASURE_AIR = 'WEIGHT_MEASURE_AIR',
  WEIGHT_MEASURE_OCEAN = 'WEIGHT_MEASURE_OCEAN'
}

export enum ChargeBasisGroup {
  BILL = 'BILL',
  BOOKING = 'BOOKING',
  CARGO = 'CARGO'
}

export enum ChargeClassification {
  DESTINATION = 'DESTINATION',
  DESTINATION_HAULAGE = 'DESTINATION_HAULAGE',
  DESTINATION_PORT = 'DESTINATION_PORT',
  FREIGHT = 'FREIGHT',
  ORIGIN = 'ORIGIN',
  ORIGIN_HAULAGE = 'ORIGIN_HAULAGE',
  ORIGIN_PORT = 'ORIGIN_PORT',
  OTHER = 'OTHER'
}

export interface ChargeConnection {
  __typename?: 'ChargeConnection';
  edges?: Maybe<Array<Maybe<ChargeEdge>>>;
  nodes?: Maybe<Array<Maybe<ChargesPayload>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface ChargeEdge {
  __typename?: 'ChargeEdge';
  cursor: Scalars['String'];
  node?: Maybe<ChargesPayload>;
}

export interface ChargeGroup {
  __typename?: 'ChargeGroup';
  id: Scalars['String'];
  name: Scalars['String'];
  type: ChargeGroupType;
}

export enum ChargeGroupType {
  ADDITIONAL_CHARGES = 'ADDITIONAL_CHARGES',
  BOOKING_MANAGEMENT = 'BOOKING_MANAGEMENT',
  CUSTOMS_CLEARANCE = 'CUSTOMS_CLEARANCE',
  FREIGHT = 'FREIGHT',
  HAULAGE = 'HAULAGE',
  TERMINAL_CHARGES = 'TERMINAL_CHARGES',
  WAREHOUSING = 'WAREHOUSING'
}

export interface ChargeInput {
  applicability: Applicability;
  chargeTypeId: Scalars['String'];
  costItems?: InputMaybe<Array<CostTrackingCostItemInput>>;
  revenueItems?: InputMaybe<Array<CostTrackingRevenueItemInput>>;
}

export enum ChargeSorting {
  APPLICABILITY = 'APPLICABILITY',
  GROUP = 'GROUP',
  NAME = 'NAME'
}

export interface ChargeType {
  __typename?: 'ChargeType';
  chargeBasis?: Maybe<ChargeBasisEnum>;
  chargeType?: Maybe<ChargeTypeEnum>;
  deletedAt?: Maybe<Scalars['String']>;
  fortyFiveFtHc?: Maybe<Scalars['Boolean']>;
  fortyFtDv?: Maybe<Scalars['Boolean']>;
  fortyFtHc?: Maybe<Scalars['Boolean']>;
  hazardous?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  modeOfTransport?: Maybe<ModeOfTransport>;
  name: Scalars['String'];
  overweight?: Maybe<Scalars['Boolean']>;
  reefer?: Maybe<Scalars['Boolean']>;
  twentyFtDv?: Maybe<Scalars['Boolean']>;
  twentyFtHc?: Maybe<Scalars['Boolean']>;
  vatApplicable: Scalars['Boolean'];
}

/** The connection type for ChargeType. */
export interface ChargeTypeConnection {
  __typename?: 'ChargeTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ChargeTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ChargeType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ChargeTypeEdge {
  __typename?: 'ChargeTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ChargeType>;
}

export enum ChargeTypeEnum {
  DESTINATION = 'DESTINATION',
  FREIGHT = 'FREIGHT',
  ORIGIN = 'ORIGIN'
}

export interface ChargeTypeInput {
  chargeBasis: Scalars['String'];
  chargeType: ChargeTypeEnum;
  deletedAt?: InputMaybe<Scalars['String']>;
  fortyFiveFtHc: Scalars['Boolean'];
  fortyFtDv: Scalars['Boolean'];
  fortyFtHc: Scalars['Boolean'];
  hazardous: Scalars['Boolean'];
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  name: Scalars['String'];
  overweight: Scalars['Boolean'];
  reefer: Scalars['Boolean'];
  twentyFtDv: Scalars['Boolean'];
  twentyFtHc: Scalars['Boolean'];
  vatApplicable: Scalars['Boolean'];
}

export interface ChargesPayload {
  __typename?: 'ChargesPayload';
  applicability: Array<Applicability>;
  basisGroup: ChargeBasisGroup;
  description: Scalars['String'];
  group: ChargeGroup;
  id: Scalars['String'];
  name: Scalars['String'];
}

export interface CheckForDuplicateAccountsInput {
  /** The id of the account in the current context which will be filtered out from the result set */
  accountId: Scalars['String'];
  accountTradingName: Scalars['String'];
  contactEmail: Scalars['String'];
}

export interface CheckForDuplicateAccountsPayload {
  __typename?: 'CheckForDuplicateAccountsPayload';
  errors: Array<Error>;
  possibleDuplicates: Array<Account>;
}

/** Autogenerated input type of CheckRule */
export interface CheckRuleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  entityType: EntityTypesEnum;
  evaluationCode: Scalars['String'];
  name: Scalars['String'];
  query: Scalars['String'];
  validationEntityId: Scalars['String'];
}

/** Autogenerated return type of CheckRule */
export interface CheckRulePayload {
  __typename?: 'CheckRulePayload';
  checkErrors?: Maybe<Array<Error>>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  result?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of ClearShipmentStatus */
export interface ClearShipmentStatusInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of ClearShipmentStatus */
export interface ClearShipmentStatusPayload {
  __typename?: 'ClearShipmentStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  status?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of ClosePurchaseOrder */
export interface ClosePurchaseOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID (pk) of purchase order to be closed. */
  purchaseOrderId: Scalars['String'];
}

/** Autogenerated return type of ClosePurchaseOrder */
export interface ClosePurchaseOrderPayload {
  __typename?: 'ClosePurchaseOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export enum CollectionAndDeliveryState {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  REQUESTED = 'REQUESTED'
}

export interface CollectionDetails {
  __typename?: 'CollectionDetails';
  arrivedAtWarehouse?: Maybe<LocalDateTimeType>;
  completedOn?: Maybe<Scalars['String']>;
  confirmedByForwarder?: Maybe<LocalDateTimeRange>;
  departedFromWarehouse?: Maybe<LocalDateTimeType>;
  driverDetails?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latestDate?: Maybe<LocalDateTimeRange>;
  location?: Maybe<NetworksAssignableInterface>;
  reference?: Maybe<Scalars['String']>;
  requestedWithForwarder?: Maybe<LocalDateTimeRange>;
  scheduleDate?: Maybe<Scalars['String']>;
  scheduleTime?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['String']>;
  specificTime?: Maybe<SpecificTime>;
  state?: Maybe<CollectionAndDeliveryState>;
  timeMode?: Maybe<Mode>;
  timedDelivery?: Maybe<Scalars['Boolean']>;
  vehiclePlateNumber?: Maybe<Scalars['String']>;
}

export interface CollectionSchedule {
  collectionDate: Scalars['Date'];
  collectionFrom: BookingCollectionAndDeliveryLocationInput;
  collectionReference: Scalars['String'];
  collectionTime?: InputMaybe<TimedCollection>;
  driverDetails?: InputMaybe<Scalars['String']>;
  vehiclePlateNumber?: InputMaybe<Scalars['String']>;
}

export interface CommodityCodes {
  __typename?: 'CommodityCodes';
  code: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['Int'];
}

/** The connection type for CommodityCodes. */
export interface CommodityCodesConnection {
  __typename?: 'CommodityCodesConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CommodityCodesEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CommodityCodes>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface CommodityCodesEdge {
  __typename?: 'CommodityCodesEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CommodityCodes>;
}

export interface Company {
  __typename?: 'Company';
  id: Scalars['String'];
  name: Scalars['String'];
}

/** Autogenerated input type of CompleteBooking */
export interface CompleteBookingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  completedAt: Scalars['ISO8601DateTime'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CompleteBooking */
export interface CompleteBookingPayload {
  __typename?: 'CompleteBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CompleteCollection */
export interface CompleteCollectionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectedOn: Scalars['Date'];
  id: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CompleteCollection */
export interface CompleteCollectionPayload {
  __typename?: 'CompleteCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CompleteDelivery */
export interface CompleteDeliveryInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  deliveredOn: Scalars['Date'];
  id: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CompleteDelivery */
export interface CompleteDeliveryPayload {
  __typename?: 'CompleteDeliveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CompleteWorkflowTaskInput {
  taskId: Scalars['String'];
  workflowTaskVariables?: InputMaybe<Array<InputMaybe<KeyValuePairInput>>>;
}

export interface CompleteWorkflowTaskPayload {
  __typename?: 'CompleteWorkflowTaskPayload';
  errors?: Maybe<Array<Error>>;
}

export enum ConfidenceLevel {
  ACTUAL = 'actual',
  ESTIMATED = 'estimated'
}

/** Autogenerated input type of ConfirmCollection */
export interface ConfirmCollectionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of ConfirmCollection */
export interface ConfirmCollectionPayload {
  __typename?: 'ConfirmCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of ConfirmDelivery */
export interface ConfirmDeliveryInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of ConfirmDelivery */
export interface ConfirmDeliveryPayload {
  __typename?: 'ConfirmDeliveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface ConfirmJourneyRoadLegInput {
  cargoId: Scalars['String'];
  collectionDate: LocalDateTimeRangeInput;
  deliveryDate?: InputMaybe<LocalDateTimeRangeInput>;
  haulierId: Scalars['String'];
  journeyLegId: Scalars['String'];
  vehicleId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface ConfirmJourneyRoadLegPayload {
  __typename?: 'ConfirmJourneyRoadLegPayload';
  errors?: Maybe<Array<Error>>;
}

export interface ConfirmJourneyScheduleInput {
  cargoIds: Array<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface ConfirmJourneySchedulePayload {
  __typename?: 'ConfirmJourneySchedulePayload';
  errors?: Maybe<Array<Error>>;
}

export enum ConnectionSourceTargetEnum {
  BOTH = 'BOTH',
  SOURCE = 'SOURCE',
  TARGET = 'TARGET'
}

export interface ConsolidatedCargo {
  __typename?: 'ConsolidatedCargo';
  consolidatedCargoItems?: Maybe<Array<CargoConsolidatedItemType>>;
  consolidatedLooseCargoItems?: Maybe<Array<ConsolidatedLooseCargoItem>>;
}

export interface ConsolidatedLooseCargoItem {
  __typename?: 'ConsolidatedLooseCargoItem';
  quantity?: Maybe<Scalars['Int']>;
  type: LooseCargoTypeEnum;
}

export interface ConsolidatedShipment {
  __typename?: 'ConsolidatedShipment';
  accountUuid: Scalars['String'];
  actualArrivalDate?: Maybe<Scalars['Date']>;
  actualDepartureDate?: Maybe<Scalars['Date']>;
  canCopy: AuthorizationResult;
  canEdit: AuthorizationResult;
  canManage: AuthorizationResult;
  canViewDetails: AuthorizationResult;
  cbm?: Maybe<Scalars['Float']>;
  cutOffDate: Scalars['Date'];
  estimatedArrivalDate: Scalars['Date'];
  grossWeight?: Maybe<Weight>;
  id: Scalars['String'];
  legs: Array<ConsolidationLegType>;
  looseCargoType?: Maybe<ConsolidationLooseCargoTypeEnum>;
  mblDocumentReference?: Maybe<Scalars['String']>;
  modeOfTransport: ConsolidationModeOfTransportEnum;
  networksDestination?: Maybe<NetworksAssignableInterface>;
  networksOrigin?: Maybe<NetworksAssignableInterface>;
  originAgent?: Maybe<NetworksAssignableInterface>;
  quantity?: Maybe<Scalars['Int']>;
  reference: Scalars['String'];
  sealNumber?: Maybe<Scalars['String']>;
  transitDocumentReference?: Maybe<Scalars['String']>;
  vehiclePlateNumber: Scalars['String'];
}

/** The connection type for ConsolidatedShipment. */
export interface ConsolidatedShipmentConnection {
  __typename?: 'ConsolidatedShipmentConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ConsolidatedShipmentEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ConsolidatedShipment>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface ConsolidatedShipmentCreateInput {
  accountUuid: Scalars['String'];
  cbm?: InputMaybe<Scalars['Float']>;
  cutOffDate: Scalars['Date'];
  grossWeight?: InputMaybe<ConsolidationWeightInputType>;
  legs: Array<ConsolidationLegInputType>;
  looseCargoType?: InputMaybe<ConsolidationLooseCargoTypeEnum>;
  mblDocumentReference?: InputMaybe<Scalars['String']>;
  modeOfTransport?: InputMaybe<ConsolidationModeOfTransportEnum>;
  originAgentId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  reference: Scalars['String'];
  sealNumber?: InputMaybe<Scalars['String']>;
  transitDocumentReference?: InputMaybe<Scalars['String']>;
  vehiclePlateNumber?: InputMaybe<Scalars['String']>;
}

/** An edge in a connection. */
export interface ConsolidatedShipmentEdge {
  __typename?: 'ConsolidatedShipmentEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ConsolidatedShipment>;
}

export interface ConsolidatedShipmentUpdateInput {
  cbm?: InputMaybe<Scalars['Float']>;
  cutOffDate: Scalars['Date'];
  grossWeight?: InputMaybe<ConsolidationWeightInputType>;
  legs: Array<ConsolidationLegInputType>;
  looseCargoType?: InputMaybe<ConsolidationLooseCargoTypeEnum>;
  mblDocumentReference?: InputMaybe<Scalars['String']>;
  originAgentId?: InputMaybe<Scalars['String']>;
  quantity?: InputMaybe<Scalars['Int']>;
  reference: Scalars['String'];
  sealNumber?: InputMaybe<Scalars['String']>;
  transitDocumentReference?: InputMaybe<Scalars['String']>;
  vehiclePlateNumber?: InputMaybe<Scalars['String']>;
}

export interface Consolidation {
  __typename?: 'Consolidation';
  canAccessRoute: AuthorizationResult;
  canAddConsolidatedShipment: AuthorizationResult;
  canAssignAgent: AuthorizationResult;
  canManageConsolidatedShipment: AuthorizationResult;
  canViewCustomers: AuthorizationResult;
}

export interface ConsolidationLegInputType {
  actualArrivalDate?: InputMaybe<Scalars['Date']>;
  actualDepartureDate?: InputMaybe<Scalars['Date']>;
  estimatedArrivalDate: Scalars['Date'];
  estimatedDepartureDate: Scalars['Date'];
  id?: InputMaybe<Scalars['String']>;
  networksDestinationId: Scalars['String'];
  networksOriginId: Scalars['String'];
}

export interface ConsolidationLegType {
  __typename?: 'ConsolidationLegType';
  actualArrivalDate?: Maybe<Scalars['Date']>;
  actualDepartureDate?: Maybe<Scalars['Date']>;
  estimatedArrivalDate: Scalars['Date'];
  estimatedDepartureDate: Scalars['Date'];
  id: Scalars['String'];
  networksDestination: NetworksAssignableInterface;
  networksOrigin: NetworksAssignableInterface;
}

export enum ConsolidationLooseCargoTypeEnum {
  ASSORTED_CARGO = 'ASSORTED_CARGO',
  BOXES_OR_CRATES = 'BOXES_OR_CRATES',
  PALLETS = 'PALLETS'
}

export enum ConsolidationModeOfTransportEnum {
  OCEAN = 'OCEAN',
  ROAD = 'ROAD'
}

export interface ConsolidationWeightInputType {
  metric?: InputMaybe<WeightUnitEnum>;
  value?: InputMaybe<Scalars['Float']>;
}

export interface ContactInput {
  autoSubscribeToOrganisationTargets?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber: Scalars['String'];
}

export interface ContactLocationAssignmentInput {
  locationId: Scalars['String'];
  mainContact: Scalars['Boolean'];
}

export enum ContactStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  GDPRREQUEST = 'GDPRREQUEST'
}

export enum ContactTypeEnum {
  CARRIER = 'CARRIER',
  CUSTOMER = 'CUSTOMER',
  HAULIER = 'HAULIER',
  SUPPLIER = 'SUPPLIER',
  WAREHOUSE = 'WAREHOUSE'
}

export interface ContainerCharge {
  __typename?: 'ContainerCharge';
  chargeValue: Scalars['Float'];
  currency: Currency;
  id: Scalars['String'];
  unitType: ContainerTypeEnum;
}

export interface ContainerChargeInput {
  chargeValue: Scalars['Float'];
  currency: Currency;
  unitType: ContainerTypeEnum;
}

export enum ContainerTypeEnum {
  X20DV = 'X20DV',
  X20HC = 'X20HC',
  X40DV = 'X40DV',
  X40HC = 'X40HC',
  X45HC = 'X45HC'
}

export interface ContainersInput {
  containerType: ContainerTypeEnum;
  dryStandard?: InputMaybe<Scalars['Boolean']>;
  flatRack?: InputMaybe<Scalars['Boolean']>;
  garmentsOnHangers?: InputMaybe<Scalars['Boolean']>;
  hazardous: Scalars['Boolean'];
  openTop?: InputMaybe<Scalars['Boolean']>;
  overweight: Scalars['Boolean'];
  quantity: Scalars['Int'];
  reefer: Scalars['Boolean'];
}

export interface ContractConnection {
  __typename?: 'ContractConnection';
  edges?: Maybe<Array<Maybe<ContractEdge>>>;
  nodes?: Maybe<Array<Maybe<ContractPayload>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
}

export interface ContractCreationInput {
  carrierId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['String'];
  contractType?: InputMaybe<ContractType>;
  simplifiedContractId: Scalars['String'];
}

export interface ContractCreationPayload {
  __typename?: 'ContractCreationPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contracts: Array<ContractPayload>;
  errors?: Maybe<Array<Error>>;
}

export interface ContractDeletePayload {
  __typename?: 'ContractDeletePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface ContractEdge {
  __typename?: 'ContractEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ContractPayload>;
}

export interface ContractInput {
  carrierId?: InputMaybe<Scalars['String']>;
  contractId: Scalars['String'];
  contractType?: InputMaybe<ContractType>;
  id: Scalars['String'];
  simplifiedContractId: Scalars['String'];
}

export interface ContractPayload {
  __typename?: 'ContractPayload';
  carrierId?: Maybe<Scalars['String']>;
  contractId: Scalars['String'];
  contractType?: Maybe<ContractType>;
  id: Scalars['String'];
  oceanCarrier?: Maybe<OceanCarrier>;
  simplifiedContractId: Scalars['String'];
}

export enum ContractType {
  BLOCK_SPACE = 'BLOCK_SPACE',
  CUSTOMER_OWNED = 'CUSTOMER_OWNED',
  FAK = 'FAK',
  INDEX_LINKED = 'INDEX_LINKED',
  NAC = 'NAC',
  NAC_BASKET_LONG_TERM = 'NAC_BASKET_LONG_TERM',
  NAC_BASKET_SHORT_TERM = 'NAC_BASKET_SHORT_TERM',
  PREPAID = 'PREPAID',
  SPOT = 'SPOT'
}

export interface ContractUpdatePayload {
  __typename?: 'ContractUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  contract?: Maybe<ContractPayload>;
  errors?: Maybe<Array<Error>>;
}

export interface ControlTower {
  __typename?: 'ControlTower';
  canCreateExternalForwarderBooking: AuthorizationResult;
  canEnable: AuthorizationResult;
  canUpdatePurchaseOrderForwarder: AuthorizationResult;
  canViewForwardersOnPurchaseOrders: AuthorizationResult;
  canViewForwardersOnShipments: AuthorizationResult;
  enabled?: Maybe<Scalars['Boolean']>;
}

export interface CoreCargo {
  __typename?: 'CoreCargo';
  accountId?: Maybe<Scalars['String']>;
  cargoAssignments?: Maybe<Array<CargoAssignment>>;
  cargoRelations?: Maybe<Array<CargoRelation>>;
  cargoType: Scalars['String'];
  chargeableWeight?: Maybe<CargoWeight>;
  configurationId?: Maybe<Scalars['String']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerSealNumber?: Maybe<Scalars['String']>;
  customFields?: Maybe<Array<CustomField>>;
  deletedAt?: Maybe<Scalars['String']>;
  dimensions?: Maybe<Dimensions>;
  dryStandard?: Maybe<Scalars['Boolean']>;
  flatRack?: Maybe<Scalars['Boolean']>;
  forklift?: Maybe<Scalars['Boolean']>;
  garmentsOnHangers?: Maybe<Scalars['Boolean']>;
  grossWeight?: Maybe<CargoWeight>;
  hazardous?: Maybe<Scalars['Boolean']>;
  healthCertificate?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  journey?: Maybe<Journey>;
  openTop?: Maybe<Scalars['Boolean']>;
  overweight?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  refrigerated?: Maybe<Scalars['Boolean']>;
  riskLevel?: Maybe<Scalars['String']>;
  stackable?: Maybe<Scalars['Boolean']>;
  tailLift?: Maybe<Scalars['Boolean']>;
  temperature?: Maybe<Temperature>;
  trailerId?: Maybe<Scalars['String']>;
  unCode?: Maybe<Scalars['String']>;
  valueOfGoods?: Maybe<ValueOfGoods>;
  volume?: Maybe<Volume>;
}

export interface Cost {
  __typename?: 'Cost';
  chargeType: ChargeType;
  currency: Currency;
  exchangeRate: Scalars['Float'];
  id: Scalars['String'];
  quantity: Scalars['Float'];
  supplierName: Scalars['String'];
  totalGbp: Scalars['Float'];
  totalLocalCcy: Scalars['Float'];
  unitCost: Scalars['Float'];
}

export interface CostInput {
  chargeTypeId: Scalars['String'];
  currency: Scalars['String'];
  exchangeRate: Scalars['Float'];
  id: Scalars['String'];
  quantity: Scalars['Float'];
  supplierName: Scalars['String'];
  totalGbp: Scalars['Float'];
  totalLocalCcy: Scalars['Float'];
  unitCost: Scalars['Float'];
}

export interface CostItem {
  __typename?: 'CostItem';
  chargeDetails?: Maybe<Scalars['String']>;
  cost: Cost;
  id: Scalars['String'];
  margin: Margin;
  revenue: Revenue;
}

export interface CostItemInput {
  chargeDetails?: InputMaybe<Scalars['String']>;
  cost: CostInput;
  id: Scalars['String'];
  margin: MarginInput;
  revenue: RevenueInput;
}

export interface CostTracking {
  __typename?: 'CostTracking';
  charges?: Maybe<Array<CostTrackingChargePayload>>;
  costTrackingId: Scalars['String'];
  totals: CostTrackingTotals;
  zencargoReference: Scalars['String'];
}

export interface CostTrackingChargePayload {
  __typename?: 'CostTrackingChargePayload';
  applicability: Applicability;
  chargeType: ChargesPayload;
  costItems?: Maybe<Array<CostTrackingCostItem>>;
  id: Scalars['String'];
  revenueItems?: Maybe<Array<CostTrackingRevenueItem>>;
}

export interface CostTrackingCostItem {
  __typename?: 'CostTrackingCostItem';
  currency: Currency;
  id: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  paidFrom: Scalars['String'];
  paidTo: Scalars['String'];
  quantity: Scalars['Float'];
  status?: Maybe<CostTrackingCostItemStatus>;
  total: Scalars['Float'];
  unitPrice: Scalars['Float'];
}

export interface CostTrackingCostItemInput {
  currency: Currency;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  paidFrom: Scalars['String'];
  paidTo: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
}

export enum CostTrackingCostItemStatus {
  APPROVED = 'APPROVED',
  CREDITED = 'CREDITED',
  DRAFT = 'DRAFT',
  MATCHED = 'MATCHED'
}

export type CostTrackingLocation = {
  id?: Maybe<Scalars['String']>;
  label?: Maybe<NetworksLabelType>;
};

export interface CostTrackingLocationConnection {
  __typename?: 'CostTrackingLocationConnection';
  edges?: Maybe<Array<Maybe<CostTrackingLocationEdge>>>;
  nodes?: Maybe<Array<Maybe<CostTrackingLocation>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface CostTrackingLocationEdge {
  __typename?: 'CostTrackingLocationEdge';
  cursor: Scalars['String'];
  node?: Maybe<CostTrackingLocation>;
}

export enum CostTrackingLocationType {
  SHIPPABLE_LOCATION = 'SHIPPABLE_LOCATION',
  TERMINAL = 'TERMINAL'
}

export interface CostTrackingPayload {
  __typename?: 'CostTrackingPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  costTracking?: Maybe<CostTracking>;
  errors?: Maybe<Array<Error>>;
}

export interface CostTrackingRevenueItem {
  __typename?: 'CostTrackingRevenueItem';
  currency: Currency;
  id: Scalars['String'];
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  paidFrom: Scalars['String'];
  paidTo: Scalars['String'];
  quantity: Scalars['Float'];
  status: CostTrackingRevenueItemStatus;
  total: Scalars['Float'];
  unitPrice: Scalars['Float'];
}

export interface CostTrackingRevenueItemInput {
  currency: Currency;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  paidFrom: Scalars['String'];
  paidTo: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
}

export enum CostTrackingRevenueItemStatus {
  APPROVED = 'APPROVED',
  CREDITED = 'CREDITED',
  DRAFT = 'DRAFT',
  INVOICED = 'INVOICED'
}

export interface CostTrackingTotals {
  __typename?: 'CostTrackingTotals';
  displayCurrency: Currency;
  margin: Scalars['Float'];
  profit: Scalars['Float'];
  thresholdDate: Scalars['Date'];
  totalCostInDisplayCurrency: Scalars['Float'];
  totalRevenueInDisplayCurrency: Scalars['Float'];
}

export interface Country {
  __typename?: 'Country';
  code: CountryCode;
  name: Scalars['String'];
}

export enum CountryCode {
  AD = 'AD',
  AE = 'AE',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AL = 'AL',
  AM = 'AM',
  AO = 'AO',
  AQ = 'AQ',
  AR = 'AR',
  AS = 'AS',
  AT = 'AT',
  AU = 'AU',
  AW = 'AW',
  AX = 'AX',
  AZ = 'AZ',
  BA = 'BA',
  BB = 'BB',
  BD = 'BD',
  BE = 'BE',
  BF = 'BF',
  BG = 'BG',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BQ = 'BQ',
  BR = 'BR',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BY = 'BY',
  BZ = 'BZ',
  CA = 'CA',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CH = 'CH',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CU = 'CU',
  CV = 'CV',
  CW = 'CW',
  CX = 'CX',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  DJ = 'DJ',
  DK = 'DK',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EE = 'EE',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ES = 'ES',
  ET = 'ET',
  FI = 'FI',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  FO = 'FO',
  FR = 'FR',
  GA = 'GA',
  GB = 'GB',
  GD = 'GD',
  GE = 'GE',
  GF = 'GF',
  GG = 'GG',
  GH = 'GH',
  GI = 'GI',
  GL = 'GL',
  GM = 'GM',
  GN = 'GN',
  GP = 'GP',
  GQ = 'GQ',
  GR = 'GR',
  GS = 'GS',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HK = 'HK',
  HM = 'HM',
  HN = 'HN',
  HR = 'HR',
  HT = 'HT',
  HU = 'HU',
  ID = 'ID',
  IE = 'IE',
  IL = 'IL',
  IM = 'IM',
  IN = 'IN',
  IO = 'IO',
  IQ = 'IQ',
  IR = 'IR',
  IS = 'IS',
  IT = 'IT',
  JE = 'JE',
  JM = 'JM',
  JO = 'JO',
  JP = 'JP',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KR = 'KR',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LI = 'LI',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  LY = 'LY',
  MA = 'MA',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MG = 'MG',
  MH = 'MH',
  MK = 'MK',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MQ = 'MQ',
  MR = 'MR',
  MS = 'MS',
  MT = 'MT',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MX = 'MX',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NL = 'NL',
  NO = 'NO',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  NZ = 'NZ',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PL = 'PL',
  PM = 'PM',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PT = 'PT',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SD = 'SD',
  SE = 'SE',
  SG = 'SG',
  SH = 'SH',
  SI = 'SI',
  SJ = 'SJ',
  SK = 'SK',
  SL = 'SL',
  SM = 'SM',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  SS = 'SS',
  ST = 'ST',
  SV = 'SV',
  SX = 'SX',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TF = 'TF',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TR = 'TR',
  TT = 'TT',
  TV = 'TV',
  TW = 'TW',
  TZ = 'TZ',
  UA = 'UA',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VA = 'VA',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  YT = 'YT',
  ZA = 'ZA',
  ZM = 'ZM',
  ZW = 'ZW'
}

export interface CountryInput {
  code: CountryCode;
}

export interface Courier {
  __typename?: 'Courier';
  courierName: Scalars['String'];
  id: Scalars['String'];
}

export interface CourierConnection {
  __typename?: 'CourierConnection';
  edges?: Maybe<Array<CourierEdge>>;
  nodes?: Maybe<Array<Courier>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface CourierEdge {
  __typename?: 'CourierEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Courier>;
}

export interface CreateAccountBusinessUnitPayload {
  __typename?: 'CreateAccountBusinessUnitPayload';
  accountId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateAccountConnectionInput {
  connectionType: AccountConnectionTypeEnum;
  sourceAccountId: Scalars['String'];
  targetAccountId: Scalars['String'];
}

export interface CreateAccountConnectionPayload {
  __typename?: 'CreateAccountConnectionPayload';
  errors?: Maybe<Array<Error>>;
}

export interface CreateAccountDivisionInput {
  accountId: Scalars['String'];
  divisionName: Scalars['String'];
  referencePrefix: Scalars['String'];
}

export interface CreateAccountInput {
  demoMode: Scalars['Boolean'];
  legalInformation: AccountLegalInformationInput;
  organizationalRole: OrganizationalRoleEnum;
  referencePrefix: Scalars['String'];
  shippingLocation: AccountLocationInput;
  tradingName: Scalars['String'];
}

export interface CreateAccountLocationInput {
  accountId: Scalars['String'];
  location: AccountLocationInput;
}

export interface CreateAccountLocationPayload {
  __typename?: 'CreateAccountLocationPayload';
  errors?: Maybe<Array<Error>>;
  location?: Maybe<AccountLocation>;
  locationId?: Maybe<Scalars['String']>;
}

export interface CreateAccountPayload {
  __typename?: 'CreateAccountPayload';
  accountId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateAccountSubsidiaryInput {
  accountId: Scalars['String'];
  legalInformation: AccountLegalInformationInput;
  organizationalRole: OrganizationalRoleEnum;
  referencePrefix: Scalars['String'];
  tradingName: Scalars['String'];
}

/** Autogenerated input type of CreateAccrualMutation */
export interface CreateAccrualMutationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CreateAccrualMutation */
export interface CreateAccrualMutationPayload {
  __typename?: 'CreateAccrualMutationPayload';
  accrual?: Maybe<Accrual>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateActionItemType */
export interface CreateActionItemTypeInput {
  actionType: ActionItemActionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title: Scalars['String'];
}

/** Autogenerated return type of CreateActionItemType */
export interface CreateActionItemTypePayload {
  __typename?: 'CreateActionItemTypePayload';
  actionItemType?: Maybe<ActionItemTypesActionItemType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateAirlineCarrierInput {
  airlineCarrierName: Scalars['String'];
  carrierIata: Scalars['String'];
}

export interface CreateAirlineCarrierPayload {
  __typename?: 'CreateAirlineCarrierPayload';
  airlineCarrier?: Maybe<AirlineCarrier>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateApiKey */
export interface CreateApiKeyInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CreateApiKey */
export interface CreateApiKeyPayload {
  __typename?: 'CreateApiKeyPayload';
  apiKey?: Maybe<CreatedApiKey>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateBookingDocumentType */
export interface CreateBookingDocumentTypeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  permissions: BookingDocumentTypePermissionsInputType;
  title?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CreateBookingDocumentType */
export interface CreateBookingDocumentTypePayload {
  __typename?: 'CreateBookingDocumentTypePayload';
  bookingDocumentType?: Maybe<BookingDocumentType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateBookingManagerAssignmentRule */
export interface CreateBookingManagerAssignmentRuleInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  managerId: Scalars['String'];
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  shipmentType?: InputMaybe<Shipment>;
  team: ManagerTeam;
}

/** Autogenerated return type of CreateBookingManagerAssignmentRule */
export interface CreateBookingManagerAssignmentRulePayload {
  __typename?: 'CreateBookingManagerAssignmentRulePayload';
  bookingManagerAssignmentRule?: Maybe<BookingManagerAssignmentRule>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateBookingViaEasyBooking */
export interface CreateBookingViaEasyBookingInput {
  accountUuid: Scalars['String'];
  cargo: CargoInput;
  cargoDescription?: InputMaybe<Scalars['String']>;
  cargoReadyDate?: InputMaybe<Scalars['Date']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReference?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['String']>;
  consignorId?: InputMaybe<Scalars['String']>;
  customsOnly?: InputMaybe<Scalars['Boolean']>;
  /** @deprecated Upload documents after booking creation instead */
  documents?: InputMaybe<Array<BookingDocumentInput>>;
  incoterms?: InputMaybe<IncotermsValue>;
  modeOfTransport: ModeOfTransport;
  multiStop?: InputMaybe<MultiStopEnum>;
  networksDestinationId?: InputMaybe<Scalars['String']>;
  networksOriginId?: InputMaybe<Scalars['String']>;
  originAgentId?: InputMaybe<Scalars['String']>;
  purchaseOrdersLotIds?: InputMaybe<Array<Scalars['String']>>;
  shipmentType?: InputMaybe<Shipment>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  tradeLaneId?: InputMaybe<Scalars['String']>;
  tradeRole?: InputMaybe<TradeRole>;
  truckSwap?: InputMaybe<TruckSwapEnum>;
}

/** Autogenerated return type of CreateBookingViaEasyBooking */
export interface CreateBookingViaEasyBookingPayload {
  __typename?: 'CreateBookingViaEasyBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateBookingViaExternalBooking */
export interface CreateBookingViaExternalBookingInput {
  accountUuid: Scalars['String'];
  cargo: CargoInput;
  cargoDescription?: InputMaybe<Scalars['String']>;
  cargoReadyDate?: InputMaybe<Scalars['Date']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReference?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['String']>;
  consignorId?: InputMaybe<Scalars['String']>;
  /** @deprecated Upload documents after booking creation instead */
  documents?: InputMaybe<Array<BookingDocumentInput>>;
  forwarderId?: InputMaybe<Scalars['String']>;
  forwarderReference?: InputMaybe<Scalars['String']>;
  incoterms?: InputMaybe<IncotermsValue>;
  modeOfTransport: ModeOfTransport;
  multiStop?: InputMaybe<MultiStopEnum>;
  networksDestinationId?: InputMaybe<Scalars['String']>;
  networksOriginId?: InputMaybe<Scalars['String']>;
  originAgentId?: InputMaybe<Scalars['String']>;
  purchaseOrdersLotIds?: InputMaybe<Array<Scalars['String']>>;
  shipmentType?: InputMaybe<Shipment>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  tradeLaneId?: InputMaybe<Scalars['String']>;
  tradeRole?: InputMaybe<TradeRole>;
  truckSwap?: InputMaybe<TruckSwapEnum>;
}

/** Autogenerated return type of CreateBookingViaExternalBooking */
export interface CreateBookingViaExternalBookingPayload {
  __typename?: 'CreateBookingViaExternalBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateBookingViaQuotedBooking */
export interface CreateBookingViaQuotedBookingInput {
  accountUuid: Scalars['String'];
  cargo: CargoInput;
  cargoDescription?: InputMaybe<Scalars['String']>;
  cargoReadyDate?: InputMaybe<Scalars['Date']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReference?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['String']>;
  consignorId?: InputMaybe<Scalars['String']>;
  /** @deprecated Upload documents after booking creation instead */
  documents?: InputMaybe<Array<BookingDocumentInput>>;
  incoterms?: InputMaybe<IncotermsValue>;
  modeOfTransport: ModeOfTransport;
  multiStop?: InputMaybe<MultiStopEnum>;
  networksDestinationId?: InputMaybe<Scalars['String']>;
  networksOriginId?: InputMaybe<Scalars['String']>;
  originAgentId?: InputMaybe<Scalars['String']>;
  purchaseOrdersLotIds?: InputMaybe<Array<Scalars['String']>>;
  shipmentType?: InputMaybe<Shipment>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  tradeLaneId?: InputMaybe<Scalars['String']>;
  tradeRole?: InputMaybe<TradeRole>;
  truckSwap?: InputMaybe<TruckSwapEnum>;
}

/** Autogenerated return type of CreateBookingViaQuotedBooking */
export interface CreateBookingViaQuotedBookingPayload {
  __typename?: 'CreateBookingViaQuotedBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateBookingViaRequestForCustomerApproval */
export interface CreateBookingViaRequestForCustomerApprovalInput {
  accountUuid: Scalars['String'];
  cargo: CargoInput;
  cargoDescription?: InputMaybe<Scalars['String']>;
  cargoReadyDate?: InputMaybe<Scalars['Date']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReference?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['String']>;
  consignorId?: InputMaybe<Scalars['String']>;
  /** @deprecated Upload documents after booking creation instead */
  documents?: InputMaybe<Array<BookingDocumentInput>>;
  incoterms?: InputMaybe<IncotermsValue>;
  modeOfTransport: ModeOfTransport;
  multiStop?: InputMaybe<MultiStopEnum>;
  networksDestinationId?: InputMaybe<Scalars['String']>;
  networksOriginId?: InputMaybe<Scalars['String']>;
  originAgentId?: InputMaybe<Scalars['String']>;
  purchaseOrdersLotIds: Array<Scalars['String']>;
  shipmentType?: InputMaybe<Shipment>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  tradeLaneId?: InputMaybe<Scalars['String']>;
  tradeRole?: InputMaybe<TradeRole>;
  truckSwap?: InputMaybe<TruckSwapEnum>;
}

/** Autogenerated return type of CreateBookingViaRequestForCustomerApproval */
export interface CreateBookingViaRequestForCustomerApprovalPayload {
  __typename?: 'CreateBookingViaRequestForCustomerApprovalPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateBookingViaRequestedBooking */
export interface CreateBookingViaRequestedBookingInput {
  accountUuid: Scalars['String'];
  cargo: CargoInput;
  cargoDescription?: InputMaybe<Scalars['String']>;
  cargoReadyDate?: InputMaybe<Scalars['Date']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReference?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['String']>;
  consignorId?: InputMaybe<Scalars['String']>;
  /** @deprecated Upload documents after booking creation instead */
  documents?: InputMaybe<Array<BookingDocumentInput>>;
  incoterms?: InputMaybe<IncotermsValue>;
  modeOfTransport: ModeOfTransport;
  multiStop?: InputMaybe<MultiStopEnum>;
  networksDestinationId?: InputMaybe<Scalars['String']>;
  networksOriginId?: InputMaybe<Scalars['String']>;
  originAgentId?: InputMaybe<Scalars['String']>;
  purchaseOrdersLotIds: Array<Scalars['String']>;
  shipmentType?: InputMaybe<Shipment>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  tradeLaneId?: InputMaybe<Scalars['String']>;
  tradeRole?: InputMaybe<TradeRole>;
  truckSwap?: InputMaybe<TruckSwapEnum>;
}

/** Autogenerated return type of CreateBookingViaRequestedBooking */
export interface CreateBookingViaRequestedBookingPayload {
  __typename?: 'CreateBookingViaRequestedBookingPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateCargoInput {
  accountId: Scalars['String'];
  cargoAssignments?: InputMaybe<Array<CargoAssignmentInput>>;
  cargoType: Scalars['String'];
  chargeableWeight?: InputMaybe<CargoWeightInput>;
  configurationId?: InputMaybe<Scalars['String']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerSealNumber?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Array<InputMaybe<CustomFieldInput>>>;
  dimensions?: InputMaybe<DimensionsInput>;
  dryStandard?: InputMaybe<Scalars['Boolean']>;
  flatRack?: InputMaybe<Scalars['Boolean']>;
  forklift?: InputMaybe<Scalars['Boolean']>;
  garmentsOnHangers?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<CargoWeightInput>;
  hazardous?: InputMaybe<Scalars['Boolean']>;
  healthCertificate?: InputMaybe<Scalars['Boolean']>;
  openTop?: InputMaybe<Scalars['Boolean']>;
  overweight?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Int']>;
  refrigerated?: InputMaybe<Scalars['Boolean']>;
  riskLevel?: InputMaybe<Scalars['String']>;
  stackable?: InputMaybe<Scalars['Boolean']>;
  tailLift?: InputMaybe<Scalars['Boolean']>;
  temperature?: InputMaybe<TemperatureInput>;
  trailerId?: InputMaybe<Scalars['String']>;
  unCode?: InputMaybe<Scalars['String']>;
  valueOfGoods?: InputMaybe<ValueOfGoodsInput>;
  volume?: InputMaybe<VolumeInput>;
}

export interface CreateCargoPayload {
  __typename?: 'CreateCargoPayload';
  coreCargos?: Maybe<Array<Maybe<CoreCargo>>>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateCargoRelationsPayload {
  __typename?: 'CreateCargoRelationsPayload';
  cargoRelation?: Maybe<CargoRelation>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateCentralPortChargeInput {
  chargeTypeId: Scalars['String'];
  chargeValue: Scalars['Float'];
  currency: Currency;
  incoterms?: InputMaybe<Array<IncotermsValue>>;
  port: Scalars['String'];
  portChargeType: OriginDestinationChargeTypeEnum;
}

/** Autogenerated input type of CreateChargeTypeMutation */
export interface CreateChargeTypeMutationInput {
  chargeType: ChargeTypeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CreateChargeTypeMutation */
export interface CreateChargeTypeMutationPayload {
  __typename?: 'CreateChargeTypeMutationPayload';
  chargeType?: Maybe<ChargeType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateConsolidatedShipment */
export interface CreateConsolidatedShipmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  consolidatedShipment: ConsolidatedShipmentCreateInput;
}

/** Autogenerated return type of CreateConsolidatedShipment */
export interface CreateConsolidatedShipmentPayload {
  __typename?: 'CreateConsolidatedShipmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consolidatedShipment?: Maybe<ConsolidatedShipment>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateContact */
export interface CreateContactInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contact: NetworkContactInput;
}

/** Autogenerated return type of CreateContact */
export interface CreateContactPayload {
  __typename?: 'CreateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<NetworkContact>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateContainerCargoItem */
export interface CreateContainerCargoItemInput {
  actualCbm?: InputMaybe<Scalars['Float']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerSealNumber?: InputMaybe<Scalars['String']>;
  containerType: ContainerTypeEnum;
  dimensions?: InputMaybe<CargoDimensionsInputType>;
  dryStandard?: InputMaybe<Scalars['Boolean']>;
  flatRack?: InputMaybe<Scalars['Boolean']>;
  garmentsOnHangers?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous?: InputMaybe<Scalars['Boolean']>;
  looseCargoType?: InputMaybe<LooseCargoTypeEnum>;
  openTop?: InputMaybe<Scalars['Boolean']>;
  overweight?: InputMaybe<Scalars['Boolean']>;
  palletType?: InputMaybe<PalletTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
  reefer?: InputMaybe<Scalars['Boolean']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CreateContainerCargoItem */
export interface CreateContainerCargoItemPayload {
  __typename?: 'CreateContainerCargoItemPayload';
  cargoItem?: Maybe<CargoCargoItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateContractInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  contracts: Array<ContractCreationInput>;
}

export interface CreateCourierInput {
  courierName: Scalars['String'];
}

export interface CreateCourierPayload {
  __typename?: 'CreateCourierPayload';
  courier?: Maybe<Courier>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateDefaultPortChargeInput {
  applicability: Applicability;
  chargeTypeId: Scalars['String'];
  currency: Currency;
  itemName: Scalars['String'];
  locationId: Scalars['String'];
  value: Scalars['Float'];
}

/** Autogenerated input type of CreateDirectUpload */
export interface CreateDirectUploadInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  directUpload: DirectUploadInput;
}

/** Autogenerated return type of CreateDirectUpload */
export interface CreateDirectUploadPayload {
  __typename?: 'CreateDirectUploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  directUpload: DirectUpload;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateDocumentTemplate */
export interface CreateDocumentTemplateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  externalKey: Scalars['String'];
  fields: DocumentTemplateSelectedFields;
  name: Scalars['String'];
}

/** Autogenerated return type of CreateDocumentTemplate */
export interface CreateDocumentTemplatePayload {
  __typename?: 'CreateDocumentTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentTemplate?: Maybe<DocumentTemplate>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateHaulierInput {
  haulier: Scalars['String'];
}

export interface CreateHaulierPayload {
  __typename?: 'CreateHaulierPayload';
  errors?: Maybe<Array<Error>>;
  haulier?: Maybe<Haulier>;
}

/** Autogenerated input type of CreateIssueTemplate */
export interface CreateIssueTemplateInput {
  active: Scalars['Boolean'];
  category: IssueCategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  problemDescription: Scalars['String'];
  ruleId: Scalars['String'];
  severity: IssueSeverity;
  title?: InputMaybe<Scalars['String']>;
  visibility: IssueVisibilityInput;
}

/** Autogenerated return type of CreateIssueTemplate */
export interface CreateIssueTemplatePayload {
  __typename?: 'CreateIssueTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issueTemplate?: Maybe<IssueTemplate>;
}

/** Autogenerated input type of CreateLooseCargoItem */
export interface CreateLooseCargoItemInput {
  actualCbm?: InputMaybe<Scalars['Float']>;
  chargeableWeight?: InputMaybe<CargoWeightInputType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<CargoDimensionsInputType>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous?: InputMaybe<Scalars['Boolean']>;
  looseCargoType: LooseCargoTypeEnum;
  palletType?: InputMaybe<PalletTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
  reefer?: InputMaybe<Scalars['Boolean']>;
  stackable?: InputMaybe<Scalars['Boolean']>;
  trailerId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CreateLooseCargoItem */
export interface CreateLooseCargoItemPayload {
  __typename?: 'CreateLooseCargoItemPayload';
  cargoItem?: Maybe<CargoCargoItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CreateOceanCarrierInput {
  carrierScac: Scalars['String'];
  oceanCarrierName: Scalars['String'];
  synonyms?: InputMaybe<Array<Scalars['String']>>;
}

export interface CreateOceanCarrierPayload {
  __typename?: 'CreateOceanCarrierPayload';
  errors?: Maybe<Array<Error>>;
  oceanCarrier?: Maybe<OceanCarrier>;
}

/** Autogenerated input type of CreateOrder */
export interface CreateOrderInput {
  /** Account UUID (pk) of your account - this argument is required */
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Input purchase order data, see inside for details - this argument is required */
  purchaseOrder: PurchaseOrderInput;
}

/** Autogenerated return type of CreateOrder */
export interface CreateOrderPayload {
  __typename?: 'CreateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  /** Returns the updated purchase order object */
  purchaseOrder?: Maybe<PurchaseOrder>;
}

/** Autogenerated input type of CreateOrganisation */
export interface CreateOrganisationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<ContactInput>>;
  location: LocationInput;
  mainContact?: InputMaybe<ContactInput>;
  name: Scalars['String'];
  networkId: Scalars['String'];
  type: OrganisationTypeValue;
}

/** Autogenerated input type of CreateOrganisationLocation */
export interface CreateOrganisationLocationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Array<ContactInput>>;
  location: LocationInput;
  mainContact?: InputMaybe<ContactInput>;
  networkId: Scalars['String'];
  organisationId: Scalars['String'];
}

/** Autogenerated return type of CreateOrganisationLocation */
export interface CreateOrganisationLocationPayload {
  __typename?: 'CreateOrganisationLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  location?: Maybe<NetworksLocation>;
  orgLoc?: Maybe<NetworksOrgLoc>;
}

/** Autogenerated return type of CreateOrganisation */
export interface CreateOrganisationPayload {
  __typename?: 'CreateOrganisationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  orgLoc?: Maybe<NetworksOrgLoc>;
  organisation?: Maybe<NetworksOrganisationInterface>;
}

/** Autogenerated input type of CreateOriginAgentAssignmentRule */
export interface CreateOriginAgentAssignmentRuleInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectionWarehouseId?: InputMaybe<Scalars['String']>;
  deliveryWarehouseId?: InputMaybe<Scalars['String']>;
  modeOfTransport: ModeOfTransport;
  originAgentId: Scalars['String'];
  portOfDestinationUnlocode?: InputMaybe<Scalars['String']>;
  portOfLoadUnlocode?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CreateOriginAgentAssignmentRule */
export interface CreateOriginAgentAssignmentRulePayload {
  __typename?: 'CreateOriginAgentAssignmentRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  originAgentAssignmentRule?: Maybe<OriginAgentAssignmentRule>;
}

/** Autogenerated input type of CreateProduct */
export interface CreateProductInput {
  /** Account UUID (pk) of your account - this argument is required */
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Input product data - this argument is required */
  product: ProductInput;
}

/** Autogenerated return type of CreateProduct */
export interface CreateProductPayload {
  __typename?: 'CreateProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Product>;
}

/** Autogenerated input type of CreatePurchaseOrderDelayReason */
export interface CreatePurchaseOrderDelayReasonInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  reason: Scalars['String'];
  responsibleParty?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CreatePurchaseOrderDelayReason */
export interface CreatePurchaseOrderDelayReasonPayload {
  __typename?: 'CreatePurchaseOrderDelayReasonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  purchaseOrderDelayReason?: Maybe<PurchaseOrderDelayReason>;
}

/** Autogenerated input type of CreateQuoteOption */
export interface CreateQuoteOptionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  templateId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CreateQuoteOption */
export interface CreateQuoteOptionPayload {
  __typename?: 'CreateQuoteOptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quoteOption?: Maybe<QuoteOption>;
}

export interface CreateRailwayCompanyInput {
  railwayCompany: Scalars['String'];
}

export interface CreateRailwayCompanyPayload {
  __typename?: 'CreateRailwayCompanyPayload';
  errors?: Maybe<Array<Error>>;
  railwayCompany?: Maybe<RailwayCompany>;
}

export interface CreateRateCardChargeInput {
  applicability: Array<Applicability>;
  basis: Array<Scalars['String']>;
  cargoOptions: Array<InputMaybe<CargoOptionEnum>>;
  chargeTypeId: Scalars['String'];
  currency: Currency;
  defaultChargeHidden?: InputMaybe<Scalars['Boolean']>;
  defaultChargeId?: InputMaybe<Scalars['String']>;
  fromLocationId?: InputMaybe<Scalars['String']>;
  itemName: Scalars['String'];
  toLocationId?: InputMaybe<Scalars['String']>;
  unitPrice: Scalars['Float'];
}

export interface CreateRateCardInput {
  cargoType: CargoModeEnum;
  charges: Array<CreateRateCardChargeInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdBy: Scalars['String'];
  customerId: Scalars['String'];
  endDate: Scalars['Date'];
  issuedBy: Scalars['String'];
  modeOfTransport: ModeOfTransport;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  startDate: Scalars['Date'];
}

export interface CreateRateCardPayload {
  __typename?: 'CreateRateCardPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  rateCardId?: Maybe<Scalars['String']>;
}

export interface CreateRoleInput {
  organizationId: Scalars['String'];
  values: CustomRoleValuesInput;
}

export interface CreateRolePayload {
  __typename?: 'CreateRolePayload';
  customRole?: Maybe<CustomRole>;
  errors: Array<Error>;
}

/** Autogenerated input type of CreateRule */
export interface CreateRuleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  entityType: EntityTypesEnum;
  evaluationCode: Scalars['String'];
  name: Scalars['String'];
  query: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  validationEntityId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CreateRule */
export interface CreateRulePayload {
  __typename?: 'CreateRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  rule?: Maybe<Rule>;
}

/** Inputs */
export interface CreateScimApiKeyInput {
  accountId: Scalars['String'];
  documentPassword: Scalars['String'];
  recipientEmail: Scalars['String'];
}

export interface CreateScimApiKeyPayload {
  __typename?: 'CreateScimApiKeyPayload';
  apiKey: Scalars['String'];
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateShipmentActionType */
export interface CreateShipmentActionTypeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CreateShipmentActionType */
export interface CreateShipmentActionTypePayload {
  __typename?: 'CreateShipmentActionTypePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  shipmentActionType?: Maybe<ShipmentActionType>;
}

/** Autogenerated input type of CreateShipmentDelayReason */
export interface CreateShipmentDelayReasonInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  reason: Scalars['String'];
}

/** Autogenerated return type of CreateShipmentDelayReason */
export interface CreateShipmentDelayReasonPayload {
  __typename?: 'CreateShipmentDelayReasonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  shipmentDelayReason?: Maybe<ShipmentDelayReason>;
}

export interface CreateShippingDelayReasonInput {
  description: Scalars['String'];
  reason: Scalars['String'];
  responsibleParty: ShippingDelayReasonResponsiblePartyEnum;
}

export interface CreateShippingDelayReasonPayload {
  __typename?: 'CreateShippingDelayReasonPayload';
  errors?: Maybe<Array<Error>>;
  shippingDelayReason?: Maybe<ShippingDelayReason>;
}

export interface CreateSsoConfigurationInput {
  accountId: Scalars['String'];
  connectionName: Scalars['String'];
  provisionMethod: ProvisionMethod;
}

export interface CreateSsoConfigurationPayload {
  __typename?: 'CreateSsoConfigurationPayload';
  accountId?: Maybe<Scalars['String']>;
  connectionName?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateTextMessage */
export interface CreateTextMessageInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  content: Scalars['String'];
  permissions?: InputMaybe<PermissionsInput>;
  targetId: Scalars['String'];
  targetType: ActivityFeedTargetTypeEnum;
  userDetails?: InputMaybe<ActivityUserDetailsInput>;
}

/** Autogenerated return type of CreateTextMessage */
export interface CreateTextMessagePayload {
  __typename?: 'CreateTextMessagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Inputs */
export interface CreateUserDefinedEventInput {
  eventId?: InputMaybe<Scalars['String']>;
  eventParameterId?: InputMaybe<Scalars['String']>;
  offsetSeconds?: InputMaybe<Scalars['Int']>;
}

/** Autogenerated input type of CreateUtilisationTarget */
export interface CreateUtilisationTargetInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  containerType: ContainerTypeEnum;
  supplierId: Scalars['String'];
  utilisationTarget: Scalars['Float'];
}

/** Autogenerated return type of CreateUtilisationTarget */
export interface CreateUtilisationTargetPayload {
  __typename?: 'CreateUtilisationTargetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateVehicleCargoItem */
export interface CreateVehicleCargoItemInput {
  actualCbm?: InputMaybe<Scalars['Float']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<CargoDimensionsInputType>;
  forklift?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous?: InputMaybe<Scalars['Boolean']>;
  healthCertificate?: InputMaybe<Scalars['Boolean']>;
  looseCargoType?: InputMaybe<LooseCargoTypeEnum>;
  palletType?: InputMaybe<PalletTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
  reefer?: InputMaybe<Scalars['Boolean']>;
  riskLevel?: InputMaybe<RiskLevelsEnum>;
  tailLift?: InputMaybe<Scalars['Boolean']>;
  trailerId?: InputMaybe<Scalars['String']>;
  valueOfGoods?: InputMaybe<MoneyInput>;
  vehicleType: VehicleTypeEnum;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of CreateVehicleCargoItem */
export interface CreateVehicleCargoItemPayload {
  __typename?: 'CreateVehicleCargoItemPayload';
  cargoItem?: Maybe<CargoCargoItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CreateWebhook */
export interface CreateWebhookInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerAccountId: Scalars['String'];
  topics?: InputMaybe<Array<WebhookSubscriptionTopicEnum>>;
  webhookInput: WebhookInput;
}

/** Autogenerated return type of CreateWebhook */
export interface CreateWebhookPayload {
  __typename?: 'CreateWebhookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  signToken?: Maybe<Scalars['String']>;
}

export interface CreatedApiKey {
  __typename?: 'CreatedApiKey';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  secret?: Maybe<Scalars['String']>;
}

export enum Currency {
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  JPY = 'JPY',
  MYR = 'MYR',
  PLN = 'PLN',
  SGD = 'SGD',
  USD = 'USD'
}

export interface CurrencyBreakdown {
  __typename?: 'CurrencyBreakdown';
  currency: Currency;
  fxRateToDisplayCurrency: Scalars['Float'];
  subTotal: Scalars['Float'];
  subTotalInDisplayCurrency: Scalars['Float'];
}

export interface CurrentUser {
  __typename?: 'CurrentUser';
  apiKeys: Array<ApiKey>;
  featureFlags: Array<CurrentUserFeatureFlag>;
  preferences?: Maybe<Preference>;
  profile?: Maybe<CurrentUserProfile>;
  uuid: Scalars['String'];
}

export type CurrentUserFeatureFlagsArgs = {
  accountId?: InputMaybe<Scalars['String']>;
};

/** Autogenerated input type of CurrentUserUpdatePreferences */
export interface CurrentUserUpdatePreferencesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  filterViews?: InputMaybe<Scalars['Boolean']>;
  filterViewsBy?: InputMaybe<FilterViewsByEnum>;
  level?: InputMaybe<ProfileLevelEnum>;
  manufacturersOfInterestIds?: InputMaybe<Array<Scalars['String']>>;
  productCategoriesOfInterestIds?: InputMaybe<Array<Scalars['String']>>;
  roles?: InputMaybe<Array<ProfileRoleEnum>>;
  savedShipmentFilters?: InputMaybe<Array<ShipmentFiltersInput>>;
}

/** Autogenerated return type of CurrentUserUpdatePreferences */
export interface CurrentUserUpdatePreferencesPayload {
  __typename?: 'CurrentUserUpdatePreferencesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of CurrentUserUpdateProfile */
export interface CurrentUserUpdateProfileInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of CurrentUserUpdateProfile */
export interface CurrentUserUpdateProfilePayload {
  __typename?: 'CurrentUserUpdateProfilePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface CustomField {
  __typename?: 'CustomField';
  name: Scalars['String'];
  type: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

export interface CustomFieldInput {
  name: Scalars['String'];
  type: Scalars['String'];
  value?: InputMaybe<Scalars['String']>;
}

export interface CustomRole {
  __typename?: 'CustomRole';
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  members?: Maybe<Array<NetworksContact>>;
  name: Scalars['String'];
  rolePermissions: Array<CustomRolePermission>;
}

export interface CustomRolePermission {
  __typename?: 'CustomRolePermission';
  groupName?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  name: AccessControlledResourceName;
  permissionType?: Maybe<PermissionType>;
}

export interface CustomRolePermissionInput {
  name: AccessControlledResourceName;
  permissionType: PermissionType;
}

export interface CustomRoleValuesInput {
  description?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  rolePermissions: Array<CustomRolePermissionInput>;
}

export interface Customer {
  __typename?: 'Customer';
  name: Scalars['String'];
  uuid: Scalars['String'];
}

export interface CustomerAdmin {
  __typename?: 'CustomerAdmin';
  canAccessRoute: AuthorizationResult;
}

export interface CustomersCustomerType {
  __typename?: 'CustomersCustomerType';
  account?: Maybe<Account>;
  division?: Maybe<Division>;
  isDivision?: Maybe<Scalars['Boolean']>;
  isParent?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  reference?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
}

/** The connection type for CustomersCustomerType. */
export interface CustomersCustomerTypeConnection {
  __typename?: 'CustomersCustomerTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CustomersCustomerTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<CustomersCustomerType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface CustomersCustomerTypeEdge {
  __typename?: 'CustomersCustomerTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<CustomersCustomerType>;
}

/** For filtering views based on whether to show or hide shipments marked as customs only */
export enum CustomsOnlyFilterEnum {
  /** Show shipments marked as customs only */
  SHOW_CUSTOMS_ONLY = 'SHOW_CUSTOMS_ONLY',
  /** Show shipments not marked as customs only */
  SHOW_NOT_CUSTOMS_ONLY = 'SHOW_NOT_CUSTOMS_ONLY'
}

export interface DailyAverageUtilisation {
  __typename?: 'DailyAverageUtilisation';
  averageUtilisation?: Maybe<Scalars['Float']>;
  reportingDate?: Maybe<Scalars['Date']>;
  totalContainers?: Maybe<Scalars['Int']>;
}

export interface Dashboard {
  __typename?: 'Dashboard';
  canAccessRoute: AuthorizationResult;
  canViewActionItems: AuthorizationResult;
}

export interface DataQuality {
  __typename?: 'DataQuality';
  productsWithIssues: Array<Product>;
  purchaseOrdersWithIssues: Array<PurchaseOrdersWithIssues>;
  totalProducts: Scalars['Int'];
  totalPurchaseOrders: Scalars['Int'];
}

export interface DateRange {
  __typename?: 'DateRange';
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
}

export interface DateRangeInput {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
}

export interface DeactivateAccountPayload {
  __typename?: 'DeactivateAccountPayload';
  errors?: Maybe<Array<Error>>;
}

export interface DeactivateCentralPortChargeInput {
  chargeId: Scalars['String'];
  clientMutationId?: InputMaybe<Scalars['String']>;
}

export interface DeactivateCentralPortChargePayload {
  __typename?: 'DeactivateCentralPortChargePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeactivateUserPayload {
  __typename?: 'DeactivateUserPayload';
  errors?: Maybe<Array<Error>>;
}

export interface DefaultChargesConnection {
  __typename?: 'DefaultChargesConnection';
  edges?: Maybe<Array<Maybe<DefaultChargesEdge>>>;
  nodes?: Maybe<Array<Maybe<DefaultChargesPayload>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface DefaultChargesEdge {
  __typename?: 'DefaultChargesEdge';
  cursor: Scalars['String'];
  node?: Maybe<DefaultChargesPayload>;
}

export interface DefaultChargesPayload {
  __typename?: 'DefaultChargesPayload';
  cargoOptions: Array<CargoOptionEnum>;
  chargeBasis: Array<ChargeBasis>;
  chargeType: ChargesPayload;
  currency: Currency;
  id: Scalars['String'];
  itemName: Scalars['String'];
  location: CostTrackingLocation;
  unitPrice: Scalars['Float'];
}

/** Provides information about the delays */
export interface Delay {
  __typename?: 'Delay';
  /** All delays affecting the booking */
  all?: Maybe<Array<DelayItem>>;
  /** Delay in days in comparation to ETD */
  days?: Maybe<Scalars['Int']>;
  /** Reason description */
  description?: Maybe<Scalars['String']>;
  /** Reason of the delay */
  type?: Maybe<Scalars['String']>;
}

/** Provides information about individual delay */
export interface DelayItem {
  __typename?: 'DelayItem';
  /** Reason description */
  description?: Maybe<Scalars['String']>;
  /** Reason of the delay */
  reason?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of DeleteAccrualTemplateMutation */
export interface DeleteAccrualTemplateMutationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  templateId: Scalars['String'];
}

/** Autogenerated return type of DeleteAccrualTemplateMutation */
export interface DeleteAccrualTemplateMutationPayload {
  __typename?: 'DeleteAccrualTemplateMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeleteAirlineCarrierInput {
  id: Scalars['String'];
}

export interface DeleteAirlineCarrierPayload {
  __typename?: 'DeleteAirlineCarrierPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteApiKey */
export interface DeleteApiKeyInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of DeleteApiKey */
export interface DeleteApiKeyPayload {
  __typename?: 'DeleteApiKeyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeleteCargoInput {
  id: Scalars['String'];
}

/** Autogenerated input type of DeleteCargoItem */
export interface DeleteCargoItemInput {
  cargoId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of DeleteCargoItem */
export interface DeleteCargoItemPayload {
  __typename?: 'DeleteCargoItemPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeleteCargoPayload {
  __typename?: 'DeleteCargoPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteContact */
export interface DeleteContactInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contactId: Scalars['String'];
}

/** Autogenerated return type of DeleteContact */
export interface DeleteContactPayload {
  __typename?: 'DeleteContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeleteContractInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

export interface DeleteCostTrackingItemsInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  costTrackingId: Scalars['String'];
  displayCurrency?: InputMaybe<Currency>;
  itemIds: Array<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface DeleteCostTrackingItemsPayload {
  __typename?: 'DeleteCostTrackingItemsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  costTracking?: Maybe<CostTracking>;
  deletedItemIds?: Maybe<Array<Scalars['String']>>;
  errors?: Maybe<Array<Error>>;
  failedItemIds?: Maybe<Array<Scalars['String']>>;
}

export interface DeleteCourierInput {
  id: Scalars['String'];
}

export interface DeleteCourierPayload {
  __typename?: 'DeleteCourierPayload';
  errors?: Maybe<Array<Error>>;
}

export interface DeleteDefaultChargeItemInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
}

export interface DeleteDefaultChargeItemPayload {
  __typename?: 'DeleteDefaultChargeItemPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
}

export interface DeleteHaulierInput {
  id: Scalars['String'];
}

export interface DeleteHaulierPayload {
  __typename?: 'DeleteHaulierPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteIssueTemplate */
export interface DeleteIssueTemplateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of DeleteIssueTemplate */
export interface DeleteIssueTemplatePayload {
  __typename?: 'DeleteIssueTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteLocationContacts */
export interface DeleteLocationContactsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contactIds: Array<Scalars['String']>;
  locationIds: Array<Scalars['String']>;
}

/** Autogenerated return type of DeleteLocationContacts */
export interface DeleteLocationContactsPayload {
  __typename?: 'DeleteLocationContactsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteLocation */
export interface DeleteLocationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  locationId: Scalars['String'];
}

/** Autogenerated return type of DeleteLocation */
export interface DeleteLocationPayload {
  __typename?: 'DeleteLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeleteOceanCarrierInput {
  id: Scalars['String'];
}

export interface DeleteOceanCarrierPayload {
  __typename?: 'DeleteOceanCarrierPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteOrder */
export interface DeleteOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID (pk) of purchase order to be deleted */
  purchaseOrderId: Scalars['String'];
}

/** Autogenerated return type of DeleteOrder */
export interface DeleteOrderPayload {
  __typename?: 'DeleteOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteOrganisation */
export interface DeleteOrganisationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  organisationId: Scalars['String'];
}

/** Autogenerated return type of DeleteOrganisation */
export interface DeleteOrganisationPayload {
  __typename?: 'DeleteOrganisationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeleteRailwayCompanyInput {
  id: Scalars['String'];
}

export interface DeleteRailwayCompanyPayload {
  __typename?: 'DeleteRailwayCompanyPayload';
  errors?: Maybe<Array<Error>>;
}

export interface DeleteRateCardInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  id: Scalars['String'];
}

export interface DeleteRateCardPayload {
  __typename?: 'DeleteRateCardPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface DeleteRoleInput {
  id: Scalars['String'];
  organizationId: Scalars['String'];
}

export interface DeleteRolePayload {
  __typename?: 'DeleteRolePayload';
  errors: Array<Error>;
}

export interface DeleteScimApiKeyPayload {
  __typename?: 'DeleteScimApiKeyPayload';
  errors?: Maybe<Array<Error>>;
}

export interface DeleteUserSettingsInput {
  name: Scalars['String'];
}

export interface DeleteUserSettingsPayload {
  __typename?: 'DeleteUserSettingsPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteUtilisationTarget */
export interface DeleteUtilisationTargetInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  utilisationTargetId: Scalars['String'];
}

/** Autogenerated return type of DeleteUtilisationTarget */
export interface DeleteUtilisationTargetPayload {
  __typename?: 'DeleteUtilisationTargetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of DeleteWebhook */
export interface DeleteWebhookInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  webhookId: Scalars['String'];
}

/** Autogenerated return type of DeleteWebhook */
export interface DeleteWebhookPayload {
  __typename?: 'DeleteWebhookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Payload Types */
export interface DeleteWorkflowRuleDefinitionPayload {
  __typename?: 'DeleteWorkflowRuleDefinitionPayload';
  errors?: Maybe<Array<Error>>;
}

export interface DeleteWorkflowRulePayload {
  __typename?: 'DeleteWorkflowRulePayload';
  errors?: Maybe<Array<Error>>;
}

export interface DeleteWorkflowTaskInput {
  deleteReason: Scalars['String'];
  taskId: Scalars['String'];
}

export interface DeleteWorkflowTaskPayload {
  __typename?: 'DeleteWorkflowTaskPayload';
  errors?: Maybe<Array<Error>>;
}

export interface DeliveryDetails {
  __typename?: 'DeliveryDetails';
  arrivedAtWarehouse?: Maybe<LocalDateTimeType>;
  completedOn?: Maybe<Scalars['String']>;
  confirmedByForwarder?: Maybe<LocalDateTimeRange>;
  driverDetails?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  latestDate?: Maybe<LocalDateTimeRange>;
  location?: Maybe<NetworksAssignableInterface>;
  reference?: Maybe<Scalars['String']>;
  requestedWithForwarder?: Maybe<LocalDateTimeRange>;
  scheduleDate?: Maybe<Scalars['String']>;
  scheduleTime?: Maybe<Scalars['String']>;
  scheduledAt?: Maybe<Scalars['String']>;
  specificTime?: Maybe<SpecificTime>;
  state?: Maybe<CollectionAndDeliveryState>;
  timeMode?: Maybe<Mode>;
  timedDelivery?: Maybe<Scalars['Boolean']>;
  vehiclePlateNumber?: Maybe<Scalars['String']>;
}

export interface DeliverySchedule {
  deliveryDate: Scalars['Date'];
  deliveryReference: Scalars['String'];
  deliveryTime?: InputMaybe<TimedDelivery>;
  deliveryTo: BookingCollectionAndDeliveryLocationInput;
  driverDetails?: InputMaybe<Scalars['String']>;
  vehiclePlateNumber?: InputMaybe<Scalars['String']>;
}

export interface DeployWorkflowModelPayload {
  __typename?: 'DeployWorkflowModelPayload';
  deploymentId: Scalars['String'];
  errors?: Maybe<Array<Error>>;
}

export interface Dimensions {
  __typename?: 'Dimensions';
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  unit: Scalars['String'];
  width?: Maybe<Scalars['Float']>;
}

export interface DimensionsInCm {
  height: Scalars['Float'];
  length: Scalars['Float'];
  unit?: InputMaybe<LengthUnitEnum>;
  width: Scalars['Float'];
}

export interface DimensionsInput {
  height?: InputMaybe<Scalars['Float']>;
  length?: InputMaybe<Scalars['Float']>;
  unit: Scalars['String'];
  width?: InputMaybe<Scalars['Float']>;
}

/** Represents direct upload credentials */
export interface DirectUpload {
  __typename?: 'DirectUpload';
  /** Created blob record ID */
  blobId: Scalars['ID'];
  /** HTTP request headers (JSON-encoded) */
  headers: Scalars['String'];
  /** Created blob record signed ID */
  signedBlobId: Scalars['ID'];
  /** Upload URL */
  url: Scalars['String'];
}

/** File information required to prepare a direct upload */
export interface DirectUploadInput {
  /** File size (bytes) */
  byteSize: Scalars['Int'];
  /** MD5 file checksum as base64 */
  checksum: Scalars['String'];
  /** File content MIME type */
  contentType: Scalars['String'];
  /** Original file name */
  filename: Scalars['String'];
}

/** Autogenerated input type of DisableDemoMode */
export interface DisableDemoModeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of DisableDemoMode */
export interface DisableDemoModePayload {
  __typename?: 'DisableDemoModePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface Division {
  __typename?: 'Division';
  accountId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  parentAccountId: Scalars['String'];
}

export interface DocumentCreation {
  __typename?: 'DocumentCreation';
  canAccessRoute: AuthorizationResult;
}

export interface DocumentTemplate {
  __typename?: 'DocumentTemplate';
  externalId: Scalars['String'];
  externalKey: Scalars['String'];
  fields: DocumentTemplateFields;
  id: Scalars['Int'];
  name: Scalars['String'];
}

export interface DocumentTemplateCargoFields {
  __typename?: 'DocumentTemplateCargoFields';
  authorisationCode: Scalars['Boolean'];
  cargoDescription: Scalars['Boolean'];
  cargoMode: Scalars['Boolean'];
  cargoType: Scalars['Boolean'];
  cargoValue: Scalars['Boolean'];
  cbm: Scalars['Boolean'];
  containerNumbers: Scalars['Boolean'];
  cpc: Scalars['Boolean'];
  customsExport: Scalars['Boolean'];
  customsImport: Scalars['Boolean'];
  dan: Scalars['Boolean'];
  doorToPortFreight: Scalars['Boolean'];
  grossWeight: Scalars['Boolean'];
  importerOfRecordNumber: Scalars['Boolean'];
  incoterms: Scalars['Boolean'];
  insuranceValue: Scalars['Boolean'];
  markAndNumbers: Scalars['Boolean'];
  netWeight: Scalars['Boolean'];
  pieceCount: Scalars['Boolean'];
  productType: Scalars['Boolean'];
  ucrNumber: Scalars['Boolean'];
}

export interface DocumentTemplateCollectionFields {
  __typename?: 'DocumentTemplateCollectionFields';
  cargoReadyDate: Scalars['Boolean'];
  collectionContactName: Scalars['Boolean'];
  collectionDate: Scalars['Boolean'];
  collectionDateTime: Scalars['Boolean'];
  collectionPoint: Scalars['Boolean'];
  equipmentBooked: Scalars['Boolean'];
}

export interface DocumentTemplateContactsFields {
  __typename?: 'DocumentTemplateContactsFields';
  attn: Scalars['Boolean'];
  to: Scalars['Boolean'];
}

export interface DocumentTemplateDeliveryFields {
  __typename?: 'DocumentTemplateDeliveryFields';
  deliveryContactName: Scalars['Boolean'];
  deliveryDate: Scalars['Boolean'];
  deliveryDetails: Scalars['Boolean'];
}

export interface DocumentTemplateField {
  __typename?: 'DocumentTemplateField';
  label: Scalars['String'];
  options?: Maybe<Array<DocumentTemplateField>>;
  value: Scalars['String'];
}

export interface DocumentTemplateFields {
  __typename?: 'DocumentTemplateFields';
  cargo?: Maybe<DocumentTemplateCargoFields>;
  collection?: Maybe<DocumentTemplateCollectionFields>;
  contacts?: Maybe<DocumentTemplateContactsFields>;
  delivery?: Maybe<DocumentTemplateDeliveryFields>;
  invoiceDate?: Maybe<DocumentTemplateInvoiceDateFields>;
  manager?: Maybe<DocumentTemplateManagerFields>;
  other?: Maybe<DocumentTemplateOtherFields>;
  rates?: Maybe<DocumentTemplateRatesFields>;
  references?: Maybe<DocumentTemplateReferencesFields>;
  supplier?: Maybe<DocumentTemplateSupplierFields>;
  tradeParties?: Maybe<DocumentTemplateTradePartiesFields>;
  voyage?: Maybe<DocumentTemplateVoyageFields>;
}

export interface DocumentTemplateInvoiceDateFields {
  __typename?: 'DocumentTemplateInvoiceDateFields';
  customerInvoiceDate: Scalars['Boolean'];
}

export interface DocumentTemplateManagerFields {
  __typename?: 'DocumentTemplateManagerFields';
  operationsManager: Scalars['Boolean'];
  operationsManagerEmail: Scalars['Boolean'];
}

export interface DocumentTemplateOtherFields {
  __typename?: 'DocumentTemplateOtherFields';
  specialInstructions: Scalars['Boolean'];
  today: Scalars['Boolean'];
}

export interface DocumentTemplateRatesFields {
  __typename?: 'DocumentTemplateRatesFields';
  agreedRate: Scalars['Boolean'];
  clientSellRate: Scalars['Boolean'];
  supplierBuyRate: Scalars['Boolean'];
  vat: Scalars['Boolean'];
}

export interface DocumentTemplateReferencesFields {
  __typename?: 'DocumentTemplateReferencesFields';
  clientReference: Scalars['Boolean'];
  purchaseOrdersReferences: Scalars['Boolean'];
  supplierReference: Scalars['Boolean'];
  zencargoReference: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedCargoFields {
  authorisationCode?: Scalars['Boolean'];
  cargoDescription?: Scalars['Boolean'];
  cargoMode?: Scalars['Boolean'];
  cargoType?: Scalars['Boolean'];
  cargoValue?: Scalars['Boolean'];
  cbm?: Scalars['Boolean'];
  containerNumbers?: Scalars['Boolean'];
  cpc?: Scalars['Boolean'];
  customsExport?: Scalars['Boolean'];
  customsImport?: Scalars['Boolean'];
  dan?: Scalars['Boolean'];
  doorToPortFreight?: Scalars['Boolean'];
  grossWeight?: Scalars['Boolean'];
  importerOfRecordNumber?: Scalars['Boolean'];
  incoterms?: Scalars['Boolean'];
  insuranceValue?: Scalars['Boolean'];
  markAndNumbers?: Scalars['Boolean'];
  netWeight?: Scalars['Boolean'];
  pieceCount?: Scalars['Boolean'];
  productType?: Scalars['Boolean'];
  ucrNumber?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedCollectionFields {
  cargoReadyDate?: Scalars['Boolean'];
  collectionContactName?: Scalars['Boolean'];
  collectionDate?: Scalars['Boolean'];
  collectionDateTime?: Scalars['Boolean'];
  collectionPoint?: Scalars['Boolean'];
  equipmentBooked?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedContactsFields {
  attn?: Scalars['Boolean'];
  to?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedDeliveryFields {
  deliveryContactName?: Scalars['Boolean'];
  deliveryDate?: Scalars['Boolean'];
  deliveryDetails?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedFields {
  cargo: DocumentTemplateSelectedCargoFields;
  collection: DocumentTemplateSelectedCollectionFields;
  contacts: DocumentTemplateSelectedContactsFields;
  delivery: DocumentTemplateSelectedDeliveryFields;
  invoiceDate: DocumentTemplateSelectedInvoiceDateFields;
  manager: DocumentTemplateSelectedManagerFields;
  other: DocumentTemplateSelectedOtherFields;
  rates: DocumentTemplateSelectedRatesFields;
  references: DocumentTemplateSelectedReferencesFields;
  supplier: DocumentTemplateSelectedSupplierFields;
  tradeParties: DocumentTemplateSelectedTradePartiesFields;
  voyage: DocumentTemplateSelectedVoyageFields;
}

export interface DocumentTemplateSelectedInvoiceDateFields {
  customerInvoiceDate?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedManagerFields {
  operationsManager?: Scalars['Boolean'];
  operationsManagerEmail?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedOtherFields {
  specialInstructions?: Scalars['Boolean'];
  today?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedRatesFields {
  agreedRate?: Scalars['Boolean'];
  clientSellRate?: Scalars['Boolean'];
  supplierBuyRate?: Scalars['Boolean'];
  vat?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedReferencesFields {
  clientReference?: Scalars['Boolean'];
  purchaseOrdersReferences?: Scalars['Boolean'];
  supplierReference?: Scalars['Boolean'];
  zencargoReference?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedSupplierFields {
  supplierCompanyName?: Scalars['Boolean'];
  supplierContactName?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedTradePartiesFields {
  accountCompanyAddress?: Scalars['Boolean'];
  accountCompanyName?: Scalars['Boolean'];
  clientContactName?: Scalars['Boolean'];
  consigneeAddress?: Scalars['Boolean'];
  consigneeName?: Scalars['Boolean'];
  notifyPartyDetails?: Scalars['Boolean'];
  shipperAddress?: Scalars['Boolean'];
  shipperName?: Scalars['Boolean'];
}

export interface DocumentTemplateSelectedVoyageFields {
  arrivalPort?: Scalars['Boolean'];
  countryOfOrigin?: Scalars['Boolean'];
  departurePort?: Scalars['Boolean'];
  flightNumber?: Scalars['Boolean'];
  houseBolNumber?: Scalars['Boolean'];
  originalBolNumber?: Scalars['Boolean'];
  scac?: Scalars['Boolean'];
  transhipmentPort?: Scalars['Boolean'];
  vessel?: Scalars['Boolean'];
  vesselEta?: Scalars['Boolean'];
  vesselEtd?: Scalars['Boolean'];
  vesselFirstUsArrival?: Scalars['Boolean'];
  vesselName?: Scalars['Boolean'];
  vesselNumber?: Scalars['Boolean'];
  voyageNumber?: Scalars['Boolean'];
}

export interface DocumentTemplateSupplierFields {
  __typename?: 'DocumentTemplateSupplierFields';
  supplierCompanyName: Scalars['Boolean'];
  supplierContactName: Scalars['Boolean'];
}

export interface DocumentTemplateTradePartiesFields {
  __typename?: 'DocumentTemplateTradePartiesFields';
  accountCompanyAddress: Scalars['Boolean'];
  accountCompanyName: Scalars['Boolean'];
  clientContactName: Scalars['Boolean'];
  consigneeAddress: Scalars['Boolean'];
  consigneeName: Scalars['Boolean'];
  notifyPartyDetails: Scalars['Boolean'];
  shipperAddress: Scalars['Boolean'];
  shipperName: Scalars['Boolean'];
}

export interface DocumentTemplateVoyageFields {
  __typename?: 'DocumentTemplateVoyageFields';
  arrivalPort: Scalars['Boolean'];
  countryOfOrigin: Scalars['Boolean'];
  departurePort: Scalars['Boolean'];
  flightNumber: Scalars['Boolean'];
  houseBolNumber: Scalars['Boolean'];
  originalBolNumber: Scalars['Boolean'];
  scac: Scalars['Boolean'];
  transhipmentPort: Scalars['Boolean'];
  vessel: Scalars['Boolean'];
  vesselEta: Scalars['Boolean'];
  vesselEtd: Scalars['Boolean'];
  vesselFirstUsArrival: Scalars['Boolean'];
  vesselName: Scalars['Boolean'];
  vesselNumber: Scalars['Boolean'];
  voyageNumber: Scalars['Boolean'];
}

export interface DuplicateCostTrackingItemInput {
  costTrackingId: Scalars['String'];
  displayCurrency?: InputMaybe<Currency>;
  itemId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface EditRoleInput {
  id: Scalars['String'];
  organizationId: Scalars['String'];
  values: CustomRoleValuesInput;
}

export interface EditRolePayload {
  __typename?: 'EditRolePayload';
  customRole?: Maybe<CustomRole>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of EnableDemoMode */
export interface EnableDemoModeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of EnableDemoMode */
export interface EnableDemoModePayload {
  __typename?: 'EnableDemoModePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of Enable */
export interface EnableInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of Enable */
export interface EnablePayload {
  __typename?: 'EnablePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface Engine {
  __typename?: 'Engine';
  exceptions?: Maybe<ExceptionConnection>;
  rules: RuleConnection;
}

export type EngineExceptionsArgs = {
  accountId?: InputMaybe<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  entityId?: InputMaybe<Scalars['String']>;
  entityType?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  ruleId?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<ExceptionStatesEnum>;
};

export type EngineRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
};

export interface Entity {
  __typename?: 'Entity';
  accountId?: Maybe<Scalars['String']>;
  entityId?: Maybe<Scalars['String']>;
  entityType?: Maybe<EntityTypesEnum>;
}

export enum EntityType {
  ARCHIVED_SHIPMENT = 'ARCHIVED_SHIPMENT',
  BOOKING_REQUEST = 'BOOKING_REQUEST',
  CONSOLIDATED_SHIPMENT = 'CONSOLIDATED_SHIPMENT',
  PRODUCT = 'PRODUCT',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  QUOTE = 'QUOTE',
  SHIPMENT = 'SHIPMENT'
}

export enum EntityTypesEnum {
  BOOKING = 'booking',
  PURCHASE_ORDER = 'purchase_order',
  PURCHASE_ORDER_LOT = 'purchase_order_lot'
}

export interface Error {
  __typename?: 'Error';
  message: Scalars['String'];
  path?: Maybe<Scalars['String']>;
}

export interface EventConnection {
  __typename?: 'EventConnection';
  edges: Array<Maybe<EventEdge>>;
  nodes: Array<Maybe<EventType>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface EventEdge {
  __typename?: 'EventEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<EventType>;
}

export interface EventParameterPayloadType {
  __typename?: 'EventParameterPayloadType';
  parameters?: Maybe<Array<Maybe<EventParametersType>>>;
}

export interface EventParametersType {
  __typename?: 'EventParametersType';
  eventId: Scalars['String'];
  id: Scalars['String'];
  parameterName?: Maybe<Scalars['String']>;
  parameterReference: Scalars['String'];
  parameterType?: Maybe<Scalars['String']>;
}

/** Types */
export interface EventPayloadType {
  __typename?: 'EventPayloadType';
  events?: Maybe<EventConnection>;
  eventsCount?: Maybe<Scalars['Int']>;
}

export interface EventType {
  __typename?: 'EventType';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  parameters?: Maybe<Array<Maybe<EventParametersType>>>;
  reference: Scalars['String'];
}

export interface Exception {
  __typename?: 'Exception';
  account?: Maybe<Company>;
  closedAt?: Maybe<RichDateTime>;
  entity?: Maybe<Entity>;
  id?: Maybe<Scalars['String']>;
  openedAt?: Maybe<RichDateTime>;
  rule?: Maybe<Rule>;
  state?: Maybe<ExceptionStatesEnum>;
}

/** The connection type for Exception. */
export interface ExceptionConnection {
  __typename?: 'ExceptionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ExceptionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Exception>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ExceptionEdge {
  __typename?: 'ExceptionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Exception>;
}

export enum ExceptionStatesEnum {
  CLOSED = 'closed',
  OPEN = 'open'
}

export interface ExchangeRate {
  __typename?: 'ExchangeRate';
  date: Scalars['Date'];
  from: ExchangeRateCurrency;
  id: Scalars['Int'];
  rate: Scalars['Float'];
  to: ExchangeRateCurrency;
  updatedAt: RichDateTime;
}

export enum ExchangeRateCurrency {
  AUD = 'AUD',
  BGN = 'BGN',
  BRL = 'BRL',
  CAD = 'CAD',
  CHF = 'CHF',
  CNY = 'CNY',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  HRK = 'HRK',
  HUF = 'HUF',
  IDR = 'IDR',
  ILS = 'ILS',
  INR = 'INR',
  ISK = 'ISK',
  JPY = 'JPY',
  KRW = 'KRW',
  MXN = 'MXN',
  MYR = 'MYR',
  NOK = 'NOK',
  NZD = 'NZD',
  PHP = 'PHP',
  PLN = 'PLN',
  RON = 'RON',
  RUB = 'RUB',
  SEK = 'SEK',
  SGD = 'SGD',
  THB = 'THB',
  TRY = 'TRY',
  USD = 'USD',
  ZAR = 'ZAR'
}

/** Autogenerated input type of ExportAccrualsMutation */
export interface ExportAccrualsMutationInput {
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  exportData: FinanceAccrualsExportDataInput;
}

/** Autogenerated return type of ExportAccrualsMutation */
export interface ExportAccrualsMutationPayload {
  __typename?: 'ExportAccrualsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  exportedData?: Maybe<ExportedData>;
}

export interface ExportPeriodsDataInput {
  columns: Array<PeriodsColumnEnum>;
  dataFilters: PeriodsDataFiltersInput;
  order?: InputMaybe<SortInput>;
}

/** Autogenerated input type of ExportPeriodsRowsData */
export interface ExportPeriodsRowsDataInput {
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  exportData: ExportPeriodsDataInput;
}

/** Autogenerated return type of ExportPeriodsRowsData */
export interface ExportPeriodsRowsDataPayload {
  __typename?: 'ExportPeriodsRowsDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  exportedData?: Maybe<ExportedData>;
}

export interface ExportProductLotsDataInput {
  columns: Array<ProductLotsColumnEnum>;
  dataFilters: ProductLotsDataFiltersInput;
  order?: InputMaybe<SortInput>;
}

/** Autogenerated input type of ExportProductLotsRowsData */
export interface ExportProductLotsRowsDataInput {
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  exportData: ExportProductLotsDataInput;
}

/** Autogenerated return type of ExportProductLotsRowsData */
export interface ExportProductLotsRowsDataPayload {
  __typename?: 'ExportProductLotsRowsDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  exportedData?: Maybe<ExportedData>;
}

export interface ExportProductsDataInput {
  columns?: InputMaybe<Array<ProductsColumnEnum>>;
  dataFilters: ProductsDataFiltersInput;
  order?: InputMaybe<SortInput>;
}

/** Autogenerated input type of ExportProductsRowsData */
export interface ExportProductsRowsDataInput {
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  exportData: ExportProductsDataInput;
}

/** Autogenerated return type of ExportProductsRowsData */
export interface ExportProductsRowsDataPayload {
  __typename?: 'ExportProductsRowsDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  exportedData?: Maybe<ExportedData>;
}

/** Autogenerated input type of ExportRowsPurchaseOrders */
export interface ExportRowsPurchaseOrdersInput {
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  exportData: PurchaseOrdersExportDataInput;
}

/** Autogenerated return type of ExportRowsPurchaseOrders */
export interface ExportRowsPurchaseOrdersPayload {
  __typename?: 'ExportRowsPurchaseOrdersPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  exportedData?: Maybe<ExportedData>;
}

/** Autogenerated input type of ExportRowsShipments */
export interface ExportRowsShipmentsInput {
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  exportData: ShipmentExportDataInput;
}

/** Autogenerated return type of ExportRowsShipments */
export interface ExportRowsShipmentsPayload {
  __typename?: 'ExportRowsShipmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  exportedData?: Maybe<ExportedData>;
}

export interface ExportedData {
  __typename?: 'ExportedData';
  id: Scalars['String'];
}

export interface FailedPortChargeItem {
  __typename?: 'FailedPortChargeItem';
  applicability: Applicability;
  chargeType?: Maybe<ChargesPayload>;
  currency: Currency;
  itemName: Scalars['String'];
  location?: Maybe<CostTrackingLocation>;
  value: Scalars['Float'];
}

export interface FailureReasons {
  __typename?: 'FailureReasons';
  /** JSON-encoded map of reasons */
  details: Scalars['String'];
  /** Human-readable errors */
  fullMessages: Array<Scalars['String']>;
}

export interface Field {
  __typename?: 'Field';
  label: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

export interface FieldGroup {
  __typename?: 'FieldGroup';
  fields: Array<Field>;
  label: Scalars['String'];
}

export interface FieldInput {
  label?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
}

export enum FilterOptionsEnum {
  ACTIVE = 'ACTIVE',
  BOOKED = 'BOOKED',
  DELIVERED = 'DELIVERED',
  NOT_BOOKED = 'NOT_BOOKED'
}

export enum FilterViewsByEnum {
  MANUFACTURERS = 'manufacturers',
  PRODUCT_CATEGORIES = 'product_categories'
}

export interface Filters {
  __typename?: 'Filters';
  purchaseOrders?: Maybe<FiltersPurchaseOrders>;
  shipments?: Maybe<FiltersShipments>;
}

export interface FiltersCustomerType {
  __typename?: 'FiltersCustomerType';
  name: Scalars['String'];
  uuid: Scalars['String'];
}

export interface FiltersFiltersType {
  __typename?: 'FiltersFiltersType';
  purchaseOrders?: Maybe<PurchaseOrdersFilter>;
  shipments?: Maybe<ShipmentsFilter>;
}

export enum FiltersPortTypeValue {
  POD = 'POD',
  POL = 'POL'
}

export interface FiltersPurchaseOrders {
  __typename?: 'FiltersPurchaseOrders';
  terminals?: Maybe<Array<Terminal>>;
}

export type FiltersPurchaseOrdersTerminalsArgs = {
  portType: PortTypeValue;
};

export interface FiltersShipments {
  __typename?: 'FiltersShipments';
  consignees?: Maybe<Array<NetworksAssignableInterface>>;
  consignors?: Maybe<Array<NetworksAssignableInterface>>;
  destinationCountries?: Maybe<Array<Country>>;
  destinations?: Maybe<Array<NetworksAssignableInterface>>;
  originCountries?: Maybe<Array<Country>>;
  origins?: Maybe<Array<NetworksAssignableInterface>>;
}

export interface FiltersTerminalType {
  __typename?: 'FiltersTerminalType';
  label?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  terminalTypes?: Maybe<Array<FiltersTerminalTypeValue>>;
  unlocode: Scalars['String'];
}

export enum FiltersTerminalTypeValue {
  AIRPORT = 'airport',
  RAIL_TERMINAL = 'rail_terminal',
  ROAD_TERMINAL = 'road_terminal',
  SEAPORT = 'seaport'
}

export interface FinanceAccrualsExportDataFiltersInput {
  accountIds: Array<Scalars['String']>;
  startDate: Scalars['Date'];
}

export interface FinanceAccrualsExportDataInput {
  columns?: InputMaybe<Array<FinanceAccuralsExportDataColumnEnum>>;
  dataFilters: FinanceAccrualsExportDataFiltersInput;
  order?: InputMaybe<SortInput>;
}

export enum FinanceAccuralsExportDataColumnEnum {
  ID = 'id',
  INVOICEDON = 'invoicedOn',
  TOTALREVENUE = 'totalRevenue',
  ZENCARGOREFERENCE = 'zencargoReference'
}

export interface ForwarderOrganisation extends NetworksOrganisationInterface {
  __typename?: 'ForwarderOrganisation';
  assignedRoles: Array<Role>;
  contacts: NetworksContactConnection;
  /** Organisation ID */
  id: Scalars['String'];
  /** Locations assigned to the organisation */
  locations: Array<NetworksLocation>;
  /** Organisation name */
  name: Scalars['String'];
  /** Organization type */
  type: OrganisationTypeValue;
}

export type ForwarderOrganisationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export interface FreightCharge {
  __typename?: 'FreightCharge';
  chargeType: ChargeType;
  containerCharges: Array<ContainerCharge>;
  destinationPort: Terminal;
  id: Scalars['String'];
  incoterms: Array<IncotermsValue>;
  originPort: Terminal;
}

export interface FreightChargeInput {
  chargeTypeId: Scalars['String'];
  containerCharges: Array<ContainerChargeInput>;
  destinationPortId: Scalars['String'];
  incoterms: Array<IncotermsValue>;
  originPortId: Scalars['String'];
}

export interface FreightLane {
  applicability: Array<Applicability>;
  cargoOptions?: InputMaybe<Array<CargoOptionEnum>>;
  chargeBasisIds: Array<Scalars['String']>;
  fromLocationId: Scalars['String'];
  toLocationId: Scalars['String'];
}

export interface FreightSpend {
  __typename?: 'FreightSpend';
  canAccessRoute: AuthorizationResult;
}

export interface FreightSpendAccountPermissions {
  __typename?: 'FreightSpendAccountPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
}

export interface FreshnessThresholdsSettings {
  __typename?: 'FreshnessThresholdsSettings';
  air: Scalars['Int'];
  ocean: Scalars['Int'];
  rail: Scalars['Int'];
  truck: Scalars['Int'];
}

export interface FreshnessThresholdsSettingsInput {
  air?: InputMaybe<Scalars['Int']>;
  ocean?: InputMaybe<Scalars['Int']>;
  rail?: InputMaybe<Scalars['Int']>;
  truck?: InputMaybe<Scalars['Int']>;
}

export enum FulfilmentStatusEnum {
  ON_TIME_IN_FULL = 'ON_TIME_IN_FULL',
  OVER_FULFILLED = 'OVER_FULFILLED',
  PARTIAL_FULFILMENT = 'PARTIAL_FULFILMENT',
  ZERO_STOCK = 'ZERO_STOCK'
}

/** Autogenerated input type of Generate */
export interface GenerateInput {
  accountId: Scalars['String'];
  bookingDocumentFields: Array<FieldInput>;
  bookingDocumentTemplateId: Scalars['Int'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  permissions: BookingDocumentsPermissionsInputType;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of Generate */
export interface GeneratePayload {
  __typename?: 'GeneratePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface Geolocation {
  __typename?: 'Geolocation';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

export interface GeolocationInput {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
}

export interface GetCostTrackingTotalBreakdownPayload {
  __typename?: 'GetCostTrackingTotalBreakdownPayload';
  displayCurrency: Currency;
  fxRateDate: Scalars['Date'];
  perCurrencyBreakdown?: Maybe<Array<CurrencyBreakdown>>;
  totalInDisplayCurrency: Scalars['Float'];
}

export interface GlobalAddress {
  __typename?: 'GlobalAddress';
  address: Address;
  addressLabel: Scalars['String'];
  geolocation?: Maybe<Geolocation>;
  id: Scalars['String'];
  timeZone?: Maybe<Scalars['String']>;
}

/** An edge in a connection. */
export interface GlobalAddressEdge {
  __typename?: 'GlobalAddressEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<GlobalAddress>;
}

export interface GlobalAddressesConnection {
  __typename?: 'GlobalAddressesConnection';
  /** A list of edges. */
  edges: Array<Maybe<GlobalAddressEdge>>;
  /** A list of nodes. */
  nodes: Array<Maybe<GlobalAddress>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total amount of elements. */
  totalCount: Scalars['Int'];
}

export interface GlobalPermissions {
  __typename?: 'GlobalPermissions';
  accounts?: Maybe<AccountGlobalPermissions>;
  apiConsole?: Maybe<ApiConsoleGlobalPermissions>;
  bookings?: Maybe<BookingGlobalPermissions>;
  platform?: Maybe<PlatformGlobalPermissions>;
  rateCards?: Maybe<RateCardGlobalPermissions>;
  settings?: Maybe<SettingsGlobalPermissions>;
}

export interface GlobalSearchMetadataBookingType {
  __typename?: 'GlobalSearchMetadataBookingType';
  archived: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
}

export interface GlobalSearchMetadataEmptyType {
  __typename?: 'GlobalSearchMetadataEmptyType';
  id?: Maybe<Scalars['String']>;
}

export interface GlobalSearchMetadataPackingListType {
  __typename?: 'GlobalSearchMetadataPackingListType';
  cargoSubType?: Maybe<CargoItemSubTypeEnum>;
  cargoType?: Maybe<CargoItemTypeEnum>;
  id?: Maybe<Scalars['String']>;
}

export type GlobalSearchMetadataType =
  | GlobalSearchMetadataBookingType
  | GlobalSearchMetadataEmptyType
  | GlobalSearchMetadataPackingListType;

export enum GlobalSearchTypeEnum {
  BOOKING = 'BOOKING',
  BOOKING_QUOTE = 'BOOKING_QUOTE',
  BOOKING_REQUEST = 'BOOKING_REQUEST',
  CONSOLIDATED_SHIPMENT = 'CONSOLIDATED_SHIPMENT',
  PACKING_LIST = 'PACKING_LIST',
  PURCHASE_ORDER = 'PURCHASE_ORDER'
}

export interface HaulageCharge {
  __typename?: 'HaulageCharge';
  chargeType: ChargeType;
  chargeValue: Scalars['Float'];
  currency: Currency;
  haulageType: OriginDestinationChargeTypeEnum;
  id: Scalars['String'];
  incoterms?: Maybe<Array<IncotermsValue>>;
  location?: Maybe<NetworksOrgLoc>;
  locationNew?: Maybe<AccountLocation>;
  port: Terminal;
}

export interface HaulageChargeInput {
  chargeType: Scalars['String'];
  chargeValue: Scalars['Float'];
  currency: Currency;
  haulageType: OriginDestinationChargeTypeEnum;
  incoterms: Array<IncotermsValue>;
  location: Scalars['String'];
  port: Scalars['String'];
}

export interface Haulier {
  __typename?: 'Haulier';
  haulier: Scalars['String'];
  id: Scalars['String'];
}

export interface HaulierConnection {
  __typename?: 'HaulierConnection';
  edges?: Maybe<Array<HaulierEdge>>;
  nodes?: Maybe<Array<Haulier>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface HaulierEdge {
  __typename?: 'HaulierEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Haulier>;
}

export interface Iso8601DateRangeInput {
  endDate: Scalars['ISO8601Date'];
  startDate: Scalars['ISO8601Date'];
}

/** Autogenerated input type of ImportTerminals */
export interface ImportTerminalsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  uri: Scalars['String'];
}

/** Autogenerated return type of ImportTerminals */
export interface ImportTerminalsPayload {
  __typename?: 'ImportTerminalsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface InFull {
  __typename?: 'InFull';
  dateTimeRange?: Maybe<LocalDateTimeRange>;
  state: InFullStateType;
}

export enum InFullStateType {
  ACTUAL = 'ACTUAL',
  CONFIRMED = 'CONFIRMED',
  ESTIMATE = 'ESTIMATE',
  REQUESTED = 'REQUESTED'
}

export interface Incoterms {
  __typename?: 'Incoterms';
  /** Incoterms */
  value?: Maybe<IncotermsValue>;
}

export enum IncotermsValue {
  /** Cost and Freight */
  CFR = 'cfr',
  /** Cost, Insurance & Freight */
  CIF = 'cif',
  /** Carriage and Insurance Paid */
  CIP = 'cip',
  /** Carriage Paid To */
  CPT = 'cpt',
  /** Delivered At Place */
  DAP = 'dap',
  /** Delivered At Terminal */
  DAT = 'dat',
  /** Delivered Duty Paid */
  DDP = 'ddp',
  /** Ex Works */
  EXWORKS = 'exworks',
  /** Free Alongside Ship */
  FAS = 'fas',
  /** Free Carrier */
  FCA = 'fca',
  /** Free on Board */
  FOB = 'fob'
}

export interface InsightsAccountPermissions {
  __typename?: 'InsightsAccountPermissions';
  analytics?: Maybe<AnalyticsAccountPermissions>;
  freightSpend?: Maybe<FreightSpendAccountPermissions>;
  intakePlanning?: Maybe<IntakePlanningAccountPermissions>;
  warehousing?: Maybe<WarehousingAccountPermissions>;
}

export interface IntakePlanning {
  __typename?: 'IntakePlanning';
  canAccessRoute: AuthorizationResult;
}

export interface IntakePlanningAccountPermissions {
  __typename?: 'IntakePlanningAccountPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
}

/** An edge in a connection. */
export interface IntakePlanningProductEdge {
  __typename?: 'IntakePlanningProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductIntake>;
}

export interface Invitation {
  __typename?: 'Invitation';
  acceptUrl?: Maybe<Scalars['String']>;
  canSend: AuthorizationResult;
  status: InvitationStatus;
}

export enum InvitationStatus {
  ACCEPTED = 'ACCEPTED',
  NOT_SENT = 'NOT_SENT',
  PENDING = 'PENDING'
}

export interface InviteAccountConnectionInput {
  inviteeCompanyTradingName: Scalars['String'];
  inviteeEmail: Scalars['String'];
  inviterAccountId: Scalars['String'];
}

export interface InviteAccountConnectionPayload {
  __typename?: 'InviteAccountConnectionPayload';
  errors?: Maybe<Array<Error>>;
  shellAccountId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of Invite */
export interface InviteInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  inviteeId: Scalars['String'];
}

/** Autogenerated return type of Invite */
export interface InvitePayload {
  __typename?: 'InvitePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  invitation?: Maybe<Invitation>;
}

export interface InviteUserPayload {
  __typename?: 'InviteUserPayload';
  errors?: Maybe<Array<Error>>;
}

export interface InviteUsersInput {
  accountId: Scalars['String'];
  businessUnits: Array<Scalars['String']>;
  emails: Array<Scalars['String']>;
  invitedBy?: InputMaybe<InvitedByInput>;
  role?: InputMaybe<Scalars['String']>;
}

export interface InviteUsersPayload {
  __typename?: 'InviteUsersPayload';
  errors?: Maybe<Array<Error>>;
}

export interface InvitedByInput {
  accountName: Scalars['String'];
  userFullName: Scalars['String'];
}

export interface Issue {
  __typename?: 'Issue';
  associatedId: Scalars['String'];
  associatedType: IssueAssociatedObject;
  canArchive: AuthorizationResult;
  canModifyVisibility: AuthorizationResult;
  canResolve: AuthorizationResult;
  canStart: AuthorizationResult;
  canUpdate: AuthorizationResult;
  canUpdateCategory: AuthorizationResult;
  canUpdateProblemDescription: AuthorizationResult;
  category: IssueCategory;
  id: Scalars['String'];
  occurredOn: RichDateTime;
  openedAt: RichDateTime;
  openedBy: NetworksContact;
  problemDescription: Scalars['String'];
  resolvedAt?: Maybe<RichDateTime>;
  resolvedBy?: Maybe<NetworksContact>;
  severity: IssueSeverity;
  solutionDescription?: Maybe<Scalars['String']>;
  startedAt?: Maybe<RichDateTime>;
  startedBy?: Maybe<NetworksContact>;
  state: IssueState;
  title?: Maybe<Scalars['String']>;
  visibility: IssueVisibility;
}

export interface IssueActivityMetadataType {
  __typename?: 'IssueActivityMetadataType';
  category: IssueCategory;
  openedBy: NetworksContact;
  problemDescription: Scalars['String'];
  resolvedBy?: Maybe<NetworksContact>;
  severity: IssueSeverity;
  startedBy?: Maybe<NetworksContact>;
  state: IssueState;
  title?: Maybe<Scalars['String']>;
}

export enum IssueAssociatedObject {
  BOOKING = 'BOOKING',
  PURCHASE_ORDER = 'PURCHASE_ORDER'
}

export enum IssueCategory {
  AGENT = 'AGENT',
  CARRIER = 'CARRIER',
  CUSTOMER = 'CUSTOMER',
  CUSTOMS = 'CUSTOMS',
  HAULIER = 'HAULIER',
  MANUFACTURER = 'MANUFACTURER',
  OTHER = 'OTHER',
  PORT = 'PORT',
  ZENCARGO = 'ZENCARGO'
}

export enum IssueSeverity {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM'
}

export enum IssueState {
  ARCHIVED = 'ARCHIVED',
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED',
  STARTED = 'STARTED'
}

export enum IssueStateInput {
  OPEN = 'OPEN',
  STARTED = 'STARTED'
}

export interface IssueTemplate {
  __typename?: 'IssueTemplate';
  active: Scalars['Boolean'];
  canDelete: AuthorizationResult;
  canUpdate: AuthorizationResult;
  category: IssueCategory;
  id: Scalars['String'];
  problemDescription: Scalars['String'];
  ruleId: Scalars['String'];
  severity: IssueSeverity;
  title?: Maybe<Scalars['String']>;
  visibility: IssueVisibility;
}

/** The connection type for IssueTemplate. */
export interface IssueTemplateConnection {
  __typename?: 'IssueTemplateConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IssueTemplateEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IssueTemplate>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface IssueTemplateEdge {
  __typename?: 'IssueTemplateEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<IssueTemplate>;
}

export interface IssueVisibility {
  __typename?: 'IssueVisibility';
  agentForwarder: Scalars['Boolean'];
  customerUser: Scalars['Boolean'];
  manufacturer: Scalars['Boolean'];
}

export interface IssueVisibilityInput {
  agentForwarder: Scalars['Boolean'];
  customerUser: Scalars['Boolean'];
  manufacturer: Scalars['Boolean'];
}

export interface IssuesFilter {
  __typename?: 'IssuesFilter';
  active?: Maybe<Scalars['Boolean']>;
  titleEq?: Maybe<Scalars['String']>;
}

export interface IssuesFilterInput {
  active?: InputMaybe<Scalars['Boolean']>;
  titleEq?: InputMaybe<Scalars['String']>;
}

export interface IssuesPurchaseOrderIssue {
  __typename?: 'IssuesPurchaseOrderIssue';
  accountId: Scalars['String'];
  category: IssueCategory;
  issueId: Scalars['String'];
  occurredOn: RichDateTime;
  openedAt: RichDateTime;
  orderReferenceNumber: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  severity: IssueSeverity;
  startedAt?: Maybe<RichDateTime>;
  state: IssueState;
  title?: Maybe<Scalars['String']>;
}

/** The connection type for IssuesPurchaseOrderIssue. */
export interface IssuesPurchaseOrderIssueConnection {
  __typename?: 'IssuesPurchaseOrderIssueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IssuesPurchaseOrderIssueEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<IssuesPurchaseOrderIssue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface IssuesPurchaseOrderIssueEdge {
  __typename?: 'IssuesPurchaseOrderIssueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<IssuesPurchaseOrderIssue>;
}

export interface IssuesStatistics {
  __typename?: 'IssuesStatistics';
  perSeverity: IssuesStatisticsPerSeverity;
  perTitle: Array<IssuesStatisticsPerTitle>;
}

export interface IssuesStatisticsPerSeverity {
  __typename?: 'IssuesStatisticsPerSeverity';
  minorIssuesCount: Scalars['Int'];
  moderateIssuesCount: Scalars['Int'];
  severeIssuesCount: Scalars['Int'];
}

export interface IssuesStatisticsPerTitle {
  __typename?: 'IssuesStatisticsPerTitle';
  severity: IssueSeverity;
  title?: Maybe<Scalars['String']>;
  total: Scalars['Int'];
}

export interface Journey {
  __typename?: 'Journey';
  lastUpdated?: Maybe<JourneyLastUpdated>;
  legs?: Maybe<Array<JourneyLeg>>;
  postLegsShippingMilestones?: Maybe<Array<JourneyShippingMilestone>>;
  preLegsShippingMilestones?: Maybe<Array<JourneyShippingMilestone>>;
}

export enum JourneyActualDateReasonEnum {
  ACTUAL_DATE_MISSING_ON_PREVIOUS_MILESTONE = 'ACTUAL_DATE_MISSING_ON_PREVIOUS_MILESTONE',
  EMPTY_CARGO_READY_DATE = 'EMPTY_CARGO_READY_DATE',
  EMPTY_COLLECTION_LOCATION = 'EMPTY_COLLECTION_LOCATION',
  EMPTY_DELIVERY_LOCATION = 'EMPTY_DELIVERY_LOCATION',
  JOURNEY_NOT_BOOKED = 'JOURNEY_NOT_BOOKED',
  PLANNED_DATE_NULL = 'PLANNED_DATE_NULL',
  SCHEDULE_NOT_ADDED = 'SCHEDULE_NOT_ADDED'
}

export interface JourneyActualDateValidationResult {
  __typename?: 'JourneyActualDateValidationResult';
  reason?: Maybe<JourneyActualDateReasonEnum>;
  value: Scalars['Boolean'];
}

export interface JourneyLastUpdated {
  __typename?: 'JourneyLastUpdated';
  updatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  updatedBy?: Maybe<NetworksContact>;
  updatedBySource?: Maybe<JourneyModificationSourceEnum>;
  updatedByUser?: Maybe<AccountMember>;
}

export interface JourneyLeg {
  __typename?: 'JourneyLeg';
  bookingStage?: Maybe<JourneyLegBookingStageEnum>;
  canManagePlannedDates?: Maybe<Scalars['Boolean']>;
  from: JourneyStop;
  id: Scalars['String'];
  modeOfTransport: JourneyModeOfTransportEnum;
  to: JourneyStop;
  transitTimeInMinutes?: Maybe<Scalars['Int']>;
  type: JourneyLegTypeEnum;
  vehicle: JourneyVehicle;
}

export enum JourneyLegBookingStageEnum {
  PLANNED = 'PLANNED',
  PROGRESSED = 'PROGRESSED',
  REQUESTED = 'REQUESTED'
}

export enum JourneyLegTypeEnum {
  CARRIAGE = 'CARRIAGE',
  ON_CARRIAGE = 'ON_CARRIAGE',
  PRE_CARRIAGE = 'PRE_CARRIAGE'
}

export enum JourneyManageDatesReasonEnum {
  ACTUAL_DATE_MISSING_ON_PREVIOUS_MILESTONE = 'ACTUAL_DATE_MISSING_ON_PREVIOUS_MILESTONE',
  EMPTY_CARGO_READY_DATE = 'EMPTY_CARGO_READY_DATE',
  EMPTY_COLLECTION_LOCATION = 'EMPTY_COLLECTION_LOCATION',
  EMPTY_DELIVERY_LOCATION = 'EMPTY_DELIVERY_LOCATION',
  JOURNEY_BOOKED = 'JOURNEY_BOOKED',
  JOURNEY_NOT_BOOKED = 'JOURNEY_NOT_BOOKED',
  SCHEDULE_NOT_ADDED = 'SCHEDULE_NOT_ADDED',
  USER_CANNOT_UPDATE_DATES = 'USER_CANNOT_UPDATE_DATES'
}

export interface JourneyManageDatesValidationResult {
  __typename?: 'JourneyManageDatesValidationResult';
  reason?: Maybe<JourneyManageDatesReasonEnum>;
  value: Scalars['Boolean'];
}

export interface JourneyMilestoneDateLog {
  __typename?: 'JourneyMilestoneDateLog';
  createdAt: Scalars['ISO8601OffsetDateTime'];
  createdBy?: Maybe<NetworksContact>;
  createdByUser?: Maybe<AccountMember>;
  date?: Maybe<LocalDateTimeRange>;
  dateType: JourneyMilestoneDateLogDateTypeEnum;
  eventType: JourneyMilestoneDateLogEventTypeEnum;
  secondaryDate?: Maybe<LocalDateTimeRange>;
  source: JourneyModificationSourceEnum;
}

export interface JourneyMilestoneDateLogConnection {
  __typename?: 'JourneyMilestoneDateLogConnection';
  edges?: Maybe<Array<JourneyMilestoneDateLogEdge>>;
  nodes?: Maybe<Array<JourneyMilestoneDateLog>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export enum JourneyMilestoneDateLogDateTypeEnum {
  ACTUAL = 'ACTUAL',
  DRAFT = 'DRAFT',
  PLANNED = 'PLANNED',
  REQUESTED = 'REQUESTED'
}

export interface JourneyMilestoneDateLogEdge {
  __typename?: 'JourneyMilestoneDateLogEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<JourneyMilestoneDateLog>;
}

export enum JourneyMilestoneDateLogEventTypeEnum {
  ADDED = 'ADDED',
  REMOVED = 'REMOVED',
  UPDATED = 'UPDATED'
}

export enum JourneyModeOfTransportEnum {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
  RAIL = 'RAIL',
  ROAD = 'ROAD'
}

export enum JourneyModificationSourceEnum {
  AIS_TRACKING = 'AIS_TRACKING',
  CARRIER_TRACKING = 'CARRIER_TRACKING',
  PREDICTION_TRACKING = 'PREDICTION_TRACKING',
  SYSTEM = 'SYSTEM',
  TRACKING = 'TRACKING',
  USER = 'USER'
}

export enum JourneyPlannedDateReasonEnum {
  ACTUAL_DATE_NOT_NULL = 'ACTUAL_DATE_NOT_NULL',
  EMPTY_CARGO_READY_DATE = 'EMPTY_CARGO_READY_DATE',
  EMPTY_COLLECTION_LOCATION = 'EMPTY_COLLECTION_LOCATION',
  EMPTY_DELIVERY_LOCATION = 'EMPTY_DELIVERY_LOCATION',
  JOURNEY_NOT_BOOKED = 'JOURNEY_NOT_BOOKED',
  MILESTONE_CANNOT_HAVE_PLANNED_DATE = 'MILESTONE_CANNOT_HAVE_PLANNED_DATE',
  MISSING_CARRIER_BOOKING_REFERENCE = 'MISSING_CARRIER_BOOKING_REFERENCE'
}

export interface JourneyPlannedDateValidationResult {
  __typename?: 'JourneyPlannedDateValidationResult';
  reason?: Maybe<JourneyPlannedDateReasonEnum>;
  value: Scalars['Boolean'];
}

export enum JourneyRemoveActualDateReasonEnum {
  ACTUAL_DATE_PRESENT_ON_FOLLOWING_MILESTONE = 'ACTUAL_DATE_PRESENT_ON_FOLLOWING_MILESTONE',
  MILESTONE_CANNOT_HAVE_ACTUAL_DATE = 'MILESTONE_CANNOT_HAVE_ACTUAL_DATE'
}

export interface JourneyRemoveActualDateValidationResult {
  __typename?: 'JourneyRemoveActualDateValidationResult';
  reason?: Maybe<JourneyRemoveActualDateReasonEnum>;
  value: Scalars['Boolean'];
}

export enum JourneyRemovePlannedDateReasonEnum {
  ACTUAL_DATE_NOT_NULL = 'ACTUAL_DATE_NOT_NULL',
  JOURNEY_BOOKED = 'JOURNEY_BOOKED',
  PLANNED_DATE_CANNOT_BE_NULL = 'PLANNED_DATE_CANNOT_BE_NULL'
}

export interface JourneyRemovePlannedDateValidationResult {
  __typename?: 'JourneyRemovePlannedDateValidationResult';
  reason?: Maybe<JourneyRemovePlannedDateReasonEnum>;
  value: Scalars['Boolean'];
}

export enum JourneyRemoveRequestedDateReasonEnum {
  ACTUAL_DATE_NOT_NULL = 'ACTUAL_DATE_NOT_NULL',
  EMPTY_REQUESTED_DATE = 'EMPTY_REQUESTED_DATE',
  MILESTONE_NOT_RELEVANT = 'MILESTONE_NOT_RELEVANT',
  PLANNED_DATE_NOT_NULL = 'PLANNED_DATE_NOT_NULL'
}

export interface JourneyRemoveRequestedDateValidationResult {
  __typename?: 'JourneyRemoveRequestedDateValidationResult';
  reason?: Maybe<JourneyRemoveRequestedDateReasonEnum>;
  value: Scalars['Boolean'];
}

export enum JourneyRequestedDateReasonEnum {
  EMPTY_CARGO_READY_DATE = 'EMPTY_CARGO_READY_DATE',
  EMPTY_COLLECTION_LOCATION = 'EMPTY_COLLECTION_LOCATION',
  EMPTY_DELIVERY_LOCATION = 'EMPTY_DELIVERY_LOCATION',
  MILESTONE_CANNOT_HAVE_REQUESTED_DATE = 'MILESTONE_CANNOT_HAVE_REQUESTED_DATE',
  PLANNED_DATE_NOT_NULL = 'PLANNED_DATE_NOT_NULL',
  SCHEDULE_NOT_ADDED = 'SCHEDULE_NOT_ADDED'
}

export interface JourneyRequestedDateValidationResult {
  __typename?: 'JourneyRequestedDateValidationResult';
  reason?: Maybe<JourneyRequestedDateReasonEnum>;
  value: Scalars['Boolean'];
}

export interface JourneyScheduledLegInput {
  fromEstimatedDate: LocalDateTimeRangeInput;
  fromUnlocode: Scalars['String'];
  toEstimatedDate: LocalDateTimeRangeInput;
  toUnlocode: Scalars['String'];
  vehicleProperties: Array<VehiclePropertyInput>;
}

export interface JourneyShippingCargoReadyDateMilestone {
  __typename?: 'JourneyShippingCargoReadyDateMilestone';
  canManageDates?: Maybe<Scalars['Boolean']>;
  canManageDatesCurrently?: Maybe<JourneyManageDatesValidationResult>;
  canManageDelays: Scalars['Boolean'];
  canUpdatePlannedDate?: Maybe<Scalars['Boolean']>;
  canUpdatePlannedDateCurrently?: Maybe<JourneyPlannedDateValidationResult>;
  category: JourneyShippingMilestoneCategoryEnum;
  completed: Scalars['Boolean'];
  datesValidation?: Maybe<JourneyShippingCargoReadyDateMilestoneDatesValidation>;
  delayInDays: Scalars['Int'];
  delays: Array<JourneyShippingMilestoneDelay>;
  id: Scalars['String'];
  initialEstimate?: Maybe<LocalDateTimeRange>;
  latestEstimate?: Maybe<LocalDateTimeRange>;
  milestoneType: ShippingMilestoneTypeEnum;
  name: JourneyShippingMilestoneNameEnum;
}

export interface JourneyShippingCargoReadyDateMilestoneDatesValidation {
  __typename?: 'JourneyShippingCargoReadyDateMilestoneDatesValidation';
  maximumDate: Scalars['ISO8601Date'];
  nextMilestone: JourneyShippingMilestone;
  nextMilestoneStopAction: JourneyStopActionEnum;
}

export interface JourneyShippingMilestone {
  __typename?: 'JourneyShippingMilestone';
  actual?: Maybe<LocalDateTimeType>;
  canManageDates?: Maybe<Scalars['Boolean']>;
  canManageDatesCurrently?: Maybe<JourneyManageDatesValidationResult>;
  canManageDelays: Scalars['Boolean'];
  canRemoveActualDateCurrently?: Maybe<JourneyRemoveActualDateValidationResult>;
  canRemovePlannedDateCurrently?: Maybe<JourneyRemovePlannedDateValidationResult>;
  canRemoveRequestedDateCurrently?: Maybe<JourneyRemoveRequestedDateValidationResult>;
  canUpdateActualDate?: Maybe<Scalars['Boolean']>;
  canUpdateActualDateCurrently?: Maybe<JourneyActualDateValidationResult>;
  canUpdatePlannedDate?: Maybe<Scalars['Boolean']>;
  canUpdatePlannedDateCurrently?: Maybe<JourneyPlannedDateValidationResult>;
  canUpdateRequestedBy?: Maybe<Scalars['Boolean']>;
  canUpdateRequestedDate?: Maybe<Scalars['Boolean']>;
  canUpdateRequestedDateCurrently?: Maybe<JourneyRequestedDateValidationResult>;
  category: JourneyShippingMilestoneCategoryEnum;
  completed: Scalars['Boolean'];
  dateType?: Maybe<ShippingMilestoneDateTypeEnum>;
  delayInDays: Scalars['Int'];
  delays: Array<JourneyShippingMilestoneDelay>;
  draft?: Maybe<LocalDateTimeRange>;
  id: Scalars['String'];
  initialEstimate?: Maybe<LocalDateTimeRange>;
  lastUpdatedAt?: Maybe<Scalars['ISO8601DateTime']>;
  lastUpdatedBy?: Maybe<NetworksContact>;
  lastUpdatedByUser?: Maybe<AccountMember>;
  latestEstimate?: Maybe<LocalDateTimeRange>;
  milestoneType: ShippingMilestoneTypeEnum;
  name: JourneyShippingMilestoneNameEnum;
  planned?: Maybe<LocalDateTimeRange>;
  requested?: Maybe<LocalDateTimeRange>;
  requestedBy?: Maybe<JourneyShippingMilestoneRequestedByEnum>;
}

export enum JourneyShippingMilestoneCategoryEnum {
  ARRIVED_AT_SCHEDULED_DEPARTURE_STOP = 'ARRIVED_AT_SCHEDULED_DEPARTURE_STOP',
  ARRIVED_AT_STOP = 'ARRIVED_AT_STOP',
  AVAILABLE_FOR_PICKUP = 'AVAILABLE_FOR_PICKUP',
  CARGO_READY = 'CARGO_READY',
  DEPARTED_FROM_STOP = 'DEPARTED_FROM_STOP',
  GATE_IN_EMPTY = 'GATE_IN_EMPTY',
  GATE_OUT_EMPTY = 'GATE_OUT_EMPTY',
  LOADED = 'LOADED',
  UNLOADED = 'UNLOADED'
}

export interface JourneyShippingMilestoneDatesValidationInput {
  dateType: ShippingMilestoneDateTypeEnum;
  milestoneId: Scalars['String'];
}

export interface JourneyShippingMilestoneDatesValidationPayload {
  __typename?: 'JourneyShippingMilestoneDatesValidationPayload';
  nextMilestoneDate?: Maybe<LocalDateTimeType>;
  nextMilestoneId?: Maybe<Scalars['String']>;
  previousMilestoneDate?: Maybe<LocalDateTimeType>;
  previousMilestoneId?: Maybe<Scalars['String']>;
}

export interface JourneyShippingMilestoneDelay {
  __typename?: 'JourneyShippingMilestoneDelay';
  days: Scalars['Int'];
  id: Scalars['String'];
  reason?: Maybe<ShippingDelayReason>;
}

export enum JourneyShippingMilestoneNameEnum {
  AIRCRAFT_ARRIVED = 'AIRCRAFT_ARRIVED',
  AIRCRAFT_DEPARTED = 'AIRCRAFT_DEPARTED',
  CARGO_AVAILABLE_FOR_PICKUP = 'CARGO_AVAILABLE_FOR_PICKUP',
  CARGO_LOADED = 'CARGO_LOADED',
  CARGO_PICKED_UP = 'CARGO_PICKED_UP',
  CARGO_READY_DATE = 'CARGO_READY_DATE',
  CARGO_RECEIVED_AT_AIRLINE_SHED = 'CARGO_RECEIVED_AT_AIRLINE_SHED',
  CARGO_UNLOADED = 'CARGO_UNLOADED',
  GATE_IN = 'GATE_IN',
  GATE_IN_EMPTY = 'GATE_IN_EMPTY',
  GATE_OUT_EMPTY = 'GATE_OUT_EMPTY',
  TRAIN_ARRIVED = 'TRAIN_ARRIVED',
  TRAIN_DEPARTED = 'TRAIN_DEPARTED',
  VEHICLE_ARRIVED_AT_STOP = 'VEHICLE_ARRIVED_AT_STOP',
  VEHICLE_ARRIVED_AT_WAREHOUSE = 'VEHICLE_ARRIVED_AT_WAREHOUSE',
  VEHICLE_DEPARTED_FROM_STOP = 'VEHICLE_DEPARTED_FROM_STOP',
  VEHICLE_DEPARTED_FROM_WAREHOUSE = 'VEHICLE_DEPARTED_FROM_WAREHOUSE',
  VESSEL_ARRIVED = 'VESSEL_ARRIVED',
  VESSEL_DEPARTED = 'VESSEL_DEPARTED'
}

export enum JourneyShippingMilestoneRequestedByEnum {
  CUSTOMER = 'CUSTOMER',
  CUSTOMERS_SUPPLIER = 'CUSTOMERS_SUPPLIER',
  THIRD_PARTY_WAREHOUSE = 'THIRD_PARTY_WAREHOUSE',
  ZENCARGO = 'ZENCARGO'
}

export interface JourneyStop {
  __typename?: 'JourneyStop';
  canUpdateLocation: Scalars['Boolean'];
  canUpdateReference?: Maybe<Scalars['Boolean']>;
  canViewReference?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  locationType: JourneyStopLocationTypeEnum;
  reference?: Maybe<Scalars['String']>;
  shippingMilestones: Array<JourneyShippingMilestone>;
  stopAction: JourneyStopActionEnum;
  terminal?: Maybe<Terminal>;
  timeZone?: Maybe<Scalars['TimeZone']>;
  warehouse?: Maybe<NetworksOrgLoc>;
  warehouseLocation?: Maybe<AccountLocation>;
}

export enum JourneyStopActionEnum {
  CARRIAGE_ARRIVAL = 'CARRIAGE_ARRIVAL',
  CARRIAGE_DEPARTURE = 'CARRIAGE_DEPARTURE',
  CARRIAGE_TRANSSHIPMENT = 'CARRIAGE_TRANSSHIPMENT',
  COLLECTION = 'COLLECTION',
  COLLECTION_DELIVERY = 'COLLECTION_DELIVERY',
  CONSOLIDATION = 'CONSOLIDATION',
  CUSTOMS = 'CUSTOMS',
  DELIVERY = 'DELIVERY',
  REPACKING = 'REPACKING',
  STORAGE = 'STORAGE',
  VEHICLE_CHANGE = 'VEHICLE_CHANGE'
}

export enum JourneyStopLocationTypeEnum {
  TERMINAL = 'TERMINAL',
  WAREHOUSE = 'WAREHOUSE',
  WAYPOINT = 'WAYPOINT'
}

export enum JourneyToggleCollectionReasonEnum {
  BOOKING_DEPARTED_ORIGIN_TERMINAL = 'BOOKING_DEPARTED_ORIGIN_TERMINAL',
  COLLECTION_COMPLETED_FOR_ONE_OF_CARGOS = 'COLLECTION_COMPLETED_FOR_ONE_OF_CARGOS'
}

export interface JourneyToggleCollectionValidationResult {
  __typename?: 'JourneyToggleCollectionValidationResult';
  reason?: Maybe<JourneyToggleCollectionReasonEnum>;
  value: Scalars['Boolean'];
}

export interface JourneyVehicle {
  __typename?: 'JourneyVehicle';
  id: Scalars['String'];
  properties?: Maybe<Array<JourneyVehicleProperty>>;
  type: JourneyVehicleTypeEnum;
}

export interface JourneyVehicleProperty {
  __typename?: 'JourneyVehicleProperty';
  canUpdate?: Maybe<Scalars['Boolean']>;
  canView?: Maybe<Scalars['Boolean']>;
  name: VehiclePropertyNameEnum;
  value?: Maybe<Scalars['String']>;
}

export enum JourneyVehicleTypeEnum {
  AIRCRAFT = 'AIRCRAFT',
  AUTOMOBILE = 'AUTOMOBILE',
  TRAIN = 'TRAIN',
  VESSEL = 'VESSEL'
}

export interface KeyValuePair {
  __typename?: 'KeyValuePair';
  key: Scalars['String'];
  value: Scalars['String'];
  valueType?: Maybe<ValueTypeEnum>;
}

export interface KeyValuePairInput {
  key: Scalars['String'];
  value: Scalars['String'];
  valueType?: InputMaybe<ValueTypeEnum>;
}

export type LastNotificationItem = Activity;

export interface LastUpdated {
  __typename?: 'LastUpdated';
  /** Date the object was updated */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Who updated */
  updatedBy?: Maybe<UpdatedBy>;
}

export interface LegacyAccount {
  __typename?: 'LegacyAccount';
  billingDetails?: Maybe<BillingDetails>;
  booking?: Maybe<Booking>;
  companyEmailAddress?: Maybe<Scalars['String']>;
  companyPhoneNumber?: Maybe<Scalars['String']>;
  controlTower: ControlTower;
  division?: Maybe<Division>;
  eoriNumber?: Maybe<Scalars['String']>;
  filters?: Maybe<Filters>;
  intakePlanningDataQuality: DataQuality;
  intakePlanningPeriods: Array<Maybe<Period>>;
  intakePlanningProductLots: ProductLotConnection;
  intakePlanningProducts: ProductIntakeConnection;
  intakePlanningSkuAnalytics: Statistics;
  intakePlanningSkuOverview: SkuOverviewType;
  isActive: Scalars['Boolean'];
  isDemo: Scalars['Boolean'];
  isDivision: Scalars['Boolean'];
  isParent: Scalars['Boolean'];
  /** The account registered name */
  name: Scalars['String'];
  pendingTradeLanes?: Maybe<Array<PendingTradeLane>>;
  referencePrefix: Scalars['String'];
  settings?: Maybe<AccountSettings>;
  shipmentQuantities?: Maybe<ShipmentQuantities>;
  ssoDetails?: Maybe<SsoDetails>;
  subdivisions: Array<Division>;
  tradeLanes?: Maybe<Array<TradeLane>>;
  /** Account identifier */
  uuid: Scalars['String'];
  vat?: Maybe<Scalars['String']>;
}

export type LegacyAccountBookingArgs = {
  zencargoReference: Scalars['String'];
};

export type LegacyAccountIntakePlanningDataQualityArgs = {
  analyticsPage?: InputMaybe<Scalars['Boolean']>;
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};

export type LegacyAccountIntakePlanningPeriodsArgs = {
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
};

export type LegacyAccountIntakePlanningProductLotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  productId?: InputMaybe<Scalars['String']>;
};

export type LegacyAccountIntakePlanningProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fulfilmentStatus?: InputMaybe<FulfilmentStatusEnum>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
};

export type LegacyAccountIntakePlanningSkuAnalyticsArgs = {
  dateRange: DateRangeInput;
  inFullThreshold?: InputMaybe<Scalars['Float']>;
  onTimeThreshold?: InputMaybe<Scalars['Int']>;
  productId: Scalars['String'];
};

export type LegacyAccountIntakePlanningSkuOverviewArgs = {
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
};

/** The connection type for LegacyAccount. */
export interface LegacyAccountConnection {
  __typename?: 'LegacyAccountConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LegacyAccountEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LegacyAccount>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface LegacyAccountEdge {
  __typename?: 'LegacyAccountEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LegacyAccount>;
}

export interface LegacyAddress {
  __typename?: 'LegacyAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countyOrState?: Maybe<Scalars['String']>;
  postalCodeOrZip?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
}

export interface LegacyAddressInput {
  city?: InputMaybe<Scalars['String']>;
  country: CountryInput;
  countyOrState?: InputMaybe<Scalars['String']>;
  postalCodeOrZip?: InputMaybe<Scalars['String']>;
  street: Scalars['String'];
}

export interface LegacyBookingIssue {
  __typename?: 'LegacyBookingIssue';
  accountId: Scalars['String'];
  bookingManagerId?: Maybe<Scalars['String']>;
  category: IssueCategory;
  clientReference?: Maybe<Scalars['String']>;
  issueId: Scalars['String'];
  occurredOn: RichDateTime;
  openedAt: RichDateTime;
  severity: IssueSeverity;
  startedAt?: Maybe<RichDateTime>;
  state: IssueState;
  title?: Maybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** The connection type for LegacyBookingIssue. */
export interface LegacyBookingIssueConnection {
  __typename?: 'LegacyBookingIssueConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LegacyBookingIssueEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<LegacyBookingIssue>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface LegacyBookingIssueEdge {
  __typename?: 'LegacyBookingIssueEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<LegacyBookingIssue>;
}

/** Autogenerated input type of LegacyCreateAccount */
export interface LegacyCreateAccountInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  referencePrefix: Scalars['String'];
  registeredCompanyName: Scalars['String'];
}

/** Autogenerated return type of LegacyCreateAccount */
export interface LegacyCreateAccountPayload {
  __typename?: 'LegacyCreateAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface LegacyCreateRateCardInput {
  cargoType: CargoModeEnum;
  clientMutationId?: InputMaybe<Scalars['String']>;
  createdBy: Scalars['String'];
  customerDivisionId: Scalars['String'];
  destinationCharges?: InputMaybe<Array<PortChargeInput>>;
  destinationHaulageCharges?: InputMaybe<Array<HaulageChargeInput>>;
  endDate: Scalars['Date'];
  freightCharges: Array<FreightChargeInput>;
  issuedBy: Scalars['String'];
  modeOfTransport: ModeOfTransport;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  originCharges?: InputMaybe<Array<PortChargeInput>>;
  originHaulageCharges?: InputMaybe<Array<HaulageChargeInput>>;
  startDate: Scalars['Date'];
}

export interface LegacyCreateRateCardPayload {
  __typename?: 'LegacyCreateRateCardPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  rateCardId?: Maybe<Scalars['String']>;
}

export interface LegacyCustomerValidityDates {
  __typename?: 'LegacyCustomerValidityDates';
  customerDivisionId: Scalars['String'];
  validityDates?: Maybe<Array<DateRange>>;
}

/** Autogenerated input type of LegacyDeactivateAccount */
export interface LegacyDeactivateAccountInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of LegacyDeactivateAccount */
export interface LegacyDeactivateAccountPayload {
  __typename?: 'LegacyDeactivateAccountPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  id: Scalars['String'];
}

export interface LegacyDeleteRateCardInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

export interface LegacyDeleteRateCardPayload {
  __typename?: 'LegacyDeleteRateCardPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface LegacyGlobalPermissions {
  __typename?: 'LegacyGlobalPermissions';
  accounts?: Maybe<Accounts>;
  accruals?: Maybe<Accruals>;
  adminPanel?: Maybe<AdminPanel>;
  allShipmentsSection?: Maybe<AllShipmentsSection>;
  backoffice?: Maybe<Backoffice>;
  bookingRequests?: Maybe<BookingRequests>;
  bookings?: Maybe<Bookings>;
  consolidation?: Maybe<Consolidation>;
  currentUserId: Scalars['String'];
  customerAdmin?: Maybe<CustomerAdmin>;
  dashboard?: Maybe<Dashboard>;
  documentCreation?: Maybe<DocumentCreation>;
  freightSpend?: Maybe<FreightSpend>;
  intakePlanning?: Maybe<IntakePlanning>;
  networks?: Maybe<Networks>;
  operationsDashboard?: Maybe<OperationsDashboard>;
  operationsSettings?: Maybe<OperationsSettings>;
  packingLists?: Maybe<PackingLists>;
  productSettings?: Maybe<ProductSettings>;
  products?: Maybe<Products>;
  productsCsvImport?: Maybe<ProductsCsvImport>;
  purchaseOrders?: Maybe<PurchaseOrders>;
  quotes?: Maybe<Quotes>;
  rulesEngine?: Maybe<RulesEngine>;
  settings?: Maybe<Settings>;
  taskList?: Maybe<TaskList>;
  workflows?: Maybe<Workflows>;
  zencargoNetwork?: Maybe<ZencargoNetwork>;
}

export interface LegacyRateCard {
  __typename?: 'LegacyRateCard';
  cargoType: CargoModeEnum;
  customerDivisionId: Scalars['String'];
  destinationCharges?: Maybe<Array<PortCharge>>;
  destinationHaulageCharges?: Maybe<Array<HaulageCharge>>;
  endDate: Scalars['Date'];
  freightCharges: Array<FreightCharge>;
  issuedAt: Scalars['DateTime'];
  issuedBy?: Maybe<NetworksContact>;
  modeOfTransport: ModeOfTransport;
  name?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  originCharges?: Maybe<Array<PortCharge>>;
  originHaulageCharges?: Maybe<Array<HaulageCharge>>;
  rateCardId: Scalars['String'];
  startDate: Scalars['Date'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<NetworksContact>;
}

export interface LegacyRateCardConnection {
  __typename?: 'LegacyRateCardConnection';
  edges?: Maybe<Array<Maybe<LegacyRateCardEdge>>>;
  nodes?: Maybe<Array<Maybe<LegacyRateCardSummary>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
}

export interface LegacyRateCardEdge {
  __typename?: 'LegacyRateCardEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<LegacyRateCardSummary>;
}

export interface LegacyRateCardSummary {
  __typename?: 'LegacyRateCardSummary';
  cargoType: CargoModeEnum;
  endDate: Scalars['Date'];
  modeOfTransport: ModeOfTransport;
  name: Scalars['String'];
  rateCardId: Scalars['String'];
  startDate: Scalars['Date'];
}

export interface LegacyUpdateRateCardInput {
  cargoType: CargoModeEnum;
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerDivisionId: Scalars['String'];
  destinationCharges?: InputMaybe<Array<PortChargeInput>>;
  destinationHaulageCharges?: InputMaybe<Array<HaulageChargeInput>>;
  endDate: Scalars['Date'];
  freightCharges: Array<FreightChargeInput>;
  id: Scalars['String'];
  issuedBy: Scalars['String'];
  modeOfTransport: ModeOfTransport;
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  originCharges?: InputMaybe<Array<PortChargeInput>>;
  originHaulageCharges?: InputMaybe<Array<HaulageChargeInput>>;
  startDate: Scalars['Date'];
  updatedBy: Scalars['String'];
}

export interface LegacyUpdateRateCardPayload {
  __typename?: 'LegacyUpdateRateCardPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  rateCardId?: Maybe<Scalars['String']>;
}

export enum LengthUnitEnum {
  CM = 'cm'
}

export interface LiveViewsFilterDateRangeInput {
  from?: InputMaybe<Scalars['ISO8601Date']>;
  to?: InputMaybe<Scalars['ISO8601Date']>;
}

export interface LiveViewsFilterDateTimeInput {
  date: Scalars['ISO8601Date'];
  time?: InputMaybe<Scalars['ISO8601Time']>;
}

export interface LiveViewsFilterDateTimeRangeInput {
  from?: InputMaybe<LiveViewsFilterDateTimeInput>;
  to?: InputMaybe<LiveViewsFilterDateTimeInput>;
}

export interface LiveViewsFilterIntRangeInput {
  from?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
}

/** Global Search */
export interface LiveViewsGlobalSearchResult {
  __typename?: 'LiveViewsGlobalSearchResult';
  searchResult: Array<SearchResult>;
}

/** Generic Table enums */
export enum LiveViewsSortOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface LocalDateTimeInput {
  date: Scalars['ISO8601Date'];
  time?: InputMaybe<Scalars['ISO8601Time']>;
}

export interface LocalDateTimeRange {
  __typename?: 'LocalDateTimeRange';
  endDateTime?: Maybe<LocalDateTimeType>;
  startDateTime: LocalDateTimeType;
}

export interface LocalDateTimeRangeInput {
  endDateTime?: InputMaybe<LocalDateTimeInput>;
  startDateTime: LocalDateTimeInput;
}

export interface LocalDateTimeType {
  __typename?: 'LocalDateTimeType';
  /** Date in the provided time zone */
  date: Scalars['ISO8601Date'];
  /** Time in the provided time zone */
  time?: Maybe<Scalars['ISO8601Time']>;
  /** Official timezone id for provided date/time formatted as 'Europe/London' */
  timeZone?: Maybe<Scalars['TimeZone']>;
}

export interface LocalDateTimeWithNoTimeZone {
  __typename?: 'LocalDateTimeWithNoTimeZone';
  /** Date in local time zone */
  date: Scalars['ISO8601Date'];
  /** Time in local timezone */
  time?: Maybe<Scalars['ISO8601Time']>;
}

export type Location = Terminal | Warehouse;

/** The connection type for NetworksAssignableInterface. */
export interface LocationAssignableConnection {
  __typename?: 'LocationAssignableConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<AssignableEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NetworksAssignableInterface>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface LocationInput {
  address: LegacyAddressInput;
  businessHours?: InputMaybe<BusinessHoursInput>;
  geolocation: GeolocationInput;
  locationType?: InputMaybe<LocationTypeValue>;
  name?: InputMaybe<Scalars['String']>;
}

export interface LocationPermissions {
  __typename?: 'LocationPermissions';
  canArchive?: Maybe<AuthorizationResult>;
  canEditAddress?: Maybe<AuthorizationResult>;
  canEditBusinessHours?: Maybe<AuthorizationResult>;
  canEditDetails?: Maybe<AuthorizationResult>;
  canUnarchive?: Maybe<AuthorizationResult>;
}

export interface LocationSearchInput {
  /** Limit search to specfic assginable type */
  assignmentName?: InputMaybe<AssignmentTypeValue>;
  /** Limit search scope to exclude assigments based on cargo_id */
  cargoId?: InputMaybe<Scalars['String']>;
  /** Search term */
  search: Scalars['String'];
  /** Limit search to specific target type */
  targetType?: InputMaybe<TargetTypeValue>;
}

export enum LocationTypeValue {
  OFFICE = 'office',
  WAREHOUSE = 'warehouse'
}

export interface LocationsAssignedToRateCard {
  __typename?: 'LocationsAssignedToRateCard';
  destinationLocations: Array<CostTrackingLocation>;
  destinationPorts: Array<CostTrackingLocation>;
  originLocations: Array<CostTrackingLocation>;
  originPorts: Array<CostTrackingLocation>;
}

export enum LooseCargoTypeEnum {
  ASSORTED_CARGO = 'ASSORTED_CARGO',
  BOXES_OR_CRATES = 'BOXES_OR_CRATES',
  PALLETS = 'PALLETS'
}

/**
 * A single shipment of a product in a purchase order. Lots represent the latest information that Zencargo
 * holds. This object is where you should query for the latest delivery estimate, the fulfilled quantity
 * and anything else that Zencargo is likely to be the source of truth for.
 */
export interface Lot {
  __typename?: 'Lot';
  atRisk?: Maybe<Scalars['Boolean']>;
  /**
   * Booking information about the shipment
   * containing this Lot. See inside for details
   */
  booking?: Maybe<Booking>;
  cargo?: Maybe<CargoCargoItem>;
  /**
   * Also known as "goods ready date
   * or "ex-factory date", this is the date the goods are ready for collection from the place of origin.
   * It represents the last known date, which is either an estimate or an actual. Check out RichDate for details
   */
  cargoReadyDate?: Maybe<RichDate>;
  /** @deprecated Renamed to core_cargo */
  cargos?: Maybe<CoreCargo>;
  /**
   * The expected volume of the fulfilled quantity of products, when fully packaged, in cubic meters.
   * It is the latest value Zencargo holds, either from the packing list or from the manufacturers' booking.
   */
  cbm?: Maybe<Scalars['Float']>;
  coreCargo?: Maybe<CoreCargo>;
  currentStage?: Maybe<ProgressStatus>;
  deliveryEstimate?: Maybe<RichDate>;
  deliveryStatus?: Maybe<ProgressStatus>;
  /**
   * The date the lot is estimated to deliver to the
   * destination warehouse. This represents the latest known date, which may be different to the original
   * RequiredDeliveryDate because of delays somewhere along the way. This is the date you should query if you need to
   * update, for instance, your ERP with accurate Intake information
   */
  estimatedDeliveryDate?: Maybe<Scalars['Date']>;
  fulfilled?: Maybe<Scalars['Boolean']>;
  /** Lot ID (pk) */
  id: Scalars['String'];
  /** The ordered line item for this lot. This holds ordered information. See inside for details */
  orderedLineItem?: Maybe<OrderedLineItem>;
  /** The purchase order this lot belongs to */
  purchaseOrder?: Maybe<PurchaseOrder>;
  /**
   * Quantity of product fulfilled, gathered either from the
   * packing list, or from the manufacturer updating Zencargo during the booking of the order
   */
  quantityFulfilled?: Maybe<Scalars['Int']>;
  stages?: Maybe<Array<ProgressStatus>>;
}

/** The connection type for Lot. */
export interface LotConnection {
  __typename?: 'LotConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<LotEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Lot>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface LotEdge {
  __typename?: 'LotEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Lot>;
}

/**
 * Provide Zencargo with expected fulfillment information about a specific lot inside a purchase order line item.
 * Zencargo uses values in this payload to calculate the basis for on time and in full.
 * Values here are used to update the latest known info, such as an updated quantity or CBM.
 * Refer to the purchase orders guide for more information.
 */
export interface LotInput {
  /** The latest known fulfilled CBM of the lot */
  cbm?: InputMaybe<Scalars['Float']>;
  /** The Zencargo-generated ID of the lot you are updating */
  id?: InputMaybe<Scalars['String']>;
  /** The latest known fulfilled quantity of the lot */
  quantityFulfilled?: InputMaybe<Scalars['Int']>;
}

export enum ManagerTeam {
  COMMERCIAL = 'commercial',
  OPERATIONS = 'operations'
}

export enum ManagerTypeEnum {
  COMMERCIAL = 'commercial',
  OPERATIONS = 'operations'
}

export interface Margin {
  __typename?: 'Margin';
  chargeType: ChargeType;
  costId: Scalars['String'];
  currency: Currency;
  exchangeRate: Scalars['Float'];
  id: Scalars['String'];
  percent?: Maybe<Scalars['Float']>;
  totalGbp: Scalars['Float'];
  totalLocalCcy: Scalars['Float'];
}

export interface MarginInput {
  chargeTypeId: Scalars['String'];
  costId: Scalars['String'];
  currency: Scalars['String'];
  exchangeRate: Scalars['Float'];
  id: Scalars['String'];
  percent?: InputMaybe<Scalars['Float']>;
  totalGbp: Scalars['Float'];
  totalLocalCcy: Scalars['Float'];
}

/** Autogenerated input type of MarkAllAsRead */
export interface MarkAllAsReadInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of MarkAllAsRead */
export interface MarkAllAsReadPayload {
  __typename?: 'MarkAllAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of MarkAsRead */
export interface MarkAsReadInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  lastNotificationItemCreatedAt: Scalars['String'];
  targetId: Scalars['String'];
}

/** Autogenerated return type of MarkAsRead */
export interface MarkAsReadPayload {
  __typename?: 'MarkAsReadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface MatchedField {
  __typename?: 'MatchedField';
  additionalInformation?: Maybe<Scalars['String']>;
  type: SearchableField;
  value: Scalars['String'];
}

export interface Member {
  __typename?: 'Member';
  account: LegacyAccount;
  /** Membership identifier */
  id: Scalars['String'];
  /** Membership status */
  status: Scalars['String'];
}

export type Metadata =
  | BookingCollectionMetadataType
  | BookingDeliveryMetadataType
  | CargoMetadataContainerType
  | CargoMetadataLooseCargoType
  | CargoMetadataVehicleType
  | IssueActivityMetadataType
  | PurchaseOrderCargoReadyDatesMetadataType
  | PurchaseOrderClosedMetadataType
  | PurchaseOrderDeliveryEstimatesMetadataType
  | ShipmentEtaMetadataType;

export interface MetricValue {
  __typename?: 'MetricValue';
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface MetricValueInput {
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface MetricValueWithSi {
  __typename?: 'MetricValueWithSI';
  si?: Maybe<MetricValue>;
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export enum MilestoneEstimateDateType {
  DRAFT = 'DRAFT',
  PLANNED = 'PLANNED',
  REQUESTED = 'REQUESTED'
}

export enum MissingRoadTrackingInputData {
  APPOINTMENT_WINDOW = 'APPOINTMENT_WINDOW',
  HAULIER_ID = 'HAULIER_ID',
  LICENSE_PLATE = 'LICENSE_PLATE',
  LOCATION_MISSING_TIME_ZONE = 'LOCATION_MISSING_TIME_ZONE',
  SHIPMENT_IDENTIFIER = 'SHIPMENT_IDENTIFIER',
  SHIPMENT_STOP_GEO_COORDINATES = 'SHIPMENT_STOP_GEO_COORDINATES',
  SHIPMENT_STOP_LOCATION = 'SHIPMENT_STOP_LOCATION',
  TRAILER_ID = 'TRAILER_ID'
}

export enum Mode {
  AM = 'am',
  PM = 'pm',
  SPECIFIC = 'specific'
}

/** Enums */
export enum ModeOfTransport {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
  RAIL = 'RAIL',
  TRUCK = 'TRUCK'
}

export interface Money {
  __typename?: 'Money';
  currency: Currency;
  value: Scalars['Float'];
}

export interface MoneyInput {
  currency: Currency;
  value: Scalars['Float'];
}

export enum MultiStopEnum {
  MORE_THAN_TWO_STOPS = 'MORE_THAN_TWO_STOPS',
  TWO_STOPS = 'TWO_STOPS',
  UNKNOWN = 'UNKNOWN'
}

/** Mutations */
export interface Mutation {
  __typename?: 'Mutation';
  acceptInvite?: Maybe<AcceptInvitePayload>;
  accountActivateAccount?: Maybe<ActivateAccountPayload>;
  accountAddDivision?: Maybe<AddDivisionPayload>;
  accountAddTradeLane?: Maybe<AddTradeLanePayload>;
  accountCreateAccount?: Maybe<LegacyCreateAccountPayload>;
  accountDeactivateAccount?: Maybe<LegacyDeactivateAccountPayload>;
  accountDisableDemoMode?: Maybe<DisableDemoModePayload>;
  accountEnableDemoMode?: Maybe<EnableDemoModePayload>;
  accountRenameDivision?: Maybe<RenameDivisionPayload>;
  accountUpdateBillingDetails?: Maybe<UpdateBillingDetailsPayload>;
  accountUpdateCompanyDetails?: Maybe<UpdateCompanyDetailsPayload>;
  actionItemTypesCreateActionItemType?: Maybe<CreateActionItemTypePayload>;
  actionItemTypesUpdateActionItemType?: Maybe<UpdateActionItemPayload>;
  activateInactiveAccount?: Maybe<ActivateInactiveAccountPayload>;
  activatePendingAccount?: Maybe<ActivatePendingAccountPayload>;
  activityFeedArchiveTextMessage?: Maybe<ArchiveTextMessagePayload>;
  activityFeedCreateTextMessage?: Maybe<CreateTextMessagePayload>;
  addCostTrackingCharges?: Maybe<CostTrackingPayload>;
  addJourneyRequestedSchedule: AddJourneyRequestedSchedulePayload;
  archiveAccountConnection?: Maybe<ArchiveAccountConnectionPayload>;
  archiveAccountLocation?: Maybe<ArchiveAccountLocationPayload>;
  archiveShippingDelayReason: ArchiveShippingDelayReasonPayload;
  assignContract?: Maybe<AssignContractPayload>;
  /** Batch assigns members to a role */
  assignRoleMembers?: Maybe<RoleMembersPayload>;
  assignWorkflowTasksToUser?: Maybe<AssignWorkflowTasksPayload>;
  authenticationSendResetPasswordInstructions?: Maybe<SendResetPasswordInstructionsPayload>;
  bookingAcceptQuoteOption?: Maybe<AcceptQuoteOptionPayload>;
  bookingActivateQuoteOption?: Maybe<ActivateQuoteOptionPayload>;
  bookingAddVoyageMilestoneDelay?: Maybe<AddVoyageMilestoneDelayPayload>;
  bookingApproveBooking?: Maybe<ApproveBookingPayload>;
  bookingArchiveBooking?: Maybe<ArchiveBookingPayload>;
  bookingArchiveQuoteOption?: Maybe<ArchiveQuoteOptionPayload>;
  bookingAssignCommercialManager?: Maybe<AssignCommercialManagerPayload>;
  bookingAssignNotifiedParty?: Maybe<AssignNotifiedPartyPayload>;
  bookingAssignOperationsManager?: Maybe<AssignOperationsManagerPayload>;
  bookingCollectionAndDeliveryCancelCollection?: Maybe<CancelCollectionPayload>;
  bookingCollectionAndDeliveryCancelDelivery?: Maybe<CancelDeliveryPayload>;
  bookingCollectionAndDeliveryCompleteCollection?: Maybe<CompleteCollectionPayload>;
  bookingCollectionAndDeliveryCompleteDelivery?: Maybe<CompleteDeliveryPayload>;
  bookingCollectionAndDeliveryConfirmCollection?: Maybe<ConfirmCollectionPayload>;
  bookingCollectionAndDeliveryConfirmDelivery?: Maybe<ConfirmDeliveryPayload>;
  bookingCollectionAndDeliveryRemoveCollectionSchedule?: Maybe<RemoveCollectionSchedulePayload>;
  bookingCollectionAndDeliveryRemoveDeliverySchedule?: Maybe<RemoveDeliverySchedulePayload>;
  bookingCollectionAndDeliveryRequireCollectionSchedule?: Maybe<RequireCollectionSchedulePayload>;
  bookingCollectionAndDeliveryRequireDeliverySchedule?: Maybe<RequireDeliverySchedulePayload>;
  bookingCollectionAndDeliveryScheduleCollection?: Maybe<ScheduleCollectionPayload>;
  bookingCollectionAndDeliveryScheduleDelivery?: Maybe<ScheduleDeliveryPayload>;
  bookingCollectionAndDeliveryUpdateCollection?: Maybe<UpdateCollectionPayload>;
  bookingCollectionAndDeliveryUpdateCollectionDates?: Maybe<UpdateCollectionDatesPayload>;
  bookingCollectionAndDeliveryUpdateCollectionReference?: Maybe<UpdateCollectionReferencePayload>;
  bookingCollectionAndDeliveryUpdateDelivery?: Maybe<UpdateDeliveryPayload>;
  bookingCollectionAndDeliveryUpdateDeliveryDates?: Maybe<UpdateDeliveryDatesPayload>;
  bookingCollectionAndDeliveryUpdateDeliveryReference?: Maybe<UpdateDeliveryReferencePayload>;
  bookingCompleteBooking?: Maybe<CompleteBookingPayload>;
  bookingCreateBookingViaEasyBooking?: Maybe<CreateBookingViaEasyBookingPayload>;
  bookingCreateBookingViaExternalBooking?: Maybe<CreateBookingViaExternalBookingPayload>;
  bookingCreateBookingViaQuotedBooking?: Maybe<CreateBookingViaQuotedBookingPayload>;
  bookingCreateBookingViaRequestForCustomerApproval?: Maybe<CreateBookingViaRequestForCustomerApprovalPayload>;
  bookingCreateBookingViaRequestedBooking?: Maybe<CreateBookingViaRequestedBookingPayload>;
  bookingCreateManagerAssignmentRule?: Maybe<CreateBookingManagerAssignmentRulePayload>;
  bookingCreateQuoteOption?: Maybe<CreateQuoteOptionPayload>;
  bookingDocumentArchive?: Maybe<ArchivePayload>;
  bookingDocumentCreateBookingDocumentType?: Maybe<CreateBookingDocumentTypePayload>;
  bookingDocumentGenerate?: Maybe<GeneratePayload>;
  bookingDocumentUpdate?: Maybe<UpdatePayload>;
  bookingDocumentUpdateBookingDocumentType?: Maybe<UpdateBookingDocumentTypePayload>;
  bookingDocumentUpload?: Maybe<UploadPayload>;
  bookingOpenIssue?: Maybe<OpenBookingIssuePayload>;
  bookingPinBooking?: Maybe<PinBookingPayload>;
  bookingProvideQuoteOptionRejectionReason?: Maybe<ProvideQuoteOptionRejectionReasonPayload>;
  bookingRejectQuoteOption?: Maybe<RejectQuoteOptionPayload>;
  bookingRemoveManagerAssignmentRule?: Maybe<RemoveBookingManagerAssignmentRulePayload>;
  bookingRemoveVoyageMilestoneDelay?: Maybe<RemoveVoyageMilestoneDelayPayload>;
  bookingRemoveVoyageMilestoneTime?: Maybe<RemoveVoyageMilestoneTimePayload>;
  bookingRequestsReject?: Maybe<RejectPayload>;
  bookingRequestsRevertRejection?: Maybe<BookingRequestsRevertRejectionPayload>;
  bookingRequestsRevertRejectionAsManufacturer?: Maybe<BookingRequestsRevertRejectionAsManufacturerPayload>;
  bookingRequestsUpdateCustomerApproved?: Maybe<UpdateCustomerApprovedPayload>;
  bookingRequestsUpdateForwarderApproved?: Maybe<BookingRequestsUpdateForwarderApprovedPayload>;
  bookingResolveIssue?: Maybe<ResolveBookingIssuePayload>;
  bookingStaleDataReconfirm?: Maybe<ReconfirmPayload>;
  bookingStaleDataSaveTrackingSettings?: Maybe<SaveTrackingSettingsPayload>;
  bookingUnassignNotifiedParty?: Maybe<UnassignNotifiedPartyPayload>;
  bookingUnpinBooking?: Maybe<UnpinBookingPayload>;
  bookingUpdateCargoMode?: Maybe<UpdateCargoModePayload>;
  bookingUpdateCarrierBookingReference?: Maybe<UpdateCarrierBookingReferencePayload>;
  bookingUpdateClientReference?: Maybe<UpdateClientReferencePayload>;
  bookingUpdateCustomsOnly?: Maybe<UpdateCustomsOnlyPayload>;
  bookingUpdateHouseBillOfLading?: Maybe<UpdateHouseBillOfLadingPayload>;
  bookingUpdateIncoterms?: Maybe<UpdateIncotermsPayload>;
  bookingUpdateManagerAssignmentRule?: Maybe<UpdateBookingManagerAssignmentRulePayload>;
  bookingUpdateMasterBillOfLading?: Maybe<UpdateMasterBillOfLadingPayload>;
  bookingUpdateModeOfTransport?: Maybe<BookingUpdateModeOfTransportPayload>;
  bookingUpdatePricingRequired?: Maybe<UpdatePricingRequiredPayload>;
  bookingUpdateQuoteOptionDescription?: Maybe<UpdateQuoteOptionDescriptionPayload>;
  bookingUpdateRoadShipment?: Maybe<UpdateRoadShipmentPayload>;
  bookingUpdateRoadTrackingMissingData?: Maybe<UpdateRoadTrackingMissingDataPayload>;
  bookingUpdateShipmentType?: Maybe<UpdateShipmentPayload>;
  bookingUpdateStage?: Maybe<UpdateStagePayload>;
  bookingUpdateTradeRole?: Maybe<UpdateTradeRolePayload>;
  bookingUpdateVoyageMilestoneDelay?: Maybe<UpdateVoyageMilestoneDelayPayload>;
  bookingUpdateVoyageMilestoneTime?: Maybe<UpdateVoyageMilestoneTimePayload>;
  bulkUpdateCostTrackingCostItemsStatus?: Maybe<BulkUpdateCostTrackingItemsStatusPayload>;
  bulkUpdateCostTrackingRevenueItemsStatus?: Maybe<BulkUpdateCostTrackingItemsStatusPayload>;
  cancelJourneyRoadLeg: CancelJourneyRoadLegPayload;
  cargoCreateContainerCargoItem?: Maybe<CreateContainerCargoItemPayload>;
  cargoCreateLooseCargoItem?: Maybe<CreateLooseCargoItemPayload>;
  cargoCreateUtilisationTarget?: Maybe<CreateUtilisationTargetPayload>;
  cargoCreateVehicleCargoItem?: Maybe<CreateVehicleCargoItemPayload>;
  cargoDeleteCargoItem?: Maybe<DeleteCargoItemPayload>;
  cargoDeleteUtilisationTarget?: Maybe<DeleteUtilisationTargetPayload>;
  cargoUpdateContainerCargoItem?: Maybe<UpdateContainerCargoItemPayload>;
  cargoUpdateLooseCargoItem?: Maybe<UpdateLooseCargoItemPayload>;
  cargoUpdateUtilisationTarget?: Maybe<UpdateUtilisationTargetPayload>;
  cargoUpdateVehicleCargoItem?: Maybe<UpdateVehicleCargoItemPayload>;
  cargoUpdateVehicleTrailerId?: Maybe<UpdateVehicleTrailerIdPayload>;
  commodityCodesUploadCommodityCodes?: Maybe<UploadCommodityCodesPayload>;
  completeWorkflowTask?: Maybe<CompleteWorkflowTaskPayload>;
  confirmJourneyRoadLeg: ConfirmJourneyRoadLegPayload;
  confirmJourneySchedule: ConfirmJourneySchedulePayload;
  consolidationCreateConsolidatedShipment?: Maybe<CreateConsolidatedShipmentPayload>;
  consolidationUpdateConsolidatedShipment?: Maybe<UpdateConsolidatedShipmentPayload>;
  controlTowerEnable?: Maybe<EnablePayload>;
  controlTowerUpdateForwarderReference?: Maybe<UpdateForwarderReferencePayload>;
  createAccount?: Maybe<CreateAccountPayload>;
  createAccountConnection?: Maybe<CreateAccountConnectionPayload>;
  createAccountDivision?: Maybe<CreateAccountBusinessUnitPayload>;
  createAccountLocation?: Maybe<CreateAccountLocationPayload>;
  createAccountSubsidiary?: Maybe<CreateAccountBusinessUnitPayload>;
  createAirlineCarrier: CreateAirlineCarrierPayload;
  createCargo?: Maybe<CreateCargoPayload>;
  createCargoRelations?: Maybe<CreateCargoRelationsPayload>;
  createContracts?: Maybe<ContractCreationPayload>;
  createCourier: CreateCourierPayload;
  createCustomRole?: Maybe<CreateRolePayload>;
  createDirectUpload?: Maybe<CreateDirectUploadPayload>;
  createHaulier: CreateHaulierPayload;
  createOceanCarrier: CreateOceanCarrierPayload;
  createRailwayCompany: CreateRailwayCompanyPayload;
  createRateCard?: Maybe<CreateRateCardPayload>;
  createScimApiKey?: Maybe<CreateScimApiKeyPayload>;
  createShippingDelayReason: CreateShippingDelayReasonPayload;
  createSsoConfiguration: CreateSsoConfigurationPayload;
  createUserDefinedEvent?: Maybe<UserDefinedEventPayload>;
  currentUserCreateApiKey?: Maybe<CreateApiKeyPayload>;
  currentUserDeleteApiKey?: Maybe<DeleteApiKeyPayload>;
  currentUserUpdatePreferences?: Maybe<CurrentUserUpdatePreferencesPayload>;
  currentUserUpdateProfile?: Maybe<CurrentUserUpdateProfilePayload>;
  deactivateAccount?: Maybe<DeactivateAccountPayload>;
  deactivatePortCharge?: Maybe<DeactivateCentralPortChargePayload>;
  deactivateUser?: Maybe<DeactivateUserPayload>;
  deleteAirlineCarrier: DeleteAirlineCarrierPayload;
  deleteCargo?: Maybe<DeleteCargoPayload>;
  deleteContract?: Maybe<ContractDeletePayload>;
  deleteCostTrackingItems?: Maybe<DeleteCostTrackingItemsPayload>;
  deleteCourier: DeleteCourierPayload;
  deleteCustomRole?: Maybe<DeleteRolePayload>;
  deleteDefaultChargeItem?: Maybe<DeleteDefaultChargeItemPayload>;
  deleteHaulier: DeleteHaulierPayload;
  deleteOceanCarrier: DeleteOceanCarrierPayload;
  deleteRailwayCompany: DeleteRailwayCompanyPayload;
  deleteRateCard?: Maybe<DeleteRateCardPayload>;
  deleteScimApiKey?: Maybe<DeleteScimApiKeyPayload>;
  deleteUserSettings?: Maybe<DeleteUserSettingsPayload>;
  deleteWorkflowRule?: Maybe<DeleteWorkflowRulePayload>;
  deleteWorkflowRuleDefinition?: Maybe<DeleteWorkflowRuleDefinitionPayload>;
  deleteWorkflowTask?: Maybe<DeleteWorkflowTaskPayload>;
  deployWorkflowModel?: Maybe<DeployWorkflowModelPayload>;
  documentTemplatesCreateDocumentTemplate?: Maybe<CreateDocumentTemplatePayload>;
  documentTemplatesUpdateDocumentTemplate?: Maybe<UpdateDocumentTemplatePayload>;
  duplicateCostTrackingItem?: Maybe<CostTrackingPayload>;
  editCustomRole?: Maybe<EditRolePayload>;
  featureTrackingTrackFiltersUsage?: Maybe<TrackFiltersUsagePayload>;
  financeArchiveAccrual?: Maybe<ArchiveAccrualMutationPayload>;
  financeCreateAccrual?: Maybe<CreateAccrualMutationPayload>;
  financeCreateChargeType?: Maybe<CreateChargeTypeMutationPayload>;
  financeDeleteAccrualTemplate?: Maybe<DeleteAccrualTemplateMutationPayload>;
  financeExportAccruals?: Maybe<ExportAccrualsMutationPayload>;
  financePublishAccrual?: Maybe<PublishAccrualMutationPayload>;
  financeSaveAccrualTemplate?: Maybe<SaveAccrualTemplateMutationPayload>;
  financeSpendAnalyticsInvoicesExportData?: Maybe<SpendAnalyticsInvoicesExportDataPayload>;
  financeUpdateAccrual?: Maybe<UpdateAccrualMutationPayload>;
  financeUpdateAccrualInvoicedDate?: Maybe<UpdateInvoicedOnMutationPayload>;
  financeUpdateChargeType?: Maybe<UpdateChargeTypeMutationPayload>;
  /** Upload accruals CSV (by attaching a blob via signed ID) */
  financeUploadAccruals?: Maybe<UploadAccrualsMutationPayload>;
  intakePlanningExportPeriodsData?: Maybe<ExportPeriodsRowsDataPayload>;
  intakePlanningExportProductLotsData?: Maybe<ExportProductLotsRowsDataPayload>;
  intakePlanningExportProductsData?: Maybe<ExportProductsRowsDataPayload>;
  invitationsInvite?: Maybe<InvitePayload>;
  inviteAccountConnection?: Maybe<InviteAccountConnectionPayload>;
  inviteUser?: Maybe<InviteUserPayload>;
  inviteUsers?: Maybe<InviteUsersPayload>;
  issueTemplatesCreateIssueTemplate?: Maybe<CreateIssueTemplatePayload>;
  issueTemplatesDeleteIssueTemplate?: Maybe<DeleteIssueTemplatePayload>;
  issueTemplatesUpdateIssueTemplate?: Maybe<UpdateIssueTemplatePayload>;
  issuesArchiveIssue?: Maybe<ArchiveIssuePayload>;
  issuesOpenIssue?: Maybe<OpenIssuePayload>;
  issuesResolveIssue?: Maybe<ResolveIssuePayload>;
  issuesStartIssue?: Maybe<StartIssuePayload>;
  issuesUpdateIssue?: Maybe<UpdateIssuePayload>;
  legacyCreateRateCard?: Maybe<LegacyCreateRateCardPayload>;
  legacyDeleteRateCard?: Maybe<LegacyDeleteRateCardPayload>;
  legacyUpdateRateCard?: Maybe<LegacyUpdateRateCardPayload>;
  networkCreateContact?: Maybe<CreateContactPayload>;
  networkUpdateContact?: Maybe<UpdateContactPayload>;
  networksAssign?: Maybe<AssignPayload>;
  networksCreateOrganisation?: Maybe<CreateOrganisationPayload>;
  networksCreateOrganisationLocation?: Maybe<CreateOrganisationLocationPayload>;
  networksDeleteContact?: Maybe<DeleteContactPayload>;
  networksDeleteLocation?: Maybe<DeleteLocationPayload>;
  networksDeleteLocationContacts?: Maybe<DeleteLocationContactsPayload>;
  networksDeleteOrganisation?: Maybe<DeleteOrganisationPayload>;
  networksRemoveAssignment?: Maybe<RemoveAssignmentPayload>;
  networksRemoveAssignments?: Maybe<RemoveAssignmentsPayload>;
  networksRenameOrganisation?: Maybe<RenameOrganisationPayload>;
  networksUpdateLocationContact?: Maybe<UpdateLocationContactPayload>;
  networksUpdateMyOrganisation?: Maybe<UpdateMyOrganisationPayload>;
  networksUpdateOrganisationLocation?: Maybe<UpdateOrganisationLocationPayload>;
  notesUpdateNote?: Maybe<UpdateNoteMutationPayload>;
  notificationsMarkAllAsRead?: Maybe<MarkAllAsReadPayload>;
  notificationsMarkAsRead?: Maybe<MarkAsReadPayload>;
  notificationsSubscribe?: Maybe<SubscribePayload>;
  notificationsToggleAutoSubscriptions?: Maybe<ToggleAutoSubscriptionsPayload>;
  notificationsUnsubscribe?: Maybe<UnsubscribePayload>;
  notificationsUpdateSettings?: Maybe<UpdateSettingsPayload>;
  operationsSetUserAsManager?: Maybe<SetUserAsManagerPayload>;
  parseDocument?: Maybe<Scalars['Boolean']>;
  /** Archive a Product */
  productsArchiveProduct?: Maybe<ArchiveProductPayload>;
  /** Create a Product */
  productsCreateProduct?: Maybe<CreateProductPayload>;
  /** Unarchive a Product */
  productsUnarchiveProduct?: Maybe<UnarchiveProductPayload>;
  /** Update a Product */
  productsUpdateProduct?: Maybe<UpdateProductPayload>;
  /** Upload products CSV (by attaching a blob via signed ID) */
  productsUploadProducts?: Maybe<UploadProductsPayload>;
  provisionUser?: Maybe<ProvisionUserPayload>;
  purchaseOrderDelayReasonsArchivePurchaseOrderDelayReason?: Maybe<ArchivePurchaseOrderDelayReasonPayload>;
  purchaseOrderDelayReasonsCreatePurchaseOrderDelayReason?: Maybe<CreatePurchaseOrderDelayReasonPayload>;
  purchaseOrderDelayReasonsUpdatePurchaseOrderDelayReason?: Maybe<UpdatePurchaseOrderDelayReasonPayload>;
  /** Close a Purchase Order */
  purchaseOrdersCloseOrder?: Maybe<ClosePurchaseOrderPayload>;
  /**
   * Provide Zencargo with details about the Purchase Order and its contents. See individual items for
   * details, especially around OrderedLineItem inputs, which is where you will find information about
   * the products in the purchase order.
   */
  purchaseOrdersCreateOrder?: Maybe<CreateOrderPayload>;
  purchaseOrdersCreateOriginAgentAssignmentRule?: Maybe<CreateOriginAgentAssignmentRulePayload>;
  /** Delete a Purchase Order */
  purchaseOrdersDeleteOrder?: Maybe<DeleteOrderPayload>;
  purchaseOrdersExportRowsPurchaseOrders?: Maybe<ExportRowsPurchaseOrdersPayload>;
  purchaseOrdersOpenIssue?: Maybe<OpenPurchaseOrderIssuePayload>;
  purchaseOrdersRemoveOriginAgentAssignmentRule?: Maybe<RemoveOriginAgentAssignmentRulePayload>;
  purchaseOrdersResolveIssue?: Maybe<ResolvePurchaseOrderIssuePayload>;
  purchaseOrdersUpdateAssignedLotsToBooking?: Maybe<UpdateAssignedLotsToBookingPayload>;
  purchaseOrdersUpdateAssignedLotsToCargo?: Maybe<UpdateAssignedLotsToCargoPayload>;
  /**
   * Update date estimates for a specific product in a Purchase Order. The lot being referenced
   * is a fixed quantity of one SKU you expect to ship in one shipment. This mutation allows you
   * to send the latest version of cargo ready dates and delivery estimates to Zencargo, if you
   * are managing purchase orders entirely in an ERP. Zencargo will store this as the latest values,
   * and keep a history of all changes.
   */
  purchaseOrdersUpdateLotEstimates?: Maybe<UpdateLotEstimatesPayload>;
  /**
   * Update quantity and volume data for a specific product in a Purchase Order, The lot being referenced
   * is a fixed quantity of one SKU that you expect to ship in one shipment. This mutation allows you to send
   * the latest known quantity that will be fulfilled by the manufacturer, as well as the latest known volume
   * of this SKU once it has been fully packaged, if you are managing purchase orders entirely in an ERP.
   * Zencargo will update the associated Lot - the previous Lot value will not be stored, although the first
   * expected value can be retrieved against the OrderedLineItem
   */
  purchaseOrdersUpdateLots?: Maybe<UpdateLotsPayload>;
  /**
   * Updates values against the PurchaseOrder or OrderedLineItem objects. Useful for removing
   * data you sent in error or deleting purchase orders that have been cancelled in your ERP.
   * CAUTION! This mutation is used to update original expected values. Only use this mutation
   * if you want to change the original values Zencargo uses to calculated the expected or estimated
   * attributes about a Purchase Order. You cannot update the fulfilment information using this
   * mutation. See The Purchase Orders guide for more information.
   */
  purchaseOrdersUpdateOrder?: Maybe<UpdateOrderPayload>;
  purchaseOrdersUpdateOriginAgentAssignmentRule?: Maybe<UpdateOriginAgentAssignmentRulePayload>;
  reactivateUser?: Maybe<ReactivateUserPayload>;
  removeJourneySchedule: RemoveJourneySchedulePayload;
  removeMilestoneActualDate: RemoveMilestoneActualDatePayload;
  removeMilestonePlannedDate: RemoveMilestonePlannedDatePayload;
  removeMilestoneRequestedDate: RemoveMilestoneRequestedDatePayload;
  resetPriceCalculationDate?: Maybe<ResetPriceCalculationDatePayload>;
  reviewParsedCommercialInvoice?: Maybe<Scalars['Boolean']>;
  rulesEngineArchiveRule?: Maybe<ArchiveRulePayload>;
  rulesEngineCheckRule?: Maybe<CheckRulePayload>;
  rulesEngineCreateRule?: Maybe<CreateRulePayload>;
  rulesEngineUpdateRule?: Maybe<UpdateRulePayload>;
  saveWorkflowRule?: Maybe<SaveWorkflowRulePayload>;
  saveWorkflowRuleDefinition?: Maybe<SaveWorkflowRuleDefinitionPayload>;
  setPriceCalculationDate?: Maybe<SetPriceCalculationDatePayload>;
  shipmentActionTypesCreateShipmentActionType?: Maybe<CreateShipmentActionTypePayload>;
  shipmentActionTypesUpdateShipmentActionType?: Maybe<UpdateShipmentActionTypePayload>;
  shipmentAddEdd?: Maybe<AddEddPayload>;
  shipmentClearShipmentStatus?: Maybe<ClearShipmentStatusPayload>;
  shipmentDelayReasonsArchiveShipmentDelayReason?: Maybe<ArchiveShipmentDelayReasonPayload>;
  shipmentDelayReasonsCreateShipmentDelayReason?: Maybe<CreateShipmentDelayReasonPayload>;
  shipmentDelayReasonsUpdateShipmentDelayReason?: Maybe<UpdateShipmentDelayReasonPayload>;
  shipmentExportRowsShipments?: Maybe<ExportRowsShipmentsPayload>;
  shipmentUpdateEdd?: Maybe<UpdateEddPayload>;
  shipmentUpdateShipmentStatus?: Maybe<UpdateShipmentStatusPayload>;
  terminalsImportTerminals?: Maybe<ImportTerminalsPayload>;
  terminalsUpdateTerminal?: Maybe<UpdateTerminalPayload>;
  toggleJourneyCollection: ToggleJourneyCollectionPayload;
  toggleOceanTracking: ToggleOceanTrackingPayload;
  unarchiveAccountLocation?: Maybe<UnarchiveAccountLocationPayload>;
  unassignContract?: Maybe<UnassignContractPayload>;
  /** Batch unassigns members from a role */
  unassignRoleMembers?: Maybe<RoleMembersPayload>;
  updateAccountDemoMode?: Maybe<UpdateAccountDemoModePayload>;
  updateAccountHeadquarters?: Maybe<UpdateAccountHeadquartersPayload>;
  updateAccountLegalDetails?: Maybe<UpdateAccountLegalDetailsPayload>;
  updateAccountLocationAddress?: Maybe<UpdateAccountLocationAddressPayload>;
  updateAccountLocationBusinessHours?: Maybe<UpdateAccountLocationBusinessHoursPayload>;
  updateAccountLocationDetails?: Maybe<UpdateAccountLocationDetailsPayload>;
  updateAccountMainDetails?: Maybe<UpdateAccountMainDetailsPayload>;
  updateAccountMemberBusinessUnits?: Maybe<UpdateAccountMemberBusinessUnitsPayload>;
  updateAccountMemberProfileInformation?: Maybe<UpdateAccountMemberProfileInformationPayload>;
  updateAccountMemberUserGroup?: Maybe<UpdateAccountMemberUserGroupPayload>;
  updateAccountReferencePrefix?: Maybe<UpdateAccountReferencePrefixPayload>;
  updateAirlineCarrier: UpdateAirlineCarrierPayload;
  updateBookingsAssignedToConsolidatedShipment?: Maybe<UpdateBookingsAssignedToConsolidatedShipmentPayload>;
  updateCargo?: Maybe<UpdateCargoPayload>;
  updateContract?: Maybe<ContractUpdatePayload>;
  updateCostTrackingCostItemStatus?: Maybe<CostTrackingPayload>;
  updateCostTrackingItemCurrency?: Maybe<CostTrackingPayload>;
  updateCostTrackingItemName?: Maybe<CostTrackingPayload>;
  updateCostTrackingItemNote?: Maybe<CostTrackingPayload>;
  updateCostTrackingItemPaidFrom?: Maybe<CostTrackingPayload>;
  updateCostTrackingItemPaidTo?: Maybe<CostTrackingPayload>;
  updateCostTrackingItemQuantity?: Maybe<CostTrackingPayload>;
  updateCostTrackingItemUnitPrice?: Maybe<CostTrackingPayload>;
  updateCostTrackingRevenueItemStatus?: Maybe<CostTrackingPayload>;
  updateCourier: UpdateCourierPayload;
  updateCurrentUserProfile?: Maybe<UpdateCurrentUserProfilePayload>;
  updateDefaultChargeItemBasis?: Maybe<UpdateDefaultChargeItemPayload>;
  updateDefaultChargeItemCargoOptions?: Maybe<UpdateDefaultChargeItemPayload>;
  updateDefaultChargeItemCurrency?: Maybe<UpdateDefaultChargeItemPayload>;
  updateDefaultChargeItemName?: Maybe<UpdateDefaultChargeItemPayload>;
  updateDefaultChargeItemUnitPrice?: Maybe<UpdateDefaultChargeItemPayload>;
  updateFlightNumber: UpdateFlightNumberPayload;
  updateHaulier: UpdateHaulierPayload;
  updateJourneyAirline: UpdateJourneyAirlinePayload;
  updateJourneyCarrierBookingReference: UpdateJourneyCarrierBookingReferencePayload;
  updateJourneyCourier: UpdateJourneyCourierPayload;
  updateJourneyMasterAirwayBill: UpdateJourneyMasterAirwayBillPayload;
  updateJourneyMasterBillOfLading: UpdateJourneyMasterBillOfLadingPayload;
  updateJourneyRailwayCompany: UpdateJourneyRailwayCompanyPayload;
  updateJourneyScac: UpdateJourneyScacPayload;
  updateJourneySchedule?: Maybe<UpdateJourneySchedulePayload>;
  updateJourneyStopReference: UpdateJourneyStopReferencePayload;
  updateJourneyTrackingNumber: UpdateJourneyTrackingNumberPayload;
  updateJourneysCargoReadyDate: UpdateJourneysCargoReadyDatePayload;
  updateMilestoneActualDate: UpdateMilestoneActualDatePayload;
  updateMilestoneDelay: UpdateMilestoneDelayPayload;
  updateMilestonePlannedDate: UpdateMilestonePlannedDatePayload;
  updateMilestoneRequestedDate: UpdateMilestoneRequestedDatePayload;
  updateOceanCarrier: UpdateOceanCarrierPayload;
  updateRailwayCompany: UpdateRailwayCompanyPayload;
  updateRateCard?: Maybe<UpdateRateCardPayload>;
  updateShippingDelayReason: UpdateShippingDelayReasonPayload;
  updateStopLocation: UpdateStopLocationPayload;
  updateTrailerId: UpdateTrailerIdPayload;
  updateUser?: Maybe<UpdateUserPayload>;
  updateVehicleHaulierId: UpdateVehicleHaulierIdPayload;
  updateVehiclePlateNumber: UpdateVehiclePlateNumberPayload;
  updateVesselName: UpdateVesselNamePayload;
  updateVoyageNumber: UpdateVoyageNumberPayload;
  uploadDefaultPortCharges?: Maybe<UploadDefaultPortChargesPayload>;
  uploadPortCharges?: Maybe<UploadCentralPortChargesPayload>;
  /** Mutation for uploading a model directly via string. */
  uploadWorkflowModel?: Maybe<UploadWorkflowModelPayload>;
  upsertUserSettings?: Maybe<UpsertUserSettingsPayload>;
  webhookSubscriptionsCreateWebhook?: Maybe<CreateWebhookPayload>;
  webhookSubscriptionsDeleteWebhook?: Maybe<DeleteWebhookPayload>;
  webhookSubscriptionsUpdateWebhook?: Maybe<UpdateWebhookPayload>;
}

/** Mutations */
export type MutationAcceptInviteArgs = {
  userId: Scalars['String'];
};

/** Mutations */
export type MutationAccountActivateAccountArgs = {
  input: ActivateAccountInput;
};

/** Mutations */
export type MutationAccountAddDivisionArgs = {
  input: AddDivisionInput;
};

/** Mutations */
export type MutationAccountAddTradeLaneArgs = {
  input: AddTradeLaneInput;
};

/** Mutations */
export type MutationAccountCreateAccountArgs = {
  input: LegacyCreateAccountInput;
};

/** Mutations */
export type MutationAccountDeactivateAccountArgs = {
  input: LegacyDeactivateAccountInput;
};

/** Mutations */
export type MutationAccountDisableDemoModeArgs = {
  input: DisableDemoModeInput;
};

/** Mutations */
export type MutationAccountEnableDemoModeArgs = {
  input: EnableDemoModeInput;
};

/** Mutations */
export type MutationAccountRenameDivisionArgs = {
  input: RenameDivisionInput;
};

/** Mutations */
export type MutationAccountUpdateBillingDetailsArgs = {
  input: UpdateBillingDetailsInput;
};

/** Mutations */
export type MutationAccountUpdateCompanyDetailsArgs = {
  input: UpdateCompanyDetailsInput;
};

/** Mutations */
export type MutationActionItemTypesCreateActionItemTypeArgs = {
  input: CreateActionItemTypeInput;
};

/** Mutations */
export type MutationActionItemTypesUpdateActionItemTypeArgs = {
  input: UpdateActionItemInput;
};

/** Mutations */
export type MutationActivateInactiveAccountArgs = {
  accountId: Scalars['String'];
};

/** Mutations */
export type MutationActivatePendingAccountArgs = {
  accountId: Scalars['String'];
};

/** Mutations */
export type MutationActivityFeedArchiveTextMessageArgs = {
  input: ArchiveTextMessageInput;
};

/** Mutations */
export type MutationActivityFeedCreateTextMessageArgs = {
  input: CreateTextMessageInput;
};

/** Mutations */
export type MutationAddCostTrackingChargesArgs = {
  input: AddCostTrackingChargesInput;
};

/** Mutations */
export type MutationAddJourneyRequestedScheduleArgs = {
  input: AddJourneyRequestedScheduleInput;
};

/** Mutations */
export type MutationArchiveAccountConnectionArgs = {
  input: ArchiveAccountConnectionInput;
};

/** Mutations */
export type MutationArchiveAccountLocationArgs = {
  locationId: Scalars['String'];
};

/** Mutations */
export type MutationArchiveShippingDelayReasonArgs = {
  input: ArchiveShippingDelayReasonInput;
};

/** Mutations */
export type MutationAssignContractArgs = {
  input: AssignContractInput;
};

/** Mutations */
export type MutationAssignRoleMembersArgs = {
  input: RoleMembersInput;
};

/** Mutations */
export type MutationAssignWorkflowTasksToUserArgs = {
  input?: InputMaybe<AssignWorkflowTasksToUserInput>;
};

/** Mutations */
export type MutationAuthenticationSendResetPasswordInstructionsArgs = {
  input: SendResetPasswordInstructionsInput;
};

/** Mutations */
export type MutationBookingAcceptQuoteOptionArgs = {
  input: AcceptQuoteOptionInput;
};

/** Mutations */
export type MutationBookingActivateQuoteOptionArgs = {
  input: ActivateQuoteOptionInput;
};

/** Mutations */
export type MutationBookingAddVoyageMilestoneDelayArgs = {
  input: AddVoyageMilestoneDelayInput;
};

/** Mutations */
export type MutationBookingApproveBookingArgs = {
  input: ApproveBookingInput;
};

/** Mutations */
export type MutationBookingArchiveBookingArgs = {
  input: ArchiveBookingInput;
};

/** Mutations */
export type MutationBookingArchiveQuoteOptionArgs = {
  input: ArchiveQuoteOptionInput;
};

/** Mutations */
export type MutationBookingAssignCommercialManagerArgs = {
  input: AssignCommercialManagerInput;
};

/** Mutations */
export type MutationBookingAssignNotifiedPartyArgs = {
  input: AssignNotifiedPartyInput;
};

/** Mutations */
export type MutationBookingAssignOperationsManagerArgs = {
  input: AssignOperationsManagerInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryCancelCollectionArgs = {
  input: CancelCollectionInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryCancelDeliveryArgs = {
  input: CancelDeliveryInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryCompleteCollectionArgs = {
  input: CompleteCollectionInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryCompleteDeliveryArgs = {
  input: CompleteDeliveryInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryConfirmCollectionArgs = {
  input: ConfirmCollectionInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryConfirmDeliveryArgs = {
  input: ConfirmDeliveryInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryRemoveCollectionScheduleArgs = {
  input: RemoveCollectionScheduleInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryRemoveDeliveryScheduleArgs = {
  input: RemoveDeliveryScheduleInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryRequireCollectionScheduleArgs = {
  input: RequireCollectionScheduleInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryRequireDeliveryScheduleArgs = {
  input: RequireDeliveryScheduleInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryScheduleCollectionArgs = {
  input: ScheduleCollectionInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryScheduleDeliveryArgs = {
  input: ScheduleDeliveryInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryUpdateCollectionArgs = {
  input: UpdateCollectionInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryUpdateCollectionDatesArgs = {
  input: UpdateCollectionDatesInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryUpdateCollectionReferenceArgs = {
  input: UpdateCollectionReferenceInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryUpdateDeliveryArgs = {
  input: UpdateDeliveryInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryUpdateDeliveryDatesArgs = {
  input: UpdateDeliveryDatesInput;
};

/** Mutations */
export type MutationBookingCollectionAndDeliveryUpdateDeliveryReferenceArgs = {
  input: UpdateDeliveryReferenceInput;
};

/** Mutations */
export type MutationBookingCompleteBookingArgs = {
  input: CompleteBookingInput;
};

/** Mutations */
export type MutationBookingCreateBookingViaEasyBookingArgs = {
  input: CreateBookingViaEasyBookingInput;
};

/** Mutations */
export type MutationBookingCreateBookingViaExternalBookingArgs = {
  input: CreateBookingViaExternalBookingInput;
};

/** Mutations */
export type MutationBookingCreateBookingViaQuotedBookingArgs = {
  input: CreateBookingViaQuotedBookingInput;
};

/** Mutations */
export type MutationBookingCreateBookingViaRequestForCustomerApprovalArgs = {
  input: CreateBookingViaRequestForCustomerApprovalInput;
};

/** Mutations */
export type MutationBookingCreateBookingViaRequestedBookingArgs = {
  input: CreateBookingViaRequestedBookingInput;
};

/** Mutations */
export type MutationBookingCreateManagerAssignmentRuleArgs = {
  input: CreateBookingManagerAssignmentRuleInput;
};

/** Mutations */
export type MutationBookingCreateQuoteOptionArgs = {
  input: CreateQuoteOptionInput;
};

/** Mutations */
export type MutationBookingDocumentArchiveArgs = {
  input: ArchiveInput;
};

/** Mutations */
export type MutationBookingDocumentCreateBookingDocumentTypeArgs = {
  input: CreateBookingDocumentTypeInput;
};

/** Mutations */
export type MutationBookingDocumentGenerateArgs = {
  input: GenerateInput;
};

/** Mutations */
export type MutationBookingDocumentUpdateArgs = {
  input: UpdateInput;
};

/** Mutations */
export type MutationBookingDocumentUpdateBookingDocumentTypeArgs = {
  input: UpdateBookingDocumentTypeInput;
};

/** Mutations */
export type MutationBookingDocumentUploadArgs = {
  input: UploadInput;
};

/** Mutations */
export type MutationBookingOpenIssueArgs = {
  input: OpenBookingIssueInput;
};

/** Mutations */
export type MutationBookingPinBookingArgs = {
  input: PinBookingInput;
};

/** Mutations */
export type MutationBookingProvideQuoteOptionRejectionReasonArgs = {
  input: ProvideQuoteOptionRejectionReasonInput;
};

/** Mutations */
export type MutationBookingRejectQuoteOptionArgs = {
  input: RejectQuoteOptionInput;
};

/** Mutations */
export type MutationBookingRemoveManagerAssignmentRuleArgs = {
  input: RemoveBookingManagerAssignmentRuleInput;
};

/** Mutations */
export type MutationBookingRemoveVoyageMilestoneDelayArgs = {
  input: RemoveVoyageMilestoneDelayInput;
};

/** Mutations */
export type MutationBookingRemoveVoyageMilestoneTimeArgs = {
  input: RemoveVoyageMilestoneTimeInput;
};

/** Mutations */
export type MutationBookingRequestsRejectArgs = {
  input: RejectInput;
};

/** Mutations */
export type MutationBookingRequestsRevertRejectionArgs = {
  input: BookingRequestsRevertRejectionInput;
};

/** Mutations */
export type MutationBookingRequestsRevertRejectionAsManufacturerArgs = {
  input: BookingRequestsRevertRejectionAsManufacturerInput;
};

/** Mutations */
export type MutationBookingRequestsUpdateCustomerApprovedArgs = {
  input: UpdateCustomerApprovedInput;
};

/** Mutations */
export type MutationBookingRequestsUpdateForwarderApprovedArgs = {
  input: BookingRequestsUpdateForwarderApprovedInput;
};

/** Mutations */
export type MutationBookingResolveIssueArgs = {
  input: ResolveBookingIssueInput;
};

/** Mutations */
export type MutationBookingStaleDataReconfirmArgs = {
  input: ReconfirmInput;
};

/** Mutations */
export type MutationBookingStaleDataSaveTrackingSettingsArgs = {
  input: SaveTrackingSettingsInput;
};

/** Mutations */
export type MutationBookingUnassignNotifiedPartyArgs = {
  input: UnassignNotifiedPartyInput;
};

/** Mutations */
export type MutationBookingUnpinBookingArgs = {
  input: UnpinBookingInput;
};

/** Mutations */
export type MutationBookingUpdateCargoModeArgs = {
  input: UpdateCargoModeInput;
};

/** Mutations */
export type MutationBookingUpdateCarrierBookingReferenceArgs = {
  input: UpdateCarrierBookingReferenceInput;
};

/** Mutations */
export type MutationBookingUpdateClientReferenceArgs = {
  input: UpdateClientReferenceInput;
};

/** Mutations */
export type MutationBookingUpdateCustomsOnlyArgs = {
  input: UpdateCustomsOnlyInput;
};

/** Mutations */
export type MutationBookingUpdateHouseBillOfLadingArgs = {
  input: UpdateHouseBillOfLadingInput;
};

/** Mutations */
export type MutationBookingUpdateIncotermsArgs = {
  input: UpdateIncotermsInput;
};

/** Mutations */
export type MutationBookingUpdateManagerAssignmentRuleArgs = {
  input: UpdateBookingManagerAssignmentRuleInput;
};

/** Mutations */
export type MutationBookingUpdateMasterBillOfLadingArgs = {
  input: UpdateMasterBillOfLadingInput;
};

/** Mutations */
export type MutationBookingUpdateModeOfTransportArgs = {
  input: BookingUpdateModeOfTransportInput;
};

/** Mutations */
export type MutationBookingUpdatePricingRequiredArgs = {
  input: UpdatePricingRequiredInput;
};

/** Mutations */
export type MutationBookingUpdateQuoteOptionDescriptionArgs = {
  input: UpdateQuoteOptionDescriptionInput;
};

/** Mutations */
export type MutationBookingUpdateRoadShipmentArgs = {
  input: UpdateRoadShipmentInput;
};

/** Mutations */
export type MutationBookingUpdateRoadTrackingMissingDataArgs = {
  input: UpdateRoadTrackingMissingDataInput;
};

/** Mutations */
export type MutationBookingUpdateShipmentTypeArgs = {
  input: UpdateShipmentInput;
};

/** Mutations */
export type MutationBookingUpdateStageArgs = {
  input: UpdateStageInput;
};

/** Mutations */
export type MutationBookingUpdateTradeRoleArgs = {
  input: UpdateTradeRoleInput;
};

/** Mutations */
export type MutationBookingUpdateVoyageMilestoneDelayArgs = {
  input: UpdateVoyageMilestoneDelayInput;
};

/** Mutations */
export type MutationBookingUpdateVoyageMilestoneTimeArgs = {
  input: UpdateVoyageMilestoneTimeInput;
};

/** Mutations */
export type MutationBulkUpdateCostTrackingCostItemsStatusArgs = {
  input: BulkUpdateCostTrackingCostItemsStatusInput;
};

/** Mutations */
export type MutationBulkUpdateCostTrackingRevenueItemsStatusArgs = {
  input: BulkUpdateCostTrackingRevenueItemsStatusInput;
};

/** Mutations */
export type MutationCancelJourneyRoadLegArgs = {
  input: CancelJourneyRoadLegInput;
};

/** Mutations */
export type MutationCargoCreateContainerCargoItemArgs = {
  input: CreateContainerCargoItemInput;
};

/** Mutations */
export type MutationCargoCreateLooseCargoItemArgs = {
  input: CreateLooseCargoItemInput;
};

/** Mutations */
export type MutationCargoCreateUtilisationTargetArgs = {
  input: CreateUtilisationTargetInput;
};

/** Mutations */
export type MutationCargoCreateVehicleCargoItemArgs = {
  input: CreateVehicleCargoItemInput;
};

/** Mutations */
export type MutationCargoDeleteCargoItemArgs = {
  input: DeleteCargoItemInput;
};

/** Mutations */
export type MutationCargoDeleteUtilisationTargetArgs = {
  input: DeleteUtilisationTargetInput;
};

/** Mutations */
export type MutationCargoUpdateContainerCargoItemArgs = {
  input: UpdateContainerCargoItemInput;
};

/** Mutations */
export type MutationCargoUpdateLooseCargoItemArgs = {
  input: UpdateLooseCargoItemInput;
};

/** Mutations */
export type MutationCargoUpdateUtilisationTargetArgs = {
  input: UpdateUtilisationTargetInput;
};

/** Mutations */
export type MutationCargoUpdateVehicleCargoItemArgs = {
  input: UpdateVehicleCargoItemInput;
};

/** Mutations */
export type MutationCargoUpdateVehicleTrailerIdArgs = {
  input: UpdateVehicleTrailerIdInput;
};

/** Mutations */
export type MutationCommodityCodesUploadCommodityCodesArgs = {
  input: UploadCommodityCodesInput;
};

/** Mutations */
export type MutationCompleteWorkflowTaskArgs = {
  input: CompleteWorkflowTaskInput;
};

/** Mutations */
export type MutationConfirmJourneyRoadLegArgs = {
  input: ConfirmJourneyRoadLegInput;
};

/** Mutations */
export type MutationConfirmJourneyScheduleArgs = {
  input: ConfirmJourneyScheduleInput;
};

/** Mutations */
export type MutationConsolidationCreateConsolidatedShipmentArgs = {
  input: CreateConsolidatedShipmentInput;
};

/** Mutations */
export type MutationConsolidationUpdateConsolidatedShipmentArgs = {
  input: UpdateConsolidatedShipmentInput;
};

/** Mutations */
export type MutationControlTowerEnableArgs = {
  input: EnableInput;
};

/** Mutations */
export type MutationControlTowerUpdateForwarderReferenceArgs = {
  input: UpdateForwarderReferenceInput;
};

/** Mutations */
export type MutationCreateAccountArgs = {
  input: CreateAccountInput;
};

/** Mutations */
export type MutationCreateAccountConnectionArgs = {
  input: CreateAccountConnectionInput;
};

/** Mutations */
export type MutationCreateAccountDivisionArgs = {
  input: CreateAccountDivisionInput;
};

/** Mutations */
export type MutationCreateAccountLocationArgs = {
  input: CreateAccountLocationInput;
};

/** Mutations */
export type MutationCreateAccountSubsidiaryArgs = {
  input: CreateAccountSubsidiaryInput;
};

/** Mutations */
export type MutationCreateAirlineCarrierArgs = {
  input: CreateAirlineCarrierInput;
};

/** Mutations */
export type MutationCreateCargoArgs = {
  input: CreateCargoInput;
};

/** Mutations */
export type MutationCreateCargoRelationsArgs = {
  inputCargoId: Scalars['String'];
  outputCargoId: Scalars['String'];
  relation: Relation;
};

/** Mutations */
export type MutationCreateContractsArgs = {
  input: CreateContractInput;
};

/** Mutations */
export type MutationCreateCourierArgs = {
  input: CreateCourierInput;
};

/** Mutations */
export type MutationCreateCustomRoleArgs = {
  input: CreateRoleInput;
};

/** Mutations */
export type MutationCreateDirectUploadArgs = {
  input: CreateDirectUploadInput;
};

/** Mutations */
export type MutationCreateHaulierArgs = {
  input: CreateHaulierInput;
};

/** Mutations */
export type MutationCreateOceanCarrierArgs = {
  input: CreateOceanCarrierInput;
};

/** Mutations */
export type MutationCreateRailwayCompanyArgs = {
  input: CreateRailwayCompanyInput;
};

/** Mutations */
export type MutationCreateRateCardArgs = {
  input: CreateRateCardInput;
};

/** Mutations */
export type MutationCreateScimApiKeyArgs = {
  input?: InputMaybe<CreateScimApiKeyInput>;
};

/** Mutations */
export type MutationCreateShippingDelayReasonArgs = {
  input: CreateShippingDelayReasonInput;
};

/** Mutations */
export type MutationCreateSsoConfigurationArgs = {
  input?: InputMaybe<CreateSsoConfigurationInput>;
};

/** Mutations */
export type MutationCreateUserDefinedEventArgs = {
  input: CreateUserDefinedEventInput;
};

/** Mutations */
export type MutationCurrentUserCreateApiKeyArgs = {
  input: CreateApiKeyInput;
};

/** Mutations */
export type MutationCurrentUserDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};

/** Mutations */
export type MutationCurrentUserUpdatePreferencesArgs = {
  input: CurrentUserUpdatePreferencesInput;
};

/** Mutations */
export type MutationCurrentUserUpdateProfileArgs = {
  input: CurrentUserUpdateProfileInput;
};

/** Mutations */
export type MutationDeactivateAccountArgs = {
  accountId: Scalars['String'];
};

/** Mutations */
export type MutationDeactivatePortChargeArgs = {
  input: DeactivateCentralPortChargeInput;
};

/** Mutations */
export type MutationDeactivateUserArgs = {
  userId: Scalars['String'];
};

/** Mutations */
export type MutationDeleteAirlineCarrierArgs = {
  input: DeleteAirlineCarrierInput;
};

/** Mutations */
export type MutationDeleteCargoArgs = {
  input: DeleteCargoInput;
};

/** Mutations */
export type MutationDeleteContractArgs = {
  input: DeleteContractInput;
};

/** Mutations */
export type MutationDeleteCostTrackingItemsArgs = {
  input: DeleteCostTrackingItemsInput;
};

/** Mutations */
export type MutationDeleteCourierArgs = {
  input: DeleteCourierInput;
};

/** Mutations */
export type MutationDeleteCustomRoleArgs = {
  input: DeleteRoleInput;
};

/** Mutations */
export type MutationDeleteDefaultChargeItemArgs = {
  input?: InputMaybe<DeleteDefaultChargeItemInput>;
};

/** Mutations */
export type MutationDeleteHaulierArgs = {
  input: DeleteHaulierInput;
};

/** Mutations */
export type MutationDeleteOceanCarrierArgs = {
  input: DeleteOceanCarrierInput;
};

/** Mutations */
export type MutationDeleteRailwayCompanyArgs = {
  input: DeleteRailwayCompanyInput;
};

/** Mutations */
export type MutationDeleteRateCardArgs = {
  input: DeleteRateCardInput;
};

/** Mutations */
export type MutationDeleteScimApiKeyArgs = {
  id: Scalars['String'];
};

/** Mutations */
export type MutationDeleteUserSettingsArgs = {
  input: DeleteUserSettingsInput;
};

/** Mutations */
export type MutationDeleteWorkflowRuleArgs = {
  workflowRuleId: Scalars['String'];
};

/** Mutations */
export type MutationDeleteWorkflowRuleDefinitionArgs = {
  workflowRuleDefinitionId: Scalars['String'];
};

/** Mutations */
export type MutationDeleteWorkflowTaskArgs = {
  input: DeleteWorkflowTaskInput;
};

/** Mutations */
export type MutationDeployWorkflowModelArgs = {
  workflowModelId: Scalars['String'];
};

/** Mutations */
export type MutationDocumentTemplatesCreateDocumentTemplateArgs = {
  input: CreateDocumentTemplateInput;
};

/** Mutations */
export type MutationDocumentTemplatesUpdateDocumentTemplateArgs = {
  input: UpdateDocumentTemplateInput;
};

/** Mutations */
export type MutationDuplicateCostTrackingItemArgs = {
  input?: InputMaybe<DuplicateCostTrackingItemInput>;
};

/** Mutations */
export type MutationEditCustomRoleArgs = {
  input: EditRoleInput;
};

/** Mutations */
export type MutationFeatureTrackingTrackFiltersUsageArgs = {
  input: TrackFiltersUsageInput;
};

/** Mutations */
export type MutationFinanceArchiveAccrualArgs = {
  input: ArchiveAccrualMutationInput;
};

/** Mutations */
export type MutationFinanceCreateAccrualArgs = {
  input: CreateAccrualMutationInput;
};

/** Mutations */
export type MutationFinanceCreateChargeTypeArgs = {
  input: CreateChargeTypeMutationInput;
};

/** Mutations */
export type MutationFinanceDeleteAccrualTemplateArgs = {
  input: DeleteAccrualTemplateMutationInput;
};

/** Mutations */
export type MutationFinanceExportAccrualsArgs = {
  input: ExportAccrualsMutationInput;
};

/** Mutations */
export type MutationFinancePublishAccrualArgs = {
  input: PublishAccrualMutationInput;
};

/** Mutations */
export type MutationFinanceSaveAccrualTemplateArgs = {
  input: SaveAccrualTemplateMutationInput;
};

/** Mutations */
export type MutationFinanceSpendAnalyticsInvoicesExportDataArgs = {
  input: SpendAnalyticsInvoicesExportDataInput;
};

/** Mutations */
export type MutationFinanceUpdateAccrualArgs = {
  input: UpdateAccrualMutationInput;
};

/** Mutations */
export type MutationFinanceUpdateAccrualInvoicedDateArgs = {
  input: UpdateInvoicedOnMutationInput;
};

/** Mutations */
export type MutationFinanceUpdateChargeTypeArgs = {
  input: UpdateChargeTypeMutationInput;
};

/** Mutations */
export type MutationFinanceUploadAccrualsArgs = {
  input: UploadAccrualsMutationInput;
};

/** Mutations */
export type MutationIntakePlanningExportPeriodsDataArgs = {
  input: ExportPeriodsRowsDataInput;
};

/** Mutations */
export type MutationIntakePlanningExportProductLotsDataArgs = {
  input: ExportProductLotsRowsDataInput;
};

/** Mutations */
export type MutationIntakePlanningExportProductsDataArgs = {
  input: ExportProductsRowsDataInput;
};

/** Mutations */
export type MutationInvitationsInviteArgs = {
  input: InviteInput;
};

/** Mutations */
export type MutationInviteAccountConnectionArgs = {
  input: InviteAccountConnectionInput;
};

/** Mutations */
export type MutationInviteUserArgs = {
  userId: Scalars['String'];
};

/** Mutations */
export type MutationInviteUsersArgs = {
  input: InviteUsersInput;
};

/** Mutations */
export type MutationIssueTemplatesCreateIssueTemplateArgs = {
  input: CreateIssueTemplateInput;
};

/** Mutations */
export type MutationIssueTemplatesDeleteIssueTemplateArgs = {
  input: DeleteIssueTemplateInput;
};

/** Mutations */
export type MutationIssueTemplatesUpdateIssueTemplateArgs = {
  input: UpdateIssueTemplateInput;
};

/** Mutations */
export type MutationIssuesArchiveIssueArgs = {
  input: ArchiveIssueInput;
};

/** Mutations */
export type MutationIssuesOpenIssueArgs = {
  input: OpenIssueInput;
};

/** Mutations */
export type MutationIssuesResolveIssueArgs = {
  input: ResolveIssueInput;
};

/** Mutations */
export type MutationIssuesStartIssueArgs = {
  input: StartIssueInput;
};

/** Mutations */
export type MutationIssuesUpdateIssueArgs = {
  input: UpdateIssueInput;
};

/** Mutations */
export type MutationLegacyCreateRateCardArgs = {
  input: LegacyCreateRateCardInput;
};

/** Mutations */
export type MutationLegacyDeleteRateCardArgs = {
  input: LegacyDeleteRateCardInput;
};

/** Mutations */
export type MutationLegacyUpdateRateCardArgs = {
  input: LegacyUpdateRateCardInput;
};

/** Mutations */
export type MutationNetworkCreateContactArgs = {
  input: CreateContactInput;
};

/** Mutations */
export type MutationNetworkUpdateContactArgs = {
  input: UpdateContactInput;
};

/** Mutations */
export type MutationNetworksAssignArgs = {
  input: AssignInput;
};

/** Mutations */
export type MutationNetworksCreateOrganisationArgs = {
  input: CreateOrganisationInput;
};

/** Mutations */
export type MutationNetworksCreateOrganisationLocationArgs = {
  input: CreateOrganisationLocationInput;
};

/** Mutations */
export type MutationNetworksDeleteContactArgs = {
  input: DeleteContactInput;
};

/** Mutations */
export type MutationNetworksDeleteLocationArgs = {
  input: DeleteLocationInput;
};

/** Mutations */
export type MutationNetworksDeleteLocationContactsArgs = {
  input: DeleteLocationContactsInput;
};

/** Mutations */
export type MutationNetworksDeleteOrganisationArgs = {
  input: DeleteOrganisationInput;
};

/** Mutations */
export type MutationNetworksRemoveAssignmentArgs = {
  input: RemoveAssignmentInput;
};

/** Mutations */
export type MutationNetworksRemoveAssignmentsArgs = {
  input: RemoveAssignmentsInput;
};

/** Mutations */
export type MutationNetworksRenameOrganisationArgs = {
  input: RenameOrganisationInput;
};

/** Mutations */
export type MutationNetworksUpdateLocationContactArgs = {
  input: UpdateLocationContactInput;
};

/** Mutations */
export type MutationNetworksUpdateMyOrganisationArgs = {
  input: UpdateMyOrganisationInput;
};

/** Mutations */
export type MutationNetworksUpdateOrganisationLocationArgs = {
  input: UpdateOrganisationLocationInput;
};

/** Mutations */
export type MutationNotesUpdateNoteArgs = {
  input: UpdateNoteMutationInput;
};

/** Mutations */
export type MutationNotificationsMarkAllAsReadArgs = {
  input: MarkAllAsReadInput;
};

/** Mutations */
export type MutationNotificationsMarkAsReadArgs = {
  input: MarkAsReadInput;
};

/** Mutations */
export type MutationNotificationsSubscribeArgs = {
  input: SubscribeInput;
};

/** Mutations */
export type MutationNotificationsToggleAutoSubscriptionsArgs = {
  input: ToggleAutoSubscriptionsInput;
};

/** Mutations */
export type MutationNotificationsUnsubscribeArgs = {
  input: UnsubscribeInput;
};

/** Mutations */
export type MutationNotificationsUpdateSettingsArgs = {
  input: UpdateSettingsInput;
};

/** Mutations */
export type MutationOperationsSetUserAsManagerArgs = {
  input: SetUserAsManagerInput;
};

/** Mutations */
export type MutationParseDocumentArgs = {
  input: ParseDocumentInput;
};

/** Mutations */
export type MutationProductsArchiveProductArgs = {
  input: ArchiveProductInput;
};

/** Mutations */
export type MutationProductsCreateProductArgs = {
  input: CreateProductInput;
};

/** Mutations */
export type MutationProductsUnarchiveProductArgs = {
  input: UnarchiveProductInput;
};

/** Mutations */
export type MutationProductsUpdateProductArgs = {
  input: UpdateProductInput;
};

/** Mutations */
export type MutationProductsUploadProductsArgs = {
  input: UploadProductsInput;
};

/** Mutations */
export type MutationProvisionUserArgs = {
  input: ProvisionUserInput;
};

/** Mutations */
export type MutationPurchaseOrderDelayReasonsArchivePurchaseOrderDelayReasonArgs = {
  input: ArchivePurchaseOrderDelayReasonInput;
};

/** Mutations */
export type MutationPurchaseOrderDelayReasonsCreatePurchaseOrderDelayReasonArgs = {
  input: CreatePurchaseOrderDelayReasonInput;
};

/** Mutations */
export type MutationPurchaseOrderDelayReasonsUpdatePurchaseOrderDelayReasonArgs = {
  input: UpdatePurchaseOrderDelayReasonInput;
};

/** Mutations */
export type MutationPurchaseOrdersCloseOrderArgs = {
  input: ClosePurchaseOrderInput;
};

/** Mutations */
export type MutationPurchaseOrdersCreateOrderArgs = {
  input: CreateOrderInput;
};

/** Mutations */
export type MutationPurchaseOrdersCreateOriginAgentAssignmentRuleArgs = {
  input: CreateOriginAgentAssignmentRuleInput;
};

/** Mutations */
export type MutationPurchaseOrdersDeleteOrderArgs = {
  input: DeleteOrderInput;
};

/** Mutations */
export type MutationPurchaseOrdersExportRowsPurchaseOrdersArgs = {
  input: ExportRowsPurchaseOrdersInput;
};

/** Mutations */
export type MutationPurchaseOrdersOpenIssueArgs = {
  input: OpenPurchaseOrderIssueInput;
};

/** Mutations */
export type MutationPurchaseOrdersRemoveOriginAgentAssignmentRuleArgs = {
  input: RemoveOriginAgentAssignmentRuleInput;
};

/** Mutations */
export type MutationPurchaseOrdersResolveIssueArgs = {
  input: ResolvePurchaseOrderIssueInput;
};

/** Mutations */
export type MutationPurchaseOrdersUpdateAssignedLotsToBookingArgs = {
  input: UpdateAssignedLotsToBookingInput;
};

/** Mutations */
export type MutationPurchaseOrdersUpdateAssignedLotsToCargoArgs = {
  input: UpdateAssignedLotsToCargoInput;
};

/** Mutations */
export type MutationPurchaseOrdersUpdateLotEstimatesArgs = {
  input: UpdateLotEstimatesInput;
};

/** Mutations */
export type MutationPurchaseOrdersUpdateLotsArgs = {
  input: UpdateLotsInput;
};

/** Mutations */
export type MutationPurchaseOrdersUpdateOrderArgs = {
  input: UpdateOrderInput;
};

/** Mutations */
export type MutationPurchaseOrdersUpdateOriginAgentAssignmentRuleArgs = {
  input: UpdateOriginAgentAssignmentRuleInput;
};

/** Mutations */
export type MutationReactivateUserArgs = {
  userId: Scalars['String'];
};

/** Mutations */
export type MutationRemoveJourneyScheduleArgs = {
  input: RemoveJourneyScheduleInput;
};

/** Mutations */
export type MutationRemoveMilestoneActualDateArgs = {
  input: RemoveMilestoneActualDateInput;
};

/** Mutations */
export type MutationRemoveMilestonePlannedDateArgs = {
  input: RemoveMilestonePlannedDateInput;
};

/** Mutations */
export type MutationRemoveMilestoneRequestedDateArgs = {
  input: RemoveMilestoneRequestedDateInput;
};

/** Mutations */
export type MutationResetPriceCalculationDateArgs = {
  input: ResetPriceCalculationDateInput;
};

/** Mutations */
export type MutationReviewParsedCommercialInvoiceArgs = {
  input: ReviewParsedCommercialInvoiceInput;
};

/** Mutations */
export type MutationRulesEngineArchiveRuleArgs = {
  input: ArchiveRuleInput;
};

/** Mutations */
export type MutationRulesEngineCheckRuleArgs = {
  input: CheckRuleInput;
};

/** Mutations */
export type MutationRulesEngineCreateRuleArgs = {
  input: CreateRuleInput;
};

/** Mutations */
export type MutationRulesEngineUpdateRuleArgs = {
  input: UpdateRuleInput;
};

/** Mutations */
export type MutationSaveWorkflowRuleArgs = {
  input: WorkflowRuleInput;
};

/** Mutations */
export type MutationSaveWorkflowRuleDefinitionArgs = {
  input: WorkflowRuleDefinitionInput;
};

/** Mutations */
export type MutationSetPriceCalculationDateArgs = {
  input: SetPriceCalculationDateInput;
};

/** Mutations */
export type MutationShipmentActionTypesCreateShipmentActionTypeArgs = {
  input: CreateShipmentActionTypeInput;
};

/** Mutations */
export type MutationShipmentActionTypesUpdateShipmentActionTypeArgs = {
  input: UpdateShipmentActionTypeInput;
};

/** Mutations */
export type MutationShipmentAddEddArgs = {
  input: AddEddInput;
};

/** Mutations */
export type MutationShipmentClearShipmentStatusArgs = {
  input: ClearShipmentStatusInput;
};

/** Mutations */
export type MutationShipmentDelayReasonsArchiveShipmentDelayReasonArgs = {
  input: ArchiveShipmentDelayReasonInput;
};

/** Mutations */
export type MutationShipmentDelayReasonsCreateShipmentDelayReasonArgs = {
  input: CreateShipmentDelayReasonInput;
};

/** Mutations */
export type MutationShipmentDelayReasonsUpdateShipmentDelayReasonArgs = {
  input: UpdateShipmentDelayReasonInput;
};

/** Mutations */
export type MutationShipmentExportRowsShipmentsArgs = {
  input: ExportRowsShipmentsInput;
};

/** Mutations */
export type MutationShipmentUpdateEddArgs = {
  input: UpdateEddInput;
};

/** Mutations */
export type MutationShipmentUpdateShipmentStatusArgs = {
  input: UpdateShipmentStatusInput;
};

/** Mutations */
export type MutationTerminalsImportTerminalsArgs = {
  input: ImportTerminalsInput;
};

/** Mutations */
export type MutationTerminalsUpdateTerminalArgs = {
  input: UpdateTerminalInput;
};

/** Mutations */
export type MutationToggleJourneyCollectionArgs = {
  input: ToggleJourneyCollectionInput;
};

/** Mutations */
export type MutationToggleOceanTrackingArgs = {
  input: ToggleOceanTrackingInput;
};

/** Mutations */
export type MutationUnarchiveAccountLocationArgs = {
  locationId: Scalars['String'];
};

/** Mutations */
export type MutationUnassignContractArgs = {
  input: UnassignContractInput;
};

/** Mutations */
export type MutationUnassignRoleMembersArgs = {
  input: RoleMembersInput;
};

/** Mutations */
export type MutationUpdateAccountDemoModeArgs = {
  input: UpdateAccountDemoModeInput;
};

/** Mutations */
export type MutationUpdateAccountHeadquartersArgs = {
  input: UpdateAccountHeadquartersInput;
};

/** Mutations */
export type MutationUpdateAccountLegalDetailsArgs = {
  input: UpdateAccountLegalDetailsInput;
};

/** Mutations */
export type MutationUpdateAccountLocationAddressArgs = {
  input: UpdateAccountLocationAddressInput;
};

/** Mutations */
export type MutationUpdateAccountLocationBusinessHoursArgs = {
  input: UpdateAccountLocationBusinessHoursInput;
};

/** Mutations */
export type MutationUpdateAccountLocationDetailsArgs = {
  input: UpdateAccountLocationDetailsInput;
};

/** Mutations */
export type MutationUpdateAccountMainDetailsArgs = {
  input: UpdateAccountMainDetailsInput;
};

/** Mutations */
export type MutationUpdateAccountMemberBusinessUnitsArgs = {
  input: UpdateAccountMemberBusinessUnitsInput;
};

/** Mutations */
export type MutationUpdateAccountMemberProfileInformationArgs = {
  input: UpdateAccountMemberProfileInformationInput;
};

/** Mutations */
export type MutationUpdateAccountMemberUserGroupArgs = {
  input: UpdateAccountMemberUserGroupInput;
};

/** Mutations */
export type MutationUpdateAccountReferencePrefixArgs = {
  input: UpdateAccountReferencePrefixInput;
};

/** Mutations */
export type MutationUpdateAirlineCarrierArgs = {
  input: UpdateAirlineCarrierInput;
};

/** Mutations */
export type MutationUpdateBookingsAssignedToConsolidatedShipmentArgs = {
  input: UpdateBookingsAssignedToConsolidatedShipmentInput;
};

/** Mutations */
export type MutationUpdateCargoArgs = {
  input: UpdateCargoInput;
};

/** Mutations */
export type MutationUpdateContractArgs = {
  input: UpdateContractInput;
};

/** Mutations */
export type MutationUpdateCostTrackingCostItemStatusArgs = {
  input: UpdateCostTrackingCostItemStatusInput;
};

/** Mutations */
export type MutationUpdateCostTrackingItemCurrencyArgs = {
  input: UpdateCostTrackingItemCurrencyInput;
};

/** Mutations */
export type MutationUpdateCostTrackingItemNameArgs = {
  input: UpdateCostTrackingItemNameInput;
};

/** Mutations */
export type MutationUpdateCostTrackingItemNoteArgs = {
  input: UpdateCostTrackingItemNoteInput;
};

/** Mutations */
export type MutationUpdateCostTrackingItemPaidFromArgs = {
  input: UpdateCostTrackingItemPaidFromInput;
};

/** Mutations */
export type MutationUpdateCostTrackingItemPaidToArgs = {
  input: UpdateCostTrackingItemPaidToInput;
};

/** Mutations */
export type MutationUpdateCostTrackingItemQuantityArgs = {
  input: UpdateCostTrackingItemQuantityInput;
};

/** Mutations */
export type MutationUpdateCostTrackingItemUnitPriceArgs = {
  input: UpdateCostTrackingItemUnitPriceInput;
};

/** Mutations */
export type MutationUpdateCostTrackingRevenueItemStatusArgs = {
  input: UpdateCostTrackingRevenueItemStatusInput;
};

/** Mutations */
export type MutationUpdateCourierArgs = {
  input: UpdateCourierInput;
};

/** Mutations */
export type MutationUpdateCurrentUserProfileArgs = {
  input?: InputMaybe<UserProfile>;
};

/** Mutations */
export type MutationUpdateDefaultChargeItemBasisArgs = {
  input: UpdateDefaultChargeItemBasisInput;
};

/** Mutations */
export type MutationUpdateDefaultChargeItemCargoOptionsArgs = {
  input: UpdateDefaultChargeItemCargoOptionsInput;
};

/** Mutations */
export type MutationUpdateDefaultChargeItemCurrencyArgs = {
  input: UpdateDefaultChargeItemCurrencyInput;
};

/** Mutations */
export type MutationUpdateDefaultChargeItemNameArgs = {
  input: UpdateDefaultChargeItemNameInput;
};

/** Mutations */
export type MutationUpdateDefaultChargeItemUnitPriceArgs = {
  input: UpdateDefaultChargeItemUnitPriceInput;
};

/** Mutations */
export type MutationUpdateFlightNumberArgs = {
  input: UpdateFlightNumberInput;
};

/** Mutations */
export type MutationUpdateHaulierArgs = {
  input: UpdateHaulierInput;
};

/** Mutations */
export type MutationUpdateJourneyAirlineArgs = {
  input: UpdateJourneyAirlineInput;
};

/** Mutations */
export type MutationUpdateJourneyCarrierBookingReferenceArgs = {
  input: UpdateJourneyCarrierBookingReferenceInput;
};

/** Mutations */
export type MutationUpdateJourneyCourierArgs = {
  input: UpdateJourneyCourierInput;
};

/** Mutations */
export type MutationUpdateJourneyMasterAirwayBillArgs = {
  input: UpdateJourneyMasterAirwayBillInput;
};

/** Mutations */
export type MutationUpdateJourneyMasterBillOfLadingArgs = {
  input: UpdateJourneyMasterBillOfLadingInput;
};

/** Mutations */
export type MutationUpdateJourneyRailwayCompanyArgs = {
  input: UpdateJourneyRailwayCompanyInput;
};

/** Mutations */
export type MutationUpdateJourneyScacArgs = {
  input: UpdateJourneyScacInput;
};

/** Mutations */
export type MutationUpdateJourneyScheduleArgs = {
  input?: InputMaybe<UpdateJourneyScheduleInput>;
};

/** Mutations */
export type MutationUpdateJourneyStopReferenceArgs = {
  input: UpdateJourneyStopReferenceInput;
};

/** Mutations */
export type MutationUpdateJourneyTrackingNumberArgs = {
  input: UpdateJourneyTrackingNumberInput;
};

/** Mutations */
export type MutationUpdateJourneysCargoReadyDateArgs = {
  input: UpdateJourneysCargoReadyDateInput;
};

/** Mutations */
export type MutationUpdateMilestoneActualDateArgs = {
  input: UpdateMilestoneActualDateInput;
};

/** Mutations */
export type MutationUpdateMilestoneDelayArgs = {
  input: UpdateMilestoneDelayInput;
};

/** Mutations */
export type MutationUpdateMilestonePlannedDateArgs = {
  input: UpdateMilestonePlannedDateInput;
};

/** Mutations */
export type MutationUpdateMilestoneRequestedDateArgs = {
  input: UpdateMilestoneRequestedDateInput;
};

/** Mutations */
export type MutationUpdateOceanCarrierArgs = {
  input: UpdateOceanCarrierInput;
};

/** Mutations */
export type MutationUpdateRailwayCompanyArgs = {
  input: UpdateRailwayCompanyInput;
};

/** Mutations */
export type MutationUpdateRateCardArgs = {
  input: UpdateRateCardInput;
};

/** Mutations */
export type MutationUpdateShippingDelayReasonArgs = {
  input: UpdateShippingDelayReasonInput;
};

/** Mutations */
export type MutationUpdateStopLocationArgs = {
  input: UpdateStopLocationInput;
};

/** Mutations */
export type MutationUpdateTrailerIdArgs = {
  input: UpdateTrailerIdInput;
};

/** Mutations */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** Mutations */
export type MutationUpdateVehicleHaulierIdArgs = {
  input: UpdateVehicleHaulierIdInput;
};

/** Mutations */
export type MutationUpdateVehiclePlateNumberArgs = {
  input: UpdateVehiclePlateNumberInput;
};

/** Mutations */
export type MutationUpdateVesselNameArgs = {
  input: UpdateVesselNameInput;
};

/** Mutations */
export type MutationUpdateVoyageNumberArgs = {
  input: UpdateVoyageNumberInput;
};

/** Mutations */
export type MutationUploadDefaultPortChargesArgs = {
  input: UploadDefaultPortChargesInput;
};

/** Mutations */
export type MutationUploadPortChargesArgs = {
  input: UploadCentralPortChargesInput;
};

/** Mutations */
export type MutationUploadWorkflowModelArgs = {
  input: UploadWorkflowModelInput;
};

/** Mutations */
export type MutationUpsertUserSettingsArgs = {
  input: UpsertUserSettingsInput;
};

/** Mutations */
export type MutationWebhookSubscriptionsCreateWebhookArgs = {
  input: CreateWebhookInput;
};

/** Mutations */
export type MutationWebhookSubscriptionsDeleteWebhookArgs = {
  input: DeleteWebhookInput;
};

/** Mutations */
export type MutationWebhookSubscriptionsUpdateWebhookArgs = {
  input: UpdateWebhookInput;
};

export interface MyOrganisation extends NetworksOrganisationInterface {
  __typename?: 'MyOrganisation';
  assignedRoles: Array<Role>;
  contacts: NetworksContactConnection;
  eori?: Maybe<Scalars['String']>;
  /** Organisation ID */
  id: Scalars['String'];
  /** Locations assigned to the organisation */
  locations: Array<NetworksLocation>;
  /** Organisation name */
  name: Scalars['String'];
  /** Organization type */
  type: OrganisationTypeValue;
  vat?: Maybe<Scalars['String']>;
}

export type MyOrganisationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export interface Network {
  __typename?: 'Network';
  canCreateAgentOrganisation: AuthorizationResult;
  canCreateForwarderOrganisation: AuthorizationResult;
  canCreateSavedOrganisation: AuthorizationResult;
  /** Network identifier */
  id?: Maybe<Scalars['String']>;
  /** Locations that belong to this network */
  locations?: Maybe<LocationAssignableConnection>;
  myOrganisation?: Maybe<MyOrganisation>;
  /** Organisations in this network */
  organisations?: Maybe<NetworksOrganisationInterfaceConnection>;
}

export type NetworkLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  searchContains?: InputMaybe<LocationSearchInput>;
};

export type NetworkOrganisationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  nameContains?: InputMaybe<Scalars['String']>;
};

export interface NetworkContact {
  __typename?: 'NetworkContact';
  bankAccountDetails?: Maybe<Scalars['String']>;
  bipCode?: Maybe<Scalars['String']>;
  carrier: Scalars['Boolean'];
  contactPeople?: Maybe<Array<NetworkContactPerson>>;
  customer: Scalars['Boolean'];
  eoriNumber?: Maybe<Scalars['String']>;
  haulier: Scalars['Boolean'];
  id: Scalars['String'];
  name: Scalars['String'];
  scacCode?: Maybe<Scalars['String']>;
  status: ContactStatus;
  supplier: Scalars['Boolean'];
  taxNumber?: Maybe<Scalars['String']>;
  types?: Maybe<Array<ContactTypeEnum>>;
  warehouse: Scalars['Boolean'];
}

/** The connection type for NetworkContact. */
export interface NetworkContactConnection {
  __typename?: 'NetworkContactConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NetworkContactEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NetworkContact>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface NetworkContactEdge {
  __typename?: 'NetworkContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NetworkContact>;
}

export interface NetworkContactInput {
  bankAccountDetails?: InputMaybe<Scalars['String']>;
  bipCode?: InputMaybe<Scalars['String']>;
  carrier?: InputMaybe<Scalars['Boolean']>;
  contactPeople?: InputMaybe<Array<NetworkContactPersonInput>>;
  customer?: InputMaybe<Scalars['Boolean']>;
  eoriNumber?: InputMaybe<Scalars['String']>;
  haulier?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  scacCode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<ContactStatus>;
  supplier?: InputMaybe<Scalars['Boolean']>;
  taxNumber?: InputMaybe<Scalars['String']>;
  warehouse?: InputMaybe<Scalars['Boolean']>;
}

export interface NetworkContactPerson {
  __typename?: 'NetworkContactPerson';
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  mainContact?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  skypeUserName?: Maybe<Scalars['String']>;
}

export interface NetworkContactPersonInput {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  mainContact?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  skypeUserName?: InputMaybe<Scalars['String']>;
}

export interface NetworkLocation {
  id: Scalars['String'];
  isMainContact: Scalars['Boolean'];
}

export interface Networks {
  __typename?: 'Networks';
  canAccessRoute: AuthorizationResult;
  canManage: AuthorizationResult;
}

export type NetworksAsMainContact = {
  asMainContact: Scalars['Boolean'];
};

export type NetworksAssignableInterface = {
  businessHours?: Maybe<BusinessHours>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<NetworksLabelType>;
  timeZone?: Maybe<Scalars['String']>;
};

export interface NetworksContact extends NetworksContactInterface {
  __typename?: 'NetworksContact';
  assignedRoles: Array<Role>;
  autoSubscribeToOrganisationTargets: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  canSendResetPasswordInstructions: AuthorizationResult;
  email: Scalars['String'];
  firstName: Scalars['String'];
  /** Networks Contact identifier */
  id: Scalars['String'];
  invitation?: Maybe<Invitation>;
  lastName: Scalars['String'];
  locations: Array<NetworksLocationInContact>;
  main: Scalars['Boolean'];
  organisation?: Maybe<NetworksOrganisationInterface>;
  phoneNumber?: Maybe<Scalars['String']>;
  slackHandle?: Maybe<Scalars['String']>;
}

/** The connection type for NetworksContact. */
export interface NetworksContactConnection {
  __typename?: 'NetworksContactConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NetworksContactEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NetworksContact>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface NetworksContactEdge {
  __typename?: 'NetworksContactEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NetworksContact>;
}

export interface NetworksContactInLocation extends NetworksAsMainContact, NetworksContactInterface {
  __typename?: 'NetworksContactInLocation';
  asMainContact: Scalars['Boolean'];
  assignedRoles: Array<Role>;
  autoSubscribeToOrganisationTargets: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  canSendResetPasswordInstructions: AuthorizationResult;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  invitation?: Maybe<Invitation>;
  lastName: Scalars['String'];
  locations: Array<NetworksLocationInContact>;
  main: Scalars['Boolean'];
  organisation?: Maybe<NetworksOrganisationInterface>;
  phoneNumber?: Maybe<Scalars['String']>;
  slackHandle?: Maybe<Scalars['String']>;
}

/** The connection type for NetworksContactInLocation. */
export interface NetworksContactInLocationConnection {
  __typename?: 'NetworksContactInLocationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NetworksContactInLocationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NetworksContactInLocation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface NetworksContactInLocationEdge {
  __typename?: 'NetworksContactInLocationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NetworksContactInLocation>;
}

export type NetworksContactInterface = {
  assignedRoles: Array<Role>;
  autoSubscribeToOrganisationTargets: Scalars['Boolean'];
  avatarUrl?: Maybe<Scalars['String']>;
  canSendResetPasswordInstructions: AuthorizationResult;
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['String'];
  invitation?: Maybe<Invitation>;
  lastName: Scalars['String'];
  locations: Array<NetworksLocationInContact>;
  main: Scalars['Boolean'];
  organisation?: Maybe<NetworksOrganisationInterface>;
  phoneNumber?: Maybe<Scalars['String']>;
  slackHandle?: Maybe<Scalars['String']>;
};

export interface NetworksLabelType {
  __typename?: 'NetworksLabelType';
  /** Long label text */
  long?: Maybe<Scalars['String']>;
  /** Short label text */
  short?: Maybe<Scalars['String']>;
}

export interface NetworksLegacyAddress {
  __typename?: 'NetworksLegacyAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Country>;
  countyOrState?: Maybe<Scalars['String']>;
  postalCodeOrZip?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
}

export interface NetworksLegacyOrgLoc extends NetworksAssignableInterface {
  __typename?: 'NetworksLegacyOrgLoc';
  businessHours?: Maybe<BusinessHours>;
  contact?: Maybe<NetworksLegacyOrgLocContact>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<NetworksLabelType>;
  location?: Maybe<NetworksLegacyOrgLocLocation>;
  organisation?: Maybe<NetworksLegacyOrgLocOrganisation>;
  timeZone?: Maybe<Scalars['String']>;
}

export interface NetworksLegacyOrgLocContact {
  __typename?: 'NetworksLegacyOrgLocContact';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
}

export interface NetworksLegacyOrgLocLocation {
  __typename?: 'NetworksLegacyOrgLocLocation';
  address?: Maybe<NetworksLegacyAddress>;
}

export interface NetworksLegacyOrgLocOrganisation {
  __typename?: 'NetworksLegacyOrgLocOrganisation';
  name?: Maybe<Scalars['String']>;
}

export interface NetworksLocation extends NetworksLocationInterface {
  __typename?: 'NetworksLocation';
  /** Location address */
  address: LegacyAddress;
  businessHours?: Maybe<BusinessHours>;
  contacts?: Maybe<NetworksContactInLocationConnection>;
  geolocation?: Maybe<Geolocation>;
  /** ID */
  id?: Maybe<Scalars['String']>;
  /** Location type */
  locationType?: Maybe<LocationTypeValue>;
  mainContact?: Maybe<NetworksContactInLocation>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  organisation: NetworksOrganisationInterface;
  timeZone?: Maybe<Scalars['String']>;
}

export type NetworksLocationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export interface NetworksLocationInContact extends NetworksAsMainContact, NetworksLocationInterface {
  __typename?: 'NetworksLocationInContact';
  /** Location address */
  address: LegacyAddress;
  asMainContact: Scalars['Boolean'];
  businessHours?: Maybe<BusinessHours>;
  contacts?: Maybe<NetworksContactInLocationConnection>;
  geolocation?: Maybe<Geolocation>;
  /** ID */
  id?: Maybe<Scalars['String']>;
  /** Location type */
  locationType?: Maybe<LocationTypeValue>;
  mainContact?: Maybe<NetworksContactInLocation>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  organisation: NetworksOrganisationInterface;
  timeZone?: Maybe<Scalars['String']>;
}

export type NetworksLocationInContactContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type NetworksLocationInterface = {
  /** Location address */
  address: LegacyAddress;
  businessHours?: Maybe<BusinessHours>;
  contacts?: Maybe<NetworksContactInLocationConnection>;
  geolocation?: Maybe<Geolocation>;
  /** ID */
  id?: Maybe<Scalars['String']>;
  /** Location type */
  locationType?: Maybe<LocationTypeValue>;
  mainContact?: Maybe<NetworksContactInLocation>;
  /** Name */
  name?: Maybe<Scalars['String']>;
  organisation: NetworksOrganisationInterface;
  timeZone?: Maybe<Scalars['String']>;
};

export type NetworksLocationInterfaceContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export interface NetworksOrgLoc extends NetworksAssignableInterface {
  __typename?: 'NetworksOrgLoc';
  businessHours?: Maybe<BusinessHours>;
  contact?: Maybe<NetworksOrgLocContact>;
  /** Networks Org Loc identifier */
  id?: Maybe<Scalars['String']>;
  label?: Maybe<NetworksLabelType>;
  location?: Maybe<NetworksOrgLocLocation>;
  organisation?: Maybe<NetworksOrgLocOrganisation>;
  timeZone?: Maybe<Scalars['String']>;
}

export interface NetworksOrgLocContact {
  __typename?: 'NetworksOrgLocContact';
  avatarUrl?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  slackHandle?: Maybe<Scalars['String']>;
}

export interface NetworksOrgLocLocation {
  __typename?: 'NetworksOrgLocLocation';
  address?: Maybe<LegacyAddress>;
  businessHours?: Maybe<BusinessHours>;
  geolocation?: Maybe<Geolocation>;
  id?: Maybe<Scalars['String']>;
  locationType?: Maybe<LocationTypeValue>;
  name?: Maybe<Scalars['String']>;
  timeZone?: Maybe<Scalars['String']>;
}

export interface NetworksOrgLocOrganisation {
  __typename?: 'NetworksOrgLocOrganisation';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

/** Provides information about the organisations within the network. */
export type NetworksOrganisationInterface = {
  assignedRoles: Array<Role>;
  contacts: NetworksContactConnection;
  /** Organisation ID */
  id: Scalars['String'];
  /** Locations assigned to the organisation */
  locations: Array<NetworksLocation>;
  /** Organisation name */
  name: Scalars['String'];
  /** Organization type */
  type: OrganisationTypeValue;
};

/** Provides information about the organisations within the network. */
export type NetworksOrganisationInterfaceContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** The connection type for NetworksOrganisationInterface. */
export interface NetworksOrganisationInterfaceConnection {
  __typename?: 'NetworksOrganisationInterfaceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<NetworksOrganisationInterfaceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<NetworksOrganisationInterface>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
}

/** An edge in a connection. */
export interface NetworksOrganisationInterfaceEdge {
  __typename?: 'NetworksOrganisationInterfaceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<NetworksOrganisationInterface>;
}

export interface NetworksTerminal extends CostTrackingLocation, NetworksAssignableInterface {
  __typename?: 'NetworksTerminal';
  businessHours?: Maybe<BusinessHours>;
  iata?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  label?: Maybe<NetworksLabelType>;
  name?: Maybe<Scalars['String']>;
  terminalTypes?: Maybe<Array<TerminalTypeValue>>;
  timeZone?: Maybe<Scalars['String']>;
  unlocode: Scalars['String'];
}

export interface Note {
  __typename?: 'Note';
  body: Scalars['String'];
  id: Scalars['String'];
  type: Scalars['String'];
}

export interface NotificationSettings {
  __typename?: 'NotificationSettings';
  booking?: Maybe<NotificationSettingsBookingType>;
  purchaseOrder?: Maybe<NotificationSettingsPurchaseOrderType>;
}

export interface NotificationSettingsBookingType {
  __typename?: 'NotificationSettingsBookingType';
  canSetActionItemSettings: AuthorizationResult;
  canSetApprovedBookingSetting: AuthorizationResult;
  canSetBookingRequestSettings: AuthorizationResult;
  canSetCargoSettings: AuthorizationResult;
  canSetCollectionSettings: AuthorizationResult;
  canSetDeliverySettings: AuthorizationResult;
  canSetEtaSettings: AuthorizationResult;
  canSetIssuesSettings: AuthorizationResult;
  canSetManufacturerRequestedBookingSetting: AuthorizationResult;
  canSetQuoteOptionSettings: AuthorizationResult;
  canSetRequiredCustomerApprovalForBookingSetting: AuthorizationResult;
  canSetSlackSettings: AuthorizationResult;
  canSetTextMessageFromAgentForwarderSetting: AuthorizationResult;
  canSetTextMessageFromCustomerUserSetting: AuthorizationResult;
  canSetTextMessageFromForwarderSetting: AuthorizationResult;
  canSetTextMessageFromManufacturerSetting: AuthorizationResult;
  canSetTextMessageSettings: AuthorizationResult;
}

export interface NotificationSettingsGlobalPermissions {
  __typename?: 'NotificationSettingsGlobalPermissions';
  booking?: Maybe<BookingNotificationSettingsGlobalPermissions>;
}

export interface NotificationSettingsPurchaseOrderType {
  __typename?: 'NotificationSettingsPurchaseOrderType';
  canSetIssuesSettings: AuthorizationResult;
  canSetLotsCargoReadyDateSettings: AuthorizationResult;
  canSetLotsDeliveryEstimateSettings: AuthorizationResult;
  canSetPurchaseOrderClosedSetting: AuthorizationResult;
  canSetPurchaseOrderCreatedSetting: AuthorizationResult;
  canSetTextMessageFromAgentForwarderSetting: AuthorizationResult;
  canSetTextMessageFromCustomerUserSetting: AuthorizationResult;
  canSetTextMessageFromForwarderSetting: AuthorizationResult;
  canSetTextMessageFromManufacturerSetting: AuthorizationResult;
  canSetTextMessageSettings: AuthorizationResult;
}

export interface Notifications {
  __typename?: 'Notifications';
  currentUserSettings?: Maybe<NotificationsSettingsType>;
  notificationsPerTarget: TargetNotificationsConnection;
  stakeholders?: Maybe<Array<NotificationsStakeholderType>>;
  subscribers?: Maybe<Array<NotificationsSubscriberType>>;
}

export type NotificationsNotificationsPerTargetArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

export type NotificationsStakeholdersArgs = {
  accountId: Scalars['String'];
  targetId: Scalars['String'];
  targetType: NotificationsTargetTypeEnum;
};

export type NotificationsSubscribersArgs = {
  targetId: Scalars['String'];
  targetType: NotificationsTargetTypeEnum;
};

export interface NotificationsAutosubscriptionSettingType {
  __typename?: 'NotificationsAutosubscriptionSettingType';
  account: Account;
  enabled: Scalars['Boolean'];
}

export interface NotificationsSettingsType {
  __typename?: 'NotificationsSettingsType';
  autosubscriptionSettings?: Maybe<Array<NotificationsAutosubscriptionSettingType>>;
  emailDelay: Scalars['Int'];
  enabled: Scalars['Boolean'];
  settingsPerTarget: Array<TargetSettings>;
}

export interface NotificationsStakeholderType {
  __typename?: 'NotificationsStakeholderType';
  contactDetails?: Maybe<NetworksContact>;
}

export interface NotificationsSubscriberType {
  __typename?: 'NotificationsSubscriberType';
  canReceiveNotifications: AuthorizationResult;
  canSeeOriginalCompanyName: AuthorizationResult;
  canUnsubscribe: AuthorizationResult;
  contactDetails?: Maybe<NetworksContact>;
}

export enum NotificationsTargetTypeEnum {
  BOOKING = 'booking',
  PURCHASE_ORDER = 'purchase_order'
}

export interface NotifiedParty {
  __typename?: 'NotifiedParty';
  assignable?: Maybe<NetworksAssignableInterface>;
  notifiedPartyId?: Maybe<Scalars['String']>;
}

export interface OceanCarrier {
  __typename?: 'OceanCarrier';
  carrierScac: Scalars['String'];
  id: Scalars['String'];
  isTrackable: Scalars['Boolean'];
  oceanCarrierName: Scalars['String'];
  synonyms?: Maybe<Array<Scalars['String']>>;
}

export interface OceanCarrierConnection {
  __typename?: 'OceanCarrierConnection';
  edges?: Maybe<Array<OceanCarrierEdge>>;
  nodes?: Maybe<Array<OceanCarrier>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface OceanCarrierEdge {
  __typename?: 'OceanCarrierEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<OceanCarrier>;
}

export interface OceanTracking {
  __typename?: 'OceanTracking';
  canToggleTracking: Scalars['Boolean'];
  canViewTrackingDetails: Scalars['Boolean'];
  issues: Array<OceanTrackingIssue>;
  status: OceanTrackingStatusEnum;
}

export interface OceanTrackingIssue {
  __typename?: 'OceanTrackingIssue';
  isActionable: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
  type: OceanTrackingIssueTypeEnum;
}

export enum OceanTrackingIssueTypeEnum {
  ACTUAL_DATE_VALIDATION_FAILURE = 'ACTUAL_DATE_VALIDATION_FAILURE',
  API_KEY_EXPIRED = 'API_KEY_EXPIRED',
  BOOKING_CANCELLED = 'BOOKING_CANCELLED',
  BOOKING_NOT_CONFIRMED = 'BOOKING_NOT_CONFIRMED',
  CARRIER_BOOKING_REFERENCE_NOT_FOUND = 'CARRIER_BOOKING_REFERENCE_NOT_FOUND',
  CARRIER_NOT_TRACKING = 'CARRIER_NOT_TRACKING',
  CONTAINERS_NOT_INITIALISED_WITH_VENDOR = 'CONTAINERS_NOT_INITIALISED_WITH_VENDOR',
  DUPLICATE_FCL = 'DUPLICATE_FCL',
  ESTIMATE_IN_PAST = 'ESTIMATE_IN_PAST',
  INVALID_CARRIER_BOOKING_REFERENCE = 'INVALID_CARRIER_BOOKING_REFERENCE',
  INVALID_DATA_RECEIVED_FROM_VENDOR = 'INVALID_DATA_RECEIVED_FROM_VENDOR',
  LEG_COUNT_MISMATCH = 'LEG_COUNT_MISMATCH',
  MISSING_CARRIER_BOOKING_REFERENCE = 'MISSING_CARRIER_BOOKING_REFERENCE',
  MISSING_SCAC = 'MISSING_SCAC',
  NON_TRACKABLE_SCAC = 'NON_TRACKABLE_SCAC',
  OTHER_VENDOR_ERROR = 'OTHER_VENDOR_ERROR',
  STOPS_MISMATCH = 'STOPS_MISMATCH',
  TRACKING_IDENTIFIER_MISMATCH = 'TRACKING_IDENTIFIER_MISMATCH',
  TRACKING_INITIALISED = 'TRACKING_INITIALISED',
  UNKNOWN = 'UNKNOWN',
  UNKNOWN_CONTAINER_NUMBER_RECEIVED_FROM_VENDOR = 'UNKNOWN_CONTAINER_NUMBER_RECEIVED_FROM_VENDOR',
  VENDOR_LEG_COUNT_HIGHER = 'VENDOR_LEG_COUNT_HIGHER',
  VENDOR_NOT_TRACKING_CONTAINER = 'VENDOR_NOT_TRACKING_CONTAINER',
  VESSEL_NAME_MISMATCH = 'VESSEL_NAME_MISMATCH',
  ZENCARGO_LEG_COUNT_HIGHER = 'ZENCARGO_LEG_COUNT_HIGHER'
}

export enum OceanTrackingStatusEnum {
  NOT_TRACKED = 'NOT_TRACKED',
  TRACKED = 'TRACKED',
  TRACKING_DISABLED = 'TRACKING_DISABLED'
}

/** Autogenerated input type of OpenBookingIssue */
export interface OpenBookingIssueInput {
  category: IssueCategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  occurredOn: Scalars['Date'];
  problemDescription: Scalars['String'];
  severity: IssueSeverity;
  solutionDescription: Scalars['String'];
  state: IssueStateInput;
  visibility: IssueVisibilityInput;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of OpenBookingIssue */
export interface OpenBookingIssuePayload {
  __typename?: 'OpenBookingIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issue?: Maybe<BookingIssue>;
}

/** Autogenerated input type of OpenIssue */
export interface OpenIssueInput {
  associatedId: Scalars['String'];
  associatedType: IssueAssociatedObject;
  category: IssueCategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  occurredOn: Scalars['Date'];
  problemDescription: Scalars['String'];
  severity: IssueSeverity;
  solutionDescription: Scalars['String'];
  state: IssueStateInput;
  visibility: IssueVisibilityInput;
}

/** Autogenerated return type of OpenIssue */
export interface OpenIssuePayload {
  __typename?: 'OpenIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issue?: Maybe<Issue>;
}

/** Autogenerated input type of OpenPurchaseOrderIssue */
export interface OpenPurchaseOrderIssueInput {
  category: IssueCategory;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  occurredOn: Scalars['Date'];
  problemDescription: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  severity: IssueSeverity;
  solutionDescription: Scalars['String'];
  state: IssueStateInput;
  visibility: IssueVisibilityInput;
}

/** Autogenerated return type of OpenPurchaseOrderIssue */
export interface OpenPurchaseOrderIssuePayload {
  __typename?: 'OpenPurchaseOrderIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issue?: Maybe<PurchaseOrderIssue>;
}

export interface OperationsDashboard {
  __typename?: 'OperationsDashboard';
  canAccessRoute: AuthorizationResult;
}

export interface OperationsSettings {
  __typename?: 'OperationsSettings';
  canAccessRoute: AuthorizationResult;
}

/**
 * Represents the original expected information about a specific product in a purchase order.
 * Not to be confused with a Lot, which represents the latest information Zencargo holds about
 * the product. Unless you are looking to compare on time in full, you almost certainly want
 * to query for the Lot instead of this. See the Lot docs for more information.
 */
export interface OrderedLineItem {
  __typename?: 'OrderedLineItem';
  canUpdateDeliveryDate: AuthorizationResult;
  /**
   * The original expected volume of the ordered quantity of products,
   * when fully packaged, in cubic meters. It is NOT the latest known volume.
   * If you are looking for the latest volume, query the Lots.
   */
  cbm?: Maybe<Scalars['Float']>;
  /** The id of the line item in the ERP system */
  erpLineId?: Maybe<Scalars['String']>;
  /** Ordered line item ID (pk) */
  id: Scalars['String'];
  /**
   * Also known as "goods ready date" or "ex-factory date",
   * this is the date the goods were originally expected to be ready for collection from the place of origin.
   * It is NOT the latest known date. If you are looking for the latest date, query the Lots.
   */
  initialCargoReadyDate?: Maybe<RichDate>;
  /** See the Lot documentation for full details */
  lots: Array<Lot>;
  /** Product Information */
  product?: Maybe<Product>;
  /** Quantity of product ordered */
  quantityOrdered?: Maybe<Scalars['Int']>;
  /**
   * The date the order was originally
   * supposed to be delivered to the destination by. It is NOT the latest known delivery estimate.
   * If you are looking for the latest delivery date, query the Lots
   */
  requiredDeliveryDate?: Maybe<RichDate>;
  /** The total cost of the ordered quantity of product due to the manufacturer. */
  totalCost?: Maybe<Money>;
}

/** The connection type for OrderedLineItem. */
export interface OrderedLineItemConnection {
  __typename?: 'OrderedLineItemConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OrderedLineItemEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OrderedLineItem>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface OrderedLineItemEdge {
  __typename?: 'OrderedLineItemEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OrderedLineItem>;
}

/**
 * Provide Zencargo with original, expected information about a specific product in a purchase order.
 * Zencargo uses values in this payload to calculate the basis for on time and in full.
 * Values here only update the ordered information, and should be used to store the original
 * "on time in full" baselines. It should not be used, for instance, to update
 * the latest known info, such as an updated quantity or latest cargo ready date, which is data stored
 * against a Lot. Refer to the purchase orders guide for more information.
 */
export interface OrderedLineItemInput {
  /**
   * The volume of the ordered quantity of products, when fully packaged, in cubic meters.
   * If you do not provide this, Zencargo will attempt to calculate it from your data
   */
  cbm?: InputMaybe<Scalars['Float']>;
  /** The id of the line item in the ERP system */
  erpLineId?: InputMaybe<Scalars['String']>;
  /**
   * Also known as "goods ready date" or "ex-factory date",
   * this is the date the goods were originally expected to be ready for collection from the place of origin
   */
  initialCargoReadyDate?: InputMaybe<Scalars['Date']>;
  /**
   * Product SKU code. Needs to be a valid SKU code associated with a product in your
   * catalogue - this argument is required
   */
  productSku: Scalars['String'];
  /** Quantity of product originally ordered - this argument is required */
  quantityOrdered?: InputMaybe<Scalars['Int']>;
  /**
   * The date the order was originally
   * supposed to be delivered to the destination by.
   * This represents the date after which the Purchase Order will be considered "late"
   */
  requiredDeliveryDate?: InputMaybe<Scalars['Date']>;
  /**
   * The total cost of the ordered quantity of product
   * due to the manufacturer. This amount should reflect what will be entered onto the commercial invoice
   */
  totalCost?: InputMaybe<MoneyInput>;
}

export enum OrganisationTypeValue {
  AGENT = 'agent',
  FORWARDER = 'forwarder',
  MY = 'my',
  SAVED = 'saved'
}

export enum OrganizationalRoleEnum {
  AGENT = 'AGENT',
  CONSIGNMENT_PARTY = 'CONSIGNMENT_PARTY',
  FORWARDER = 'FORWARDER'
}

export interface OriginAgentAssignmentRule {
  __typename?: 'OriginAgentAssignmentRule';
  account: LegacyAccount;
  collectionWarehouse?: Maybe<NetworksOrgLoc>;
  deliveryWarehouse?: Maybe<NetworksOrgLoc>;
  id: Scalars['String'];
  modeOfTransport: ModeOfTransport;
  originAgent: NetworksOrgLoc;
  portOfDestination?: Maybe<Terminal>;
  portOfLoad?: Maybe<Terminal>;
}

/** The connection type for OriginAgentAssignmentRule. */
export interface OriginAgentAssignmentRuleConnection {
  __typename?: 'OriginAgentAssignmentRuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<OriginAgentAssignmentRuleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<OriginAgentAssignmentRule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface OriginAgentAssignmentRuleEdge {
  __typename?: 'OriginAgentAssignmentRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<OriginAgentAssignmentRule>;
}

export enum OriginDestinationChargeTypeEnum {
  DESTINATION = 'DESTINATION',
  ORIGIN = 'ORIGIN'
}

export interface PackageInfo {
  __typename?: 'PackageInfo';
  /** Depth of master carton this product ships in */
  depth?: Maybe<MetricValueWithSi>;
  /** Length of master carton this product ships in */
  length?: Maybe<MetricValueWithSi>;
  /** Type of packaging */
  type?: Maybe<PackageTypeEnum>;
  /** Width of master carton this product ships in */
  width?: Maybe<MetricValueWithSi>;
}

export interface PackageInfoInput {
  /** Depth of master carton this product ships in */
  depth?: InputMaybe<MetricValueInput>;
  /** Length of master carton this product ships in */
  length?: InputMaybe<MetricValueInput>;
  /** Type of packaging */
  type?: InputMaybe<PackageTypeEnum>;
  /** Width of master carton this product ships in */
  width?: InputMaybe<MetricValueInput>;
}

export enum PackageTypeEnum {
  CARTON = 'carton'
}

export interface PackagingTypesPackagingType {
  __typename?: 'PackagingTypesPackagingType';
  displayed: Scalars['Boolean'];
  id: Scalars['Int'];
  packageCode: Scalars['String'];
  packageType: Scalars['String'];
  title: Scalars['String'];
}

/** The connection type for PackagingTypesPackagingType. */
export interface PackagingTypesPackagingTypeConnection {
  __typename?: 'PackagingTypesPackagingTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PackagingTypesPackagingTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PackagingTypesPackagingType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface PackagingTypesPackagingTypeEdge {
  __typename?: 'PackagingTypesPackagingTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PackagingTypesPackagingType>;
}

export interface PackingList {
  __typename?: 'PackingList';
  /** Booking identifier at Zencargo */
  bookingReference?: Maybe<Scalars['String']>;
  /** Cargo related details */
  cargo?: Maybe<CargoCargoItem>;
  coreCargo?: Maybe<CoreCargo>;
  customerId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  /**
   * The lines each represent a single SKU that has been included
   * in the packing list. See inside for more details about what is available
   */
  lines?: Maybe<Array<PackingListLine>>;
  /** @deprecated Renamed to core_cargo */
  newCargo?: Maybe<CoreCargo>;
  orders?: Maybe<Array<PurchaseOrder>>;
}

export type PackingListLinesArgs = {
  orderId?: InputMaybe<Scalars['String']>;
};

/** The connection type for PackingList. */
export interface PackingListConnection {
  __typename?: 'PackingListConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PackingListEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PackingList>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface PackingListEdge {
  __typename?: 'PackingListEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PackingList>;
}

export interface PackingListFindBy {
  /** Filter key: BOOKING_REFERENCE */
  key: PackingListFindByEnum;
  /**
   * Use the findBy option/filter to retrieve a list for packing lists for a booking
   * or a packing list for a specific cargo. To retrieve the packing list for a booking
   * you need to specify {key: BOOKING_REFERENCE, value: "ZTEST-1"} where key represents
   * the search criterion and value the value for that criterion (here for example ZTEST-1
   * is our booking reference). To retrieve the packing list for a specific cargo
   * {key: CARGO_ID, value: "2d632c5b-6827-4937-93c6-e1fd2adf0c66"} (value is the id of the cargo)
   */
  value: Scalars['String'];
}

export enum PackingListFindByEnum {
  BOOKING_REFERENCE = 'BOOKING_REFERENCE',
  CARGO_ID = 'CARGO_ID'
}

export interface PackingListLine {
  __typename?: 'PackingListLine';
  /** Lot Information */
  lot?: Maybe<Lot>;
  /** Product Information */
  product?: Maybe<Product>;
}

export interface PackingLists {
  __typename?: 'PackingLists';
  canAccessRoute: AuthorizationResult;
}

/** Information about pagination in a connection. */
export interface PageInfo {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
}

export enum Pallet {
  EURO = 'EURO',
  OTHER = 'OTHER',
  UK = 'UK',
  US = 'US'
}

export enum PalletTypeEnum {
  EURO = 'EURO',
  OTHER = 'OTHER',
  UK = 'UK',
  US = 'US'
}

export interface PalletsInput {
  /** @deprecated This field will be deprecated in favour of the `dimensions_with_metric`. */
  dimensions?: InputMaybe<DimensionsInCm>;
  dimensionsWithMetric?: InputMaybe<CargoDimensionsInputType>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous: Scalars['Boolean'];
  palletType: Pallet;
  quantity: Scalars['Int'];
  reefer: Scalars['Boolean'];
  stackable?: InputMaybe<Scalars['Boolean']>;
  volume?: InputMaybe<Scalars['Float']>;
  /** @deprecated This field will be deprecated in favour of the `gross_weight`. */
  weight?: InputMaybe<Scalars['Float']>;
}

export interface ParseDocumentInput {
  documentType: ParserEngineDocumentType;
  file: Scalars['String'];
  fileId: Scalars['String'];
  fileName: Scalars['String'];
}

export interface ParsedCommercialInvoiceResult {
  __typename?: 'ParsedCommercialInvoiceResult';
  commodityCode?: Maybe<Scalars['String']>;
  consignee?: Maybe<Scalars['String']>;
  consignor?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  invoiceNumber?: Maybe<Scalars['String']>;
  lineItems?: Maybe<Array<Maybe<ParsedLineItemResult>>>;
  originCountry?: Maybe<Scalars['String']>;
  totalAmount?: Maybe<Scalars['Float']>;
}

export interface ParsedCommercialInvoiceResultInput {
  commodityCode?: InputMaybe<Scalars['String']>;
  consignee?: InputMaybe<Scalars['String']>;
  consignor?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  lineItems: Array<ParsedLineItemResultInput>;
  originCountry?: InputMaybe<Scalars['String']>;
  totalAmount?: InputMaybe<Scalars['Float']>;
}

export interface ParsedDimensionsResult {
  __typename?: 'ParsedDimensionsResult';
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  unit?: Maybe<Scalars['String']>;
  width?: Maybe<Scalars['Float']>;
}

export interface ParsedDimensionsResultInput {
  height?: InputMaybe<Scalars['Float']>;
  length?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
  width?: InputMaybe<Scalars['Float']>;
}

export interface ParsedLineItemResult {
  __typename?: 'ParsedLineItemResult';
  containerNumber?: Maybe<Scalars['String']>;
  containerType?: Maybe<Scalars['String']>;
  dimensions?: Maybe<ParsedDimensionsResult>;
  hsCode?: Maybe<Scalars['String']>;
  looseCargoQuantity?: Maybe<Scalars['Float']>;
  looseCargoType?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  purchaseOrderNumber?: Maybe<Scalars['String']>;
  skuCode?: Maybe<Scalars['String']>;
  unitQuantity?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
  weightUnit?: Maybe<Scalars['String']>;
}

export interface ParsedLineItemResultInput {
  containerNumber?: InputMaybe<Scalars['String']>;
  containerType?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<ParsedDimensionsResultInput>;
  hsCode?: InputMaybe<Scalars['String']>;
  looseCargoQuantity?: InputMaybe<Scalars['Float']>;
  looseCargoType?: InputMaybe<Scalars['String']>;
  productName?: InputMaybe<Scalars['String']>;
  purchaseOrderNumber?: InputMaybe<Scalars['String']>;
  skuCode?: InputMaybe<Scalars['String']>;
  unitQuantity?: InputMaybe<Scalars['Float']>;
  weight?: InputMaybe<Scalars['Float']>;
  weightUnit?: InputMaybe<Scalars['String']>;
}

export enum ParserEngineDocumentType {
  COMMERCIAL_INVOICE = 'COMMERCIAL_INVOICE'
}

export interface PendingTradeLane {
  __typename?: 'PendingTradeLane';
  destination?: Maybe<Location>;
  id: Scalars['String'];
  importExport?: Maybe<Scalars['String']>;
  origin?: Maybe<Location>;
}

export interface PerUnitCbm {
  __typename?: 'PerUnitCbm';
  source?: Maybe<PerUnitCbmSourceEnum>;
  value?: Maybe<Scalars['Float']>;
}

export enum PerUnitCbmSourceEnum {
  LOT = 'LOT',
  PRODUCT = 'PRODUCT'
}

export interface Percentile {
  __typename?: 'Percentile';
  percentile: Scalars['Int'];
  value: Scalars['Float'];
}

export interface Period {
  __typename?: 'Period';
  dateRange: DateRange;
  quantityArriving: Scalars['Int'];
  quantityPlanned: Scalars['Int'];
  valueArriving?: Maybe<Money>;
  valuePlanned?: Maybe<Money>;
}

export enum PeriodsColumnEnum {
  ENDDATE = 'endDate',
  QUANTITYARRIVING = 'quantityArriving',
  QUANTITYPLANNED = 'quantityPlanned',
  STARTDATE = 'startDate',
  VALUEARRIVING = 'valueArriving',
  VALUEPLANNED = 'valuePlanned'
}

export interface PeriodsDataFiltersInput {
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
}

export enum PermissionType {
  VIEW = 'view'
}

export interface Permissions {
  __typename?: 'Permissions';
  agentForwarder: Scalars['Boolean'];
  customerUser: Scalars['Boolean'];
  manufacturer: Scalars['Boolean'];
}

export interface PermissionsInput {
  agentForwarder: Scalars['Boolean'];
  customerUser: Scalars['Boolean'];
  manufacturer: Scalars['Boolean'];
}

/** Autogenerated input type of PinBooking */
export interface PinBookingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of PinBooking */
export interface PinBookingPayload {
  __typename?: 'PinBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface PlatformGlobalPermissions {
  __typename?: 'PlatformGlobalPermissions';
  canCreateAccounts?: Maybe<AuthorizationResult>;
}

export interface PortCharge {
  __typename?: 'PortCharge';
  centralPortChargeId?: Maybe<Scalars['String']>;
  chargeType: ChargeType;
  chargeValue: Scalars['Float'];
  currency: Currency;
  excluded?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  incoterms: Array<IncotermsValue>;
  port: Terminal;
  portChargeType: OriginDestinationChargeTypeEnum;
}

export interface PortChargeConnection {
  __typename?: 'PortChargeConnection';
  edges?: Maybe<Array<Maybe<CentralPortChargeEdge>>>;
  nodes?: Maybe<Array<Maybe<CentralPortCharge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
}

export interface PortChargeFilter {
  incoterms?: InputMaybe<Array<IncotermsValue>>;
  port?: InputMaybe<Scalars['String']>;
  portType?: InputMaybe<OriginDestinationChargeTypeEnum>;
}

export interface PortChargeInput {
  chargeTypeId: Scalars['String'];
  chargeValue: Scalars['Float'];
  currency: Currency;
  excluded?: InputMaybe<Scalars['Boolean']>;
  incoterms: Array<IncotermsValue>;
  portChargeId?: InputMaybe<Scalars['String']>;
  portChargeType: OriginDestinationChargeTypeEnum;
  portId: Scalars['String'];
}

export enum PortTypeValue {
  POD = 'POD',
  POL = 'POL'
}

export interface Preference {
  __typename?: 'Preference';
  filterViews?: Maybe<Scalars['Boolean']>;
  filterViewsBy?: Maybe<FilterViewsByEnum>;
  level?: Maybe<ProfileLevelEnum>;
  manufacturersOfInterestIds: Array<Scalars['String']>;
  productCategoriesOfInterestIds: Array<Scalars['String']>;
  roles: Array<ProfileRoleEnum>;
  savedShipmentFilters?: Maybe<Array<SavedFilters>>;
}

export interface PriceCalculation {
  __typename?: 'PriceCalculation';
  date?: Maybe<Scalars['Date']>;
  milestone: PriceCalculationMilestone;
}

export enum PriceCalculationMilestone {
  CARRIER_PUBLISHED = 'CARRIER_PUBLISHED',
  COLLECTION = 'COLLECTION',
  CUSTOM = 'CUSTOM',
  GATE_IN = 'GATE_IN',
  VESSEL_DEPARTED = 'VESSEL_DEPARTED'
}

export interface Product {
  __typename?: 'Product';
  /** Account UUID (pk) */
  accountUuid: Scalars['String'];
  /**
   * Indicates whether the product has been archived or not.
   *
   * Note: archived products will not appear in regular Zencargo UI
   * and analytics, as they are assumed no longer be relevant to
   * your business
   */
  archived?: Maybe<Scalars['Boolean']>;
  /** Name of existing or new category */
  categoryName?: Maybe<Scalars['String']>;
  /** Product cost price */
  costPrice?: Maybe<Money>;
  /** Product HS code */
  hsCode?: Maybe<Scalars['String']>;
  /** Product HTS code */
  htsCode?: Maybe<Scalars['String']>;
  /** Product ID (pk) */
  id: Scalars['String'];
  /** Product name */
  name?: Maybe<Scalars['String']>;
  /** Master carton dimensions - length, width and depth measurements */
  packageInfo?: Maybe<PackageInfo>;
  /** Product per unit cbm - calculated by using the dimensions and units per master carton fields */
  perUnitCbm?: Maybe<PerUnitCbm>;
  /** Optional product properties e.g. Season: AW2021 */
  properties: Array<ProductProperty>;
  /** Product sell price or RRP */
  sellPrice?: Maybe<Money>;
  /** Product SKU code */
  skuCode?: Maybe<Scalars['String']>;
  /** The number of units of this product that ship inside one master carton */
  unitsPerMasterCarton?: Maybe<Scalars['Int']>;
  /** The weight of a full master carton of this product */
  weight?: Maybe<MetricValueWithSi>;
}

export interface ProductAccountPermissions {
  __typename?: 'ProductAccountPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
  canCreate?: Maybe<AuthorizationResult>;
}

export interface ProductCategory {
  __typename?: 'ProductCategory';
  /** Category ID */
  id: Scalars['String'];
  /** Category name */
  name: Scalars['String'];
}

/** The connection type for Product. */
export interface ProductConnection {
  __typename?: 'ProductConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Product>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ProductEdge {
  __typename?: 'ProductEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Product>;
}

/**
 * Attributes of a product that you want Zencargo to know about. We use data provided here to
 * offer analytics and reporting inside our application. Physical attributes like weight, dimensions
 * etc should be provided at the carton level, not at the individual item level.
 */
export interface ProductInput {
  /** Name of existing or new category */
  categoryName?: InputMaybe<Scalars['String']>;
  /** Product cost price */
  costPrice?: InputMaybe<MoneyInput>;
  /** Product HS code */
  hsCode?: InputMaybe<Scalars['String']>;
  /** Product HTS code */
  htsCode?: InputMaybe<Scalars['String']>;
  /** Product name - this argument is required */
  name?: InputMaybe<Scalars['String']>;
  /** Master carton dimensions - length, width and depth measurements. */
  packageInfo?: InputMaybe<PackageInfoInput>;
  /** Optional product properties e.g. Season: AW2021. */
  properties?: InputMaybe<Array<ProductPropertyInput>>;
  /** Product sell price or RRP */
  sellPrice?: InputMaybe<MoneyInput>;
  /** Product SKU code - this argument is required */
  skuCode?: InputMaybe<Scalars['String']>;
  /** The number of units of this product that ship inside one master carton */
  unitsPerMasterCarton?: InputMaybe<Scalars['Int']>;
  /** The weight of a full master carton of this product */
  weight?: InputMaybe<MetricValueInput>;
}

export interface ProductIntake {
  __typename?: 'ProductIntake';
  lastUpdated?: Maybe<Scalars['Date']>;
  product: Product;
  quantityArriving: Scalars['Int'];
  quantityPlanned: Scalars['Int'];
  valueArriving?: Maybe<Money>;
  valuePlanned?: Maybe<Money>;
}

/** The connection type for ProductIntake. */
export interface ProductIntakeConnection {
  __typename?: 'ProductIntakeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<IntakePlanningProductEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductIntake>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface ProductLot {
  __typename?: 'ProductLot';
  atRisk?: Maybe<Scalars['Boolean']>;
  booking?: Maybe<Booking>;
  lastUpdated?: Maybe<Scalars['Date']>;
  orderedLineItem: OrderedLineItem;
  purchaseOrder: PurchaseOrder;
  quantityArriving: Scalars['Int'];
  quantityPlanned: Scalars['Int'];
  stages: Array<ProgressStatus>;
  valueArriving?: Maybe<Money>;
  valuePlanned?: Maybe<Money>;
}

/** The connection type for ProductLot. */
export interface ProductLotConnection {
  __typename?: 'ProductLotConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ProductLotEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ProductLot>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ProductLotEdge {
  __typename?: 'ProductLotEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ProductLot>;
}

export enum ProductLotsColumnEnum {
  ARRIVING = 'arriving',
  BOOKINGSTATUS = 'bookingStatus',
  ESTIMATEDDELIVERYDATE = 'estimatedDeliveryDate',
  INTRANSIT = 'inTransit',
  LASTUPDATED = 'lastUpdated',
  ORDERED = 'ordered',
  PURCHASEORDER = 'purchaseOrder',
  QUANTITYARRIVING = 'quantityArriving',
  QUANTITYPLANNED = 'quantityPlanned',
  READY = 'ready',
  REQUIREDDELIVERYDATE = 'requiredDeliveryDate',
  STATUS = 'status',
  VALUEARRIVING = 'valueArriving',
  VALUEPLANNED = 'valuePlanned'
}

export interface ProductLotsDataFiltersInput {
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
  productId: Scalars['String'];
}

export interface ProductProperty {
  __typename?: 'ProductProperty';
  /** Property name e.g. Colour */
  name: Scalars['String'];
  /** Property value e.g. Green */
  value: Scalars['String'];
}

export interface ProductPropertyInput {
  /** Property name e.g. Category */
  name: Scalars['String'];
  /** Property value e.g. Homeware */
  value: Scalars['String'];
}

export interface ProductSettings {
  __typename?: 'ProductSettings';
  canAccessRoute: AuthorizationResult;
}

export interface Products {
  __typename?: 'Products';
  canAccessRoute: AuthorizationResult;
}

export enum ProductsColumnEnum {
  LASTUPDATED = 'lastUpdated',
  PRODUCTNAME = 'productName',
  PRODUCTSKUCODE = 'productSkuCode',
  QUANTITYARRIVING = 'quantityArriving',
  QUANTITYPLANNED = 'quantityPlanned',
  VALUEARRIVING = 'valueArriving',
  VALUEPLANNED = 'valuePlanned'
}

export interface ProductsCsvImport {
  __typename?: 'ProductsCsvImport';
  canAccessRoute: AuthorizationResult;
  canImport: AuthorizationResult;
}

export interface ProductsDataFiltersInput {
  dateRange: DateRangeInput;
  destinationId?: InputMaybe<Scalars['String']>;
}

export enum ProfileLevelEnum {
  CSUITE = 'CSUITE',
  DIRECTOR_VP = 'DIRECTOR_VP',
  HEAD_OF_DEPARTMENT = 'HEAD_OF_DEPARTMENT',
  MANAGER = 'MANAGER',
  PRACTITIONER = 'PRACTITIONER'
}

export enum ProfileRoleEnum {
  ANALYST = 'ANALYST',
  BUYER = 'BUYER',
  DEVELOPER = 'DEVELOPER',
  FINANCE = 'FINANCE',
  FORWARDER = 'FORWARDER',
  LOGISTICS = 'LOGISTICS',
  MANUFACTURING = 'MANUFACTURING',
  MERCHANDISING = 'MERCHANDISING',
  PROCUREMENT = 'PROCUREMENT',
  SALES = 'SALES',
  WAREHOUSING = 'WAREHOUSING'
}

export interface ProgressStatus {
  __typename?: 'ProgressStatus';
  /** Indicates whether this stage is current or not */
  currentStage?: Maybe<Scalars['Boolean']>;
  /** Date stage was set */
  date?: Maybe<RichDate>;
  /** Additional information on stage */
  label?: Maybe<Scalars['String']>;
  /** Description of stage */
  name?: Maybe<Scalars['String']>;
  /** Status of stage */
  statusType?: Maybe<Status>;
}

/** Autogenerated input type of ProvideQuoteOptionRejectionReason */
export interface ProvideQuoteOptionRejectionReasonInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteOptionId: Scalars['String'];
  reasonDescription?: InputMaybe<Scalars['String']>;
  rejectionReason: Scalars['String'];
}

/** Autogenerated return type of ProvideQuoteOptionRejectionReason */
export interface ProvideQuoteOptionRejectionReasonPayload {
  __typename?: 'ProvideQuoteOptionRejectionReasonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quoteOption?: Maybe<QuoteOption>;
}

export enum ProvisionMethod {
  AUTO = 'AUTO',
  SCIM = 'SCIM'
}

export interface ProvisionUserInput {
  accountId?: InputMaybe<Scalars['String']>;
  autoSubscribeToOrganisationTargets?: InputMaybe<Scalars['Boolean']>;
  invitedBy?: InputMaybe<InvitedByInput>;
  networkLocations: Array<NetworkLocation>;
  networkOrganisationId: Scalars['String'];
  userProfile: UserProfile;
  userType: UserType;
}

export interface ProvisionUserPayload {
  __typename?: 'ProvisionUserPayload';
  errors?: Maybe<Array<Error>>;
  userId: Scalars['String'];
}

/** Autogenerated input type of PublishAccrualMutation */
export interface PublishAccrualMutationInput {
  accrualId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of PublishAccrualMutation */
export interface PublishAccrualMutationPayload {
  __typename?: 'PublishAccrualMutationPayload';
  accrual?: Maybe<Accrual>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/**
 * A purchase order represents one order that will be fulfilled between a manufacturer and importer.
 * See the documentation for the various pieces for how this works, or consult the API docs for a general
 * explanation of how this works on Zencargo.
 */
export interface PurchaseOrder {
  __typename?: 'PurchaseOrder';
  account?: Maybe<Account>;
  accountId?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<RichDateTime>;
  bookedCbm?: Maybe<Scalars['Float']>;
  bookings?: Maybe<Array<Booking>>;
  buyer?: Maybe<NetworksAssignableInterface>;
  canClose: AuthorizationResult;
  canModifyIssueVisibility: AuthorizationResult;
  canOpenIssue: AuthorizationResult;
  canRemove: AuthorizationResult;
  canSendTextMessageToAgentForwarder: AuthorizationResult;
  canSendTextMessageToCustomerUser: AuthorizationResult;
  canSendTextMessageToManufacturer: AuthorizationResult;
  canUpdate: AuthorizationResult;
  canViewCustomer: AuthorizationResult;
  cargoReadyDateEstimate?: Maybe<PurchaseOrderEstimatedDateType>;
  customer?: Maybe<Customer>;
  deliveryEstimate?: Maybe<PurchaseOrderEstimatedDateType>;
  destination?: Maybe<NetworksAssignableInterface>;
  /** The assigned Forwarder for this PurchaseOrder */
  forwarder?: Maybe<NetworksAssignableInterface>;
  fulfilledCbm?: Maybe<Scalars['Float']>;
  /** The unique ID assigned to this Purchase Order by Zencargo */
  id: Scalars['String'];
  incoterms?: Maybe<Incoterms>;
  issues?: Maybe<Array<PurchaseOrderIssue>>;
  lastUpdatedAt?: Maybe<RichDate>;
  lotsAvailableForCargoCount?: Maybe<Scalars['Int']>;
  lotsBookedCount: Scalars['Int'];
  lotsBookedForCargoCount?: Maybe<Scalars['Int']>;
  lotsByStageCount?: Maybe<Array<PurchaseOrderLotStageCountType>>;
  lotsInitialCargoReadyDate?: Maybe<PurchaseOrderLotsDateType>;
  lotsRequiredDeliveryDate?: Maybe<PurchaseOrderLotsDateType>;
  lotsTotalCount: Scalars['Int'];
  manufacturer?: Maybe<NetworksAssignableInterface>;
  modeOfTransport?: Maybe<ModeOfTransport>;
  /** The date the PO was confirmed with the manufacturer */
  orderDate?: Maybe<Scalars['Date']>;
  /** The reference number assigned to this purchase order by your ERP */
  orderReferenceNumber?: Maybe<Scalars['String']>;
  orderedCbm?: Maybe<Scalars['Float']>;
  /**
   * Each line represents ordered information about a specific product. See the docs for OrderedLineItems for more info.
   * The fulfilment information is accessible via the Lot object, which is associated to the OrderedLineItem.
   * See the docs for OrderedLineItem and Lot for full details.
   */
  orderedLineItems: Array<OrderedLineItem>;
  origin?: Maybe<NetworksAssignableInterface>;
  originAgent?: Maybe<NetworksAssignableInterface>;
  permissions?: Maybe<PurchaseOrderPermissions>;
  portOfDestination?: Maybe<Terminal>;
  portOfLoad?: Maybe<Terminal>;
  requiredDeliveryDate?: Maybe<Scalars['Date']>;
  seller?: Maybe<NetworksAssignableInterface>;
  status?: Maybe<PurchaseOrderStatusEnum>;
}

/**
 * A purchase order represents one order that will be fulfilled between a manufacturer and importer.
 * See the documentation for the various pieces for how this works, or consult the API docs for a general
 * explanation of how this works on Zencargo.
 */
export type PurchaseOrderLotsAvailableForCargoCountArgs = {
  cargoId?: InputMaybe<Scalars['String']>;
};

/**
 * A purchase order represents one order that will be fulfilled between a manufacturer and importer.
 * See the documentation for the various pieces for how this works, or consult the API docs for a general
 * explanation of how this works on Zencargo.
 */
export type PurchaseOrderLotsBookedForCargoCountArgs = {
  cargoId?: InputMaybe<Scalars['String']>;
};

export interface PurchaseOrderAccountPermissions {
  __typename?: 'PurchaseOrderAccountPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
  canCreate?: Maybe<AuthorizationResult>;
}

export enum PurchaseOrderBookingStateEnum {
  FULLY_BOOKED = 'FULLY_BOOKED',
  NOT_BOOKED = 'NOT_BOOKED',
  PARTIALLY_BOOKED = 'PARTIALLY_BOOKED'
}

export interface PurchaseOrderCargoReadyDatesMetadataType {
  __typename?: 'PurchaseOrderCargoReadyDatesMetadataType';
  additionalInformation?: Maybe<Scalars['String']>;
  cargoReadyDate: Scalars['Date'];
  confidenceLevel: ConfidenceLevel;
  lotIds: Array<Scalars['String']>;
  metadataType: Scalars['String'];
  reasonCategory?: Maybe<Scalars['String']>;
}

export interface PurchaseOrderClosedMetadataType {
  __typename?: 'PurchaseOrderClosedMetadataType';
  manually: Scalars['Boolean'];
  metadataType: Scalars['String'];
}

/** The connection type for PurchaseOrder. */
export interface PurchaseOrderConnection {
  __typename?: 'PurchaseOrderConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseOrderEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseOrder>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface PurchaseOrderDelayReason {
  __typename?: 'PurchaseOrderDelayReason';
  archivedAt?: Maybe<RichDateTime>;
  description: Scalars['String'];
  id: Scalars['String'];
  reason: Scalars['String'];
  responsibleParty?: Maybe<PurchaseOrdersDelayReasonResponsibleParty>;
}

/** The connection type for PurchaseOrderDelayReason. */
export interface PurchaseOrderDelayReasonConnection {
  __typename?: 'PurchaseOrderDelayReasonConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PurchaseOrderDelayReasonEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PurchaseOrderDelayReason>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface PurchaseOrderDelayReasonEdge {
  __typename?: 'PurchaseOrderDelayReasonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseOrderDelayReason>;
}

export interface PurchaseOrderDeliveryEstimatesMetadataType {
  __typename?: 'PurchaseOrderDeliveryEstimatesMetadataType';
  additionalInformation?: Maybe<Scalars['String']>;
  confidenceLevel: ConfidenceLevel;
  deliveryDate: Scalars['Date'];
  lotIds: Array<Scalars['String']>;
  metadataType: Scalars['String'];
}

/** An edge in a connection. */
export interface PurchaseOrderEdge {
  __typename?: 'PurchaseOrderEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PurchaseOrder>;
}

export interface PurchaseOrderEstimatedDateType {
  __typename?: 'PurchaseOrderEstimatedDateType';
  date?: Maybe<Scalars['Date']>;
  delayInDays?: Maybe<Scalars['Int']>;
  initialDate?: Maybe<Scalars['Date']>;
  lastUpdatedAt?: Maybe<Scalars['Date']>;
  lastUpdatedBy?: Maybe<UpdatedBy>;
}

/**
 * Provide Zencargo with details about the Purchase Order and its contents. See individual items for
 * details, especially around OrderedLineItem inputs, which is where you will find information about
 * the products in the purchase order.
 */
export interface PurchaseOrderInput {
  /**
   * Location ID of the buyer of this purchase order. This should be the ID of an existing location
   * within your network. See the networks API for details
   */
  buyerId?: InputMaybe<Scalars['String']>;
  /**
   * Location ID of the destination of this purchase order. This should be the ID of an existing location
   * within your network. See the networks API for details
   */
  destinationId?: InputMaybe<Scalars['String']>;
  forwarderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  /** Incoterms this purchase order was raised under */
  incoterms?: InputMaybe<IncotermsValue>;
  /**
   * Account ID of the supplier for this purchase order. This must be the ID of a supplier within your network.
   * Refer to the AccountConnection API for further details.
   */
  manufacturerId?: InputMaybe<Scalars['String']>;
  /** Optional argument for defining mode of transport, which can be air, ocean, truck, or rail */
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  /** Date the PO was confirmed with the manufacturer */
  orderDate?: InputMaybe<Scalars['Date']>;
  /** Reference number assigned to this purchase order by your ERP - this argument is required */
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  /** Input ordered line item data - this argument is required */
  orderedLineItems?: InputMaybe<Array<OrderedLineItemInput>>;
  /**
   * ID of the agent for this purchase order. This should be the ID of an existing agent
   * within your network. Contact your Zencargo representative if you are unsure what to enter here
   */
  originAgentId?: InputMaybe<Scalars['String']>;
  /**
   * Location ID of the place of origin of this purchase order. This should be the ID of an existing location
   * within your network. See the networks API for details
   */
  originId?: InputMaybe<Scalars['String']>;
  /** UNlocode of the port of destination expected for this order. See https://locode.info for codes */
  portOfDestinationUnlocode?: InputMaybe<Scalars['String']>;
  /** UNlocode of the port of load expected for this order. See https://locode.info for codes */
  portOfLoadUnlocode?: InputMaybe<Scalars['String']>;
  /**
   * Location ID of the seller of this purchase order. Note: this is not the same as the manufacturer.
   * This should be the ID of an existing location within your network. See the networks API for details
   */
  sellerId?: InputMaybe<Scalars['String']>;
}

export interface PurchaseOrderIssue {
  __typename?: 'PurchaseOrderIssue';
  associatedId: Scalars['String'];
  associatedType: IssueAssociatedObject;
  canArchive: AuthorizationResult;
  canModifyVisibility: AuthorizationResult;
  canResolve: AuthorizationResult;
  canStart: AuthorizationResult;
  canUpdate: AuthorizationResult;
  canUpdateCategory: AuthorizationResult;
  canUpdateProblemDescription: AuthorizationResult;
  category: IssueCategory;
  id: Scalars['String'];
  occurredOn: RichDateTime;
  openedAt: RichDateTime;
  openedBy: NetworksContact;
  problemDescription: Scalars['String'];
  purchaseOrderId: Scalars['String'];
  resolvedAt?: Maybe<RichDateTime>;
  resolvedBy?: Maybe<NetworksContact>;
  severity: IssueSeverity;
  solutionDescription?: Maybe<Scalars['String']>;
  startedAt?: Maybe<RichDateTime>;
  startedBy?: Maybe<NetworksContact>;
  state: IssueState;
  title?: Maybe<Scalars['String']>;
  visibility: IssueVisibility;
}

export interface PurchaseOrderIssueSortInput {
  direction: SortingOrder;
  field: PurchaseOrderIssueSortingField;
}

export enum PurchaseOrderIssueSortingField {
  OCCURRED_ON = 'OCCURRED_ON',
  OPENED_AT = 'OPENED_AT'
}

export interface PurchaseOrderLotStageCountType {
  __typename?: 'PurchaseOrderLotStageCountType';
  count?: Maybe<Scalars['Int']>;
  stage?: Maybe<PurchaseOrderLotStageEnum>;
}

export enum PurchaseOrderLotStageEnum {
  ARRIVED_PORT = 'ARRIVED_PORT',
  DELIVERED = 'DELIVERED',
  IN_TRANSIT = 'IN_TRANSIT',
  ORDERED = 'ORDERED',
  READY = 'READY'
}

export interface PurchaseOrderLotsDateType {
  __typename?: 'PurchaseOrderLotsDateType';
  date?: Maybe<Scalars['Date']>;
  sameForAllLots: Scalars['Boolean'];
}

export interface PurchaseOrderPermissions {
  __typename?: 'PurchaseOrderPermissions';
  isCustomerOnPO?: Maybe<AuthorizationResult>;
  isSupplierOnPO?: Maybe<AuthorizationResult>;
}

export interface PurchaseOrderReference {
  __typename?: 'PurchaseOrderReference';
  id: Scalars['String'];
  orderReferenceNumber?: Maybe<Scalars['String']>;
}

export interface PurchaseOrderReferences {
  __typename?: 'PurchaseOrderReferences';
  nodes?: Maybe<Array<PurchaseOrderReference>>;
  totalCount: Scalars['Int'];
}

export enum PurchaseOrderStatusEnum {
  CLOSED = 'CLOSED',
  OPEN = 'OPEN'
}

export interface PurchaseOrders {
  __typename?: 'PurchaseOrders';
  canAccessRoute: AuthorizationResult;
  canAssignAgent: AuthorizationResult;
  canCreate: AuthorizationResult;
  canExport: AuthorizationResult;
  canViewCustomers: AuthorizationResult;
}

export enum PurchaseOrdersColumnEnum {
  BOOKEDCBM = 'bookedCbm',
  BOOKING = 'booking',
  BOOKINGS = 'bookings',
  CARGOREADYDATEESTIMATE = 'cargoReadyDateEstimate',
  CARGOREADYDATEUPDATEDAT = 'cargoReadyDateUpdatedAt',
  CUSTOMER = 'customer',
  DELIVERYESTIMATE = 'deliveryEstimate',
  DESTINATION = 'destination',
  FULFILLEDCBM = 'fulfilledCbm',
  ISSUES = 'issues',
  LOTSBYSTAGECOUNT = 'lotsByStageCount',
  MANUFACTURER = 'manufacturer',
  MODEOFTRANSPORT = 'modeOfTransport',
  ORDERREFERENCENUMBER = 'orderReferenceNumber',
  ORDEREDCBM = 'orderedCbm',
  REQUIREDDELIVERYDATE = 'requiredDeliveryDate'
}

export interface PurchaseOrdersDataFiltersInput {
  /** Filter by the booking state of PO */
  bookingState?: InputMaybe<Array<PurchaseOrderBookingStateEnum>>;
  /** Filter by cargo ready date, this takes date range. */
  cargoReadyDateBetween?: InputMaybe<DateRangeInput>;
  /** Limit purchase orders to particular customer */
  customerUuid?: InputMaybe<Scalars['String']>;
  /** Filter by state of purchase order */
  filterBy?: InputMaybe<FilterOptionsEnum>;
  /** Filter by incoterms */
  incoterms?: InputMaybe<Scalars['String']>;
  issues?: InputMaybe<PurchaseOrdersIssuesFilterInput>;
  /** Filter by last updated, this takes date range. */
  lastUpdatedBetween?: InputMaybe<DateRangeInput>;
  /** Lookup by manufacturer(s) */
  manufacturerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Filter by destination */
  portOfDestinationUnlocode?: InputMaybe<Scalars['String']>;
  /** Filter by origin */
  portOfLoadUnlocode?: InputMaybe<Scalars['String']>;
  /** Filter by status of purchase order */
  status?: InputMaybe<PurchaseOrderStatusEnum>;
}

export enum PurchaseOrdersDelayReasonResponsibleParty {
  CUSTOMER = 'Customer',
  OTHER = 'Other',
  SUPPLIER = 'Supplier',
  ZENCARGO = 'Zencargo'
}

export interface PurchaseOrdersExportDataInput {
  columns?: InputMaybe<Array<PurchaseOrdersColumnEnum>>;
  dataFilters: PurchaseOrdersDataFiltersInput;
  order?: InputMaybe<SortInput>;
}

export interface PurchaseOrdersFilter {
  __typename?: 'PurchaseOrdersFilter';
  accounts: Array<Account>;
  canViewCustomerFilter: AuthorizationResult;
  customers: Array<FiltersCustomerType>;
  issueTitles: Array<Maybe<Scalars['String']>>;
  terminals?: Maybe<Array<FiltersTerminalType>>;
}

export type PurchaseOrdersFilterTerminalsArgs = {
  portType: FiltersPortTypeValue;
};

export enum PurchaseOrdersIntakePlanningIssue {
  MISSING_ORDER_DATE = 'missing_order_date',
  MISSING_REQUIRED_DELIVERY_DATE = 'missing_required_delivery_date'
}

export interface PurchaseOrdersIssuesFilterInput {
  active?: InputMaybe<Scalars['Boolean']>;
  titleEq?: InputMaybe<Scalars['String']>;
}

export interface PurchaseOrdersWithIssues {
  __typename?: 'PurchaseOrdersWithIssues';
  purchaseOrder: PurchaseOrder;
  relatedIssues?: Maybe<Array<PurchaseOrdersIntakePlanningIssue>>;
}

/** Queries */
export interface Query {
  __typename?: 'Query';
  account?: Maybe<Account>;
  accountBusinessUnits?: Maybe<AccountConnection>;
  accountCompanies?: Maybe<Array<Scalars['String']>>;
  accountConnections?: Maybe<AccountConnectionTypeConnection>;
  accountHeadquarters: AccountLocation;
  accountLocation?: Maybe<AccountLocation>;
  accountLocations?: Maybe<AccountLocationConnection>;
  accountMember?: Maybe<AccountMember>;
  accountMembers?: Maybe<AccountMemberConnection>;
  accountUserGroups: Array<AccountUserGroup>;
  accounts?: Maybe<AccountConnection>;
  accrualTemplates: AccrualTemplateConnection;
  actionItemTypes: Array<ActionItemTypesActionItemType>;
  activityFeed: ActivityFeed;
  airlineCarrier?: Maybe<AirlineCarrier>;
  airlineCarriers?: Maybe<AirlineCarrierConnection>;
  assignablePrivileges: Array<AssignablePrivilege>;
  assignedChargesOnDefaultCharges?: Maybe<Array<ChargesPayload>>;
  assignedLocationsOnDefaultCharges?: Maybe<Array<CostTrackingLocation>>;
  /** The current user's access level. */
  authorization: Authorization;
  backofficeBookings: Array<Booking>;
  bookingDocumentTemplates: Array<BookingDocumentTemplate>;
  bookingDocumentTypes: BookingDocumentTypeConnection;
  bookingIssue: BookingIssue;
  bookingIssuesShipmentStatistics: IssuesStatistics;
  bookingManagerAssignmentRules: BookingManagerAssignmentRuleConnection;
  bookingManagers: Array<BookingManager>;
  /** Query bookings */
  bookings: BookingConnection;
  businessKeys?: Maybe<WorkflowBusinessKeyConnection>;
  cargoDailyAverageUtilisation?: Maybe<Array<DailyAverageUtilisation>>;
  cargoSupplierAverageUtilisations?: Maybe<Array<AverageUtilisation>>;
  cargoUtilisationTargets?: Maybe<Array<UtilisationTarget>>;
  cargoUtilisations?: Maybe<UtilisationConnection>;
  chargeType?: Maybe<ChargeType>;
  chargeTypes: ChargeTypeConnection;
  /** checks for similar accounts based on the provided contact email and account name */
  checkForDuplicateAccounts?: Maybe<CheckForDuplicateAccountsPayload>;
  commodityCodes: CommodityCodesConnection;
  consolidations?: Maybe<ConsolidatedShipmentConnection>;
  contact: NetworkContact;
  contacts?: Maybe<NetworkContactConnection>;
  coreCargo?: Maybe<CoreCargo>;
  countries?: Maybe<Array<Country>>;
  courier?: Maybe<Courier>;
  couriers?: Maybe<CourierConnection>;
  currentUser?: Maybe<CurrentUser>;
  currentUserDetails?: Maybe<AccountMember>;
  customRole?: Maybe<CustomRole>;
  customRoles?: Maybe<Array<CustomRole>>;
  customers?: Maybe<CustomersCustomerTypeConnection>;
  documentTemplate: DocumentTemplate;
  documentTemplateFields: Array<DocumentTemplateField>;
  documentTemplates: Array<DocumentTemplate>;
  enterpriseConnectionNames?: Maybe<Array<Scalars['String']>>;
  eventParameters?: Maybe<EventParameterPayloadType>;
  events?: Maybe<EventPayloadType>;
  exchangeRates: Array<ExchangeRate>;
  filters: FiltersFiltersType;
  financeAdjustedExchangeRates?: Maybe<Array<AdjustedExchangeRate>>;
  financeSpendAnalytics: Array<Spend>;
  financeSpendAnalyticsStartDate?: Maybe<SpendAnalyticsStartDate>;
  forwarders?: Maybe<LocationAssignableConnection>;
  getActivePortCharges?: Maybe<PortChargeConnection>;
  getAllCharges?: Maybe<Array<ChargesPayload>>;
  getAllUserSettings?: Maybe<Array<UserSettings>>;
  getCargoOptions?: Maybe<Array<Maybe<CargoOptions>>>;
  getChargeBasis: Array<Maybe<ChargeBasis>>;
  getChargeGroups?: Maybe<Array<ChargeGroup>>;
  getCharges?: Maybe<ChargeConnection>;
  getContract?: Maybe<ContractPayload>;
  getContracts?: Maybe<ContractConnection>;
  getCostTrackingLocations?: Maybe<CostTrackingLocationConnection>;
  getCostTrackingTotalBreakdown?: Maybe<GetCostTrackingTotalBreakdownPayload>;
  getCurrentPortCharges?: Maybe<PortChargeConnection>;
  getDefaultCharges?: Maybe<DefaultChargesConnection>;
  getDefaultChargesForRateCard?: Maybe<Array<DefaultChargesPayload>>;
  getLocation?: Maybe<CostTrackingLocation>;
  getParsedCommercialInvoice?: Maybe<ParsedCommercialInvoiceResult>;
  getRateCard?: Maybe<RateCardPayload>;
  getRateCardValidityDates?: Maybe<RateCardValidityDates>;
  getRateCards?: Maybe<RateCardSummaryConnection>;
  getUserSettings?: Maybe<UserSettings>;
  globalAddress?: Maybe<GlobalAddress>;
  globalAddresses?: Maybe<GlobalAddressesConnection>;
  globalPermissions: GlobalPermissions;
  globalSearch?: Maybe<Array<Search>>;
  haulier?: Maybe<Haulier>;
  hauliers?: Maybe<HaulierConnection>;
  isValidUserDefinedEvent?: Maybe<Scalars['Boolean']>;
  issue?: Maybe<Issue>;
  issueTemplates?: Maybe<IssueTemplateConnection>;
  journeyMilestoneDateLog?: Maybe<JourneyMilestoneDateLogConnection>;
  journeyShippingMilestoneDatesValidation: JourneyShippingMilestoneDatesValidationPayload;
  /** Query information about the account */
  legacyAccount?: Maybe<LegacyAccount>;
  legacyAccounts: LegacyAccountConnection;
  legacyBookingIssues?: Maybe<LegacyBookingIssueConnection>;
  legacyGetRateCard?: Maybe<LegacyRateCard>;
  legacyGetRateCards?: Maybe<LegacyRateCardConnection>;
  legacyGetValidityDates?: Maybe<LegacyCustomerValidityDates>;
  legacyGlobalPermissions: LegacyGlobalPermissions;
  legacyPurchaseOrderIssues?: Maybe<IssuesPurchaseOrderIssueConnection>;
  liveViewsGlobalSearch: LiveViewsGlobalSearchResult;
  location?: Maybe<NetworksLocation>;
  locationContact?: Maybe<NetworksContact>;
  /** The user's current account memberships */
  members: Array<Member>;
  /** Query your network */
  network?: Maybe<Network>;
  networksUsedAssignables?: Maybe<Array<NetworksAssignableInterface>>;
  note?: Maybe<Note>;
  notifications?: Maybe<Notifications>;
  oceanCarrier?: Maybe<OceanCarrier>;
  oceanCarrierByScac?: Maybe<OceanCarrier>;
  oceanCarriers?: Maybe<OceanCarrierConnection>;
  packagingTypes: PackagingTypesPackagingTypeConnection;
  /** Query packing lists */
  packingLists?: Maybe<PackingListConnection>;
  platformPermissions?: Maybe<Array<CustomRolePermission>>;
  /** Query Product Categories */
  productCategories: Array<ProductCategory>;
  /** Query Products and associated information */
  products: ProductConnection;
  purchaseOrderDelayReasons: PurchaseOrderDelayReasonConnection;
  purchaseOrderIssue: PurchaseOrderIssue;
  purchaseOrderIssuesStatistics: IssuesStatistics;
  purchaseOrderOrderedLineItems: OrderedLineItemConnection;
  /** Query purchase orders */
  purchaseOrders: PurchaseOrderConnection;
  purchaseOrdersLots?: Maybe<LotConnection>;
  purchaseOrdersOriginAgentAssignmentRules: OriginAgentAssignmentRuleConnection;
  railwayCompanies?: Maybe<RailwayCompanyConnection>;
  railwayCompany?: Maybe<RailwayCompany>;
  roadTrackedShipmentFilterOptions: RoadTrackedShipmentFilterOptions;
  roadTrackedShipmentFilters: Array<RoadTrackedShipmentFilter>;
  roadTrackedShipments?: Maybe<RoadTrackedShipmentConnection>;
  rulesEngine: Engine;
  searchAccountAndConnectionLocations?: Maybe<AccountLocationConnection>;
  searchAssignableAccounts?: Maybe<AccountConnectionTypeConnection>;
  shipmentActionTypes: ShipmentActionTypeConnection;
  shipmentDelayReasons: ShipmentDelayReasonConnection;
  shipmentsTableBookingRequests: ShipmentsTableBookingRequests;
  shipmentsTableCargoGroups?: Maybe<ShipmentsTableCargoGroupsConnection>;
  shipmentsTableCollectionLocationFilterValues: ShipmentsTableFilterValueConnection;
  shipmentsTableDeliveryLocationFilterValues: ShipmentsTableFilterValueConnection;
  shipmentsTableFilterValues: ShipmentsTableFilterValues;
  shipmentsTablePortOfDestinationFilterValues: ShipmentsTableFilterValueConnection;
  shipmentsTablePortOfLoadingFilterValues: ShipmentsTableFilterValueConnection;
  shipmentsTableProductCategoryFilterValues: ShipmentsTableFilterValueConnection;
  shipmentsTableProductsFilterValues: ShipmentsTableFilterValueConnection;
  shippingDelayReason?: Maybe<ShippingDelayReason>;
  shippingDelayReasons?: Maybe<ShippingDelayReasonConnection>;
  taskById?: Maybe<WorkflowTaskType>;
  taskDetails?: Maybe<WorkflowTaskDetailsPayloadType>;
  /** @deprecated Field no longer supported */
  tempRoadTrackedShipments?: Maybe<RoadTrackedShipmentConnection>;
  /** Query terminals */
  terminals?: Maybe<TerminalConnection>;
  uniqueTaskNames: Array<Scalars['String']>;
  userDefinedEvents?: Maybe<UserDefinedEventConnection>;
  usersWithNoRole?: Maybe<Array<NetworksContact>>;
  warehousingViewCalendarSlots?: Maybe<Array<WarehousingViewCalendarSlot>>;
  warehousingViewCargoAggregates?: Maybe<Array<WarehousingViewCargoAggregate>>;
  warehousingViewLocations?: Maybe<Array<WarehousingViewLocation>>;
  webhookSubscriptionsWebhooks: Array<WebhookSubscriptionsWebhook>;
  workflowModelById?: Maybe<WorkflowModelType>;
  workflowModels?: Maybe<WorkflowModelsPayloadType>;
  workflowRuleById: WorkflowRuleType;
  workflowRuleDefinitionById: WorkflowRuleDefinitionType;
  workflowRuleDefinitions: Array<Maybe<WorkflowRuleDefinitionType>>;
  workflowRuleFieldInputOptions: Array<WorkflowRuleFieldType>;
  workflowRuleFieldOutputOptions: Array<WorkflowRuleFieldType>;
  workflowRules?: Maybe<WorkflowRuleConnection>;
  zencargoNetwork?: Maybe<Network>;
}

/** Queries */
export type QueryAccountArgs = {
  accountId: Scalars['String'];
};

/** Queries */
export type QueryAccountBusinessUnitsArgs = {
  accountId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
};

/** Queries */
export type QueryAccountCompaniesArgs = {
  accountId: Scalars['String'];
};

/** Queries */
export type QueryAccountConnectionsArgs = {
  accountConnectionsFiltersInput: AccountConnectionsFiltersInput;
  accountId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryAccountHeadquartersArgs = {
  accountId: Scalars['String'];
  companyName?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryAccountLocationArgs = {
  locationId: Scalars['String'];
};

/** Queries */
export type QueryAccountLocationsArgs = {
  accountId: Scalars['String'];
  accountLocationsFiltersInput?: InputMaybe<AccountLocationsFiltersInput>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
};

/** Queries */
export type QueryAccountMemberArgs = {
  memberId: Scalars['String'];
};

/** Queries */
export type QueryAccountMembersArgs = {
  accountId: Scalars['String'];
  accountMembersFiltersInput?: InputMaybe<AccountMembersFiltersInput>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
};

/** Queries */
export type QueryAccountUserGroupsArgs = {
  accountId: Scalars['String'];
};

/** Queries */
export type QueryAccountsArgs = {
  accountsFiltersInput?: InputMaybe<AccountsFiltersInput>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  nestedBusinessUnits?: InputMaybe<Scalars['Boolean']>;
  order?: InputMaybe<SortInput>;
};

/** Queries */
export type QueryAccrualTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryActionItemTypesArgs = {
  order?: InputMaybe<SortInput>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryAirlineCarrierArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryAirlineCarriersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryAssignablePrivilegesArgs = {
  organisationId: Scalars['String'];
  roleId: Scalars['String'];
};

/** Queries */
export type QueryAssignedChargesOnDefaultChargesArgs = {
  applicability?: InputMaybe<Applicability>;
};

/** Queries */
export type QueryAssignedLocationsOnDefaultChargesArgs = {
  applicability?: InputMaybe<Applicability>;
};

/** Queries */
export type QueryBackofficeBookingsArgs = {
  activeOnly?: InputMaybe<Scalars['Boolean']>;
  bookingManagerId?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryBookingDocumentTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryBookingIssueArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryBookingIssuesShipmentStatisticsArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  bookingManagerIds?: InputMaybe<Array<Scalars['String']>>;
  onlySubscribed?: InputMaybe<Scalars['Boolean']>;
};

/** Queries */
export type QueryBookingManagerAssignmentRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryBookingManagersArgs = {
  managerType?: InputMaybe<ManagerTeam>;
};

/** Queries */
export type QueryBookingsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  availableForConsolidation?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  consignees?: InputMaybe<Array<Scalars['String']>>;
  consignors?: InputMaybe<Array<Scalars['String']>>;
  consolidatedShipmentId?: InputMaybe<Scalars['String']>;
  customerUuid?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customsOnly?: InputMaybe<Array<InputMaybe<CustomsOnlyFilterEnum>>>;
  destinationCountries?: InputMaybe<Array<Scalars['String']>>;
  destinations?: InputMaybe<Array<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  forwarders?: InputMaybe<Array<Scalars['String']>>;
  incoterms?: InputMaybe<Array<InputMaybe<IncotermsValue>>>;
  issues?: InputMaybe<IssuesFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
  onlyBookingRequests?: InputMaybe<Scalars['Boolean']>;
  onlyManufacturerRequested?: InputMaybe<Scalars['Boolean']>;
  onlyQuotes?: InputMaybe<Scalars['Boolean']>;
  onlyReadyForApproval?: InputMaybe<Scalars['Boolean']>;
  onlyRejected?: InputMaybe<Scalars['Boolean']>;
  onlyShipments?: InputMaybe<Scalars['Boolean']>;
  onlySingleCargo?: InputMaybe<Scalars['Boolean']>;
  operationsManagers?: InputMaybe<Array<Scalars['String']>>;
  order?: InputMaybe<SortInput>;
  originCountries?: InputMaybe<Array<Scalars['String']>>;
  origins?: InputMaybe<Array<Scalars['String']>>;
  showExceptions?: InputMaybe<Scalars['Boolean']>;
  showInTransit?: InputMaybe<Scalars['Boolean']>;
  showPinned?: InputMaybe<Scalars['Boolean']>;
  showSubscribed?: InputMaybe<Scalars['Boolean']>;
  stages?: InputMaybe<Array<InputMaybe<StageValue>>>;
  textContains?: InputMaybe<Scalars['String']>;
  transportModes?: InputMaybe<Array<InputMaybe<ModeOfTransport>>>;
  vesselNames?: InputMaybe<Array<Scalars['String']>>;
  withPendingActionItems?: InputMaybe<Scalars['Boolean']>;
  withSteps?: InputMaybe<Array<BookingStep>>;
  withinTimeRange?: InputMaybe<WithinTimeRangeInput>;
  zencargoReferences?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryBusinessKeysArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  businessKeyLike?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Queries */
export type QueryCargoDailyAverageUtilisationArgs = {
  customerAccountId?: InputMaybe<Scalars['String']>;
  dateRange: DateRangeInput;
  supplierId?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryCargoSupplierAverageUtilisationsArgs = {
  customerAccountId?: InputMaybe<Scalars['String']>;
  dateRange: DateRangeInput;
  groupBy?: InputMaybe<AverageUtilisationGroupByEnum>;
  order?: InputMaybe<SortInput>;
};

/** Queries */
export type QueryCargoUtilisationTargetsArgs = {
  accountUuid: Scalars['String'];
};

/** Queries */
export type QueryCargoUtilisationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerAccountId?: InputMaybe<Scalars['String']>;
  dateRange: DateRangeInput;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  supplierId: Scalars['String'];
};

/** Queries */
export type QueryChargeTypeArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryChargeTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  basis?: InputMaybe<ChargeBasisEnum>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  textContains?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<ChargeTypeEnum>;
};

/** Queries */
export type QueryCheckForDuplicateAccountsArgs = {
  input: CheckForDuplicateAccountsInput;
};

/** Queries */
export type QueryCommodityCodesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryConsolidationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  consolidatedShipmentId?: InputMaybe<Scalars['String']>;
  customerUuid?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryContactArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  statuses?: InputMaybe<Array<ContactStatus>>;
  textContains?: InputMaybe<Scalars['String']>;
  types?: InputMaybe<Array<ContactTypeEnum>>;
};

/** Queries */
export type QueryCoreCargoArgs = {
  cargoId: Scalars['String'];
};

/** Queries */
export type QueryCourierArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryCouriersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryCustomRoleArgs = {
  id: Scalars['String'];
  organizationId: Scalars['String'];
};

/** Queries */
export type QueryCustomRolesArgs = {
  organizationId: Scalars['String'];
  search?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryCustomersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  searchContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryDocumentTemplateArgs = {
  id: Scalars['Int'];
};

/** Queries */
export type QueryEventParametersArgs = {
  eventId?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Queries */
export type QueryFiltersArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  accountUuid?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryFinanceSpendAnalyticsArgs = {
  currency?: InputMaybe<Currency>;
  customerId: Scalars['String'];
  dateRange: DateRangeInput;
  destination?: InputMaybe<Array<Scalars['String']>>;
  origin?: InputMaybe<Array<Scalars['String']>>;
  transportMode?: InputMaybe<Array<ModeOfTransport>>;
};

/** Queries */
export type QueryFinanceSpendAnalyticsStartDateArgs = {
  customerId: Scalars['String'];
  destination?: InputMaybe<Array<Scalars['String']>>;
  origin?: InputMaybe<Array<Scalars['String']>>;
  transportMode?: InputMaybe<Array<ModeOfTransport>>;
};

/** Queries */
export type QueryForwardersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  networkId: Scalars['String'];
  searchContains: LocationSearchInput;
};

/** Queries */
export type QueryGetActivePortChargesArgs = {
  activePortChargeFilters?: InputMaybe<Array<PortChargeFilter>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Queries */
export type QueryGetAllChargesArgs = {
  applicability?: InputMaybe<Applicability>;
  chargeGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** Queries */
export type QueryGetCargoOptionsArgs = {
  cargoType: CargoModeEnum;
  modeOfTransport: ModeOfTransport;
};

/** Queries */
export type QueryGetChargeBasisArgs = {
  cargoType?: InputMaybe<CargoModeEnum>;
  chargeBasisGroup?: InputMaybe<ChargeBasisGroup>;
  modeOfTransport?: InputMaybe<ModeOfTransport>;
};

/** Queries */
export type QueryGetChargesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chargeGroupIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ChargeSorting>;
};

/** Queries */
export type QueryGetContractArgs = {
  zencargoReference: Scalars['String'];
};

/** Queries */
export type QueryGetContractsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryGetCostTrackingLocationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  costTrackingLocationType?: InputMaybe<CostTrackingLocationType>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryGetCostTrackingTotalBreakdownArgs = {
  breakdownType: BreakdownType;
  costTrackingId: Scalars['String'];
  displayCurrency?: InputMaybe<Currency>;
  zencargoReference: Scalars['String'];
};

/** Queries */
export type QueryGetCurrentPortChargesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  chargeTypes?: InputMaybe<Array<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  incoterms?: InputMaybe<Array<IncotermsValue>>;
  last?: InputMaybe<Scalars['Int']>;
  portChargeType?: InputMaybe<OriginDestinationChargeTypeEnum>;
  ports?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryGetDefaultChargesArgs = {
  after?: InputMaybe<Scalars['String']>;
  applicabilityTraits: Array<ApplicabilityTraits>;
  before?: InputMaybe<Scalars['String']>;
  chargeTypeId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryGetDefaultChargesForRateCardArgs = {
  freightLanes: Array<FreightLane>;
};

/** Queries */
export type QueryGetLocationArgs = {
  id?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryGetParsedCommercialInvoiceArgs = {
  documentId: Scalars['ID'];
};

/** Queries */
export type QueryGetRateCardArgs = {
  id: Scalars['String'];
  locationsFilter?: InputMaybe<RateCardLocationsFilterInput>;
};

/** Queries */
export type QueryGetRateCardValidityDatesArgs = {
  cargoType: CargoModeEnum;
  customerId: Scalars['String'];
  modeOfTransport: ModeOfTransport;
};

/** Queries */
export type QueryGetRateCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cargoType?: InputMaybe<CargoModeEnum>;
  customerId: Scalars['String'];
  destinationLocationId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  originLocationId?: InputMaybe<Scalars['String']>;
  validOn?: InputMaybe<Scalars['Date']>;
};

/** Queries */
export type QueryGetUserSettingsArgs = {
  name: Scalars['String'];
};

/** Queries */
export type QueryGlobalAddressArgs = {
  addressId: Scalars['String'];
};

/** Queries */
export type QueryGlobalAddressesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  includeArchived?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  searchQuery?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryGlobalSearchArgs = {
  searchTerm: Scalars['String'];
};

/** Queries */
export type QueryHaulierArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryHauliersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryIsValidUserDefinedEventArgs = {
  id?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryIssueArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryIssueTemplatesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  ruleIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryJourneyMilestoneDateLogArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  milestoneId: Scalars['String'];
};

/** Queries */
export type QueryJourneyShippingMilestoneDatesValidationArgs = {
  input: JourneyShippingMilestoneDatesValidationInput;
};

/** Queries */
export type QueryLegacyAccountArgs = {
  uuid: Scalars['String'];
};

/** Queries */
export type QueryLegacyAccountsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryLegacyBookingIssuesArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookingManagerIds?: InputMaybe<Array<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<BookingIssueSortInput>;
};

/** Queries */
export type QueryLegacyGetRateCardArgs = {
  customerId?: InputMaybe<Scalars['String']>;
  destinationLocations?: InputMaybe<Array<Scalars['String']>>;
  destinationPorts?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  incoterms?: InputMaybe<Array<IncotermsValue>>;
  originLocations?: InputMaybe<Array<Scalars['String']>>;
  originPorts?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryLegacyGetRateCardsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  cargoType?: InputMaybe<CargoModeEnum>;
  customerDivisionId: Scalars['String'];
  destinationPort?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  originPort?: InputMaybe<Scalars['String']>;
  validOn?: InputMaybe<Scalars['Date']>;
};

/** Queries */
export type QueryLegacyGetValidityDatesArgs = {
  cargoType: CargoModeEnum;
  customerDivisionId: Scalars['String'];
  modeOfTransport: ModeOfTransport;
};

/** Queries */
export type QueryLegacyPurchaseOrderIssuesArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<PurchaseOrderIssueSortInput>;
};

/** Queries */
export type QueryLiveViewsGlobalSearchArgs = {
  searchTerm: Scalars['String'];
};

/** Queries */
export type QueryLocationArgs = {
  locationId: Scalars['String'];
  organisationId: Scalars['String'];
};

/** Queries */
export type QueryLocationContactArgs = {
  contactId: Scalars['String'];
  organisationId: Scalars['String'];
};

/** Queries */
export type QueryNetworkArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryNetworksUsedAssignablesArgs = {
  assignableIds?: InputMaybe<Array<Scalars['String']>>;
  assignmentNames: Array<AssignmentTypeValue>;
  networkIds?: InputMaybe<Array<Scalars['String']>>;
  targetTypes: Array<AssignmentTargetTypeEnum>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryNoteArgs = {
  id: Scalars['String'];
  type: Scalars['String'];
};

/** Queries */
export type QueryOceanCarrierArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryOceanCarrierByScacArgs = {
  scac: Scalars['String'];
};

/** Queries */
export type QueryOceanCarriersArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryPackagingTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryPackingListsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  findBy: PackingListFindBy;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryProductCategoriesArgs = {
  customerId?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  archived?: InputMaybe<Scalars['Boolean']>;
  before?: InputMaybe<Scalars['String']>;
  customerId?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  nameContains?: InputMaybe<Scalars['String']>;
  skuContains?: InputMaybe<Scalars['String']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryPurchaseOrderDelayReasonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryPurchaseOrderIssueArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryPurchaseOrderIssuesStatisticsArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryPurchaseOrderOrderedLineItemsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  purchaseOrderId: Scalars['String'];
};

/** Queries */
export type QueryPurchaseOrdersArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  availableForBooking?: InputMaybe<Scalars['String']>;
  availableForCargo?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  bookingState?: InputMaybe<Array<PurchaseOrderBookingStateEnum>>;
  cargoReadyDate?: InputMaybe<Scalars['Int']>;
  cargoReadyDateBetween?: InputMaybe<DateRangeInput>;
  customerUuid?: InputMaybe<Scalars['String']>;
  customerUuids?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  filterBy?: InputMaybe<FilterOptionsEnum>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  incoterms?: InputMaybe<Scalars['String']>;
  issues?: InputMaybe<PurchaseOrdersIssuesFilterInput>;
  last?: InputMaybe<Scalars['Int']>;
  lastUpdatedBetween?: InputMaybe<DateRangeInput>;
  manufacturerIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  order?: InputMaybe<SortInput>;
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  portOfDestinationUnlocode?: InputMaybe<Scalars['String']>;
  portOfLoadUnlocode?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PurchaseOrderStatusEnum>;
  textContains?: InputMaybe<Scalars['String']>;
  zencargoReferences?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryPurchaseOrdersLotsArgs = {
  after?: InputMaybe<Scalars['String']>;
  availableForBooking?: InputMaybe<Scalars['String']>;
  availableForCargo?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  ids?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  onlyNotBooked?: InputMaybe<Scalars['Boolean']>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  purchaseOrderIds?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryPurchaseOrdersOriginAgentAssignmentRulesArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  destinations?: InputMaybe<Array<Scalars['String']>>;
  first?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['String']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  originAgentIds?: InputMaybe<Array<Scalars['String']>>;
  origins?: InputMaybe<Array<Scalars['String']>>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  transportModes?: InputMaybe<Array<ModeOfTransport>>;
};

/** Queries */
export type QueryRailwayCompaniesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryRailwayCompanyArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryRoadTrackedShipmentFilterOptionsArgs = {
  customerId: Scalars['String'];
};

/** Queries */
export type QueryRoadTrackedShipmentFiltersArgs = {
  active: RoadTrackedShipmentActive;
  customerId: Scalars['String'];
  event?: InputMaybe<RoadTrackedShipmentEvent>;
  firstStopCountryCodes?: InputMaybe<Array<Scalars['String']>>;
  lastStopCountryCodes?: InputMaybe<Array<Scalars['String']>>;
};

/** Queries */
export type QueryRoadTrackedShipmentsArgs = {
  active: RoadTrackedShipmentActive;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  event?: InputMaybe<RoadTrackedShipmentEvent>;
  first?: InputMaybe<Scalars['Int']>;
  firstStopCountryCodes?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  lastStopCountryCodes?: InputMaybe<Array<Scalars['String']>>;
  status: RoadTrackedShipmentStatus;
};

/** Queries */
export type QuerySearchAccountAndConnectionLocationsArgs = {
  accountId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchAccountAndConnectionLocationsFiltersInput: SearchAccountAndConnectionLocationsFiltersInput;
};

/** Queries */
export type QuerySearchAssignableAccountsArgs = {
  accountId: Scalars['String'];
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchAssignableAccountsFiltersInput: SearchAssignableAccountsFiltersInput;
};

/** Queries */
export type QueryShipmentActionTypesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  paginated?: InputMaybe<Scalars['Boolean']>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShipmentDelayReasonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  textContains?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShipmentsTableBookingRequestsArgs = {
  accountIds: Array<Scalars['String']>;
};

/** Queries */
export type QueryShipmentsTableCargoGroupsArgs = {
  accountIds: Array<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableCargoGroupsFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  groupBy: ShipmentsTableCargoGroupsGroupByColumn;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<Array<ShipmentsTableCargoGroupsSortByInput>>;
};

/** Queries */
export type QueryShipmentsTableCollectionLocationFilterValuesArgs = {
  accountIds: Array<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShipmentsTableDeliveryLocationFilterValuesArgs = {
  accountIds: Array<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShipmentsTableFilterValuesArgs = {
  accountIds: Array<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableFiltersInput>;
};

/** Queries */
export type QueryShipmentsTablePortOfDestinationFilterValuesArgs = {
  accountIds: Array<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShipmentsTablePortOfLoadingFilterValuesArgs = {
  accountIds: Array<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShipmentsTableProductCategoryFilterValuesArgs = {
  accountIds: Array<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShipmentsTableProductsFilterValuesArgs = {
  accountIds: Array<Scalars['String']>;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  filterBy?: InputMaybe<ShipmentsTableFiltersInput>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryShippingDelayReasonArgs = {
  id: Scalars['String'];
};

/** Queries */
export type QueryShippingDelayReasonsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  isPaginated?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
};

/** Queries */
export type QueryTaskByIdArgs = {
  taskId: Scalars['String'];
};

/** Queries */
export type QueryTaskDetailsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  taskDetailsFiltersInput?: InputMaybe<TaskDetailsFiltersInput>;
};

/** Queries */
export type QueryTempRoadTrackedShipmentsArgs = {
  active: RoadTrackedShipmentActive;
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  event?: InputMaybe<RoadTrackedShipmentEvent>;
  first?: InputMaybe<Scalars['Int']>;
  firstStopCountryCodes?: InputMaybe<Array<Scalars['String']>>;
  last?: InputMaybe<Scalars['Int']>;
  lastStopCountryCodes?: InputMaybe<Array<Scalars['String']>>;
  status: RoadTrackedShipmentStatus;
};

/** Queries */
export type QueryTerminalsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  fuzzySearch?: InputMaybe<Scalars['Boolean']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  query: Scalars['String'];
  terminalType?: InputMaybe<TerminalTypeValue>;
  terminalTypes?: InputMaybe<Array<TerminalTypeValue>>;
};

/** Queries */
export type QueryUserDefinedEventsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
};

/** Queries */
export type QueryUsersWithNoRoleArgs = {
  organisationId?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryWarehousingViewCalendarSlotsArgs = {
  accountId: Scalars['String'];
  from: Scalars['ISO8601Date'];
  to: Scalars['ISO8601Date'];
  warehouseId: Scalars['String'];
};

/** Queries */
export type QueryWarehousingViewCargoAggregatesArgs = {
  accountId: Scalars['String'];
  from: Scalars['ISO8601Date'];
  to: Scalars['ISO8601Date'];
  warehouseId: Scalars['String'];
};

/** Queries */
export type QueryWarehousingViewLocationsArgs = {
  accountId: Scalars['String'];
  from: Scalars['ISO8601Date'];
  to: Scalars['ISO8601Date'];
};

/** Queries */
export type QueryWebhookSubscriptionsWebhooksArgs = {
  accountIds?: InputMaybe<Array<Scalars['String']>>;
  customerAccountId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryWorkflowModelByIdArgs = {
  workflowModelId: Scalars['String'];
};

/** Queries */
export type QueryWorkflowModelsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
  searchQuery?: InputMaybe<Scalars['String']>;
};

/** Queries */
export type QueryWorkflowRuleByIdArgs = {
  workflowRuleId: Scalars['String'];
};

/** Queries */
export type QueryWorkflowRuleDefinitionByIdArgs = {
  workflowRuleDefinitionId: Scalars['String'];
};

/** Queries */
export type QueryWorkflowRulesArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  workflowRuleDefinitionId: Scalars['String'];
  workflowRulesInputObjectFiltersInput?: InputMaybe<WorkflowRulesInputObjectFiltersInput>;
  workflowRulesOutputObjectFiltersInput?: InputMaybe<WorkflowRulesOutputObjectFiltersInput>;
};

export interface QuoteOption {
  __typename?: 'QuoteOption';
  /** Id of accrual */
  accrualId?: Maybe<Scalars['String']>;
  /** Description of the quote */
  description?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<RichDate>;
  /** Id of quote option */
  id?: Maybe<Scalars['String']>;
  /** Unique reference of the quote option */
  quoteReference?: Maybe<Scalars['String']>;
  reasonDescription?: Maybe<Scalars['String']>;
  rejectionReason?: Maybe<QuoteOptionRejectionReason>;
  /** State of the quote option */
  state?: Maybe<QuoteOptionState>;
  /** Total value of the quote option */
  total?: Maybe<Money>;
  /** The zencargo reference of the booking */
  zencargoReference: Scalars['String'];
}

export enum QuoteOptionRejectionReason {
  ACCEPTED_ANOTHER_QUOTE = 'accepted_another_quote',
  OTHER = 'other',
  PRICE = 'price',
  RESPONSE_TIME = 'response_time',
  SCHEDULE = 'schedule',
  UNKNOWN = 'unknown'
}

export enum QuoteOptionState {
  ACCEPTED = 'accepted',
  ACTIVE = 'active',
  ARCHIVED = 'archived',
  DRAFT = 'draft',
  EXPIRED = 'expired',
  REJECTED = 'rejected'
}

export interface Quotes {
  __typename?: 'Quotes';
  canAccessRoute: AuthorizationResult;
}

export interface RailwayCompany {
  __typename?: 'RailwayCompany';
  id: Scalars['String'];
  railwayCompany: Scalars['String'];
}

export interface RailwayCompanyConnection {
  __typename?: 'RailwayCompanyConnection';
  edges?: Maybe<Array<RailwayCompanyEdge>>;
  nodes?: Maybe<Array<RailwayCompany>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface RailwayCompanyEdge {
  __typename?: 'RailwayCompanyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<RailwayCompany>;
}

export interface RateCard {
  __typename?: 'RateCard';
  cargoType: CargoModeEnum;
  charges: Array<RateCardCharge>;
  customerId: Scalars['String'];
  endDate: Scalars['Date'];
  issuedAt: Scalars['Date'];
  issuedBy?: Maybe<NetworksContact>;
  modeOfTransport: ModeOfTransport;
  name: Scalars['String'];
  note?: Maybe<Scalars['String']>;
  rateCardId: Scalars['String'];
  startDate: Scalars['Date'];
  updatedBy?: Maybe<NetworksContact>;
}

export interface RateCardCharge {
  __typename?: 'RateCardCharge';
  applicability: Array<Applicability>;
  cargoOptions: Array<CargoOptionEnum>;
  chargeBasis: Array<ChargeBasis>;
  chargeClassification: ChargeClassification;
  chargeType: ChargesPayload;
  currency: Currency;
  defaultCharge?: Maybe<DefaultChargesPayload>;
  defaultChargeHidden?: Maybe<Scalars['Boolean']>;
  fromLocation?: Maybe<CostTrackingLocation>;
  id: Scalars['String'];
  itemName: Scalars['String'];
  toLocation?: Maybe<CostTrackingLocation>;
  unitPrice: Scalars['Float'];
}

export enum RateCardChargeType {
  FREIGHT = 'FREIGHT',
  HAULAGE = 'HAULAGE',
  OTHER = 'OTHER',
  TERMINAL = 'TERMINAL'
}

export interface RateCardGlobalPermissions {
  __typename?: 'RateCardGlobalPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
  canCreate?: Maybe<AuthorizationResult>;
  canDelete?: Maybe<AuthorizationResult>;
}

export interface RateCardLocationsFilterInput {
  destinationLocationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  destinationPortIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  originLocationIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  originPortIds?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface RateCardPayload {
  __typename?: 'RateCardPayload';
  locationsAssigned: LocationsAssignedToRateCard;
  rateCard: RateCard;
}

export interface RateCardSummary {
  __typename?: 'RateCardSummary';
  cargoType: CargoModeEnum;
  endDate: Scalars['Date'];
  modeOfTransport: ModeOfTransport;
  name: Scalars['String'];
  rateCardId: Scalars['String'];
  startDate: Scalars['Date'];
}

export interface RateCardSummaryConnection {
  __typename?: 'RateCardSummaryConnection';
  edges?: Maybe<Array<Maybe<RateCardSummaryEdge>>>;
  nodes?: Maybe<Array<Maybe<RateCardSummary>>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface RateCardSummaryEdge {
  __typename?: 'RateCardSummaryEdge';
  cursor: Scalars['String'];
  node?: Maybe<RateCardSummary>;
}

export interface RateCardValidityDates {
  __typename?: 'RateCardValidityDates';
  validityDates: Array<DateRange>;
}

export interface ReactivateUserPayload {
  __typename?: 'ReactivateUserPayload';
  errors?: Maybe<Array<Error>>;
}

export interface ReasonForChange {
  __typename?: 'ReasonForChange';
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}

export interface ReasonForChangeInput {
  reasonCategory?: InputMaybe<Scalars['String']>;
  reasonDescription?: InputMaybe<Scalars['String']>;
  reasonId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of Reconfirm */
export interface ReconfirmInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of Reconfirm */
export interface ReconfirmPayload {
  __typename?: 'ReconfirmPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of Reject */
export interface RejectInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  rejectedAdditionalInfo?: InputMaybe<Scalars['String']>;
  rejectedReason: RejectedReasonEnum;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of Reject */
export interface RejectPayload {
  __typename?: 'RejectPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RejectQuoteOption */
export interface RejectQuoteOptionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  quoteOptionId: Scalars['String'];
}

/** Autogenerated return type of RejectQuoteOption */
export interface RejectQuoteOptionPayload {
  __typename?: 'RejectQuoteOptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quoteOption?: Maybe<QuoteOption>;
}

export enum RejectedReasonEnum {
  FAILED_QC_INSPECTION = 'failed_qc_inspection',
  INCOTERM = 'incoterm',
  OTHER = 'other',
  POS_ATTACHED_OR_ITEMS = 'pos_attached_or_items',
  SHIPMENT_MODE = 'shipment_mode',
  SHIPMENT_SAMPLE_NOT_APPROVED = 'shipment_sample_not_approved',
  SHIPMENT_SAMPLE_NOT_RECEIVED = 'shipment_sample_not_received',
  SHIPPING_TOO_EARLY = 'shipping_too_early',
  SHIPPING_TOO_LATE = 'shipping_too_late',
  UNITS_BOOKED_EXCEED_ORDERED_UNITS = 'units_booked_exceed_ordered_units',
  UNITS_BOOKED_LESS_THAN_ORDERED_UNITS = 'units_booked_less_than_ordered_units',
  WRONGLY_ENTERED_DATA = 'wrongly_entered_data'
}

export enum Relation {
  CONSOLIDATION = 'CONSOLIDATION',
  PACK = 'PACK',
  REPACK = 'REPACK'
}

/** Autogenerated input type of RemoveAssignment */
export interface RemoveAssignmentInput {
  assignmentName: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: AssignmentTargetTypeEnum;
}

/** Autogenerated return type of RemoveAssignment */
export interface RemoveAssignmentPayload {
  __typename?: 'RemoveAssignmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RemoveAssignments */
export interface RemoveAssignmentsInput {
  assignmentNames: Array<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: AssignmentTargetTypeEnum;
}

/** Autogenerated return type of RemoveAssignments */
export interface RemoveAssignmentsPayload {
  __typename?: 'RemoveAssignmentsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RemoveBookingManagerAssignmentRule */
export interface RemoveBookingManagerAssignmentRuleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of RemoveBookingManagerAssignmentRule */
export interface RemoveBookingManagerAssignmentRulePayload {
  __typename?: 'RemoveBookingManagerAssignmentRulePayload';
  bookingManagerAssignmentRule?: Maybe<BookingManagerAssignmentRule>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RemoveCollectionSchedule */
export interface RemoveCollectionScheduleInput {
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of RemoveCollectionSchedule */
export interface RemoveCollectionSchedulePayload {
  __typename?: 'RemoveCollectionSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  events: Array<BookingAggregatesCollectionAndDeliveryEventType>;
}

/** Autogenerated input type of RemoveDeliverySchedule */
export interface RemoveDeliveryScheduleInput {
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of RemoveDeliverySchedule */
export interface RemoveDeliverySchedulePayload {
  __typename?: 'RemoveDeliverySchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  events: Array<BookingAggregatesCollectionAndDeliveryEventType>;
}

export interface RemoveJourneyScheduleInput {
  cargoIds: Array<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface RemoveJourneySchedulePayload {
  __typename?: 'RemoveJourneySchedulePayload';
  errors?: Maybe<Array<Error>>;
}

export interface RemoveMilestoneActualDateInput {
  milestoneId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface RemoveMilestoneActualDatePayload {
  __typename?: 'RemoveMilestoneActualDatePayload';
  errors?: Maybe<Array<Error>>;
}

export interface RemoveMilestonePlannedDateInput {
  milestoneId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface RemoveMilestonePlannedDatePayload {
  __typename?: 'RemoveMilestonePlannedDatePayload';
  errors?: Maybe<Array<Error>>;
}

export interface RemoveMilestoneRequestedDateInput {
  milestoneId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface RemoveMilestoneRequestedDatePayload {
  __typename?: 'RemoveMilestoneRequestedDatePayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RemoveOriginAgentAssignmentRule */
export interface RemoveOriginAgentAssignmentRuleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of RemoveOriginAgentAssignmentRule */
export interface RemoveOriginAgentAssignmentRulePayload {
  __typename?: 'RemoveOriginAgentAssignmentRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  originAgentAssignmentRule?: Maybe<OriginAgentAssignmentRule>;
}

/** Autogenerated input type of RemoveVoyageMilestoneDelay */
export interface RemoveVoyageMilestoneDelayInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  delayId: Scalars['String'];
}

/** Autogenerated return type of RemoveVoyageMilestoneDelay */
export interface RemoveVoyageMilestoneDelayPayload {
  __typename?: 'RemoveVoyageMilestoneDelayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RemoveVoyageMilestoneTime */
export interface RemoveVoyageMilestoneTimeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  milestoneName: VoyageMilestoneNameEnum;
  timeType: VoyageMilestoneTime;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of RemoveVoyageMilestoneTime */
export interface RemoveVoyageMilestoneTimePayload {
  __typename?: 'RemoveVoyageMilestoneTimePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RenameDivision */
export interface RenameDivisionInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  parentAccountId: Scalars['String'];
}

/** Autogenerated return type of RenameDivision */
export interface RenameDivisionPayload {
  __typename?: 'RenameDivisionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of RenameOrganisation */
export interface RenameOrganisationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  networkId: Scalars['String'];
  organisationId: Scalars['String'];
}

/** Autogenerated return type of RenameOrganisation */
export interface RenameOrganisationPayload {
  __typename?: 'RenameOrganisationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  organisation?: Maybe<NetworksOrganisationInterface>;
}

/** Autogenerated input type of RequireCollectionSchedule */
export interface RequireCollectionScheduleInput {
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of RequireCollectionSchedule */
export interface RequireCollectionSchedulePayload {
  __typename?: 'RequireCollectionSchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  events: Array<BookingAggregatesCollectionAndDeliveryEventType>;
}

/** Autogenerated input type of RequireDeliverySchedule */
export interface RequireDeliveryScheduleInput {
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of RequireDeliverySchedule */
export interface RequireDeliverySchedulePayload {
  __typename?: 'RequireDeliverySchedulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  events: Array<BookingAggregatesCollectionAndDeliveryEventType>;
}

export interface ResetPriceCalculationDateInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface ResetPriceCalculationDatePayload {
  __typename?: 'ResetPriceCalculationDatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
}

/** Autogenerated input type of ResolveBookingIssue */
export interface ResolveBookingIssueInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ResolveBookingIssue */
export interface ResolveBookingIssuePayload {
  __typename?: 'ResolveBookingIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of ResolveIssue */
export interface ResolveIssueInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ResolveIssue */
export interface ResolveIssuePayload {
  __typename?: 'ResolveIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issue?: Maybe<Issue>;
}

/** Autogenerated input type of ResolvePurchaseOrderIssue */
export interface ResolvePurchaseOrderIssueInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of ResolvePurchaseOrderIssue */
export interface ResolvePurchaseOrderIssuePayload {
  __typename?: 'ResolvePurchaseOrderIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface Revenue {
  __typename?: 'Revenue';
  chargeType: ChargeType;
  combinedTotal: Scalars['Float'];
  costId: Scalars['String'];
  currency: Currency;
  exchangeRate: Scalars['Float'];
  id: Scalars['String'];
  marginAmount: Scalars['Float'];
  quantity: Scalars['Float'];
  totalGbp: Scalars['Float'];
  unitCost: Scalars['Float'];
}

export interface RevenueInput {
  chargeTypeId: Scalars['String'];
  combinedTotal: Scalars['Float'];
  costId: Scalars['String'];
  currency: Scalars['String'];
  exchangeRate: Scalars['Float'];
  id: Scalars['String'];
  marginAmount: Scalars['Float'];
  quantity: Scalars['Float'];
  totalGbp: Scalars['Float'];
  unitCost: Scalars['Float'];
}

export interface ReviewParsedCommercialInvoiceInput {
  documentId: Scalars['ID'];
  expectedResult: ParsedCommercialInvoiceResultInput;
}

export interface RichDate {
  __typename?: 'RichDate';
  /** Confidence of date accuracy */
  confidenceLevel?: Maybe<ConfidenceLevel>;
  /** Date in local time zone */
  date?: Maybe<Scalars['Date']>;
  /** History of date changes */
  history?: Maybe<Array<RichDate>>;
  /** Who changed the date and when */
  lastUpdated?: Maybe<LastUpdated>;
  /** Reason for changing the date */
  reasonForChange?: Maybe<ReasonForChange>;
}

export interface RichDateInput {
  confidenceLevel?: InputMaybe<ConfidenceLevel>;
  date: Scalars['Date'];
  reasonForChange?: InputMaybe<ReasonForChangeInput>;
}

export interface RichDateTime {
  __typename?: 'RichDateTime';
  /** Date in the local time zone */
  date?: Maybe<Scalars['Date']>;
  /** Time in the local time zone (although time zone always set to GMT) */
  dateTime?: Maybe<Scalars['ISO8601DateTime']>;
}

export enum RiskLevelsEnum {
  HIGH = 'high',
  LOW = 'low',
  MEDIUM = 'medium'
}

export interface RoadShipmentInput {
  cargoDescription?: InputMaybe<Scalars['String']>;
  cargoReadyDate?: InputMaybe<Scalars['Date']>;
  clientReference?: InputMaybe<Scalars['String']>;
  consigneeId?: InputMaybe<Scalars['String']>;
  consignorId?: InputMaybe<Scalars['String']>;
  customsOnly?: InputMaybe<Scalars['Boolean']>;
  destinationId?: InputMaybe<Scalars['String']>;
  haulierId?: InputMaybe<Scalars['String']>;
  incoterms?: InputMaybe<IncotermsValue>;
  multiStop?: InputMaybe<MultiStopEnum>;
  originId?: InputMaybe<Scalars['String']>;
  shipmentType?: InputMaybe<Shipment>;
  specialInstructions?: InputMaybe<Scalars['String']>;
  tradeRole?: InputMaybe<TradeRole>;
  truckSwap?: InputMaybe<TruckSwapEnum>;
}

export interface RoadTrackedShipment {
  __typename?: 'RoadTrackedShipment';
  active: Scalars['Boolean'];
  booking?: Maybe<Booking>;
  createdAt: Scalars['String'];
  customerId: Scalars['String'];
  /** @deprecated Field no longer supported */
  destinationCountryCode: Scalars['String'];
  /** @deprecated Field no longer supported */
  estimatedArrivalWindowEndDateTime: Scalars['String'];
  /** @deprecated Field no longer supported */
  estimatedArrivalWindowStartDateTime: Scalars['String'];
  geolocation: Geolocation;
  lastUpdatedAt: Scalars['String'];
  latestStopUpdates?: Maybe<Array<RoadTrackingStopUpdate>>;
  /** @deprecated Field no longer supported */
  originCountryCode: Scalars['String'];
  status: RoadTrackedShipmentStatus;
  /** @deprecated Field no longer supported */
  timeZone: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export enum RoadTrackedShipmentActive {
  ACTIVE = 'ACTIVE',
  ALL = 'ALL',
  INACTIVE = 'INACTIVE'
}

export interface RoadTrackedShipmentConnection {
  __typename?: 'RoadTrackedShipmentConnection';
  edges?: Maybe<Array<Maybe<RoadTrackedShipmentEdge>>>;
  nodes?: Maybe<Array<Maybe<RoadTrackedShipment>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
}

export interface RoadTrackedShipmentEdge {
  __typename?: 'RoadTrackedShipmentEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<RoadTrackedShipment>;
}

export interface RoadTrackedShipmentEvent {
  dateRange: Iso8601DateRangeInput;
  type: StopType;
}

export interface RoadTrackedShipmentFilter {
  __typename?: 'RoadTrackedShipmentFilter';
  count: Scalars['Int'];
  status: RoadTrackedShipmentStatus;
}

export interface RoadTrackedShipmentFilterOptions {
  __typename?: 'RoadTrackedShipmentFilterOptions';
  firstStopCountryCodes: Array<Scalars['String']>;
  lastStopCountryCodes: Array<Scalars['String']>;
}

export enum RoadTrackedShipmentStatus {
  EARLY = 'EARLY',
  LATE = 'LATE',
  ON_TIME = 'ON_TIME',
  UNKNOWN = 'UNKNOWN'
}

export interface RoadTrackingDetails {
  __typename?: 'RoadTrackingDetails';
  /** @deprecated Use booking */
  cargoId?: Maybe<Scalars['String']>;
  trackingInput?: Maybe<RoadTrackingInput>;
  /** @deprecated Use booking */
  zencargoReference?: Maybe<Scalars['String']>;
}

export interface RoadTrackingInput {
  __typename?: 'RoadTrackingInput';
  id: Scalars['String'];
  missingData?: Maybe<Array<MissingRoadTrackingInputData>>;
  trackable: Scalars['Boolean'];
  trackingSubscription?: Maybe<RoadTrackingSubscription>;
}

export enum RoadTrackingStatus {
  CURRENTLY_BEING_TRACKED = 'CURRENTLY_BEING_TRACKED',
  TRACKING_COMPLETED = 'TRACKING_COMPLETED',
  TRACKING_FAILED = 'TRACKING_FAILED',
  TRACKING_NOT_LIVE = 'TRACKING_NOT_LIVE'
}

export enum RoadTrackingStatusReason {
  ACQUIRING_LOCATION = 'ACQUIRING_LOCATION',
  APPROVAL_DENIED = 'APPROVAL_DENIED',
  ARRIVED_FINAL_STOP = 'ARRIVED_FINAL_STOP',
  CANCELED = 'CANCELED',
  DATA_ENTERED_TOO_LATE = 'DATA_ENTERED_TOO_LATE',
  DEPARTED_FINAL_STOP = 'DEPARTED_FINAL_STOP',
  FAILED_TO_ACQUIRE_LOCATION = 'FAILED_TO_ACQUIRE_LOCATION',
  HAULIER_NOT_ONBOARDED = 'HAULIER_NOT_ONBOARDED',
  IDLE = 'IDLE',
  INFO = 'INFO',
  IN_MOTION = 'IN_MOTION',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  PENDING_CARRIER = 'PENDING_CARRIER',
  PENDING_TRACKING_METHOD = 'PENDING_TRACKING_METHOD',
  SCHEDULED = 'SCHEDULED',
  TIMED_OUT = 'TIMED_OUT',
  UNKNOWN = 'UNKNOWN',
  UNKNOWN_VEHICLE = 'UNKNOWN_VEHICLE'
}

export interface RoadTrackingStopUpdate {
  __typename?: 'RoadTrackingStopUpdate';
  arrivalWindow?: Maybe<LocalDateTimeRange>;
  confidenceLevel?: Maybe<ConfidenceLevel>;
  countryCode: Scalars['String'];
  stopType?: Maybe<Array<StopType>>;
}

export interface RoadTrackingSubscription {
  __typename?: 'RoadTrackingSubscription';
  mapUrl?: Maybe<Scalars['String']>;
  status: RoadTrackingStatus;
  statusReason: RoadTrackingStatusReason;
}

export enum Role {
  ADMIN = 'ADMIN',
  AGENT_FORWARDER = 'AGENT_FORWARDER',
  CUSTOMER_USER = 'CUSTOMER_USER',
  MANUFACTURER = 'MANUFACTURER'
}

export interface RoleMembersInput {
  organizationId: Scalars['String'];
  roleId: Scalars['String'];
  userIds: Array<Scalars['String']>;
}

/** Response payload for mutations affecting role members */
export interface RoleMembersPayload {
  __typename?: 'RoleMembersPayload';
  errors?: Maybe<Array<Error>>;
}

export interface Rule {
  __typename?: 'Rule';
  enabled: Scalars['Boolean'];
  entityType?: Maybe<EntityTypesEnum>;
  evaluationCode?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
}

/** The connection type for Rule. */
export interface RuleConnection {
  __typename?: 'RuleConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<RuleEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Rule>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface RuleEdge {
  __typename?: 'RuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Rule>;
}

/** Rule input types for rule evaluation */
export interface RuleInputObjectInput {
  /** Customer account - accountSelect */
  accountId?: InputMaybe<Scalars['String']>;
  /** Country of destination - countrySelect */
  countryOfDestination?: InputMaybe<Scalars['String']>;
  /** Country of origin - countrySelect */
  countryOfOrigin?: InputMaybe<Scalars['String']>;
  /** Destination address/UNLO - networksContactSelect */
  destination?: InputMaybe<Scalars['String']>;
  /** Mode of transport - modeOfTransportSelect */
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  /** Origin address/UNLO - networksContactSelect */
  origin?: InputMaybe<Scalars['String']>;
  /** Shipment type - shipmentTypeSelect */
  shipmentType?: InputMaybe<Shipment>;
}

export interface RuleInputObjectType {
  __typename?: 'RuleInputObjectType';
  account?: Maybe<Account>;
  countryOfDestination?: Maybe<Scalars['String']>;
  countryOfOrigin?: Maybe<Scalars['String']>;
  destination?: Maybe<Scalars['String']>;
  legacyAccount?: Maybe<LegacyAccount>;
  modeOfTransport?: Maybe<ModeOfTransport>;
  origin?: Maybe<Scalars['String']>;
  shipmentType?: Maybe<Shipment>;
}

/** Rule output types for rule evaluation */
export interface RuleOutputObjectInput {
  /** Customer operations coordinator - customerCoordinatorSelect */
  customerOperationsCoordinatorId?: InputMaybe<Scalars['String']>;
  /** Customs operations coordinator - operationsCoordinatorSelect */
  customsOperationsCoordinatorId?: InputMaybe<Scalars['String']>;
  /** Destination operations coordinator - operationsCoordinatorSelect */
  destinationOperationsCoordinatorId?: InputMaybe<Scalars['String']>;
  /** Origin agent - agentSelect */
  originAgentId?: InputMaybe<Scalars['String']>;
  /** Origin operations coordinator - operationsCoordinatorSelect */
  originOperationsCoordinatorId?: InputMaybe<Scalars['String']>;
}

export interface RuleOutputObjectType {
  __typename?: 'RuleOutputObjectType';
  customerOperationsCoordinator?: Maybe<NetworksContact>;
  customsOperationsCoordinator?: Maybe<NetworksContact>;
  destinationOperationsCoordinator?: Maybe<NetworksContact>;
  originAgent?: Maybe<NetworksOrgLoc>;
  originOperationsCoordinator?: Maybe<NetworksContact>;
}

export interface RulesEngine {
  __typename?: 'RulesEngine';
  canAccessRoute: AuthorizationResult;
  canCreateIssueTemplate: AuthorizationResult;
  canListIssueTemplates: AuthorizationResult;
}

/** Autogenerated input type of SaveAccrualTemplateMutation */
export interface SaveAccrualTemplateMutationInput {
  accrualTemplateId?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  sourceAccrualId: Scalars['String'];
  templateName: Scalars['String'];
}

/** Autogenerated return type of SaveAccrualTemplateMutation */
export interface SaveAccrualTemplateMutationPayload {
  __typename?: 'SaveAccrualTemplateMutationPayload';
  accrualTemplate?: Maybe<AccrualTemplate>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of SaveTrackingSettings */
export interface SaveTrackingSettingsInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  freshnessThresholds?: InputMaybe<FreshnessThresholdsSettingsInput>;
}

/** Autogenerated return type of SaveTrackingSettings */
export interface SaveTrackingSettingsPayload {
  __typename?: 'SaveTrackingSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  errors?: Maybe<Array<Error>>;
  freshnessThresholds?: Maybe<FreshnessThresholdsSettings>;
}

export interface SaveWorkflowRuleDefinitionPayload {
  __typename?: 'SaveWorkflowRuleDefinitionPayload';
  errors?: Maybe<Array<Error>>;
  workflowRuleDefinition?: Maybe<WorkflowRuleDefinitionType>;
}

export interface SaveWorkflowRulePayload {
  __typename?: 'SaveWorkflowRulePayload';
  errors?: Maybe<Array<Error>>;
  workflowRule?: Maybe<WorkflowRuleType>;
}

export interface SavedFilters {
  __typename?: 'SavedFilters';
  filters?: Maybe<SavedFiltersShipments>;
  name?: Maybe<Scalars['String']>;
}

export interface SavedFiltersInput {
  active?: InputMaybe<Scalars['Boolean']>;
  consignees?: InputMaybe<Array<Scalars['String']>>;
  consignors?: InputMaybe<Array<Scalars['String']>>;
  customers?: InputMaybe<Array<Scalars['String']>>;
  /** @deprecated This field will be deprecated and replaced by within_time_range. */
  days?: InputMaybe<Scalars['Int']>;
  destinationCountries?: InputMaybe<Array<Scalars['String']>>;
  destinations?: InputMaybe<Array<Scalars['String']>>;
  forwarders?: InputMaybe<Array<Scalars['String']>>;
  incoterms?: InputMaybe<Array<IncotermsValue>>;
  issues?: InputMaybe<IssuesFilterInput>;
  /** @deprecated This field will be deprecated as it is not a search input. */
  option?: InputMaybe<Scalars['String']>;
  originCountries?: InputMaybe<Array<Scalars['String']>>;
  origins?: InputMaybe<Array<Scalars['String']>>;
  showInTransit?: InputMaybe<Scalars['Boolean']>;
  stages?: InputMaybe<Array<StageValue>>;
  status?: InputMaybe<Array<StatusEnum>>;
  transportModes?: InputMaybe<Array<ModeOfTransport>>;
  vesselNames?: InputMaybe<Array<Scalars['String']>>;
  withSteps?: InputMaybe<Array<BookingStep>>;
  withinTimeRange?: InputMaybe<WithinTimeRangeInput>;
}

export interface SavedFiltersShipments {
  __typename?: 'SavedFiltersShipments';
  active?: Maybe<Scalars['Boolean']>;
  consignees?: Maybe<Array<Scalars['String']>>;
  consignors?: Maybe<Array<Scalars['String']>>;
  customers?: Maybe<Array<Scalars['String']>>;
  days?: Maybe<Scalars['Int']>;
  destinationCountries?: Maybe<Array<Scalars['String']>>;
  destinations?: Maybe<Array<Scalars['String']>>;
  forwarders?: Maybe<Array<Scalars['String']>>;
  incoterms?: Maybe<Array<IncotermsValue>>;
  issues?: Maybe<IssuesFilter>;
  option?: Maybe<WithinDaysLabel>;
  originCountries?: Maybe<Array<Scalars['String']>>;
  origins?: Maybe<Array<Scalars['String']>>;
  showInTransit?: Maybe<Scalars['Boolean']>;
  stages?: Maybe<Array<StageValue>>;
  status?: Maybe<Array<StatusEnum>>;
  transportModes?: Maybe<Array<ModeOfTransport>>;
  vesselNames?: Maybe<Array<Scalars['String']>>;
  withSteps?: Maybe<Array<BookingStep>>;
  withinTimeRange?: Maybe<WithinTimeRange>;
}

export interface SavedOrganisation extends NetworksOrganisationInterface {
  __typename?: 'SavedOrganisation';
  assignedRoles: Array<Role>;
  contacts: NetworksContactConnection;
  /** Organisation ID */
  id: Scalars['String'];
  /** Locations assigned to the organisation */
  locations: Array<NetworksLocation>;
  /** Organisation name */
  name: Scalars['String'];
  /** Organization type */
  type: OrganisationTypeValue;
}

export type SavedOrganisationContactsArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
};

/** Autogenerated input type of ScheduleCollection */
export interface ScheduleCollectionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schedule: CollectionSchedule;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of ScheduleCollection */
export interface ScheduleCollectionPayload {
  __typename?: 'ScheduleCollectionPayload';
  cargo?: Maybe<Cargo>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of ScheduleDelivery */
export interface ScheduleDeliveryInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  schedule: DeliverySchedule;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of ScheduleDelivery */
export interface ScheduleDeliveryPayload {
  __typename?: 'ScheduleDeliveryPayload';
  cargo?: Maybe<Cargo>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** ScheduledLeg object type */
export interface ScheduledLeg {
  __typename?: 'ScheduledLeg';
  actualArrivalLocalDateTime?: Maybe<LocalDateTimeWithNoTimeZone>;
  actualDepartureLocalDateTime?: Maybe<LocalDateTimeWithNoTimeZone>;
  estimatedArrivalDate?: Maybe<Scalars['ISO8601Date']>;
  estimatedDepartureDate?: Maybe<Scalars['ISO8601Date']>;
  from?: Maybe<Terminal>;
  /** Main mode of transport for the booking */
  modeOfTransport?: Maybe<ScheduledModeOfTransportEnum>;
  to?: Maybe<Terminal>;
}

export interface ScheduledLegInput {
  fromRequestedDate: LocalDateTimeRangeInput;
  fromUnlocode: Scalars['String'];
  toRequestedDate: LocalDateTimeRangeInput;
  toUnlocode: Scalars['String'];
  vehicleProperties: Array<VehiclePropertyInput>;
}

export enum ScheduledModeOfTransportEnum {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
  RAIL = 'RAIL'
}

export interface ScimApiKey {
  __typename?: 'ScimApiKey';
  expiryDate: Scalars['DateTime'];
  id: Scalars['String'];
  status: ApiKeyStatus;
}

export interface Search {
  __typename?: 'Search';
  accountName: Scalars['String'];
  id: Scalars['String'];
  metadata?: Maybe<GlobalSearchMetadataType>;
  reference: Scalars['String'];
  type: GlobalSearchTypeEnum;
}

export interface SearchAccountAndConnectionLocationsFiltersInput {
  accountIds: Array<Scalars['String']>;
  displayHeadquartersAtTop?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  usageContext?: InputMaybe<UsageContext>;
}

export interface SearchAssignableAccountsFiltersInput {
  connectionType: AccountConnectionTypeEnum;
  excludeAccountId?: InputMaybe<Scalars['String']>;
  searchQuery: Scalars['String'];
}

export interface SearchResult {
  __typename?: 'SearchResult';
  accountName?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  matchedFields: Array<MatchedField>;
  type: EntityType;
}

export enum SearchableField {
  CARRIER_BOOKING_REFERENCE = 'CARRIER_BOOKING_REFERENCE',
  CLIENT_REFERENCE = 'CLIENT_REFERENCE',
  COLLECTION_LOCATION = 'COLLECTION_LOCATION',
  CONSIGNEE = 'CONSIGNEE',
  CONSOLIDATION_REFERENCE = 'CONSOLIDATION_REFERENCE',
  CONTAINER_NUMBER = 'CONTAINER_NUMBER',
  COURIER_TRACKING_NUMBER = 'COURIER_TRACKING_NUMBER',
  DELIVERY_LOCATION = 'DELIVERY_LOCATION',
  FLIGHT_NUMBER = 'FLIGHT_NUMBER',
  HOUSE_BILL_OF_LADING = 'HOUSE_BILL_OF_LADING',
  MANUFACTURER = 'MANUFACTURER',
  MASTER_AIRWAY_BILL = 'MASTER_AIRWAY_BILL',
  MASTER_BILL_OF_LADING = 'MASTER_BILL_OF_LADING',
  ORDER_NUMBER = 'ORDER_NUMBER',
  PORT_OF_DESTINATION = 'PORT_OF_DESTINATION',
  PORT_OF_LOADING = 'PORT_OF_LOADING',
  PRODUCT_NAME = 'PRODUCT_NAME',
  SCAC_CODE = 'SCAC_CODE',
  SKU_CODE = 'SKU_CODE',
  VEHICLE_PLATE = 'VEHICLE_PLATE',
  VESSEL_NAME = 'VESSEL_NAME',
  ZENCARGO_REFERENCE = 'ZENCARGO_REFERENCE'
}

/** Autogenerated input type of SendResetPasswordInstructions */
export interface SendResetPasswordInstructionsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
}

/** Autogenerated return type of SendResetPasswordInstructions */
export interface SendResetPasswordInstructionsPayload {
  __typename?: 'SendResetPasswordInstructionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface SetPriceCalculationDateInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  date: Scalars['Date'];
  zencargoReference: Scalars['String'];
}

export interface SetPriceCalculationDatePayload {
  __typename?: 'SetPriceCalculationDatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
}

/** Autogenerated input type of SetUserAsManager */
export interface SetUserAsManagerInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  managerType: ManagerTypeEnum;
  userId: Scalars['String'];
}

/** Autogenerated return type of SetUserAsManager */
export interface SetUserAsManagerPayload {
  __typename?: 'SetUserAsManagerPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  userId?: Maybe<Scalars['Int']>;
}

export enum SettingKeyEnum {
  ACCEPTED_QUOTE_OPTION = 'accepted_quote_option',
  ADDED_ACTION_ITEM = 'added_action_item',
  ADDED_CARGO_ITEM = 'added_cargo_item',
  APPROVED_ACTION_ITEM = 'approved_action_item',
  APPROVED_BOOKING = 'approved_booking',
  ARCHIVED_ISSUE = 'archived_issue',
  CANCELLED_ACTION_ITEM = 'cancelled_action_item',
  CLOSED_PURCHASE_ORDER = 'closed_purchase_order',
  COMPLETED_COLLECTION = 'completed_collection',
  COMPLETED_DELIVERY = 'completed_delivery',
  CONFIRMED_COLLECTION = 'confirmed_collection',
  CONFIRMED_DELIVERY = 'confirmed_delivery',
  CREATED_PURCHASE_ORDER = 'created_purchase_order',
  DELETED_CARGO_ITEM = 'deleted_cargo_item',
  ETA_REMOVED_ITEM = 'eta_removed_item',
  ETA_UPDATED_ITEM = 'eta_updated_item',
  MANUFACTURER_REQUESTED_BOOKING = 'manufacturer_requested_booking',
  OPENED_ISSUE = 'opened_issue',
  QUOTE_OPTION = 'quote_option',
  REJECTED_BOOKING_REQUEST = 'rejected_booking_request',
  REJECTED_QUOTE_OPTION = 'rejected_quote_option',
  REQUESTED_COLLECTION = 'requested_collection',
  REQUESTED_DELIVERY = 'requested_delivery',
  REQUIRED_CUSTOMER_APPROVAL_FOR_BOOKING = 'required_customer_approval_for_booking',
  RESOLVED_ACTION_ITEM = 'resolved_action_item',
  RESOLVED_ISSUE = 'resolved_issue',
  STARTED_ISSUE = 'started_issue',
  TEXT_MESSAGE_FROM_AGENT = 'text_message_from_agent',
  TEXT_MESSAGE_FROM_CUSTOMER_USER = 'text_message_from_customer_user',
  TEXT_MESSAGE_FROM_FORWARDER = 'text_message_from_forwarder',
  TEXT_MESSAGE_FROM_MANUFACTURER = 'text_message_from_manufacturer',
  UPDATED_CARGO_ITEM = 'updated_cargo_item',
  UPDATED_CARGO_READY_DATES = 'updated_cargo_ready_dates',
  UPDATED_CARGO_TYPE = 'updated_cargo_type',
  UPDATED_DELIVERY_ESTIMATES = 'updated_delivery_estimates',
  UPDATED_ISSUE = 'updated_issue'
}

export interface SettingKeyValue {
  __typename?: 'SettingKeyValue';
  channelsAvailable: SettingValue;
  key: SettingKeyEnum;
  value: SettingValue;
}

export interface SettingKeyValueInput {
  key: SettingKeyEnum;
  value: SettingValueInput;
}

export interface SettingValue {
  __typename?: 'SettingValue';
  email: Scalars['Boolean'];
  inApp: Scalars['Boolean'];
  slack: Scalars['Boolean'];
}

export interface SettingValueInput {
  email: Scalars['Boolean'];
  inApp: Scalars['Boolean'];
  slack: Scalars['Boolean'];
}

export interface Settings {
  __typename?: 'Settings';
  canAccessRoute: AuthorizationResult;
  canSetPreferences: AuthorizationResult;
  notificationSettings?: Maybe<NotificationSettings>;
}

export interface SettingsGlobalPermissions {
  __typename?: 'SettingsGlobalPermissions';
  notificationSettings?: Maybe<NotificationSettingsGlobalPermissions>;
}

export enum Shipment {
  CROSS_TRADE = 'CROSS_TRADE',
  DOMESTIC = 'DOMESTIC',
  EXPORT = 'EXPORT',
  IMPORT = 'IMPORT'
}

export interface ShipmentActionType {
  __typename?: 'ShipmentActionType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  title?: Maybe<Scalars['String']>;
}

/** The connection type for ShipmentActionType. */
export interface ShipmentActionTypeConnection {
  __typename?: 'ShipmentActionTypeConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShipmentActionTypeEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShipmentActionType>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ShipmentActionTypeEdge {
  __typename?: 'ShipmentActionTypeEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShipmentActionType>;
}

export enum ShipmentColumnEnum {
  CARGODESCRIPTION = 'cargoDescription',
  CLIENTREFERENCE = 'clientReference',
  CONSIGNEE = 'consignee',
  CONSIGNOR = 'consignor',
  DELAY = 'delay',
  DESTINATION = 'destination',
  ESTIMATEDARRIVAL = 'estimatedArrival',
  ESTIMATEDDEPARTURE = 'estimatedDeparture',
  FORWARDER = 'forwarder',
  INCOTERMS = 'incoterms',
  LATESTCOLLECTIONESTIMATE = 'latestCollectionEstimate',
  LATESTDELIVERYESTIMATE = 'latestDeliveryEstimate',
  MODEOFTRANSPORT = 'modeOfTransport',
  ORIGIN = 'origin',
  PORTOFDESTINATION = 'portOfDestination',
  PORTOFLOAD = 'portOfLoad',
  PURCHASEORDERS = 'purchaseOrders',
  SHIPPINGORDERSTATUS = 'shippingOrderStatus',
  STAGE = 'stage',
  VESSELNAME = 'vesselName',
  ZENCARGOREFERENCE = 'zencargoReference'
}

export interface ShipmentDataFiltersInput {
  active?: InputMaybe<Scalars['Boolean']>;
  consignees?: InputMaybe<Array<Scalars['String']>>;
  consignors?: InputMaybe<Array<Scalars['String']>>;
  consolidatedShipmentId?: InputMaybe<Scalars['String']>;
  customers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  destinationCountries?: InputMaybe<Array<Scalars['String']>>;
  destinations?: InputMaybe<Array<Scalars['String']>>;
  forwarders?: InputMaybe<Array<Scalars['String']>>;
  incoterms?: InputMaybe<Array<IncotermsValue>>;
  issues?: InputMaybe<IssuesFilterInput>;
  originCountries?: InputMaybe<Array<Scalars['String']>>;
  origins?: InputMaybe<Array<Scalars['String']>>;
  showInTransit?: InputMaybe<Scalars['Boolean']>;
  stages?: InputMaybe<Array<StageValue>>;
  transportModes?: InputMaybe<Array<ModeOfTransport>>;
  vesselNames?: InputMaybe<Array<Scalars['String']>>;
  withSteps?: InputMaybe<Array<BookingStep>>;
  withinTimeRange?: InputMaybe<WithinTimeRangeInput>;
}

export interface ShipmentDelayReason {
  __typename?: 'ShipmentDelayReason';
  archivedAt?: Maybe<RichDateTime>;
  description: Scalars['String'];
  id: Scalars['Int'];
  reason: Scalars['String'];
}

/** The connection type for ShipmentDelayReason. */
export interface ShipmentDelayReasonConnection {
  __typename?: 'ShipmentDelayReasonConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<ShipmentDelayReasonEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<ShipmentDelayReason>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface ShipmentDelayReasonEdge {
  __typename?: 'ShipmentDelayReasonEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<ShipmentDelayReason>;
}

export interface ShipmentEtaMetadataType {
  __typename?: 'ShipmentEtaMetadataType';
  description?: Maybe<Scalars['String']>;
  metadataType: Scalars['String'];
  newEstimate?: Maybe<LocalDateTimeType>;
  previousEstimate?: Maybe<LocalDateTimeType>;
  reason?: Maybe<Scalars['String']>;
}

export interface ShipmentExportDataInput {
  columns?: InputMaybe<Array<ShipmentColumnEnum>>;
  dataFilters: ShipmentDataFiltersInput;
  order?: InputMaybe<SortInput>;
}

export interface ShipmentFiltersInput {
  filters: SavedFiltersInput;
  name: Scalars['String'];
}

export interface ShipmentQuantities {
  __typename?: 'ShipmentQuantities';
  deliveredShipments: Scalars['Int'];
  liveShipments: Scalars['Int'];
  totalShipments: Scalars['Int'];
}

export interface ShipmentsFilter {
  __typename?: 'ShipmentsFilter';
  canViewCustomerFilter: AuthorizationResult;
  customers: Array<FiltersCustomerType>;
  destinationCountries: Array<Country>;
  forwarders: Array<NetworksAssignableInterface>;
  issueTitles: Array<Maybe<Scalars['String']>>;
  originCountries: Array<Country>;
  vesselNames: Array<Scalars['String']>;
}

export interface ShipmentsTableAccount {
  __typename?: 'ShipmentsTableAccount';
  account?: Maybe<Account>;
  accountName?: Maybe<Scalars['String']>;
  legacyAccount?: Maybe<LegacyAccount>;
}

export enum ShipmentsTableAirShipmentType {
  AIRLINE = 'AIRLINE',
  COURIER = 'COURIER'
}

export interface ShipmentsTableBookingRequestRejectedReason {
  __typename?: 'ShipmentsTableBookingRequestRejectedReason';
  rejectedAdditionalInfo?: Maybe<Scalars['String']>;
  rejectedReason: ShipmentsTableBookingRequestRejectedReasonValue;
}

export enum ShipmentsTableBookingRequestRejectedReasonValue {
  FAILED_QC_INSPECTION = 'FAILED_QC_INSPECTION',
  INCOTERM = 'INCOTERM',
  OTHER = 'OTHER',
  POS_ATTACHED_OR_ITEMS = 'POS_ATTACHED_OR_ITEMS',
  SHIPMENT_MODE = 'SHIPMENT_MODE',
  SHIPMENT_SAMPLE_NOT_APPROVED = 'SHIPMENT_SAMPLE_NOT_APPROVED',
  SHIPMENT_SAMPLE_NOT_RECEIVED = 'SHIPMENT_SAMPLE_NOT_RECEIVED',
  SHIPPING_TOO_EARLY = 'SHIPPING_TOO_EARLY',
  SHIPPING_TOO_LATE = 'SHIPPING_TOO_LATE',
  UNITS_BOOKED_EXCEED_ORDERED_UNITS = 'UNITS_BOOKED_EXCEED_ORDERED_UNITS',
  UNITS_BOOKED_LESS_THAN_ORDERED_UNITS = 'UNITS_BOOKED_LESS_THAN_ORDERED_UNITS',
  WRONGLY_ENTERED_DATA = 'WRONGLY_ENTERED_DATA'
}

export enum ShipmentsTableBookingRequestStatus {
  MANUFACTURER_REQUESTED = 'MANUFACTURER_REQUESTED',
  READY_FOR_APPROVAL = 'READY_FOR_APPROVAL',
  REJECTED = 'REJECTED'
}

export interface ShipmentsTableBookingRequests {
  __typename?: 'ShipmentsTableBookingRequests';
  manufacturerRequested: Scalars['Int'];
  readyForApproval: Scalars['Int'];
  rejected: Scalars['Int'];
}

export interface ShipmentsTableCargo {
  __typename?: 'ShipmentsTableCargo';
  account?: Maybe<ShipmentsTableAccount>;
  airShipmentType?: Maybe<ShipmentsTableAirShipmentType>;
  arrivalDate?: Maybe<ShipmentsTableDateWithCertainty>;
  arrivalStatus?: Maybe<ShipmentsTableDelayWithStatus>;
  bookingPurchaseOrders: Array<ShipmentsTablePurchaseOrder>;
  bookingRequestRejectedReason?: Maybe<ShipmentsTableBookingRequestRejectedReason>;
  cargoId: Scalars['String'];
  cargoPurchaseOrders: Array<ShipmentsTablePurchaseOrder>;
  cargoReadyDate?: Maybe<Scalars['ISO8601Date']>;
  cargoType: Scalars['String'];
  carrierScac?: Maybe<Scalars['String']>;
  clientReference?: Maybe<Scalars['String']>;
  collectionDate?: Maybe<ShipmentsTableDateWithCertainty>;
  collectionLocation?: Maybe<ShipmentsTableLocation>;
  consignee?: Maybe<ShipmentsTableLocation>;
  consignor?: Maybe<ShipmentsTableLocation>;
  consolidationReference?: Maybe<Scalars['String']>;
  containerNumber?: Maybe<Scalars['String']>;
  currentMilestone?: Maybe<ShipmentsTableMilestone>;
  customsOnly?: Maybe<Scalars['Boolean']>;
  deliveryDate?: Maybe<ShipmentsTableDateWithCertainty>;
  deliveryLocation?: Maybe<ShipmentsTableLocation>;
  deliveryStatus?: Maybe<ShipmentsTableDelayWithStatus>;
  departureDate?: Maybe<ShipmentsTableDateWithCertainty>;
  departureStatus?: Maybe<ShipmentsTableDelayWithStatus>;
  incoterms?: Maybe<IncotermsValue>;
  masterAirwayBill?: Maybe<Scalars['String']>;
  masterBillOfLading?: Maybe<Scalars['String']>;
  modeOfTransport: ShipmentsTableModeOfTransport;
  portOfDestination?: Maybe<ShipmentsTableLocation>;
  portOfLoading?: Maybe<ShipmentsTableLocation>;
  quantity: Scalars['Int'];
  quotesRequest?: Maybe<ShipmentsTableQuotesRequest>;
  vehiclePlate?: Maybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface ShipmentsTableCargoGroup {
  __typename?: 'ShipmentsTableCargoGroup';
  cargos: Array<ShipmentsTableCargo>;
  groupId: Scalars['String'];
  totalHits: Scalars['Int'];
  totalInGroup: Scalars['Int'];
}

/** Shipment Table - Data */
export interface ShipmentsTableCargoGroupsConnection {
  __typename?: 'ShipmentsTableCargoGroupsConnection';
  edges?: Maybe<Array<Maybe<ShipmentsTableCargoGroupsEdge>>>;
  groupsInfo?: Maybe<ShipmentsTableGroupsInfo>;
  nodes?: Maybe<Array<Maybe<ShipmentsTableCargoGroup>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
}

export interface ShipmentsTableCargoGroupsEdge {
  __typename?: 'ShipmentsTableCargoGroupsEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ShipmentsTableCargoGroup>;
}

export interface ShipmentsTableCargoGroupsFiltersInput {
  airShipmentType?: InputMaybe<Array<ShipmentsTableAirShipmentType>>;
  arrivalDate?: InputMaybe<LiveViewsFilterDateRangeInput>;
  arrivalOffset?: InputMaybe<Array<LiveViewsFilterIntRangeInput>>;
  bookingCoordinator?: InputMaybe<Array<Scalars['String']>>;
  bookingRequestStatus?: InputMaybe<ShipmentsTableBookingRequestStatus>;
  cargoReadyDate?: InputMaybe<LiveViewsFilterDateRangeInput>;
  carrierScac?: InputMaybe<Array<Scalars['String']>>;
  collectionCountry?: InputMaybe<Array<Scalars['String']>>;
  collectionDate?: InputMaybe<LiveViewsFilterDateRangeInput>;
  collectionLocation?: InputMaybe<Array<Scalars['String']>>;
  currentMilestone?: InputMaybe<Array<ShipmentsTableMilestone>>;
  customerAccountId?: InputMaybe<Array<Scalars['String']>>;
  customsOnly?: InputMaybe<ShipmentsTableCustomsOnlyValue>;
  deliveryCountry?: InputMaybe<Array<Scalars['String']>>;
  deliveryDate?: InputMaybe<LiveViewsFilterDateRangeInput>;
  deliveryLocation?: InputMaybe<Array<Scalars['String']>>;
  deliveryOffset?: InputMaybe<Array<LiveViewsFilterIntRangeInput>>;
  departureDate?: InputMaybe<LiveViewsFilterDateRangeInput>;
  departureOffset?: InputMaybe<Array<LiveViewsFilterIntRangeInput>>;
  incoterms?: InputMaybe<Array<IncotermsValue>>;
  modeOfTransport?: InputMaybe<Array<ShipmentsTableModeOfTransport>>;
  portOfDestination?: InputMaybe<Array<Scalars['String']>>;
  portOfLoading?: InputMaybe<Array<Scalars['String']>>;
  product?: InputMaybe<Array<Scalars['String']>>;
  productCategory?: InputMaybe<Array<Scalars['String']>>;
  quotesRequestStatus?: InputMaybe<Array<ShipmentsTableQuotesRequestStatus>>;
}

/** Shipment Table - Group By */
export enum ShipmentsTableCargoGroupsGroupByColumn {
  CARGO_ID = 'CARGO_ID',
  CONSOLIDATION_REFERENCE = 'CONSOLIDATION_REFERENCE',
  CONTAINER_NUMBER = 'CONTAINER_NUMBER',
  MASTER_AIRWAY_BILL = 'MASTER_AIRWAY_BILL',
  MASTER_BILL_OF_LADING = 'MASTER_BILL_OF_LADING',
  VEHICLE_PLATE = 'VEHICLE_PLATE',
  ZENCARGO_REFERENCE = 'ZENCARGO_REFERENCE'
}

export enum ShipmentsTableCargoGroupsSortByColumn {
  ARRIVAL_DATE = 'ARRIVAL_DATE',
  ARRIVAL_OFFSET = 'ARRIVAL_OFFSET',
  CARGO_READY_DATE = 'CARGO_READY_DATE',
  COLLECTION_DATE = 'COLLECTION_DATE',
  CONSIGNEE = 'CONSIGNEE',
  CONSIGNOR = 'CONSIGNOR',
  CURRENT_MILESTONE = 'CURRENT_MILESTONE',
  DELIVERY_DATE = 'DELIVERY_DATE',
  DELIVERY_OFFSET = 'DELIVERY_OFFSET',
  DEPARTURE_DATE = 'DEPARTURE_DATE',
  DEPARTURE_OFFSET = 'DEPARTURE_OFFSET',
  MODE_OF_TRANSPORT = 'MODE_OF_TRANSPORT',
  ZENCARGO_REFERENCE = 'ZENCARGO_REFERENCE'
}

/** Shipment Table - Sort By */
export interface ShipmentsTableCargoGroupsSortByInput {
  column: ShipmentsTableCargoGroupsSortByColumn;
  order: LiveViewsSortOrder;
}

export enum ShipmentsTableCustomsOnlyValue {
  CUSTOMS_ONLY = 'CUSTOMS_ONLY',
  NOT_CUSTOMS_ONLY = 'NOT_CUSTOMS_ONLY'
}

export enum ShipmentsTableDateCertainty {
  ACTUAL = 'ACTUAL',
  BOOKED = 'BOOKED',
  CALCULATED = 'CALCULATED',
  REQUESTED = 'REQUESTED'
}

export interface ShipmentsTableDateWithCertainty {
  __typename?: 'ShipmentsTableDateWithCertainty';
  date: Scalars['ISO8601Date'];
  dateCertainty: ShipmentsTableDateCertainty;
}

export interface ShipmentsTableDelayReason {
  __typename?: 'ShipmentsTableDelayReason';
  delayInDays: Scalars['Int'];
  reason?: Maybe<Scalars['String']>;
}

export enum ShipmentsTableDelayStatus {
  DELAYED = 'DELAYED',
  EARLY = 'EARLY',
  ON_TIME = 'ON_TIME'
}

export interface ShipmentsTableDelayWithStatus {
  __typename?: 'ShipmentsTableDelayWithStatus';
  delayInDays: Scalars['Int'];
  delayReasons?: Maybe<Array<ShipmentsTableDelayReason>>;
  status: ShipmentsTableDelayStatus;
}

export interface ShipmentsTableFilterValue {
  __typename?: 'ShipmentsTableFilterValue';
  additionalValue?: Maybe<Scalars['String']>;
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

/** Shipment Table Locations - Data */
export interface ShipmentsTableFilterValueConnection {
  __typename?: 'ShipmentsTableFilterValueConnection';
  edges?: Maybe<Array<Maybe<ShipmentsTableFilterValueEdge>>>;
  nodes?: Maybe<Array<Maybe<ShipmentsTableFilterValue>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
}

export interface ShipmentsTableFilterValueEdge {
  __typename?: 'ShipmentsTableFilterValueEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ShipmentsTableFilterValue>;
}

/** Shipment Table Filter Values */
export interface ShipmentsTableFilterValues {
  __typename?: 'ShipmentsTableFilterValues';
  carrierScac: Array<Scalars['String']>;
  collectionCountry: Array<ShipmentsTableFilterValue>;
  customerAccount: Array<ShipmentsTableFilterValue>;
  deliveryCountry: Array<ShipmentsTableFilterValue>;
}

/** Shipment Table Filters */
export interface ShipmentsTableFiltersInput {
  bookingRequestStatus?: InputMaybe<ShipmentsTableBookingRequestStatus>;
  currentMilestone?: InputMaybe<Array<ShipmentsTableMilestone>>;
  customerAccountId?: InputMaybe<Array<Scalars['String']>>;
  quotesRequestStatus?: InputMaybe<Array<ShipmentsTableQuotesRequestStatus>>;
}

export interface ShipmentsTableGroupsInfo {
  __typename?: 'ShipmentsTableGroupsInfo';
  numberOfGroups?: Maybe<Scalars['Int']>;
  numberOfUngroupedCargos?: Maybe<Scalars['Int']>;
}

export interface ShipmentsTableLocation {
  __typename?: 'ShipmentsTableLocation';
  id: Scalars['String'];
  location?: Maybe<AccountLocation>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  networksOrgLoc?: Maybe<NetworksOrgLoc>;
}

export enum ShipmentsTableMilestone {
  ARRIVED_AT_PORT_OF_DESTINATION = 'ARRIVED_AT_PORT_OF_DESTINATION',
  BOOKING_CONFIRMED = 'BOOKING_CONFIRMED',
  BOOKING_RECEIVED = 'BOOKING_RECEIVED',
  BOOKING_REQUESTED = 'BOOKING_REQUESTED',
  CARGO_ARRIVED_AT_PORT_OF_LOADING = 'CARGO_ARRIVED_AT_PORT_OF_LOADING',
  CARGO_LOADED = 'CARGO_LOADED',
  DELIVERED = 'DELIVERED',
  EN_ROUTE_TO_FINAL_DESTINATION = 'EN_ROUTE_TO_FINAL_DESTINATION',
  EN_ROUTE_TO_PORT_OF_LOADING = 'EN_ROUTE_TO_PORT_OF_LOADING',
  IN_TRANSIT = 'IN_TRANSIT'
}

export enum ShipmentsTableModeOfTransport {
  AIR = 'AIR',
  OCEAN = 'OCEAN',
  RAIL = 'RAIL',
  ROAD = 'ROAD'
}

export interface ShipmentsTablePurchaseOrder {
  __typename?: 'ShipmentsTablePurchaseOrder';
  id: Scalars['String'];
  referenceNumber: Scalars['String'];
}

export interface ShipmentsTableQuotesRequest {
  __typename?: 'ShipmentsTableQuotesRequest';
  numberOfActiveQuotes: Scalars['Int'];
  status: ShipmentsTableQuotesRequestStatus;
}

export enum ShipmentsTableQuotesRequestStatus {
  QUOTES_ACTIVE = 'QUOTES_ACTIVE',
  QUOTES_EXPIRED = 'QUOTES_EXPIRED',
  QUOTES_REJECTED = 'QUOTES_REJECTED',
  QUOTES_REQUESTED = 'QUOTES_REQUESTED'
}

export interface ShippingDelayReason {
  __typename?: 'ShippingDelayReason';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  createdAt: Scalars['ISO8601DateTime'];
  description: Scalars['String'];
  id: Scalars['String'];
  lastUpdatedAt: Scalars['ISO8601DateTime'];
  lastUpdatedBy?: Maybe<NetworksContact>;
  lastUpdatedByUser?: Maybe<AccountMember>;
  reason: Scalars['String'];
  responsibleParty: ShippingDelayReasonResponsiblePartyEnum;
}

export interface ShippingDelayReasonConnection {
  __typename?: 'ShippingDelayReasonConnection';
  edges?: Maybe<Array<Maybe<ShippingDelayReasonEdge>>>;
  nodes?: Maybe<Array<Maybe<ShippingDelayReason>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
}

export interface ShippingDelayReasonEdge {
  __typename?: 'ShippingDelayReasonEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ShippingDelayReason>;
}

export enum ShippingDelayReasonResponsiblePartyEnum {
  AI_ESTIMATE = 'AI_ESTIMATE',
  CARRIER = 'CARRIER',
  CUSTOMER_BUYER = 'CUSTOMER_BUYER',
  CUSTOMER_DC = 'CUSTOMER_DC',
  CUSTOMER_SHIPPING = 'CUSTOMER_SHIPPING',
  CUSTOMS = 'CUSTOMS',
  HAULIER = 'HAULIER',
  OTHER = 'OTHER',
  PORT = 'PORT',
  SUPPLIER = 'SUPPLIER',
  ZENCARGO = 'ZENCARGO'
}

export enum ShippingMilestoneDateTypeEnum {
  DRAFT = 'DRAFT',
  PLANNED = 'PLANNED',
  REQUESTED = 'REQUESTED'
}

export enum ShippingMilestoneTypeEnum {
  CARGO = 'CARGO',
  VEHICLE = 'VEHICLE'
}

export interface SkuOverviewType {
  __typename?: 'SkuOverviewType';
  onTimeInFull?: Maybe<Scalars['Int']>;
  overFulfilled?: Maybe<Scalars['Int']>;
  partialFulfilment?: Maybe<Scalars['Int']>;
  zeroStock?: Maybe<Scalars['Int']>;
}

export interface SortInput {
  direction: SortingOrder;
  field: Scalars['String'];
}

export enum SortingOrder {
  ASC = 'ASC',
  DESC = 'DESC'
}

export interface SpecificTime {
  __typename?: 'SpecificTime';
  ampm: AmPm;
  hour: Scalars['Int'];
}

export interface SpecificTimeInput {
  ampm: AmPmEnum;
  hour: Scalars['Int'];
}

export interface Spend {
  __typename?: 'Spend';
  currency: Currency;
  destinationSpend?: Maybe<Scalars['Float']>;
  freightSpend?: Maybe<Scalars['Float']>;
  invoiceList?: Maybe<SpendInvoiceConnection>;
  invoices?: Maybe<Array<SpendInvoice>>;
  originSpend?: Maybe<Scalars['Float']>;
  spend: Scalars['Float'];
}

export type SpendInvoiceListArgs = {
  after?: InputMaybe<Scalars['String']>;
  before?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  last?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<SortInput>;
};

export enum SpendAnalyticsInvoicesColumnEnum {
  CLIENTREFERENCE = 'clientReference',
  DESTINATION = 'destination',
  DESTINATIONSPEND = 'destinationSpend',
  FREIGHTSPEND = 'freightSpend',
  MODEOFTRANSPORT = 'modeOfTransport',
  ORIGIN = 'origin',
  ORIGINSPEND = 'originSpend',
  PUBLISHEDAT = 'publishedAt',
  SPEND = 'spend',
  ZENCARGOREFERENCE = 'zencargoReference'
}

export interface SpendAnalyticsInvoicesDataExportFiltersInput {
  currency: Currency;
  dateRange: DateRangeInput;
  destination?: InputMaybe<Array<Scalars['String']>>;
  origin?: InputMaybe<Array<Scalars['String']>>;
  transportMode?: InputMaybe<Array<ModeOfTransport>>;
}

export interface SpendAnalyticsInvoicesDataInput {
  columns?: InputMaybe<Array<SpendAnalyticsInvoicesColumnEnum>>;
  dataFilters: SpendAnalyticsInvoicesDataExportFiltersInput;
  order?: InputMaybe<SortInput>;
}

/** Autogenerated input type of SpendAnalyticsInvoicesExportData */
export interface SpendAnalyticsInvoicesExportDataInput {
  accountUuid?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  exportData: SpendAnalyticsInvoicesDataInput;
}

/** Autogenerated return type of SpendAnalyticsInvoicesExportData */
export interface SpendAnalyticsInvoicesExportDataPayload {
  __typename?: 'SpendAnalyticsInvoicesExportDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  exportedData?: Maybe<ExportedData>;
}

export interface SpendAnalyticsStartDate {
  __typename?: 'SpendAnalyticsStartDate';
  startDate?: Maybe<Scalars['Date']>;
}

export interface SpendInvoice {
  __typename?: 'SpendInvoice';
  booking?: Maybe<Booking>;
  currency: Currency;
  destinationSpend?: Maybe<Scalars['Float']>;
  freightSpend?: Maybe<Scalars['Float']>;
  originSpend?: Maybe<Scalars['Float']>;
  publishedAt?: Maybe<Scalars['Date']>;
  spend: Scalars['Float'];
}

/** The connection type for SpendInvoice. */
export interface SpendInvoiceConnection {
  __typename?: 'SpendInvoiceConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<SpendInvoiceEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<SpendInvoice>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface SpendInvoiceEdge {
  __typename?: 'SpendInvoiceEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<SpendInvoice>;
}

export interface SsoDetails {
  __typename?: 'SsoDetails';
  apiKeys: Array<ScimApiKey>;
  connectionName?: Maybe<Scalars['String']>;
  domains: Array<Scalars['String']>;
  enabled: Scalars['Boolean'];
  provisionMethod?: Maybe<ProvisionMethod>;
  uuid?: Maybe<Scalars['String']>;
}

export interface Stage {
  __typename?: 'Stage';
  actionItemCount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  statusUpdate?: Maybe<StatusUpdate>;
  /** Current stage */
  value?: Maybe<StageValue>;
}

/**
 * ### Stages in chronological order
 *
 * 1. [Booking Requested](#booking_requested)
 * 2. [Quote Requested](#quote_requested)
 * 3. [Booking Received](#pending)
 * 4. [Booking Confirmed](#booked)
 * 5. [On Route to Pick Up](#container_out)
 * 6. [Departed Warehouse](#departed_warehouse)
 * 7. [Arrived at Port of Origin](#in_gate)
 * 8. [Cargo Aboard](#cargo_aboard)
 * 9. [Departed Port of Origin](#departed_pol)
 * 10. [Arrived at Port of Destination](#arrived_pod)
 * 11. [Unloaded](#discharged)
 * 12. [On Route to Final Destination](#on_route_to_final_destination)
 * 13. [Shipment Delivered](#arrived)
 */
export enum StageValue {
  /** Shipment Delivered */
  ARRIVED = 'arrived',
  /** Arrived at Port of Destination */
  ARRIVED_POD = 'arrived_pod',
  /** Booking Confirmed */
  BOOKED = 'booked',
  /** Booking Requested */
  BOOKING_REQUESTED = 'booking_requested',
  /** Cargo Aboard */
  CARGO_ABOARD = 'cargo_aboard',
  /** On Route to Pick Up */
  CONTAINER_OUT = 'container_out',
  /** Departed Port of Origin */
  DEPARTED_POL = 'departed_pol',
  /** Departed Warehouse */
  DEPARTED_WAREHOUSE = 'departed_warehouse',
  /** Unloaded */
  DISCHARGED = 'discharged',
  /** Arrived at Port of Origin */
  IN_GATE = 'in_gate',
  /** On Route to Final Destination */
  ON_ROUTE_TO_FINAL_DESTINATION = 'on_route_to_final_destination',
  /** Booking Received */
  PENDING = 'pending',
  /** Quote Requested */
  QUOTE_REQUESTED = 'quote_requested'
}

export interface StaleDataSettings {
  __typename?: 'StaleDataSettings';
  accountId: Scalars['String'];
  enabled: Scalars['Boolean'];
  freshnessThresholds?: Maybe<FreshnessThresholdsSettings>;
}

/** Autogenerated input type of StartIssue */
export interface StartIssueInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of StartIssue */
export interface StartIssuePayload {
  __typename?: 'StartIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issue?: Maybe<Issue>;
}

export enum State {
  CURRENT = 'CURRENT',
  PAST = 'PAST',
  PLANNED = 'PLANNED'
}

export interface Statistics {
  __typename?: 'Statistics';
  averageEarliness?: Maybe<Scalars['Float']>;
  averageFulfilmentRate?: Maybe<Scalars['Float']>;
  averageLateness?: Maybe<Scalars['Float']>;
  earlyPercentage?: Maybe<Scalars['Float']>;
  inFullPercentage?: Maybe<Scalars['Float']>;
  latePercentage?: Maybe<Scalars['Float']>;
  leadTimeDistribution?: Maybe<Array<Percentile>>;
  onTimePercentage?: Maybe<Scalars['Float']>;
}

export enum Status {
  ALERT = 'ALERT',
  NONE = 'NONE',
  OK = 'OK',
  PROBLEM = 'PROBLEM',
  WARNING = 'WARNING'
}

export enum StatusEnum {
  ACTIVE = 'ACTIVE',
  CONFIRMED = 'CONFIRMED',
  DELIVERED = 'DELIVERED',
  IN_TRANSIT = 'IN_TRANSIT',
  PENDING = 'PENDING'
}

export interface StatusUpdate {
  __typename?: 'StatusUpdate';
  name?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
}

export enum StopType {
  COLLECTION = 'COLLECTION',
  DELIVERY = 'DELIVERY'
}

/** Autogenerated input type of Subscribe */
export interface SubscribeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriberId: Scalars['String'];
  targetId: Scalars['String'];
  targetType: NotificationsTargetTypeEnum;
}

/** Autogenerated return type of Subscribe */
export interface SubscribePayload {
  __typename?: 'SubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface Subscription {
  __typename?: 'Subscription';
  activityAdded: Activity;
  bookingDocumentGeneration: BookingDocument;
  cargoSummaryUpdated: CargoSummary;
  unreadNotificationsCounterUpdated: Scalars['Int'];
  webhookEventOccurred?: Maybe<WebhookSubscriptionEvent>;
}

export type SubscriptionActivityAddedArgs = {
  targetId: Scalars['String'];
  targetType: ActivityFeedTargetTypeEnum;
};

export type SubscriptionBookingDocumentGenerationArgs = {
  zencargoReference: Scalars['String'];
};

export type SubscriptionCargoSummaryUpdatedArgs = {
  zencargoReference: Scalars['String'];
};

export type SubscriptionWebhookEventOccurredArgs = {
  topic: WebhookSubscriptionTopicEnum;
};

export interface Supplier {
  __typename?: 'Supplier';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
}

export interface TargetNotifications {
  __typename?: 'TargetNotifications';
  lastNotificationItem: LastNotificationItem;
  targetId: Scalars['String'];
  targetType: NotificationsTargetTypeEnum;
  unreadNotificationIds: Array<Scalars['String']>;
}

/** The connection type for TargetNotifications. */
export interface TargetNotificationsConnection {
  __typename?: 'TargetNotificationsConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TargetNotificationsEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<TargetNotifications>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface TargetNotificationsEdge {
  __typename?: 'TargetNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<TargetNotifications>;
}

export interface TargetSettings {
  __typename?: 'TargetSettings';
  settingKeyValuePairs: Array<SettingKeyValue>;
  targetType: NotificationsTargetTypeEnum;
}

export interface TargetSettingsInput {
  settingKeyValuePairs: Array<InputMaybe<SettingKeyValueInput>>;
  targetType: NotificationsTargetTypeEnum;
}

export enum TargetTypeValue {
  BOOKING = 'booking',
  BOOKING_CARGO = 'booking_cargo',
  BOOKING_NOTIFIED_PARTY = 'booking_notified_party',
  CLIENT_LOGISTICS = 'client_logistics',
  CONSOLIDATED_SHIPMENT = 'consolidated_shipment',
  CONSOLIDATED_SHIPMENT_LEG = 'consolidated_shipment_leg',
  PURCHASE_ORDER = 'purchase_order',
  TRADE_LANE = 'trade_lane'
}

export interface TaskDetailsFiltersInput {
  account?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  after?: InputMaybe<Scalars['DateTime']>;
  assignee?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  before?: InputMaybe<Scalars['DateTime']>;
  businessKey?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  milestone?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modeOfTransport?: InputMaybe<Array<InputMaybe<ModeOfTransport>>>;
  onlyCurrentUsersTasks?: InputMaybe<Scalars['Boolean']>;
  onlyUnassignedTasks?: InputMaybe<Scalars['Boolean']>;
  priority?: InputMaybe<Scalars['Int']>;
  taskName?: InputMaybe<Scalars['String']>;
}

export interface TaskList {
  __typename?: 'TaskList';
  canAccessRoute: AuthorizationResult;
}

export interface Temperature {
  __typename?: 'Temperature';
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface TemperatureInput {
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface Terminal {
  __typename?: 'Terminal';
  functionField?: Maybe<Scalars['String']>;
  iata?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  /** Description of terminal (name + unlocode) */
  label?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  remarks?: Maybe<Scalars['String']>;
  subDiv?: Maybe<Scalars['String']>;
  /** The type of the terminal */
  terminalTypes?: Maybe<Array<TerminalTypeValue>>;
  timeZone?: Maybe<Scalars['String']>;
  /** Unlocode of the terminal */
  unlocode: Scalars['String'];
}

/** The connection type for Terminal. */
export interface TerminalConnection {
  __typename?: 'TerminalConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<TerminalEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Terminal>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface TerminalEdge {
  __typename?: 'TerminalEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Terminal>;
}

export enum TerminalTypeValue {
  AIRPORT = 'airport',
  RAIL_TERMINAL = 'rail_terminal',
  ROAD_TERMINAL = 'road_terminal',
  SEAPORT = 'seaport'
}

export interface TextMessage {
  __typename?: 'TextMessage';
  archivedAt?: Maybe<Scalars['ISO8601DateTime']>;
  canArchive: AuthorizationResult;
  content?: Maybe<Scalars['String']>;
  id: Scalars['String'];
}

export interface TextMessagesInput {
  ids: Array<Scalars['String']>;
  targetId: Scalars['String'];
  targetType: ActivityFeedTargetTypeEnum;
}

export interface TimeRange {
  __typename?: 'TimeRange';
  endTime: Scalars['ISO8601Time'];
  startTime: Scalars['ISO8601Time'];
}

export interface TimeRangeInput {
  endTime: Scalars['ISO8601Time'];
  startTime: Scalars['ISO8601Time'];
}

export interface TimedCollection {
  mode: TimedMode;
  specificTime?: InputMaybe<SpecificTimeInput>;
}

export interface TimedDelivery {
  mode: TimedMode;
  specificTime?: InputMaybe<SpecificTimeInput>;
}

export enum TimedMode {
  AM = 'am',
  PM = 'pm',
  SPECIFIC = 'specific'
}

/** Autogenerated input type of ToggleAutoSubscriptions */
export interface ToggleAutoSubscriptionsInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
}

/** Autogenerated return type of ToggleAutoSubscriptions */
export interface ToggleAutoSubscriptionsPayload {
  __typename?: 'ToggleAutoSubscriptionsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface ToggleJourneyCollectionInput {
  isEnabled: Scalars['Boolean'];
  zencargoReference: Scalars['String'];
}

export interface ToggleJourneyCollectionPayload {
  __typename?: 'ToggleJourneyCollectionPayload';
  errors?: Maybe<Array<Error>>;
}

export interface ToggleOceanTrackingInput {
  isEnabled: Scalars['Boolean'];
  reason?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface ToggleOceanTrackingPayload {
  __typename?: 'ToggleOceanTrackingPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of TrackFiltersUsage */
export interface TrackFiltersUsageInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  payload: Scalars['String'];
}

/** Autogenerated return type of TrackFiltersUsage */
export interface TrackFiltersUsagePayload {
  __typename?: 'TrackFiltersUsagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface TradeLane {
  __typename?: 'TradeLane';
  destination?: Maybe<Location>;
  hashValue: Scalars['String'];
  id: Scalars['String'];
  importExport?: Maybe<Scalars['String']>;
  origin?: Maybe<Location>;
}

export enum TradeRole {
  BUYER = 'buyer',
  SELLER = 'seller',
  TRANSFER = 'transfer'
}

export interface TruckInput {
  actualCbm?: InputMaybe<Scalars['Float']>;
  dimensionsWithMetric?: InputMaybe<CargoDimensionsInputType>;
  forklift?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous: Scalars['Boolean'];
  healthCertificate?: InputMaybe<Scalars['Boolean']>;
  looseCargoType?: InputMaybe<LooseCargoTypeEnum>;
  palletType?: InputMaybe<PalletTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
  reefer: Scalars['Boolean'];
  riskLevel?: InputMaybe<RiskLevelsEnum>;
  tailLift: Scalars['Boolean'];
  valueOfGoods?: InputMaybe<MoneyInput>;
  vehicleType: Scalars['String'];
}

export enum TruckSwapEnum {
  NOT_TRUCK_SWAP = 'NOT_TRUCK_SWAP',
  TRUCK_SWAP = 'TRUCK_SWAP',
  UNKNOWN = 'UNKNOWN'
}

export interface UnarchiveAccountLocationPayload {
  __typename?: 'UnarchiveAccountLocationPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UnarchiveProduct */
export interface UnarchiveProductInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID (pk) of product to be unarchived */
  id: Scalars['String'];
}

/** Autogenerated return type of UnarchiveProduct */
export interface UnarchiveProductPayload {
  __typename?: 'UnarchiveProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Product>;
}

export interface UnassignContractInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UnassignContractPayload {
  __typename?: 'UnassignContractPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UnassignNotifiedParty */
export interface UnassignNotifiedPartyInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  notifiedPartyId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UnassignNotifiedParty */
export interface UnassignNotifiedPartyPayload {
  __typename?: 'UnassignNotifiedPartyPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UnpinBooking */
export interface UnpinBookingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UnpinBooking */
export interface UnpinBookingPayload {
  __typename?: 'UnpinBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of Unsubscribe */
export interface UnsubscribeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  subscriberId: Scalars['String'];
  targetId: Scalars['String'];
  targetType: NotificationsTargetTypeEnum;
}

/** Autogenerated return type of Unsubscribe */
export interface UnsubscribePayload {
  __typename?: 'UnsubscribePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountBillingDetailsPayload {
  __typename?: 'UpdateAccountBillingDetailsPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountContactDetailsPayload {
  __typename?: 'UpdateAccountContactDetailsPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountDemoModeInput {
  accountId: Scalars['String'];
  demoMode: Scalars['Boolean'];
}

export interface UpdateAccountDemoModePayload {
  __typename?: 'UpdateAccountDemoModePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountHeadquartersInput {
  accountId: Scalars['String'];
  companyName: Scalars['String'];
  newHeadquartersLocationId: Scalars['String'];
}

export interface UpdateAccountHeadquartersPayload {
  __typename?: 'UpdateAccountHeadquartersPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountLegalDetailsInput {
  accountId: Scalars['String'];
  legalInformation: AccountLegalInformationInput;
}

export interface UpdateAccountLegalDetailsPayload {
  __typename?: 'UpdateAccountLegalDetailsPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountLocationAddressInput {
  address: AddressInput;
  geolocation: GeolocationInput;
  locationId: Scalars['String'];
}

export interface UpdateAccountLocationAddressPayload {
  __typename?: 'UpdateAccountLocationAddressPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountLocationBusinessHoursInput {
  businessHours: BusinessHoursInput;
  locationId: Scalars['String'];
}

export interface UpdateAccountLocationBusinessHoursPayload {
  __typename?: 'UpdateAccountLocationBusinessHoursPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountLocationDetailsInput {
  companyName: Scalars['String'];
  locationId: Scalars['String'];
  name: Scalars['String'];
  usageContext: UsageContext;
}

export interface UpdateAccountLocationDetailsPayload {
  __typename?: 'UpdateAccountLocationDetailsPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountLocationNameInput {
  locationId: Scalars['String'];
  name: Scalars['String'];
}

export interface UpdateAccountLocationNamePayload {
  __typename?: 'UpdateAccountLocationNamePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountMainDetailsInput {
  accountId: Scalars['String'];
  referencePrefix: Scalars['String'];
  tradingName: Scalars['String'];
}

export interface UpdateAccountMainDetailsPayload {
  __typename?: 'UpdateAccountMainDetailsPayload';
  accountId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountMemberBusinessUnitsInput {
  businessUnits: Array<Scalars['String']>;
  memberId: Scalars['String'];
}

export interface UpdateAccountMemberBusinessUnitsPayload {
  __typename?: 'UpdateAccountMemberBusinessUnitsPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountMemberProfileInformationInput {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
}

export interface UpdateAccountMemberProfileInformationPayload {
  __typename?: 'UpdateAccountMemberProfileInformationPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountMemberUserGroupInput {
  accountId: Scalars['String'];
  memberId: Scalars['String'];
  userGroupId: Scalars['String'];
}

export interface UpdateAccountMemberUserGroupPayload {
  __typename?: 'UpdateAccountMemberUserGroupPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAccountReferencePrefixInput {
  accountId: Scalars['String'];
  newReferencePrefix: Scalars['String'];
}

export interface UpdateAccountReferencePrefixPayload {
  __typename?: 'UpdateAccountReferencePrefixPayload';
  accountId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  newReferencePrefix?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateAccrualMutation */
export interface UpdateAccrualMutationInput {
  accrualParams: AccrualInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of UpdateAccrualMutation */
export interface UpdateAccrualMutationPayload {
  __typename?: 'UpdateAccrualMutationPayload';
  accrual?: Maybe<Accrual>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateActionItem */
export interface UpdateActionItemInput {
  actionType: ActionItemActionType;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  title: Scalars['String'];
}

/** Autogenerated return type of UpdateActionItem */
export interface UpdateActionItemPayload {
  __typename?: 'UpdateActionItemPayload';
  actionItemType?: Maybe<ActionItemTypesActionItemType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateAirlineCarrierInput {
  airlineCarrierName?: InputMaybe<Scalars['String']>;
  carrierIata?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

export interface UpdateAirlineCarrierPayload {
  __typename?: 'UpdateAirlineCarrierPayload';
  airlineCarrier?: Maybe<AirlineCarrier>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateAssignedLotsToBooking */
export interface UpdateAssignedLotsToBookingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  lotIdsToAssign?: InputMaybe<Array<Scalars['String']>>;
  lotIdsToUnassign?: InputMaybe<Array<Scalars['String']>>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateAssignedLotsToBooking */
export interface UpdateAssignedLotsToBookingPayload {
  __typename?: 'UpdateAssignedLotsToBookingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateAssignedLotsToCargo */
export interface UpdateAssignedLotsToCargoInput {
  cargoId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  lotIdsToAssign?: InputMaybe<Array<Scalars['String']>>;
  lotIdsToUnassign?: InputMaybe<Array<Scalars['String']>>;
}

/** Autogenerated return type of UpdateAssignedLotsToCargo */
export interface UpdateAssignedLotsToCargoPayload {
  __typename?: 'UpdateAssignedLotsToCargoPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateBillingDetails */
export interface UpdateBillingDetailsInput {
  city?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateBillingDetails */
export interface UpdateBillingDetailsPayload {
  __typename?: 'UpdateBillingDetailsPayload';
  billingDetails: BillingDetails;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateBookingDocumentType */
export interface UpdateBookingDocumentTypeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  permissions: BookingDocumentTypePermissionsInputType;
  title?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateBookingDocumentType */
export interface UpdateBookingDocumentTypePayload {
  __typename?: 'UpdateBookingDocumentTypePayload';
  bookingDocumentType?: Maybe<BookingDocumentType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateBookingManagerAssignmentRule */
export interface UpdateBookingManagerAssignmentRuleInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  managerId: Scalars['String'];
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  shipmentType?: InputMaybe<Shipment>;
  team: ManagerTeam;
}

/** Autogenerated return type of UpdateBookingManagerAssignmentRule */
export interface UpdateBookingManagerAssignmentRulePayload {
  __typename?: 'UpdateBookingManagerAssignmentRulePayload';
  bookingManagerAssignmentRule?: Maybe<BookingManagerAssignmentRule>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateBookingsAssignedToConsolidatedShipment */
export interface UpdateBookingsAssignedToConsolidatedShipmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  consolidatedShipmentId: Scalars['String'];
  zencargoReferencesToAssign?: InputMaybe<Array<Scalars['String']>>;
  zencargoReferencesToUnassign?: InputMaybe<Array<Scalars['String']>>;
}

/** Autogenerated return type of UpdateBookingsAssignedToConsolidatedShipment */
export interface UpdateBookingsAssignedToConsolidatedShipmentPayload {
  __typename?: 'UpdateBookingsAssignedToConsolidatedShipmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateCargoInput {
  accountId?: InputMaybe<Scalars['String']>;
  cargoAssignments?: InputMaybe<Array<CargoAssignmentInput>>;
  cargoType: Scalars['String'];
  chargeableWeight?: InputMaybe<CargoWeightInput>;
  configurationId?: InputMaybe<Scalars['String']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerSealNumber?: InputMaybe<Scalars['String']>;
  customFields?: InputMaybe<Array<InputMaybe<CustomFieldInput>>>;
  dimensions?: InputMaybe<DimensionsInput>;
  dryStandard?: InputMaybe<Scalars['Boolean']>;
  flatRack?: InputMaybe<Scalars['Boolean']>;
  forklift?: InputMaybe<Scalars['Boolean']>;
  garmentsOnHangers?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<CargoWeightInput>;
  hazardous?: InputMaybe<Scalars['Boolean']>;
  healthCertificate?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  openTop?: InputMaybe<Scalars['Boolean']>;
  overweight?: InputMaybe<Scalars['Boolean']>;
  quantity?: InputMaybe<Scalars['Int']>;
  refrigerated?: InputMaybe<Scalars['Boolean']>;
  riskLevel?: InputMaybe<Scalars['String']>;
  stackable?: InputMaybe<Scalars['Boolean']>;
  tailLift?: InputMaybe<Scalars['Boolean']>;
  temperature?: InputMaybe<TemperatureInput>;
  trailerId?: InputMaybe<Scalars['String']>;
  unCode?: InputMaybe<Scalars['String']>;
  valueOfGoods?: InputMaybe<ValueOfGoodsInput>;
  volume?: InputMaybe<VolumeInput>;
}

/** Autogenerated input type of UpdateCargoMode */
export interface UpdateCargoModeInput {
  cargoMode: CargoModeEnum;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateCargoMode */
export interface UpdateCargoModePayload {
  __typename?: 'UpdateCargoModePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateCargoPayload {
  __typename?: 'UpdateCargoPayload';
  coreCargo?: Maybe<CoreCargo>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateCarrierBookingReference */
export interface UpdateCarrierBookingReferenceInput {
  carrierBookingReference: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateCarrierBookingReference */
export interface UpdateCarrierBookingReferencePayload {
  __typename?: 'UpdateCarrierBookingReferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateChargeTypeMutation */
export interface UpdateChargeTypeMutationInput {
  chargeTypeParams: ChargeTypeInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
}

/** Autogenerated return type of UpdateChargeTypeMutation */
export interface UpdateChargeTypeMutationPayload {
  __typename?: 'UpdateChargeTypeMutationPayload';
  chargeType?: Maybe<ChargeType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateClientReference */
export interface UpdateClientReferenceInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  clientReference: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateClientReference */
export interface UpdateClientReferencePayload {
  __typename?: 'UpdateClientReferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  clientReference: Scalars['String'];
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateCollectionDates */
export interface UpdateCollectionDatesInput {
  arrivedAtWarehouse?: InputMaybe<LocalDateTimeInput>;
  cargoId: Scalars['String'];
  clearSchedule?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confirmedByForwarder?: InputMaybe<LocalDateTimeRangeInput>;
  departedFromWarehouse?: InputMaybe<LocalDateTimeInput>;
  requestedWithForwarder?: InputMaybe<LocalDateTimeRangeInput>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateCollectionDates */
export interface UpdateCollectionDatesPayload {
  __typename?: 'UpdateCollectionDatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection?: Maybe<CollectionDetails>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateCollection */
export interface UpdateCollectionInput {
  /** Cargo UUID */
  cargoId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectionReference?: InputMaybe<Scalars['String']>;
  driverDetails?: InputMaybe<Scalars['String']>;
  vehiclePlateNumber?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateCollection */
export interface UpdateCollectionPayload {
  __typename?: 'UpdateCollectionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection?: Maybe<CollectionDetails>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateCollectionReference */
export interface UpdateCollectionReferenceInput {
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Cargo UUID */
  id: Scalars['String'];
  reference: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateCollectionReference */
export interface UpdateCollectionReferencePayload {
  __typename?: 'UpdateCollectionReferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  collection?: Maybe<CollectionDetails>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateCompanyDetails */
export interface UpdateCompanyDetailsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  companyEmailAddress?: InputMaybe<Scalars['String']>;
  companyPhoneNumber?: InputMaybe<Scalars['String']>;
  eoriNumber?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  registeredCompanyName?: InputMaybe<Scalars['String']>;
  vat?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateCompanyDetails */
export interface UpdateCompanyDetailsPayload {
  __typename?: 'UpdateCompanyDetailsPayload';
  account: LegacyAccount;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateConsolidatedShipment */
export interface UpdateConsolidatedShipmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  consolidatedShipment: ConsolidatedShipmentUpdateInput;
  consolidatedShipmentId: Scalars['String'];
}

/** Autogenerated return type of UpdateConsolidatedShipment */
export interface UpdateConsolidatedShipmentPayload {
  __typename?: 'UpdateConsolidatedShipmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  consolidatedShipment?: Maybe<ConsolidatedShipment>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateContact */
export interface UpdateContactInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contactParams: NetworkContactInput;
  id: Scalars['String'];
}

/** Autogenerated return type of UpdateContact */
export interface UpdateContactPayload {
  __typename?: 'UpdateContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<NetworkContact>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateContainerCargoItem */
export interface UpdateContainerCargoItemInput {
  actualCbm?: InputMaybe<Scalars['Float']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  containerSealNumber?: InputMaybe<Scalars['String']>;
  containerType: ContainerTypeEnum;
  dimensions?: InputMaybe<CargoDimensionsInputType>;
  dryStandard?: InputMaybe<Scalars['Boolean']>;
  flatRack?: InputMaybe<Scalars['Boolean']>;
  garmentsOnHangers?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous: Scalars['Boolean'];
  id: Scalars['String'];
  looseCargoType?: InputMaybe<LooseCargoTypeEnum>;
  openTop?: InputMaybe<Scalars['Boolean']>;
  overweight: Scalars['Boolean'];
  palletType?: InputMaybe<PalletTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
  reefer: Scalars['Boolean'];
}

/** Autogenerated return type of UpdateContainerCargoItem */
export interface UpdateContainerCargoItemPayload {
  __typename?: 'UpdateContainerCargoItemPayload';
  cargoItem?: Maybe<CargoCargoItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateContractInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  contract: ContractInput;
}

export interface UpdateCostTrackingCostItemStatusInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  status: CostTrackingCostItemStatus;
}

export interface UpdateCostTrackingItemCurrencyInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  currency: Currency;
}

export interface UpdateCostTrackingItemInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  costTrackingId: Scalars['String'];
  displayCurrency?: InputMaybe<Currency>;
  itemId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateCostTrackingItemNameInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  name: Scalars['String'];
}

export interface UpdateCostTrackingItemNoteInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  note: Scalars['String'];
}

export interface UpdateCostTrackingItemPaidFromInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  paidFrom: Scalars['String'];
}

export interface UpdateCostTrackingItemPaidToInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  paidTo: Scalars['String'];
}

export interface UpdateCostTrackingItemQuantityInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  quantity: Scalars['Float'];
}

export interface UpdateCostTrackingItemUnitPriceInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  unitPrice: Scalars['Float'];
}

export interface UpdateCostTrackingRevenueItemStatusInput {
  costTrackingItemInput: UpdateCostTrackingItemInput;
  status: CostTrackingRevenueItemStatus;
}

export interface UpdateCourierInput {
  courierName: Scalars['String'];
  id: Scalars['String'];
}

export interface UpdateCourierPayload {
  __typename?: 'UpdateCourierPayload';
  courier?: Maybe<Courier>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateCurrentUserProfilePayload {
  __typename?: 'UpdateCurrentUserProfilePayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateCustomerApproved */
export interface UpdateCustomerApprovedInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerApproved: Scalars['Boolean'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateCustomerApproved */
export interface UpdateCustomerApprovedPayload {
  __typename?: 'UpdateCustomerApprovedPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateCustomsOnly */
export interface UpdateCustomsOnlyInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  customsOnly: Scalars['Boolean'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateCustomsOnly */
export interface UpdateCustomsOnlyPayload {
  __typename?: 'UpdateCustomsOnlyPayload';
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateDefaultChargeItemBasisInput {
  chargeBasisIds: Array<Scalars['String']>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
}

export interface UpdateDefaultChargeItemCargoOptionsInput {
  cargoOptions: Array<CargoOptionEnum>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
}

export interface UpdateDefaultChargeItemCurrencyInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  currency: Currency;
  itemId: Scalars['String'];
}

export interface UpdateDefaultChargeItemNameInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  itemName: Scalars['String'];
}

export interface UpdateDefaultChargeItemPayload {
  __typename?: 'UpdateDefaultChargeItemPayload';
  errors?: Maybe<Array<Maybe<Error>>>;
  item?: Maybe<DefaultChargesPayload>;
}

export interface UpdateDefaultChargeItemUnitPriceInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  itemId: Scalars['String'];
  unitPrice: Scalars['Float'];
}

/** Autogenerated input type of UpdateDeliveryDates */
export interface UpdateDeliveryDatesInput {
  arrivedAtWarehouse?: InputMaybe<LocalDateTimeInput>;
  cargoId: Scalars['String'];
  clearSchedule?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  confirmedByForwarder?: InputMaybe<LocalDateTimeRangeInput>;
  requestedWithForwarder?: InputMaybe<LocalDateTimeRangeInput>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateDeliveryDates */
export interface UpdateDeliveryDatesPayload {
  __typename?: 'UpdateDeliveryDatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  delivery?: Maybe<DeliveryDetails>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateDelivery */
export interface UpdateDeliveryInput {
  /** Cargo UUID */
  cargoId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  deliveryReference?: InputMaybe<Scalars['String']>;
  driverDetails?: InputMaybe<Scalars['String']>;
  vehiclePlateNumber?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateDelivery */
export interface UpdateDeliveryPayload {
  __typename?: 'UpdateDeliveryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  delivery?: Maybe<DeliveryDetails>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateDeliveryReference */
export interface UpdateDeliveryReferenceInput {
  accountUuid: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Cargo UUID */
  id: Scalars['String'];
  reference: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateDeliveryReference */
export interface UpdateDeliveryReferencePayload {
  __typename?: 'UpdateDeliveryReferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  delivery?: Maybe<DeliveryDetails>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateDocumentTemplate */
export interface UpdateDocumentTemplateInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  externalId: Scalars['String'];
  externalKey: Scalars['String'];
  fields: DocumentTemplateSelectedFields;
  id: Scalars['Int'];
  name: Scalars['String'];
}

/** Autogenerated return type of UpdateDocumentTemplate */
export interface UpdateDocumentTemplatePayload {
  __typename?: 'UpdateDocumentTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  documentTemplate?: Maybe<DocumentTemplate>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateEdd */
export interface UpdateEddInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  uedd: Scalars['ISO8601Date'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateEdd */
export interface UpdateEddPayload {
  __typename?: 'UpdateEddPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateFlightNumberInput {
  flightNumber?: InputMaybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateFlightNumberPayload {
  __typename?: 'UpdateFlightNumberPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateForwarderReference */
export interface UpdateForwarderReferenceInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  forwarderReference: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateForwarderReference */
export interface UpdateForwarderReferencePayload {
  __typename?: 'UpdateForwarderReferencePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateHaulierInput {
  haulier: Scalars['String'];
  id: Scalars['String'];
}

export interface UpdateHaulierPayload {
  __typename?: 'UpdateHaulierPayload';
  errors?: Maybe<Array<Error>>;
  haulier?: Maybe<Haulier>;
}

/** Autogenerated input type of UpdateHouseBillOfLading */
export interface UpdateHouseBillOfLadingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  houseBillOfLading: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateHouseBillOfLading */
export interface UpdateHouseBillOfLadingPayload {
  __typename?: 'UpdateHouseBillOfLadingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateIncoterms */
export interface UpdateIncotermsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  incoterms: IncotermsValue;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateIncoterms */
export interface UpdateIncotermsPayload {
  __typename?: 'UpdateIncotermsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of Update */
export interface UpdateInput {
  bookingDocumentTypeName?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  permissions: BookingDocumentsPermissionsInputType;
}

/** Autogenerated input type of UpdateInvoicedOnMutation */
export interface UpdateInvoicedOnMutationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  invoicedOn?: InputMaybe<Scalars['Date']>;
}

/** Autogenerated return type of UpdateInvoicedOnMutation */
export interface UpdateInvoicedOnMutationPayload {
  __typename?: 'UpdateInvoicedOnMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateIssue */
export interface UpdateIssueInput {
  category?: InputMaybe<IssueCategory>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  occurredOn?: InputMaybe<Scalars['Date']>;
  problemDescription?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<IssueSeverity>;
  solutionDescription?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<IssueVisibilityInput>;
}

/** Autogenerated return type of UpdateIssue */
export interface UpdateIssuePayload {
  __typename?: 'UpdateIssuePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issue?: Maybe<Issue>;
}

/** Autogenerated input type of UpdateIssueTemplate */
export interface UpdateIssueTemplateInput {
  active?: InputMaybe<Scalars['Boolean']>;
  category?: InputMaybe<IssueCategory>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  problemDescription?: InputMaybe<Scalars['String']>;
  ruleId?: InputMaybe<Scalars['String']>;
  severity?: InputMaybe<IssueSeverity>;
  title?: InputMaybe<Scalars['String']>;
  visibility?: InputMaybe<IssueVisibilityInput>;
}

/** Autogenerated return type of UpdateIssueTemplate */
export interface UpdateIssueTemplatePayload {
  __typename?: 'UpdateIssueTemplatePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  issueTemplate?: Maybe<IssueTemplate>;
}

export interface UpdateJourneyAirlineInput {
  airline?: InputMaybe<Scalars['String']>;
  cargoIds: Array<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyAirlinePayload {
  __typename?: 'UpdateJourneyAirlinePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyCarrierBookingReferenceInput {
  cargoIds: Array<Scalars['String']>;
  carrierBookingReference: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyCarrierBookingReferencePayload {
  __typename?: 'UpdateJourneyCarrierBookingReferencePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyCourierInput {
  cargoIds: Array<Scalars['String']>;
  courier: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyCourierPayload {
  __typename?: 'UpdateJourneyCourierPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyMasterAirwayBillInput {
  cargoIds: Array<Scalars['String']>;
  masterAirwayBill?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyMasterAirwayBillPayload {
  __typename?: 'UpdateJourneyMasterAirwayBillPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyMasterBillOfLadingInput {
  cargoIds: Array<Scalars['String']>;
  masterBillOfLading: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyMasterBillOfLadingPayload {
  __typename?: 'UpdateJourneyMasterBillOfLadingPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyRailwayCompanyInput {
  cargoIds: Array<Scalars['String']>;
  railwayCompany?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyRailwayCompanyPayload {
  __typename?: 'UpdateJourneyRailwayCompanyPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyScacInput {
  cargoIds: Array<Scalars['String']>;
  scac?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyScacPayload {
  __typename?: 'UpdateJourneyScacPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyScheduleInput {
  cargoIds: Array<Scalars['String']>;
  carrier: Scalars['String'];
  isCourier: Scalars['Boolean'];
  scheduledLegs: Array<JourneyScheduledLegInput>;
  trackingNumber?: InputMaybe<Scalars['String']>;
  updateType: UpdateScheduleType;
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneySchedulePayload {
  __typename?: 'UpdateJourneySchedulePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneySoReleaseDateInput {
  cargoIds: Array<Scalars['String']>;
  soReleaseDate?: InputMaybe<Scalars['ISO8601Date']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneySoReleaseDatePayload {
  __typename?: 'UpdateJourneySoReleaseDatePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneySoReleasedPayload {
  __typename?: 'UpdateJourneySoReleasedPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyStopReferenceInput {
  journeyStopId: Scalars['String'];
  reference?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyStopReferencePayload {
  __typename?: 'UpdateJourneyStopReferencePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneyTrackingNumberInput {
  cargoIds: Array<Scalars['String']>;
  trackingNumber: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneyTrackingNumberPayload {
  __typename?: 'UpdateJourneyTrackingNumberPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateJourneysCargoReadyDateInput {
  date: Scalars['ISO8601Date'];
  zencargoReference: Scalars['String'];
}

export interface UpdateJourneysCargoReadyDatePayload {
  __typename?: 'UpdateJourneysCargoReadyDatePayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateLocationContact */
export interface UpdateLocationContactInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  contact: ContactInput;
  contactId: Scalars['String'];
  contactLocationAssignments: Array<ContactLocationAssignmentInput>;
  organisationId: Scalars['String'];
}

/** Autogenerated return type of UpdateLocationContact */
export interface UpdateLocationContactPayload {
  __typename?: 'UpdateLocationContactPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  contact?: Maybe<NetworksContact>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateLooseCargoItem */
export interface UpdateLooseCargoItemInput {
  actualCbm?: InputMaybe<Scalars['Float']>;
  chargeableWeight?: InputMaybe<CargoWeightInputType>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  containerNumber?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<CargoDimensionsInputType>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous: Scalars['Boolean'];
  id: Scalars['String'];
  looseCargoType: LooseCargoTypeEnum;
  palletType?: InputMaybe<PalletTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
  reefer: Scalars['Boolean'];
  stackable?: InputMaybe<Scalars['Boolean']>;
  trailerId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateLooseCargoItem */
export interface UpdateLooseCargoItemPayload {
  __typename?: 'UpdateLooseCargoItemPayload';
  cargoItem?: Maybe<CargoCargoItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateLotEstimates */
export interface UpdateLotEstimatesInput {
  /**
   * Provide Zencargo with the new date the goods are expected to be ready from the place of origin,
   * and optional associated metadata such as the reason for the change and whether this is an estimate or not.
   * See the guides for more information.
   */
  cargoReadyDate: RichDateInput;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /**
   * Provide Zencargo with the new date the goods are expected to be delivered to the destination warehouse,
   * and optional associated metadata such as the reason for the change and whether this is an estimate or not.
   * See the guides for more information.
   */
  deliveryEstimate?: InputMaybe<RichDateInput>;
  /** The Zencargo-generated IDs of the lots you are updating */
  lotIds: Array<Scalars['String']>;
}

/** Autogenerated return type of UpdateLotEstimates */
export interface UpdateLotEstimatesPayload {
  __typename?: 'UpdateLotEstimatesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  /** Returns the updated lots objects */
  lots?: Maybe<Array<Lot>>;
  /** Returns the purchase order object that contains the updated lots */
  purchaseOrder?: Maybe<PurchaseOrder>;
}

/** Autogenerated input type of UpdateLots */
export interface UpdateLotsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** The Zencargo-generated IDs of the lots you are deleting */
  lotIdsToDelete?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Input lot data - this argument is required.
   * Provide an empty array if you do not want to update this data
   */
  lots: Array<LotInput>;
  /** The Zencargo-generated ID of the line item you are updating */
  orderedLineItemId: Scalars['String'];
}

/** Autogenerated return type of UpdateLots */
export interface UpdateLotsPayload {
  __typename?: 'UpdateLotsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  /** Returns the purchase order object that contains the updated lots */
  purchaseOrder?: Maybe<PurchaseOrder>;
}

/** Autogenerated input type of UpdateMasterBillOfLading */
export interface UpdateMasterBillOfLadingInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  masterBillOfLading: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateMasterBillOfLading */
export interface UpdateMasterBillOfLadingPayload {
  __typename?: 'UpdateMasterBillOfLadingPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateMilestoneActualDateInput {
  dateTime: LocalDateTimeInput;
  milestoneId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateMilestoneActualDatePayload {
  __typename?: 'UpdateMilestoneActualDatePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateMilestoneDelayInput {
  delayId: Scalars['String'];
  delayReasonId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateMilestoneDelayPayload {
  __typename?: 'UpdateMilestoneDelayPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateMilestonePlannedDateInput {
  date: LocalDateTimeRangeInput;
  milestoneId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateMilestonePlannedDatePayload {
  __typename?: 'UpdateMilestonePlannedDatePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateMilestoneRequestedDateInput {
  date: LocalDateTimeRangeInput;
  milestoneId: Scalars['String'];
  requestedBy?: InputMaybe<JourneyShippingMilestoneRequestedByEnum>;
  zencargoReference: Scalars['String'];
}

export interface UpdateMilestoneRequestedDatePayload {
  __typename?: 'UpdateMilestoneRequestedDatePayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateMilestonesCargoReadyDateInput {
  date: Scalars['ISO8601Date'];
  milestoneIds: Array<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateMilestonesCargoReadyDatePayload {
  __typename?: 'UpdateMilestonesCargoReadyDatePayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateMyOrganisation */
export interface UpdateMyOrganisationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  eori?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationId: Scalars['String'];
  vat?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateMyOrganisation */
export interface UpdateMyOrganisationPayload {
  __typename?: 'UpdateMyOrganisationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  organisation?: Maybe<MyOrganisation>;
}

/** Autogenerated input type of UpdateNoteMutation */
export interface UpdateNoteMutationInput {
  body: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  type: Scalars['String'];
}

/** Autogenerated return type of UpdateNoteMutation */
export interface UpdateNoteMutationPayload {
  __typename?: 'UpdateNoteMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  note?: Maybe<Note>;
}

export interface UpdateOceanCarrierInput {
  carrierScac?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  oceanCarrierName?: InputMaybe<Scalars['String']>;
  synonyms?: InputMaybe<Array<Scalars['String']>>;
}

export interface UpdateOceanCarrierPayload {
  __typename?: 'UpdateOceanCarrierPayload';
  errors?: Maybe<Array<Error>>;
  oceanCarrier?: Maybe<OceanCarrier>;
}

/** Autogenerated input type of UpdateOrder */
export interface UpdateOrderInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Purchase order data to update. See inside for info - this argument is required */
  purchaseOrder: UpdatePurchaseOrderInput;
  /** ID (pk) of purchase order to be updated - this argument is required */
  purchaseOrderId: Scalars['String'];
}

/** Autogenerated return type of UpdateOrder */
export interface UpdateOrderPayload {
  __typename?: 'UpdateOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  /** Returns the created purchase order object */
  purchaseOrder?: Maybe<PurchaseOrder>;
}

/**
 * Provide Zencargo with original, expected information about a specific product in a purchase order.
 * Zencargo uses values in this payload to calculate the basis for on time and in full.
 * Values here only update the ordered information, and should be used to store the original
 * "on time in full" baselines. It should not be used, for instance, to update
 * the latest known info, such as an updated quantity or latest cargo ready date, which is data stored
 * against a Lot. Refer to the purchase orders guide for more information.
 */
export interface UpdateOrderedLineItemInput {
  /** Cubic metre/volume of product */
  cbm?: InputMaybe<Scalars['Float']>;
  /** The id of the line item in the ERP system */
  erpLineId?: InputMaybe<Scalars['String']>;
  /** Ordered line item ID (pk) */
  id?: InputMaybe<Scalars['String']>;
  /**
   * Also known as "goods ready date" or "ex-factory date",
   * this is the date the goods were originally expected to be ready for collection from the place of origin
   */
  initialCargoReadyDate?: InputMaybe<Scalars['Date']>;
  /** Product sku code */
  productSku?: InputMaybe<Scalars['String']>;
  /** Quantity of product ordered */
  quantityOrdered?: InputMaybe<Scalars['Int']>;
  /**
   * The date the order was originally
   * supposed to be delivered to the destination by.
   * This represents the date after which the Purchase Order will be considered "late"
   */
  requiredDeliveryDate?: InputMaybe<Scalars['Date']>;
  /** Total cost */
  totalCost?: InputMaybe<MoneyInput>;
}

/** Autogenerated input type of UpdateOrganisationLocation */
export interface UpdateOrganisationLocationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  location: LocationInput;
  locationId: Scalars['String'];
  organisationId: Scalars['String'];
}

/** Autogenerated return type of UpdateOrganisationLocation */
export interface UpdateOrganisationLocationPayload {
  __typename?: 'UpdateOrganisationLocationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  location?: Maybe<NetworksLocation>;
  orgLoc?: Maybe<NetworksOrgLoc>;
}

/** Autogenerated input type of UpdateOriginAgentAssignmentRule */
export interface UpdateOriginAgentAssignmentRuleInput {
  accountId?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectionWarehouseId?: InputMaybe<Scalars['String']>;
  deliveryWarehouseId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  originAgentId?: InputMaybe<Scalars['String']>;
  portOfDestinationUnlocode?: InputMaybe<Scalars['String']>;
  portOfLoadUnlocode?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateOriginAgentAssignmentRule */
export interface UpdateOriginAgentAssignmentRulePayload {
  __typename?: 'UpdateOriginAgentAssignmentRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  originAgentAssignmentRule?: Maybe<OriginAgentAssignmentRule>;
}

/** Autogenerated return type of Update */
export interface UpdatePayload {
  __typename?: 'UpdatePayload';
  bookingDocument?: Maybe<BookingDocument>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdatePricingRequired */
export interface UpdatePricingRequiredInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  pricingRequired: Scalars['Boolean'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdatePricingRequired */
export interface UpdatePricingRequiredPayload {
  __typename?: 'UpdatePricingRequiredPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateProduct */
export interface UpdateProductInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** ID (pk) of product to be updated  - this argument is required */
  id: Scalars['String'];
  /** Product data to update - this argument is required */
  product: ProductInput;
}

/** Autogenerated return type of UpdateProduct */
export interface UpdateProductPayload {
  __typename?: 'UpdateProductPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  product?: Maybe<Product>;
}

/** Autogenerated input type of UpdatePurchaseOrderDelayReason */
export interface UpdatePurchaseOrderDelayReasonInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  reason: Scalars['String'];
  responsibleParty?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdatePurchaseOrderDelayReason */
export interface UpdatePurchaseOrderDelayReasonPayload {
  __typename?: 'UpdatePurchaseOrderDelayReasonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  purchaseOrderDelayReason?: Maybe<PurchaseOrderDelayReason>;
}

/**
 * Updates values against the PurchaseOrder or OrderedLineItem objects. Useful for removing
 * data you sent in error or deleting purchase orders that have been cancelled in your ERP.
 * CAUTION! This mutation is used to update original expected values. Only use this mutation
 * if you want to change the original values Zencargo uses to calculated the expected or estimated
 * attributes about a Purchase Order. You cannot update the fulfilment information using this
 * mutation. See The Purchase Orders guide for more information.
 */
export interface UpdatePurchaseOrderInput {
  /**
   * Location ID of the buyer of this purchase order. This should be the ID of an existing location
   * within your network. See the networks API for details
   */
  buyerId?: InputMaybe<Scalars['String']>;
  /**
   * Location ID of the destination of this purchase order. This should be the ID of an existing location
   * within your network. See the networks API for details
   */
  destinationId?: InputMaybe<Scalars['String']>;
  forwarderId?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  /** Incoterms this purchase order was raised under */
  incoterms?: InputMaybe<IncotermsValue>;
  /**
   * 'Add IDs of ordered line items to be deleted - this argument is
   * required. Provide an empty array if you do not want to update this data
   */
  lineItemIdsToDelete?: InputMaybe<Array<Scalars['String']>>;
  /**
   * Account ID of the supplier for this purchase order. This must be the ID of a supplier within your network.
   * Refer to the AccountConnection API for further details.
   */
  manufacturerId?: InputMaybe<Scalars['String']>;
  /** Optional argument for defining mode of transport, which can be air, ocean, truck, or rail */
  modeOfTransport?: InputMaybe<ModeOfTransport>;
  /** Date the PO was confirmed with the manufacturer */
  orderDate?: InputMaybe<Scalars['Date']>;
  /** Reference number assigned to this purchase order by your ERP */
  orderReferenceNumber?: InputMaybe<Scalars['String']>;
  /**
   * Input ordered line item data - this argument is required.
   * Provide an empty array if you do not want to update this data
   */
  orderedLineItems?: InputMaybe<Array<UpdateOrderedLineItemInput>>;
  /**
   * ID of the agent for this purchase order. This should be the ID of an existing agent
   * within your network. Contact your Zencargo representative if you are unsure what to enter here
   */
  originAgentId?: InputMaybe<Scalars['String']>;
  /**
   * Location ID of the place of origin of this purchase order. This should be the ID of an existing location
   * within your network. See the networks API for details
   */
  originId?: InputMaybe<Scalars['String']>;
  /** UNlocode of the port of destination expected for this order. See https://locode.info for codes */
  portOfDestinationUnlocode?: InputMaybe<Scalars['String']>;
  /** UNlocode of the port of load expected for this order. See https://locode.info for codes */
  portOfLoadUnlocode?: InputMaybe<Scalars['String']>;
  /**
   * Location ID of the seller of this purchase order. Note: this is not the same as the manufacturer.
   * This should be the ID of an existing location within your network. See the networks API for details
   */
  sellerId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateQuoteOptionDescription */
export interface UpdateQuoteOptionDescriptionInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  quoteOptionId: Scalars['String'];
}

/** Autogenerated return type of UpdateQuoteOptionDescription */
export interface UpdateQuoteOptionDescriptionPayload {
  __typename?: 'UpdateQuoteOptionDescriptionPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  quoteOption?: Maybe<QuoteOption>;
}

export interface UpdateRailwayCompanyInput {
  id: Scalars['String'];
  railwayCompany: Scalars['String'];
}

export interface UpdateRailwayCompanyPayload {
  __typename?: 'UpdateRailwayCompanyPayload';
  errors?: Maybe<Array<Error>>;
  railwayCompany?: Maybe<RailwayCompany>;
}

export interface UpdateRateCardInput {
  charges: Array<CreateRateCardChargeInput>;
  clientMutationId?: InputMaybe<Scalars['String']>;
  customerId: Scalars['String'];
  endDate: Scalars['Date'];
  issuedBy: Scalars['String'];
  name: Scalars['String'];
  note?: InputMaybe<Scalars['String']>;
  rateCardId: Scalars['String'];
  startDate: Scalars['Date'];
  updatedBy: Scalars['String'];
}

export interface UpdateRateCardPayload {
  __typename?: 'UpdateRateCardPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Error>>>;
  rateCardId?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UpdateRoadShipment */
export interface UpdateRoadShipmentInput {
  bookingInput?: InputMaybe<RoadShipmentInput>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateRoadShipment */
export interface UpdateRoadShipmentPayload {
  __typename?: 'UpdateRoadShipmentPayload';
  /** Returns the updated booking */
  booking?: Maybe<Booking>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateRoadTrackingMissingData */
export interface UpdateRoadTrackingMissingDataInput {
  cargoId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  collectionConfirmedByForwarder?: InputMaybe<LocalDateTimeRangeInput>;
  collectionWarehouseId?: InputMaybe<Scalars['String']>;
  deliveryConfirmedByForwarder?: InputMaybe<LocalDateTimeRangeInput>;
  deliveryWarehouseId?: InputMaybe<Scalars['String']>;
  haulierId?: InputMaybe<Scalars['String']>;
  trailerId?: InputMaybe<Scalars['String']>;
  vehiclePlateNumber?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateRoadTrackingMissingData */
export interface UpdateRoadTrackingMissingDataPayload {
  __typename?: 'UpdateRoadTrackingMissingDataPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateRule */
export interface UpdateRuleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  enabled: Scalars['Boolean'];
  evaluationCode: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
  query: Scalars['String'];
  tags?: InputMaybe<Array<Scalars['String']>>;
  validationEntityId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateRule */
export interface UpdateRulePayload {
  __typename?: 'UpdateRulePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  rule?: Maybe<Rule>;
}

export enum UpdateScheduleType {
  ADD_REQUESTED_SCHEDULE = 'ADD_REQUESTED_SCHEDULE',
  UPDATE_PLANNED_SCHEDULE = 'UPDATE_PLANNED_SCHEDULE'
}

/** Autogenerated input type of UpdateSettings */
export interface UpdateSettingsInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  emailDelay: Scalars['Int'];
  enabled: Scalars['Boolean'];
  settingsPerTarget: Array<TargetSettingsInput>;
}

/** Autogenerated return type of UpdateSettings */
export interface UpdateSettingsPayload {
  __typename?: 'UpdateSettingsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateShipmentActionType */
export interface UpdateShipmentActionTypeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateShipmentActionType */
export interface UpdateShipmentActionTypePayload {
  __typename?: 'UpdateShipmentActionTypePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  shipmentActionType?: Maybe<ShipmentActionType>;
}

/** Autogenerated input type of UpdateShipmentDelayReason */
export interface UpdateShipmentDelayReasonInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['Int'];
  reason: Scalars['String'];
}

/** Autogenerated return type of UpdateShipmentDelayReason */
export interface UpdateShipmentDelayReasonPayload {
  __typename?: 'UpdateShipmentDelayReasonPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  shipmentDelayReason?: Maybe<ShipmentDelayReason>;
}

/** Autogenerated input type of UpdateShipment */
export interface UpdateShipmentInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  shipmentType: Shipment;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateShipment */
export interface UpdateShipmentPayload {
  __typename?: 'UpdateShipmentPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateShipmentStatus */
export interface UpdateShipmentStatusInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  status: Scalars['String'];
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateShipmentStatus */
export interface UpdateShipmentStatusPayload {
  __typename?: 'UpdateShipmentStatusPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  status?: Maybe<Scalars['String']>;
}

export interface UpdateShippingDelayReasonInput {
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  reason?: InputMaybe<Scalars['String']>;
  responsibleParty?: InputMaybe<ShippingDelayReasonResponsiblePartyEnum>;
}

export interface UpdateShippingDelayReasonPayload {
  __typename?: 'UpdateShippingDelayReasonPayload';
  errors?: Maybe<Array<Error>>;
  shippingDelayReason?: Maybe<ShippingDelayReason>;
}

/** Autogenerated input type of UpdateStage */
export interface UpdateStageInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  occurredAt?: InputMaybe<Scalars['ISO8601Date']>;
  stage: BookingStageEnum;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateStage */
export interface UpdateStagePayload {
  __typename?: 'UpdateStagePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateStopLocationInput {
  journeyStopId: Scalars['String'];
  /** locationValue can either be an id of a location or a terminal unlocode */
  locationValue: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateStopLocationPayload {
  __typename?: 'UpdateStopLocationPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateTerminal */
export interface UpdateTerminalInput {
  airportFunction?: InputMaybe<Scalars['Boolean']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  railTerminalFunction?: InputMaybe<Scalars['Boolean']>;
  roadTerminalFunction?: InputMaybe<Scalars['Boolean']>;
  seaportFunction?: InputMaybe<Scalars['Boolean']>;
}

/** Autogenerated return type of UpdateTerminal */
export interface UpdateTerminalPayload {
  __typename?: 'UpdateTerminalPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  terminal?: Maybe<Terminal>;
}

/** Autogenerated input type of UpdateTradeRole */
export interface UpdateTradeRoleInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  tradeRole: TradeRole;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateTradeRole */
export interface UpdateTradeRolePayload {
  __typename?: 'UpdateTradeRolePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateTrailerIdInput {
  trailerId?: InputMaybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateTrailerIdPayload {
  __typename?: 'UpdateTrailerIdPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateUserInput {
  autoSubscribeToOrganisationTargets?: InputMaybe<Scalars['Boolean']>;
  networkLocations: Array<NetworkLocation>;
  networkOrganisationId: Scalars['String'];
  userId: Scalars['String'];
  userProfile: UserProfile;
}

export interface UpdateUserPayload {
  __typename?: 'UpdateUserPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateUtilisationTarget */
export interface UpdateUtilisationTargetInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  containerType?: InputMaybe<ContainerTypeEnum>;
  supplierId?: InputMaybe<Scalars['String']>;
  utilisationTarget?: InputMaybe<Scalars['Float']>;
  utilisationTargetId: Scalars['String'];
}

/** Autogenerated return type of UpdateUtilisationTarget */
export interface UpdateUtilisationTargetPayload {
  __typename?: 'UpdateUtilisationTargetPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateVehicleCargoItem */
export interface UpdateVehicleCargoItemInput {
  actualCbm?: InputMaybe<Scalars['Float']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  dimensions?: InputMaybe<CargoDimensionsInputType>;
  forklift?: InputMaybe<Scalars['Boolean']>;
  grossWeight?: InputMaybe<CargoWeightInputType>;
  hazardous: Scalars['Boolean'];
  healthCertificate?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['String'];
  looseCargoType?: InputMaybe<LooseCargoTypeEnum>;
  palletType?: InputMaybe<PalletTypeEnum>;
  quantity?: InputMaybe<Scalars['Int']>;
  reefer: Scalars['Boolean'];
  riskLevel?: InputMaybe<RiskLevelsEnum>;
  tailLift: Scalars['Boolean'];
  trailerId?: InputMaybe<Scalars['String']>;
  valueOfGoods?: InputMaybe<MoneyInput>;
  vehicleType: VehicleTypeEnum;
}

/** Autogenerated return type of UpdateVehicleCargoItem */
export interface UpdateVehicleCargoItemPayload {
  __typename?: 'UpdateVehicleCargoItemPayload';
  cargoItem?: Maybe<CargoCargoItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateVehicleHaulierIdInput {
  haulierId?: InputMaybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateVehicleHaulierIdPayload {
  __typename?: 'UpdateVehicleHaulierIdPayload';
  errors?: Maybe<Array<Error>>;
}

export interface UpdateVehiclePlateNumberInput {
  plateNumber?: InputMaybe<Scalars['String']>;
  vehicleId: Scalars['String'];
  zencargoReference: Scalars['String'];
}

export interface UpdateVehiclePlateNumberPayload {
  __typename?: 'UpdateVehiclePlateNumberPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateVehicleTrailerId */
export interface UpdateVehicleTrailerIdInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  id: Scalars['String'];
  trailerId?: InputMaybe<Scalars['String']>;
}

/** Autogenerated return type of UpdateVehicleTrailerId */
export interface UpdateVehicleTrailerIdPayload {
  __typename?: 'UpdateVehicleTrailerIdPayload';
  cargoItem?: Maybe<CargoCargoItem>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateVesselNameInput {
  vehicleId: Scalars['String'];
  vesselName?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateVesselNamePayload {
  __typename?: 'UpdateVesselNamePayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateVoyageMilestoneDelay */
export interface UpdateVoyageMilestoneDelayInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  delayId: Scalars['String'];
  description: Scalars['String'];
  reason: Scalars['String'];
}

/** Autogenerated return type of UpdateVoyageMilestoneDelay */
export interface UpdateVoyageMilestoneDelayPayload {
  __typename?: 'UpdateVoyageMilestoneDelayPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateVoyageMilestoneTime */
export interface UpdateVoyageMilestoneTimeInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  milestoneName: VoyageMilestoneNameEnum;
  reason?: InputMaybe<Scalars['String']>;
  time: Scalars['ISO8601DateTime'];
  timeType: VoyageMilestoneTime;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of UpdateVoyageMilestoneTime */
export interface UpdateVoyageMilestoneTimePayload {
  __typename?: 'UpdateVoyageMilestoneTimePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  delay?: Maybe<VoyageDelay>;
  errors?: Maybe<Array<Error>>;
}

export interface UpdateVoyageNumberInput {
  vehicleId: Scalars['String'];
  voyageNumber?: InputMaybe<Scalars['String']>;
  zencargoReference: Scalars['String'];
}

export interface UpdateVoyageNumberPayload {
  __typename?: 'UpdateVoyageNumberPayload';
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UpdateWebhook */
export interface UpdateWebhookInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  topics?: InputMaybe<Array<WebhookSubscriptionTopicEnum>>;
  webhookId: Scalars['String'];
  webhookInput: WebhookInput;
}

/** Autogenerated return type of UpdateWebhook */
export interface UpdateWebhookPayload {
  __typename?: 'UpdateWebhookPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  webhook?: Maybe<WebhookSubscriptionsWebhook>;
}

export interface UpdatedBy {
  __typename?: 'UpdatedBy';
  /** Username of the user that updated */
  username?: Maybe<Scalars['String']>;
  /** Wether this user is from Zencargo or not */
  zencargoEmployee?: Maybe<Scalars['Boolean']>;
}

/** Autogenerated input type of UploadAccrualsMutation */
export interface UploadAccrualsMutationInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Signed blob ID generated via `createDirectUpload` mutation */
  signedBlobId: Scalars['String'];
}

/** Autogenerated return type of UploadAccrualsMutation */
export interface UploadAccrualsMutationPayload {
  __typename?: 'UploadAccrualsMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  result: UploadAccrualsResult;
}

export interface UploadAccrualsResult {
  __typename?: 'UploadAccrualsResult';
  recordsUpdated: Scalars['Int'];
  totalRows: Scalars['Int'];
}

export interface UploadCentralPortChargesInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  portCharges: Array<CreateCentralPortChargeInput>;
}

export interface UploadCentralPortChargesPayload {
  __typename?: 'UploadCentralPortChargesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

/** Autogenerated input type of UploadCommodityCodes */
export interface UploadCommodityCodesInput {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Signed blob ID generated via `createDirectUpload` mutation */
  signedBlobId: Scalars['String'];
}

/** Autogenerated return type of UploadCommodityCodes */
export interface UploadCommodityCodesPayload {
  __typename?: 'UploadCommodityCodesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  result?: Maybe<UploadCommodityCodesResult>;
}

export interface UploadCommodityCodesResult {
  __typename?: 'UploadCommodityCodesResult';
  created: Scalars['Int'];
  updated: Scalars['Int'];
}

export interface UploadDefaultPortChargesInput {
  clientMutationId?: InputMaybe<Scalars['String']>;
  portChargesInput: Array<CreateDefaultPortChargeInput>;
}

export interface UploadDefaultPortChargesPayload {
  __typename?: 'UploadDefaultPortChargesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  failedPortCharges?: Maybe<Array<FailedPortChargeItem>>;
  newCharges: Scalars['Int'];
  totalCharges: Scalars['Int'];
  updatedCharges: Scalars['Int'];
}

/** Autogenerated input type of Upload */
export interface UploadInput {
  accountUuid: Scalars['String'];
  attachmentId?: InputMaybe<Scalars['String']>;
  bookingDocumentTypeName?: InputMaybe<Scalars['String']>;
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  permissions: BookingDocumentsPermissionsInputType;
  zencargoReference: Scalars['String'];
}

/** Autogenerated return type of Upload */
export interface UploadPayload {
  __typename?: 'UploadPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
  id?: Maybe<Scalars['String']>;
}

/** Autogenerated input type of UploadProducts */
export interface UploadProductsInput {
  accountId: Scalars['String'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: InputMaybe<Scalars['String']>;
  /** Signed blob ID generated via `createDirectUpload` mutation */
  signedBlobId: Scalars['String'];
}

/** Autogenerated return type of UploadProducts */
export interface UploadProductsPayload {
  __typename?: 'UploadProductsPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Error>>;
}

export interface UploadWorkflowModelInput {
  fileName: Scalars['String'];
  modelXml: Scalars['String'];
}

export interface UploadWorkflowModelPayload {
  __typename?: 'UploadWorkflowModelPayload';
  deploymentId: Scalars['String'];
  errors?: Maybe<Array<Error>>;
}

export interface UpsertUserSettingsInput {
  name: Scalars['String'];
  value: Scalars['String'];
}

export interface UpsertUserSettingsPayload {
  __typename?: 'UpsertUserSettingsPayload';
  errors?: Maybe<Array<Error>>;
  userSettings?: Maybe<UserSettings>;
}

export enum UsageContext {
  BOTH = 'BOTH',
  SHIP_FROM = 'SHIP_FROM',
  SHIP_TO = 'SHIP_TO'
}

export interface User {
  __typename?: 'User';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
}

export interface UserDefinedEventConnection {
  __typename?: 'UserDefinedEventConnection';
  edges: Array<Maybe<EventEdge>>;
  nodes: Array<Maybe<UserDefinedEventType>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface UserDefinedEventEdge {
  __typename?: 'UserDefinedEventEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UserDefinedEventType>;
}

export interface UserDefinedEventPayload {
  __typename?: 'UserDefinedEventPayload';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}

export interface UserDefinedEventType {
  __typename?: 'UserDefinedEventType';
  /** User Defined Event Type identifier */
  id: Scalars['String'];
  name: Scalars['String'];
}

export interface UserProfile {
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
}

export interface UserSettings {
  __typename?: 'UserSettings';
  name: Scalars['String'];
  value: Scalars['String'];
}

export enum UserType {
  EXTERNAL = 'EXTERNAL',
  INTERNAL = 'INTERNAL'
}

export interface Utilisation {
  __typename?: 'Utilisation';
  cargo?: Maybe<UtilisationCargo>;
  reportingDate?: Maybe<Scalars['Date']>;
  utilisation?: Maybe<Scalars['Float']>;
}

export interface UtilisationCargo {
  __typename?: 'UtilisationCargo';
  actualCbm?: Maybe<Scalars['Float']>;
  clientReference?: Maybe<Scalars['String']>;
  containerNumber?: Maybe<Scalars['String']>;
  containerType?: Maybe<ContainerTypeEnum>;
  zencargoReference?: Maybe<Scalars['String']>;
}

/** The connection type for Utilisation. */
export interface UtilisationConnection {
  __typename?: 'UtilisationConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<UtilisationEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Utilisation>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

/** An edge in a connection. */
export interface UtilisationEdge {
  __typename?: 'UtilisationEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Utilisation>;
}

export interface UtilisationTarget {
  __typename?: 'UtilisationTarget';
  containerType?: Maybe<ContainerTypeEnum>;
  supplierId: Scalars['String'];
  utilisationTarget?: Maybe<Scalars['Float']>;
  utilisationTargetId: Scalars['String'];
}

export interface ValueOfGoods {
  __typename?: 'ValueOfGoods';
  currency: Scalars['String'];
  value: Scalars['Float'];
}

export interface ValueOfGoodsInput {
  currency: Scalars['String'];
  value: Scalars['Float'];
}

export enum ValueTypeEnum {
  BOOLEAN = 'BOOLEAN',
  DOUBLE = 'DOUBLE',
  FLOAT = 'FLOAT',
  INT = 'INT',
  LONG = 'LONG',
  OBJECT = 'OBJECT',
  STRING = 'STRING'
}

export interface VehiclePropertyInput {
  name: VehiclePropertyNameEnum;
  value?: InputMaybe<Scalars['String']>;
}

export enum VehiclePropertyNameEnum {
  AIRLINE = 'AIRLINE',
  CARRIER_BOOKING_REFERENCE = 'CARRIER_BOOKING_REFERENCE',
  COURIER = 'COURIER',
  FLIGHT_NUMBER = 'FLIGHT_NUMBER',
  HAULIER_ID = 'HAULIER_ID',
  LICENSE_PLATE = 'LICENSE_PLATE',
  MASTER_AIRWAY_BILL = 'MASTER_AIRWAY_BILL',
  MASTER_BILL_OF_LADING = 'MASTER_BILL_OF_LADING',
  RAILWAY_COMPANY = 'RAILWAY_COMPANY',
  SCAC = 'SCAC',
  TRACKING_NUMBER = 'TRACKING_NUMBER',
  TRAILER_ID = 'TRAILER_ID',
  TYPE = 'TYPE',
  VESSEL_NAME = 'VESSEL_NAME',
  VOYAGE_NUMBER = 'VOYAGE_NUMBER'
}

export enum VehicleTypeEnum {
  STRAIGHT_TRUCK = 'STRAIGHT_TRUCK',
  TRAILER_3_5_T_BOX = 'TRAILER_3_5_T_BOX',
  TRAILER_3_5_T_CURTAIN_SIDED = 'TRAILER_3_5_T_CURTAIN_SIDED',
  TRAILER_7_5_T_BOX = 'TRAILER_7_5_T_BOX',
  TRAILER_7_5_T_CURTAIN_SIDED = 'TRAILER_7_5_T_CURTAIN_SIDED',
  TRAILER_7_5_T_SLIDING_ROOF = 'TRAILER_7_5_T_SLIDING_ROOF',
  TRAILER_13_6_M_BOX = 'TRAILER_13_6_M_BOX',
  TRAILER_13_6_M_CURTAIN_SIDED = 'TRAILER_13_6_M_CURTAIN_SIDED',
  TRAILER_13_6_M_HIAB = 'TRAILER_13_6_M_HIAB',
  TRAILER_13_6_M_MEGATRAILER = 'TRAILER_13_6_M_MEGATRAILER',
  TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED = 'TRAILER_13_6_M_MEGATRAILER_CURTAIN_SIDED',
  TRAILER_13_6_M_REFRIGERATED = 'TRAILER_13_6_M_REFRIGERATED',
  TRAILER_13_6_M_SLIDING_ROOF = 'TRAILER_13_6_M_SLIDING_ROOF',
  TRAILER_18_T_BOX = 'TRAILER_18_T_BOX',
  TRAILER_18_T_CURTAIN_SIDED = 'TRAILER_18_T_CURTAIN_SIDED',
  TRAILER_18_T_HIAB = 'TRAILER_18_T_HIAB',
  TRAILER_18_T_REFRIGERATED = 'TRAILER_18_T_REFRIGERATED',
  TRAILER_18_T_SLIDING_ROOF = 'TRAILER_18_T_SLIDING_ROOF',
  TRAILER_53_FT_BOXED = 'TRAILER_53_FT_BOXED',
  VAN_BOX = 'VAN_BOX',
  VAN_CURTAIN_SIDED = 'VAN_CURTAIN_SIDED',
  VAN_SLIDING_ROOF = 'VAN_SLIDING_ROOF'
}

export interface Volume {
  __typename?: 'Volume';
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface VolumeInput {
  unit: Scalars['String'];
  value: Scalars['Float'];
}

export interface VoyageDelay {
  __typename?: 'VoyageDelay';
  description: Scalars['String'];
  id: Scalars['String'];
  reason: Scalars['String'];
}

export interface VoyageDelayDataInput {
  description?: InputMaybe<Scalars['String']>;
  reason: Scalars['String'];
}

export interface VoyageMilestone {
  __typename?: 'VoyageMilestone';
  actual?: Maybe<LocalDateTimeType>;
  current: Scalars['Boolean'];
  delays: Array<VoyageDelay>;
  id: Scalars['String'];
  latestEstimate?: Maybe<LocalDateTimeType>;
  location?: Maybe<NetworksAssignableInterface>;
  name: VoyageMilestoneNameEnum;
  occurred: Scalars['Boolean'];
  originalEstimate?: Maybe<LocalDateTimeType>;
  portName?: Maybe<Scalars['String']>;
  primary: Scalars['Boolean'];
  updatedAt: RichDateTime;
  updatedBy?: Maybe<UpdatedBy>;
  vesselName?: Maybe<Scalars['String']>;
}

export enum VoyageMilestoneNameEnum {
  ARRIVED_AT_DESTINATION_TERMINAL = 'arrived_at_destination_terminal',
  CARGO_ABOARD = 'cargo_aboard',
  CARGO_UNLOADED = 'cargo_unloaded',
  COLLECTED = 'collected',
  DELIVERED = 'delivered',
  DEPARTED_ORIGIN_TERMINAL = 'departed_origin_terminal',
  GATE_IN = 'gate_in',
  GATE_OUT = 'gate_out',
  GATE_OUT_EMPTY = 'gate_out_empty'
}

export enum VoyageMilestoneTime {
  ACTUAL = 'ACTUAL',
  ESTIMATED = 'ESTIMATED'
}

export interface Warehouse {
  __typename?: 'Warehouse';
  address: Scalars['String'];
  countryCode: Scalars['String'];
  postalCode?: Maybe<Scalars['String']>;
}

export interface WarehousingAccountPermissions {
  __typename?: 'WarehousingAccountPermissions';
  canAccessRoute?: Maybe<AuthorizationResult>;
}

export interface WarehousingViewCalendarSlot {
  __typename?: 'WarehousingViewCalendarSlot';
  cargoId: Scalars['String'];
  cargoMode: CargoModeEnum;
  cargoType: Scalars['String'];
  clientReference?: Maybe<Scalars['String']>;
  containerNumber?: Maybe<Scalars['String']>;
  deliveryStatus: WarehousingViewDeliveryStatus;
  from: WarehousingViewDateTime;
  quantity: Scalars['Int'];
  to?: Maybe<WarehousingViewDateTime>;
  zencargoReference: Scalars['String'];
}

export interface WarehousingViewCargo {
  __typename?: 'WarehousingViewCargo';
  cargoMode: CargoModeEnum;
  cargoType: Scalars['String'];
  innerCargos?: Maybe<Array<WarehousingViewCargo>>;
  quantity: Scalars['Int'];
}

/** Warehousing Intake */
export interface WarehousingViewCargoAggregate {
  __typename?: 'WarehousingViewCargoAggregate';
  cargos?: Maybe<Array<WarehousingViewCargo>>;
  date: Scalars['ISO8601Date'];
}

export interface WarehousingViewDateTime {
  __typename?: 'WarehousingViewDateTime';
  date: Scalars['ISO8601Date'];
  time?: Maybe<Scalars['ISO8601Time']>;
}

export enum WarehousingViewDeliveryStatus {
  BOOKED = 'BOOKED',
  DELIVERED = 'DELIVERED',
  REQUESTED = 'REQUESTED'
}

export interface WarehousingViewLocation {
  __typename?: 'WarehousingViewLocation';
  id: Scalars['String'];
  location?: Maybe<AccountLocation>;
  name?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  networksOrgLoc?: Maybe<NetworksOrgLoc>;
  numberOfSlots: Scalars['Int'];
}

export interface WebhookHeader {
  __typename?: 'WebhookHeader';
  name: Scalars['String'];
  value: Scalars['String'];
}

export interface WebhookHeaderInput {
  name: Scalars['String'];
  value: Scalars['String'];
}

export interface WebhookInput {
  callbackUrl: Scalars['String'];
  contactEmail: Scalars['String'];
  headers?: InputMaybe<Array<WebhookHeaderInput>>;
  name: Scalars['String'];
}

export interface WebhookSubscriptionEvent {
  __typename?: 'WebhookSubscriptionEvent';
  targetId: Scalars['String'];
  targetType: WebhookSubscriptionTargetTypeEnum;
  topic: WebhookSubscriptionTopicEnum;
}

export enum WebhookSubscriptionTargetTypeEnum {
  BOOKING = 'BOOKING',
  CARGO = 'CARGO',
  LOT = 'LOT',
  PACKING_LIST = 'PACKING_LIST',
  PURCHASE_ORDER = 'PURCHASE_ORDER',
  SHIPMENT = 'SHIPMENT'
}

export enum WebhookSubscriptionTopicEnum {
  BOOKING_CREATED = 'BOOKING_CREATED',
  CARGO_CONTAINER_CONFIGURATION_CHANGED = 'CARGO_CONTAINER_CONFIGURATION_CHANGED',
  CARGO_CONTAINER_NUMBER_CHANGED = 'CARGO_CONTAINER_NUMBER_CHANGED',
  LOT_BOOKED = 'LOT_BOOKED',
  LOT_QUANTITY_CHANGED = 'LOT_QUANTITY_CHANGED',
  PACKING_LIST_LINES_CHANGED = 'PACKING_LIST_LINES_CHANGED',
  PURCHASE_ORDER_CARGO_READY_DATES_CHANGED = 'PURCHASE_ORDER_CARGO_READY_DATES_CHANGED',
  PURCHASE_ORDER_CLOSED = 'PURCHASE_ORDER_CLOSED',
  SHIPMENT_DELIVERY_ESTIMATE_CHANGED = 'SHIPMENT_DELIVERY_ESTIMATE_CHANGED',
  SHIPMENT_SAILING_SCHEDULE_CHANGED = 'SHIPMENT_SAILING_SCHEDULE_CHANGED',
  SHIPMENT_STAGE_CHANGED = 'SHIPMENT_STAGE_CHANGED'
}

export interface WebhookSubscriptionsWebhook {
  __typename?: 'WebhookSubscriptionsWebhook';
  account?: Maybe<Account>;
  callbackUrl: Scalars['String'];
  contactEmail: Scalars['String'];
  disabled: Scalars['Boolean'];
  headers?: Maybe<Array<WebhookHeader>>;
  id: Scalars['String'];
  name: Scalars['String'];
  topics: Array<WebhookSubscriptionTopicEnum>;
}

export interface Weight {
  __typename?: 'Weight';
  metric?: Maybe<WeightUnitEnum>;
  value?: Maybe<Scalars['Float']>;
}

export enum WeightUnitEnum {
  KG = 'kg',
  LBS = 'lbs'
}

export enum WithinDaysLabel {
  ARRIVING = 'ARRIVING',
  COLLECTING = 'COLLECTING',
  DELIVERING = 'DELIVERING',
  DEPARTING = 'DEPARTING'
}

export interface WithinTimeRange {
  __typename?: 'WithinTimeRange';
  dateRange: DateRange;
  eventType: BookingUpcomingEventEnum;
}

export interface WithinTimeRangeInput {
  dateRange: DateRangeInput;
  eventType: BookingUpcomingEventEnum;
}

export interface WorkflowBusinessKeyConnection {
  __typename?: 'WorkflowBusinessKeyConnection';
  edges: Array<Maybe<WorkflowBusinessKeyEdge>>;
  nodes: Array<Scalars['String']>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface WorkflowBusinessKeyEdge {
  __typename?: 'WorkflowBusinessKeyEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Scalars['String']>;
}

export interface WorkflowFormInput {
  __typename?: 'WorkflowFormInput';
  label: Scalars['String'];
  maxLength?: Maybe<Scalars['Int']>;
  minLength?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
}

export interface WorkflowFormMetaData {
  __typename?: 'WorkflowFormMetaData';
  formInputs?: Maybe<Array<Maybe<WorkflowFormInput>>>;
  submitButtonLabel?: Maybe<Scalars['String']>;
}

export enum WorkflowFormType {
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA'
}

export interface WorkflowModelConnection {
  __typename?: 'WorkflowModelConnection';
  edges: Array<Maybe<WorkflowModelEdge>>;
  nodes: Array<Maybe<WorkflowModelType>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface WorkflowModelEdge {
  __typename?: 'WorkflowModelEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<WorkflowModelType>;
}

/** Enums */
export enum WorkflowModelStatusEnum {
  ACTIVE = 'ACTIVE',
  DRAFT = 'DRAFT'
}

export interface WorkflowModelType {
  __typename?: 'WorkflowModelType';
  decisionModels?: Maybe<Array<Maybe<WorkflowModelType>>>;
  definitionKey: Scalars['String'];
  id: Scalars['String'];
  modelXml?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  status: WorkflowModelStatusEnum;
  triggers?: Maybe<Array<Maybe<UserDefinedEventType>>>;
  type: WorkflowModelTypeEnum;
  uploadedBy?: Maybe<NetworksContact>;
  uploadedDate?: Maybe<Scalars['DateTime']>;
  version?: Maybe<Scalars['Int']>;
}

export enum WorkflowModelTypeEnum {
  BPMN = 'BPMN',
  DMN = 'DMN'
}

export interface WorkflowModelsPayloadType {
  __typename?: 'WorkflowModelsPayloadType';
  activeModelCount?: Maybe<Scalars['Int']>;
  activeModels?: Maybe<WorkflowModelConnection>;
  draftModelCount?: Maybe<Scalars['Int']>;
  draftModels?: Maybe<WorkflowModelConnection>;
}

export interface WorkflowRuleConnection {
  __typename?: 'WorkflowRuleConnection';
  /** A list of edges. */
  edges: Array<Maybe<WorkflowRuleEdge>>;
  /** A list of nodes. */
  nodes: Array<Maybe<WorkflowRuleType>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** Total amount of elements. */
  totalCount: Scalars['Int'];
}

/** Inputs */
export interface WorkflowRuleDefinitionInput {
  id?: InputMaybe<Scalars['String']>;
  ruleDescription: Scalars['String'];
  ruleInputFields: Array<WorkflowRuleFieldInput>;
  ruleName: Scalars['String'];
  ruleOutputFields: Array<WorkflowRuleFieldInput>;
}

export interface WorkflowRuleDefinitionType {
  __typename?: 'WorkflowRuleDefinitionType';
  id: Scalars['String'];
  ruleDescription: Scalars['String'];
  ruleInputFields: Array<WorkflowRuleFieldType>;
  ruleName: Scalars['String'];
  ruleOutputFields: Array<WorkflowRuleFieldType>;
  rules?: Maybe<Array<Maybe<WorkflowRuleType>>>;
}

/** An edge in a connection. */
export interface WorkflowRuleEdge {
  __typename?: 'WorkflowRuleEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<WorkflowRuleType>;
}

export interface WorkflowRuleFieldInput {
  component: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
}

/** Types */
export interface WorkflowRuleFieldType {
  __typename?: 'WorkflowRuleFieldType';
  component: Scalars['String'];
  label: Scalars['String'];
  name: Scalars['String'];
}

export interface WorkflowRuleInput {
  id?: InputMaybe<Scalars['String']>;
  ruleDefinitionId?: InputMaybe<Scalars['String']>;
  ruleInput: RuleInputObjectInput;
  ruleOutput: RuleOutputObjectInput;
}

export interface WorkflowRuleType {
  __typename?: 'WorkflowRuleType';
  id: Scalars['String'];
  ruleDefinition: WorkflowRuleDefinitionType;
  ruleDefinitionId: Scalars['String'];
  ruleInput: RuleInputObjectType;
  ruleOutput: RuleOutputObjectType;
}

export interface WorkflowRulesInputObjectFiltersInput {
  accountId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryOfDestination?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  countryOfOrigin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  destination?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  modeOfTransport?: InputMaybe<Array<InputMaybe<ModeOfTransport>>>;
  origin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  shipmentType?: InputMaybe<Array<InputMaybe<Shipment>>>;
}

export interface WorkflowRulesOutputObjectFiltersInput {
  customerOperationsCoordinatorId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  customsOperationsCoordinatorId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  destinationOperationsCoordinatorId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  originAgentId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  originOperationsCoordinatorId?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}

export interface WorkflowTaskConnection {
  __typename?: 'WorkflowTaskConnection';
  edges: Array<Maybe<WorkflowTaskEdge>>;
  nodes: Array<WorkflowTaskType>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int'];
}

export interface WorkflowTaskDetailsPayloadType {
  __typename?: 'WorkflowTaskDetailsPayloadType';
  activeTaskCount?: Maybe<Scalars['Int']>;
  activeTasks?: Maybe<WorkflowTaskConnection>;
  allOpenTaskCount?: Maybe<Scalars['Int']>;
  allUnassignedTaskCount?: Maybe<Scalars['Int']>;
  completedTaskCount?: Maybe<Scalars['Int']>;
  completedTasks?: Maybe<WorkflowTaskConnection>;
}

export interface WorkflowTaskEdge {
  __typename?: 'WorkflowTaskEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<WorkflowModelType>;
}

/** The Task Type for representing Camunda user Tasks. */
export interface WorkflowTaskType {
  __typename?: 'WorkflowTaskType';
  assignedDate: Scalars['DateTime'];
  assignedGroups: Array<Scalars['String']>;
  assignee?: Maybe<NetworksContact>;
  canCompleteTask?: Maybe<Scalars['Boolean']>;
  canDeleteTask?: Maybe<Scalars['Boolean']>;
  completedDate?: Maybe<Scalars['DateTime']>;
  description: Scalars['String'];
  dueDate?: Maybe<Scalars['DateTime']>;
  /** Form data for dynamic rendering. */
  formMetaData?: Maybe<WorkflowFormMetaData>;
  name: Scalars['String'];
  priority: Scalars['Int'];
  taskId: Scalars['String'];
  workflowTaskVariables?: Maybe<Array<KeyValuePair>>;
  /** Reference for workflow task. */
  zencargoReference?: Maybe<Scalars['String']>;
}

export interface Workflows {
  __typename?: 'Workflows';
  canAccessRoute: AuthorizationResult;
}

export interface ZencargoNetwork {
  __typename?: 'ZencargoNetwork';
  canAccessRoute: AuthorizationResult;
}

export interface BookingDocumentsPermissionsInputType {
  agentForwarder?: InputMaybe<Scalars['Boolean']>;
  customerUser?: InputMaybe<Scalars['Boolean']>;
  manufacturer?: InputMaybe<Scalars['Boolean']>;
}

export interface CurrentUserFeatureFlag {
  __typename?: 'current_user_feature_flag';
  enabled: Scalars['Boolean'];
  name: Scalars['String'];
}

export interface CurrentUserProfile {
  __typename?: 'current_user_profile';
  avatarUrl?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  slackHandle?: Maybe<Scalars['String']>;
}
