import type { RateCard } from '../RateCardDetails/types';
import { ChargeClassification } from './types';

const prepareRateCardCharges = (charges: RateCard['charges']): RateCard['charges'] => {
  return charges.map((charge: RateCard['charges'][0]) => {
    if (charge.chargeClassification === ChargeClassification.FREIGHT) {
      return {
        ...charge,
        unitPrice: 0
      };
    }

    return charge;
  });
};

export const prepareRateCard = (rateCard: RateCard): RateCard => {
  return {
    ...rateCard,
    charges: prepareRateCardCharges(rateCard?.charges),
    endDate: '',
    startDate: ''
  };
};
